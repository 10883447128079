import React, { memo } from 'react';
import * as RadixSlider from '@radix-ui/react-slider';


type Props = {
  onValueChange: (value: number[]) => void;
  value: number[];
  wrapperClassName?: string;
  colorClass?: string;
  trackClassName?: string;
  min?: number;
  max?: number;
  step?: number;
}

const Slider = memo(({
  onValueChange,
  value,
  wrapperClassName,
  trackClassName,
  colorClass,
  min = 0,
  max = 100,
  step = 1,
}: Props) => {
  return (
    <RadixSlider.Root
      className={`SliderRoot ${wrapperClassName}`}
      value={value}
      defaultValue={value}
      onValueChange={onValueChange}
      max={max}
      step={step}
      min={min}
      aria-label="Volume"
    >
      <RadixSlider.Track className={`SliderTrack ${trackClassName}`}>
        <RadixSlider.Range className={`SliderRange background-${colorClass}`} />
      </RadixSlider.Track>
      <RadixSlider.Thumb className={`SliderThumb border-${colorClass} background-${colorClass}`} />
    </RadixSlider.Root>
  );
})

export default Slider;