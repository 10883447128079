import React from "react";
import { useLinkToolbarButton, useLinkToolbarButtonState } from '@udecode/plate-link/react';

import ToolbarButton from '../../../shared/ToolbarControls/ToolbarButton';

const Link: React.FC = () => {
  const state = useLinkToolbarButtonState();
  const { props } = useLinkToolbarButton(state);
  return <ToolbarButton onClick={props.onClick} iconName={'link'} />
}

export default Link;