import React, { memo, useState } from "react"

import TooltipIcon from "../../../../shared/ToolTip/TooltipIcon"

type Props = {
  prompt: string
}

const PromptItem: React.FC<Props> = memo(({ prompt }) => {
  const [tooltipText, setTooltipText] = useState('Copy prompt');
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(prompt)
      .then(() => {
        setTooltipText('Copied!');
        setTooltipOpen(true);
        setTimeout(() => {
          setTooltipText('Copy prompt');
          setTooltipOpen(false);
        }, 5000);
      });
  };
  return (
    <div className='dl-item mb-4 last:mb-0' id="prompt_tooltip_anchor">
      <div className="flex gap-2">
        <div className="flex items-center text-xs text-gray-500 font-semibold uppercase">
          Prompt
        </div>
        <TooltipIcon
          tooltip={tooltipText}
          side="top"
          container="prompt_tooltip_anchor"
          icon="faCopy"
          onClick={handleCopy}
          tooltipOpen={tooltipOpen}
          setTooltipOpen={setTooltipOpen}
          iconWrapperClassName='btn btn-small btn-white flex justify-center w-6 h-6 hover-only-action text-gray-600'
        />
      </div>
      <div className="text-gray-800 p-0 m-0 flex"> {prompt} </div>
    </div>
  )
})

export default PromptItem
