import React, { useEffect } from 'react';
import WebFont from 'webfontloader';

import { useAppSelector } from '../store/hooks/redux-hooks';
import { generateGlobalColorVariables, generateGlobalFontVariables } from '../utils/variables.util';


type Props = {
  children: React.ReactNode
}

const GlobalStylesLoader = ({ children }: Props) => {
  const page_fonts = useAppSelector(state => state.page_fonts);
  const page_colors = useAppSelector(state => state.page_colors);
  const { paragraph_font, paragraphAvailableWeights, header_font, headerAvailableWeights } = page_fonts
  useEffect(() => {
    // This should prevent unlimited amount of connections to google fonts
    const removeGoogleFontsLinks = () => {
      const links = document.head.querySelectorAll('link[rel="stylesheet"][href^="https://fonts.googleapis.com"]');
      links.forEach(link => {
        document.head.removeChild(link);
      });
    };
    removeGoogleFontsLinks();
    WebFont.load({
      google: {
        families: ['Libre Franklin:100,200,300,400,500,600,700,800,900',
          `${paragraph_font}:${paragraphAvailableWeights.join(',')}`,
          `${header_font}:${headerAvailableWeights.join(',')}`
        ]
      },
      classes: false,
    });
  }, [
    paragraph_font,
    paragraphAvailableWeights,
    header_font,
    headerAvailableWeights
  ]);
  const fontStyles = generateGlobalFontVariables(page_fonts)
  const colorStyles = generateGlobalColorVariables(page_colors.activePalette)
  return (
    <div className='style_variables_injection' style={{ ...fontStyles, ...colorStyles }}>
      {children}
    </div>
  )
}

export default GlobalStylesLoader