import React, { memo } from "react"

import Icon from "../Icon/Icon"
import Tooltip from "./Tooltip"
import FeedbackCounter from "../FeedbackCounter/FeedbackCounter"

type Props = {
  tooltip: string
  side: "top" | "right" | "bottom" | "left" | undefined
  container: string
  icon: string
  iconWrapperClassName?: string
  iconClassName?: string
  iconWrapperStyle?: React.CSSProperties
  onClick?: () => void
  tooltipOpen?: boolean
  setTooltipOpen?: (open: boolean) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  counter?: number
}

const TooltipIcon: React.FC<Props> = memo(({
  tooltip, side, container, icon, iconWrapperClassName, iconClassName, iconWrapperStyle,
  onClick, setTooltipOpen, tooltipOpen, onMouseEnter, onMouseLeave, counter
}) => {
  return (
    <Tooltip
      content={tooltip}
      side={side}
      container={container}
      open={tooltipOpen}
      onOpenChange={setTooltipOpen}
    >
      <div
        className={iconWrapperClassName}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={iconWrapperStyle}
      >
        <Icon icon={icon} className={iconClassName} shouldBeHidden={true} />
        {counter && <FeedbackCounter counter={counter} />}
      </div>
    </Tooltip>
  )
})

export default TooltipIcon