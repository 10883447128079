import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import axios from 'axios';

// Code: https://github.com/stimulus-components/stimulus-sortable/blob/master/src/index.ts
// Docs: https://www.stimulus-components.com/docs/stimulus-sortable/
// Demo: https://stimulus-sortable.stimulus-components.com/
export default class extends Controller {
  static values = {
    resourceName: String,
    parentName: String,
    paramName: {
      type: String,
      default: 'position'
    },
    responseKind: {
      type: String,
      default: 'html'
    },
    animation: Number,
    handle: String
  }

  initialize() {
    this.onUpdate = this.onUpdate.bind(this)
  }

  connect() {
    this.sortable = new Sortable(this.element, {
      ...this.defaultOptions,
      ...this.options
    })
  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = undefined
  }

  async onUpdate({ item, _newIndex }) {
    const sortableUrl = this.element.dataset.sortableUpdateUrl

    // Loop through the li elements and get the id and position
    // I want the submitted data to look like:
    // room: page_attributes: [{ id: id, position: 1 }, { id: id, position: 2 }]
    const nestedAttributes = []
    this.element.querySelectorAll('li').forEach((li, index) => {
      const id = li.dataset.id
      nestedAttributes.push({ id: id, position: index })

      // find data-target="display-position" and update the position
      const displayPosition = li.querySelector('[data-target="display-position"]')
      if (displayPosition) {
        displayPosition.innerText = index + 1
      }
    })

    const resourceParam = this.resourceNameValue
    const parentParam = this.parentNameValue
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    axios({
      method: "put",
      url: `${sortableUrl}.json`,
      params: {
        [parentParam]: {
          [resourceParam]: nestedAttributes
        }
      },
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      },
      responseType: 'json'
    })
  }

  get options() {
    return {
      animation: this.animationValue || this.defaultOptions.animation || 150,
      handle: this.handleValue || this.defaultOptions.handle || undefined,
      onUpdate: this.onUpdate
    }
  }

  get defaultOptions() {
    return {}
  }
}
