import React, { memo, useState } from 'react';

import { TemplateVariable } from '../../../store/reducers/api/api.types';
import TooltipIcon from '../../../shared/ToolTip/TooltipIcon';

type Props = {
  variable: TemplateVariable
}


const TemplateVariableItem = memo(({ variable }: Props) => {
  const [tooltipText, setTooltipText] = useState('Copy');
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(variable.value)
      .then(() => {
        setTooltipText('Copied!');
        setTooltipOpen(true);
        setTimeout(() => {
          setTooltipText('Copy')
          setTooltipOpen(false);
        }, 5000);
      });
  };

  return (
    <div
      id='add_template_variable_tooltip_anchor'
      className="listed-item border-b border-gray-200 px-4 py-2 flex flex-row justify-between cursor-pointer hover:bg-gray-200 last:border-b-0 last:rounded-b-xl"
      onClick={handleCopy}
    >
      <div>
        <div className="font-semibold text-sm text-gray-800">{variable.name}</div>
        <div className="text-xs text-gray-500">{variable.about}</div>
      </div>
      <div className="hidden-button-width-holder">
        <TooltipIcon
          tooltip={tooltipText}
          side="top"
          container="add_template_variable_tooltip_anchor"
          icon="faCopy"
          onClick={handleCopy}
          tooltipOpen={tooltipOpen}
          setTooltipOpen={setTooltipOpen}
          iconWrapperClassName='btn btn-icon btn-white rounded-full hover-only-action text-gray-600'
        />
      </div>
    </div>
  );
});

export default TemplateVariableItem;
