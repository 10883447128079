import React, { useEffect, useState } from "react";
import { findNode } from "@udecode/plate-common";
import { PlateElement, isEditorReadOnly, withRef } from "@udecode/plate-common/react";

import embedUrlPatterns, { EmbedPlatform, EmbedConfig } from "./config/config";
import { PlateElementEmbed } from "../../../components/plate-config/Plugins/Embed/Embed.plugin";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import SourceSelector from "./SourceSelector/SourceSelector";
import EmbedIframe from "./EmbedIframe/EmbedIframe";

const EmbedVoidElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const block = element as PlateElementEmbed
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const isReadOnly = isEditorReadOnly(editor)
    const [platform, setPlatform] = useState<EmbedConfig | null>(null)
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const [url, setUrl] = useState('');

    useEffect(() => {
      if (block?.url && !isEditing) {
        setUrl(block.url)
        validatePlatformUrl(block.url)
      }
    }, [])

    const validatePlatformUrl = (url: string) => {
      for (let config of embedUrlPatterns) {
        const match = url.match(config.pattern);
        if (match) {
          const isGoogleSheets = config.name === EmbedPlatform.GoogleSheets;
          if (isGoogleSheets) {
            const parsedUrl = new URL(url);
            if (parsedUrl.searchParams.has('output')) {
              setError('Unsupported URL type');
              return;
            }
          }
          setPlatformHandler({ ...config, link: url })
          editor.setNodes({ url } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
          setEditMode(false);
          setError(null);
          return;
        }
      }
      setError('Unsupported URL type');
    }

    const onSelectorConfirm = () => {
      if (url) {
        validatePlatformUrl(url)
      } else {
        editor.setNodes({ url: null } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
        setError('Please enter a valid URL')
      }
    };

    const onSelectorInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(e.target.value)
      setError(null)
    };
    const onElementDeleteHandle = () => editor.removeNodes({ at: [], match: (n: any) => n.id === element.id })
    const setEditMode = (mode: boolean) => setIsEditing(mode);
    const setPlatformHandler = (platform: EmbedConfig) => setPlatform(platform);

    const nodePath = findNode(editor, { at: [], match: { id: element.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;

    if (isReadOnly) return platform ?
      <EmbedIframe
        ref={ref}
        element={block}
        className={className}
        isFirstChild={isFirstChild}
        isLastChild={isLastChild}
        setEditMode={setEditMode}
        plateElProps={props}
        isReadOnly={true}
        platform={platform}
      >
        {children}
      </EmbedIframe >
      : null

    return (
      <>
        {!isEditing && platform
          ? <EmbedIframe
            ref={ref}
            element={block}
            className={className}
            isFirstChild={isFirstChild}
            isLastChild={isLastChild}
            setEditMode={setEditMode}
            plateElProps={props}
            isReadOnly={isReadOnly}
            platform={platform}
          >
            {children}
          </EmbedIframe >

          : <SourceSelector
            ref={ref}
            element={block}
            className={className}
            isFirstChild={isFirstChild}
            isLastChild={isLastChild}
            plateElProps={props}
            onSelectorConfirm={onSelectorConfirm}
            onSelectorInputChanged={onSelectorInputChanged}
            onElementDeleteHandle={onElementDeleteHandle}
            error={error}
            url={url}
          >
            {children}
          </SourceSelector >
        }
      </>
    )
  })

export default EmbedVoidElement
