import { TElement, TNodeEntry, addRangeMarks, findNode } from '@udecode/plate-common';
import { createPlatePlugin, PlateEditor } from '@udecode/plate-common/react';

import { textNodeTypes } from '../../../utils/plate.util';
import { SectionPlugin } from '../Section/Section.plugin';

const extendEditor = ({ editor }) => {

  const { normalizeNode } = editor as PlateEditor;

  editor.normalizeNode = ([node, path]: TNodeEntry) => {
    // If section node has font_size property we need to add font_size marks to all text nodes inside the section
    if (textNodeTypes.includes(node.type as string)) {
      const textNode = node as TElement;
      const section = findNode(editor, { at: [path[0]], match: { type: SectionPlugin.key } });
      if (section) {
        if (section[0].font_size) {
          textNode.children.forEach((child, index) => {
            if (child.font_size) {
              return;
            }
            addRangeMarks(editor, { font_size: section[0].font_size }, { at: [...path, index] });
          });
        }
      }
    }
    normalizeNode([node, path]);
  };

  return editor
}

export const TextSizeLeafPlugin = createPlatePlugin({
  key: 'font_size',
  node: {
    isLeaf: true,
  },
  extendEditor: extendEditor,
});