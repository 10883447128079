import React, { useState } from "react"
import { useEditorRef } from "@udecode/plate-common/react"

import LockScreenBtn from "./LockScreenBtn/LockScreenBtn"
import LockScreenQuestion from "./LockScreenQuestion/LockScreenQuestion"
import EditableField from "../../../shared/EditableField/EditableField"
import { PlateAssessmentElement } from "../../../plate-config/Plugins/Assessment/Assessment.plugin"
import { useAppSelector } from "../../../store/hooks/redux-hooks"
import { StoreCalcVariable } from "../../../store/reducers/page_calc_variables/page_calc_variables"
import { textColorFromColorClass } from "../../../utils/color.util"


type Props = {
  element: PlateAssessmentElement,
  lockScreenToggle: () => void,
  trackAssessmentUnlocked: (answer: number) => void,
  isEditorReadOnly: boolean,
  calc_variables: StoreCalcVariable[]
}

const LockScreen = ({ element, lockScreenToggle, trackAssessmentUnlocked, isEditorReadOnly, calc_variables }: Props) => {
  const editor = useEditorRef()
  const { isMobilePreviewActive } = useAppSelector(state => state.page_addendums)
  const [newVarValue, setNewVarValue] = useState<number | null>(null)
  const colors = useAppSelector(state => state.page_colors.activePalette.colors)
  const textColor = textColorFromColorClass(element.primary_color, colors)

  const onRoiLockedMessageChange = (roi_locked_message: string) =>
    editor.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          roi_locked_message
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onRoiLockedLabelChange = (roi_locked_label: string) =>
    editor.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          roi_locked_label
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onRoiLockedVariableIdChange = (roi_locked_variable_id: string) =>
    editor.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          roi_locked_variable_id
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onRoiLockedButtonColorChange = (roi_locked_button_color: string) =>
    editor.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          roi_locked_button_color
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onRoiLockedButtonTextColorChange = (roi_locked_button_text_color: string) =>
    editor.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          roi_locked_button_text_color
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onRoiLockedButtonTextChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    editor.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          roi_locked_button_text: e.target.value
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  return (
    <div className={`${isMobilePreviewActive ? 'p-6' : 'p-12'} flex flex-col items-center justify-between h-full editor-assessment-container`}>
      <EditableField
        placeholder={isEditorReadOnly ? '' : "Calculate Your ROI"}
        value={element.assessment_detail_attributes.roi_locked_message || ''}
        onChange={onRoiLockedMessageChange}
        wrapperClassName="w-[100%]"
        inputClassName={`text-xl text-3xl italic inline-content-editable-input text-${textColor} header-font-family`}
        styleOptions={{ textAlign: 'center' }}
        disabled={isEditorReadOnly}
      />
      <EditableField
        placeholder={isEditorReadOnly ? '' : "What key question do you want to ask"}
        value={element.assessment_detail_attributes.roi_locked_label || ''}
        onChange={onRoiLockedLabelChange}
        wrapperClassName="w-[100%]"
        inputClassName={`text-center text-xl italic inline-content-editable-input text-${textColor} paragraph-font-family`}
        styleOptions={{ textAlign: 'center' }}
        disabled={isEditorReadOnly}
      />
      <LockScreenQuestion
        element={element}
        onRoiLockedVariableIdChange={onRoiLockedVariableIdChange}
        calc_variables={calc_variables}
        newVarValue={newVarValue}
        setNewVarValue={setNewVarValue}
        isEditorReadOnly={isEditorReadOnly}
      />
      <LockScreenBtn
        element={element}
        calc_variables={calc_variables}
        onRoiLockedButtonColorChange={onRoiLockedButtonColorChange}
        onRoiLockedButtonTextColorChange={onRoiLockedButtonTextColorChange}
        onRoiLockedButtonTextChange={onRoiLockedButtonTextChange}
        newVarValue={newVarValue}
        lockScreenToggle={lockScreenToggle}
        trackAssessmentUnlocked={trackAssessmentUnlocked}
        isEditorReadOnly={isEditorReadOnly}
      />
    </div>
  )
}

export default LockScreen