import React from 'react';

export const ColumnLabel = ({ widths }) => (
  <div className={`w-16 h-9 p-1 rounded flex gap-1`}>
    {widths.map((width, index) => (
      <div
        key={`key-${width}-${index}`}
        className={`h-7 border border-black rounded`}
        style={{ width: `${width}px` }}
      />
    ))}
  </div>
);

export const layoutConfigs = {
  2: [
    { name: '50/50', widths: [26, 26] },
    { name: '30/70', widths: [15, 37] },
    { name: '70/30', widths: [37, 15] },
    { name: '20/80', widths: [11, 41] },
    { name: '80/20', widths: [41, 11] },
  ],
  3: [
    { name: '33/33/33', widths: [17, 17, 17] },
    { name: '25/25/50', widths: [13, 13, 26] },
    { name: '50/25/25', widths: [26, 13, 13] },
    { name: '25/50/25', widths: [13, 26, 13] },
  ],
  4: [
    { name: '25/25/25/25', widths: [10, 10, 10, 10] },
  ]
};

export const columnLayoutModes = Object.keys(layoutConfigs).reduce((acc, key) => {
  acc[key] = layoutConfigs[key].map(layout => ({
    ...layout,
    label: <ColumnLabel widths={layout.widths} />
  }));
  return acc;
}, {});

export const layoutConfigStyleTypes = Object.keys(layoutConfigs).reduce((acc, key) => {
  acc[key] = layoutConfigs[key].map(config => config.name);
  return acc;
}, {});

export const styleCheckArr: string[] = Object.keys(layoutConfigs).reduce((accumulatedArray, key) => {
  return accumulatedArray.concat(layoutConfigs[key]);
}, []);