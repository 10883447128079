import React, { useEffect } from 'react';
import { withRef } from '@udecode/cn';
import { findNode } from '@udecode/plate-common';
import { PlateElement, isEditorReadOnly } from '@udecode/plate-common/react';
import { useSelected } from 'slate-react';

import { Popover, PopoverContent, PopoverAnchor } from "../../shared/Popover/Popover";
import { PlateColumnGroupElement } from '../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin';
import { PlateSectionElement } from '../../plate-config/Plugins/Section/Section.plugin';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import { createDefaultColumnElement } from '../../plate-config/Plugins/ColumnGroup/Column.plugin';
import ToolbarButton from '../../shared/ToolbarControls/ToolbarButton';
import ToolbarSeparator from '../../shared/ToolbarControls/ToolbarSeparator';
import ToolbarDropdown from '../../shared/ToolbarControls/ToolbarDropdown';
import { columnLayoutModes } from './ColumnLayouts/ColumnLayouts';

export const ColumnGroupElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const block = element as PlateColumnGroupElement
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
    const isMobilePreviewActive = useAppSelector(state => state.page_addendums.isMobilePreviewActive)

    const selected = useSelected();
    const isReadOnly = isEditorReadOnly(editor)

    useEffect(() => {
      editor.setNodes({ selected } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
    }, [selected])

    const isChildMenuOpen = element.children.map(child => child.menuOpen).includes(true);
    const childAmount = block.children.length
    const node = findNode(editor, { at: [], match: (n: any) => n.id === element.id })!
    const nodePath = node[1]
    const section = editor.children[nodePath[0]] as PlateSectionElement
    const sectionChildren = section.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;

    const onTwoColumnsHandle = (): void => {
      if (childAmount === 2) return;
      if (childAmount >= 3) {
        element.children.slice(2, childAmount).forEach((child: any) => {
          editor.removeNodes({ at: [], match: (n: any) => n.id === child.id });
        });
        editor.setNodes({ style: '50/50' } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
      }
    };

    const onThreeColumnsHandle = (): void => {
      if (childAmount === 3) return;
      switch (childAmount) {
        case 2:
          editor.insertNodes(createDefaultColumnElement(), { at: [...nodePath, block.children.length] });
          break;
        case 4:
          editor.removeNodes({ at: [], match: (n: any) => n.id === element.children[3].id });
          break;
      }
      editor.setNodes({ style: '33/33/33' } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
    };

    const onFourColumnsHandle = (): void => {
      if (childAmount === 4) return;
      const insertCount = 4 - childAmount;
      for (let i = 0; i < insertCount; i++) {
        editor.insertNodes(createDefaultColumnElement(), { at: [...nodePath, block.children.length + i] });
      }
      editor.setNodes({ style: '25/25/25/25' } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
    };

    const onDeleteColumnsHandle = () =>
      editor.removeNodes({ at: [], match: (n: any) => n.id === element.id })
    const onLayoutModeHandle = (style: string) =>
      editor.setNodes({ style } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
    const preventDefault = (e: Event) => e.preventDefault();

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={element}
        data-id={block.id}
        data-plate-selectable
        className={`
        ${className} 
        page-block group-column-block toolbar-z-index-control py-2 px-[22px] mx-[-31px] rounded-lg group/col_group_wrapper
        transition-all duration-300 ease-in-out
        ${isFirstChild ? 'first-section-child' : ""} 
        ${isLastChild ? 'last-section-child' : ""}
        ${!isReadOnly
            ? `${selected
              ? 'border-blue-500/60 border hover:border-blue-500/60'
              : 'hover:border-blue-500/30 border border-transparent'}`
            : ''
          }     
        `}
        {...props}
      >
        <Popover open={isReadOnly ? false : isChildMenuOpen ? false : selected} >
          <PopoverAnchor className={`flex w-full gap-[8px] ${isMobilePreviewActive ? "flex-col" : ""}`}>
            {children}
          </PopoverAnchor>
          <PopoverContent
            onOpenAutoFocus={preventDefault}
            onCloseAutoFocus={preventDefault}
            side='top'
            sideOffset={12}
            align='center'
            className="block_toolbar"
          >
            <ToolbarButton
              onClick={onTwoColumnsHandle}
              disabled={childAmount === 2}
              iconName='twoColumn'
              isActive={childAmount === 2}
            />
            <ToolbarButton
              onClick={onThreeColumnsHandle}
              disabled={childAmount === 3}
              iconName='threeColumn'
              isActive={childAmount === 3}
            />
            <ToolbarButton
              onClick={onFourColumnsHandle}
              disabled={childAmount === 4}
              iconName='fourColumn'
              isActive={childAmount === 4}
            />
            <ToolbarSeparator />
            <ToolbarDropdown
              onOptionSet={onLayoutModeHandle}
              label='Layout'
              activeOption={block.style}
              data={columnLayoutModes[element.children.length]}
              disabled={childAmount === 4}
            />
            <ToolbarSeparator />
            <ToolbarButton
              onClick={onDeleteColumnsHandle}
              iconName='delete'
              type='delete'
            />
          </PopoverContent>
        </Popover>
      </PlateElement>
    );
  }
);


