import { Controller } from "@hotwired/stimulus"
import noUiSlider from "nouislider/dist/nouislider.js"

export default class extends Controller {
  static targets = ["slider"]

  connect() {
    const minValue = parseInt(this.element.dataset.sliderMin, 10)
    const maxValue = parseInt(this.element.dataset.sliderMax, 10)
    const currentValue = parseInt(this.element.dataset.sliderValue, 10)
    const stepValue = parseInt(this.element.dataset.sliderStep, 10)

    noUiSlider.create(this.sliderTarget, {
      start: currentValue,
      connect: "lower",
      range: {
        'min': minValue,
        'max': maxValue
      },
      step: stepValue,
      tooltips: {
        to: function (value) {
          return Math.round(value)
        }
      },
      format: {
        to: function (value) {
          return Math.round(value);
        },
        from: function (value) {
          return Number(value);
        }
      }
    })

    this.sliderTarget.noUiSlider.on('change', (values, handle) => {
      this.submitValue(values[handle])
    })
  }

  submitValue(value) {
    const form = this.element.querySelector('form')
    const input = form.querySelector('input[name="contacts_count"]')
    input.value = value
    form.requestSubmit()
  }
}
