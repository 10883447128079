import React from "react";

export const Layout4ColumnIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#000000"} fill={"none"} {...props}>
    <path d="M20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.75 2.5V21.5" stroke="currentColor" strokeWidth="1.5" />
    <path d="M12 2.5V21.5" stroke="currentColor" strokeWidth="1.5" />
    <path d="M17.25 2.5V21.5" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

export const ellipse = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16px" height="16px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="layer1">
      <path d="M 10 3 C 7.5494704 3 5.3212279 3.7550444 3.6855469 5.0058594 C 2.0498658
        6.2566743 1 8.0283922 1 10 C 1 11.971608 2.0498658 13.743326 3.6855469 14.994141
        C 5.3212279 16.244956 7.5494704 17 10 17 C 12.45053 17 14.678772 16.244956 16.314453
        14.994141 C 17.950134 13.743326 19 11.971608 19 10 C 19 8.0283922 17.950134 6.2566743 
        16.314453 5.0058594 C 14.678772 3.7550444 12.45053 3 10 3 z M 10 4 C 12.243891 4 14.266317 
        4.6990589 15.707031 5.8007812 C 17.147745 6.9025036 18 8.3817569 18 10 C 18 11.618243 17.147745
        13.097496 15.707031 14.199219 C 14.266317 15.300941 12.243891 16 10 16 C 7.7561092 16 5.7336826 15.300941
        4.2929688 14.199219 C 2.8522549 13.097496 2 11.618243 2 10 C 2 8.3817569 2.8522549 6.9025036 4.2929688 
        5.8007812 C 5.7336826 4.6990589 7.7561092 4 10 4 z " stroke="#000000" strokeWidth="0.5" />
    </g>
  </svg>
);

export const parallelogram = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16px" height="16px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M41.2796 8H15.4704C14.5956 8 13.8223 8.5685 13.5614 9.40345L4.81142 37.4035C4.40897 38.6913
     5.3711 40 6.72038 40H32.5296C33.4044 40 34.1777 39.4315 34.4386 38.5965L43.1886 10.5965C43.591 9.30869 42.6289 8 41.2796 8Z" stroke="#000000" strokeWidth="3" />
  </svg>
);

export const sizeSmall = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2.5" y="2.5" width="11" height="11" rx="1.5" stroke="#000000" />
    <path d="M9.6444 6.20047C9.4844 6.02714 9.28773 5.8738 9.0544 5.74047C8.82107 5.60714 8.50107 5.54047
      8.0944 5.54047C7.64773 5.54047 7.30773 5.62714 7.0744 5.80047C6.84107 5.9738 6.7244 6.2038 6.7244 6.49047C6.7244
      6.5838 6.7444 6.6838 6.7844 6.79047C6.83107 6.89714 6.91773 7.0038 7.0444 7.11047C7.17773 7.21047 7.36773 7.30047
      7.6144 7.38047L9.1544 7.84047C9.76773 8.02047 10.2077 8.29047 10.4744 8.65047C10.7477 9.0038 10.8844 9.41714 10.8844 
      9.89047C10.8844 10.3438 10.7677 10.7371 10.5344 11.0705C10.3011 11.4038 9.97107 11.6605 9.5444 11.8405C9.11773 12.0138 
      8.60773 12.1005 8.0144 12.1005C7.58107 12.1005 7.1744 12.0505 6.7944 11.9505C6.42107 11.8505 6.08773 11.7038 5.7944
      11.5105C5.50773 11.3171 5.2744 11.0871 5.0944 10.8205C5.06107 10.7671 5.04773 10.7205 5.0544 10.6805C5.06773 10.6338 
      5.10107 10.5905 5.1544 10.5505L5.7844 10.1105C5.8644 10.0638 5.9244 10.0438 5.9644 10.0505C6.01107 10.0571 6.05107 
      10.0805 6.0844 10.1205C6.25107 10.3338 6.4144 10.5105 6.5744 10.6505C6.74107 10.7838 6.93107 10.8838 7.1444 10.9505C7.35773
      11.0171 7.61773 11.0505 7.9244 11.0505C8.39107 11.0505 8.7644 10.9738 9.0444 10.8205C9.3244 10.6605 9.4644 10.4138 9.4644
      10.0805C9.4644 9.94714 9.4344 9.82714 9.3744 9.72047C9.32107 9.60714 9.22773 9.5038 9.0944 9.41047C8.96773 9.31714 8.78107 
      9.23047 8.5344 9.15047L7.0244 8.69047C6.63773 8.57047 6.31773 8.41047 6.0644 8.21047C5.81773 8.0038 5.63107 7.76714 5.5044
      7.50047C5.37773 7.2338 5.3144 6.95714 5.3144 6.67047C5.3144 6.2438 5.42773 5.86714 5.6544 5.54047C5.88107 5.20714 6.20107 
      4.94714 6.6144 4.76047C7.02773 4.5738 7.51107 4.48047 8.0644 4.48047C8.4244 4.48047 8.76107 4.5238 9.0744 4.61047C9.3944 4.69047
      9.68107 4.80714 9.9344 4.96047C10.1944 5.1138 10.4077 5.29714 10.5744 5.51047C10.6011 5.5438 10.6211 5.58047 10.6344 5.62047C10.6477
      5.6538 10.6277 5.6938 10.5744 5.74047L9.8644 6.27047C9.83107 6.29714 9.79773 6.30714 9.7644 6.30047C9.73773 6.28714 9.69773 
      6.2538 9.6444 6.20047Z" fill="#000000" />
  </svg>
)

export const sizeMedium = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2.5" y="2.5" width="11" height="11" rx="1.5" stroke="#000000" />
    <path d="M4.31109 12.0001C4.18443 12.0001 4.12109 11.9334 4.12109 11.8001V4.75008C4.12109 4.63674 4.17443 4.58008 
      4.28109 4.58008H5.97109C6.03776 4.58008 6.08443 4.59008 6.11109 4.61008C6.14443 4.62341 6.16776 4.66008 6.18109
      4.72008L7.94109 9.44008C7.96109 9.49341 7.98443 9.52008 8.01109 9.52008C8.03776 9.51341 8.06109 9.48341 8.08109 
      9.43008L9.79109 4.77008C9.83109 4.64341 9.89776 4.58008 9.99109 4.58008H11.7411C11.8344 4.58008 11.8811 4.63341
      11.8811 4.74008V11.7901C11.8811 11.9301 11.8078 12.0001 11.6611 12.0001H10.5911C10.5244 12.0001 10.4744 11.9867
      10.4411 11.9601C10.4144 11.9267 10.4011 11.8801 10.4011 11.8201V6.59008C10.4011 6.55008 10.3911 6.53341 10.3711
      6.54008C10.3511 6.54008 10.3344 6.56008 10.3211 6.60008L8.42109 11.8501C8.38776 11.9501 8.32109 12.0001 8.22109
      12.0001H7.51109C7.39776 12.0001 7.32443 11.9501 7.29109 11.8501L5.41109 6.66008C5.39776 6.61341 5.38109 6.59341
      5.36109 6.60008C5.34109 6.60008 5.33109 6.62341 5.33109 6.67008V11.8401C5.33109 11.9001 5.32109 11.9434 5.30109
      11.9701C5.28109 11.9901 5.23443 12.0001 5.16109 12.0001H4.31109Z" fill="#000000" />
  </svg>
)

export const sizeLarge = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2.5" y="2.5" width="11" height="11" rx="1.5" stroke="#000000" />
    <path d="M5.69336 4.75008C5.69336 4.63674 5.74336 4.58008 5.84336 4.58008H7.03336C7.12669 4.58008 7.17336 4.63341
      7.17336 4.74008V10.7801C7.17336 10.8601 7.21003 10.9001 7.28336 10.9001H10.5634C10.67 10.9001 10.7234 10.9467
      10.7234 11.0401V11.8501C10.7234 11.8901 10.71 11.9267 10.6834 11.9601C10.6567 11.9867 10.6134 12.0001 10.5534
      12.0001H5.88336C5.81003 12.0001 5.76003 11.9867 5.73336 11.9601C5.70669 11.9267 5.69336 11.8801 5.69336
       11.8201V4.75008Z" fill="#000000" />
  </svg>
)