import { withTriggerCombobox } from '@udecode/plate-combobox';
import { TElement, createSlatePlugin } from '@udecode/plate-common';

export const TemplateVariableInputPlugin = createSlatePlugin({
  key: 'template_variable_input',
  node: { isElement: true, isInline: true, isVoid: true },
});

export const TemplateVariablePlugin = createSlatePlugin({
  key: 'template_variable',
  node: {
    isElement: true,
    isInline: true,
    isVoid: true,
  },
  options: {
    createComboboxInput: (trigger) => ({
      children: [{ text: '' }],
      trigger,
      type: TemplateVariableInputPlugin.key,
    }),
    trigger: '{',
    triggerPreviousCharPattern: /^\s?$/,
  },
  plugins: [TemplateVariableInputPlugin],
  extendEditor: withTriggerCombobox,
});

export interface PlateTemplateVariableElement extends TElement {
  id: string,
  type: typeof TemplateVariablePlugin.key,
  variableId: string,
}

export const createDefaultTemplateVariableInputElement = (): TElement => ({
  type: TemplateVariableInputPlugin.key,
  children: [{ text: '' }],
})