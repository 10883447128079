import React from "react"
import { useEditorRef } from "@udecode/plate-common/react"

import PopoverSelectorTitle from "../../../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"
import ButtonSelector from "../../../../../../shared/ButtonSelector/ButtonSelector"
import { BACKGROUND_TINT_KINDS, SECTION_IMAGE_SIZING_OPTIONS, SECTION_SIZING_OPTIONS } from "../../../../section.config"
import ColorSelector from "../../../../../../shared/ColorSelector/ColorSelector"
import { PlateSectionElement } from "../../../../../../plate-config/Plugins/Section/Section.plugin"
import StyleSlider from "../../../../../../shared/Sliders/StyleSlider"


type Props = {
  sectionElement: PlateSectionElement
}

const StyleOptions = ({ sectionElement }: Props) => {
  const editor = useEditorRef()

  const fixedSection = sectionElement.fixed_kind === "recording"

  const onCardColorChange = (card_color: string) =>
    editor.setNodes(
      { card_color, card_opacity: typeof sectionElement.card_opacity === 'number' ? sectionElement.card_opacity : 50 } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )

  const onTintColorChange = (tint_color: string) =>
    editor.setNodes(
      { tint_color, tint_opacity: typeof sectionElement.tint_opacity == 'number' ? sectionElement.tint_opacity : 50 } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )

  const onTintKindChange = (tint_kind: string) =>
    editor.setNodes(
      { tint_kind } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )

  const onTintOpacityChange = (value: number[]) =>
    editor.setNodes(
      { tint_opacity: value[0] } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )

  const onCardOpacityChange = (value: number[]) =>
    editor.setNodes(
      { card_opacity: value[0] } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )

  const onSectionWidthChange = (width: string) =>
    editor.setNodes(
      { width } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )

  const onVerticalSpacingChange = (padding: string) =>
    editor.setNodes(
      { padding } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )

  const onBackgroundBlurChange = (value: number[]) =>
    editor.setNodes(
      { background_blur: value[0] } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )

  const onBackgroundImageSizeChange = (image_size: string) =>
    editor.setNodes(
      { image_size } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )

  return (
    <>
      <PopoverSelectorTitle title="Background" />
      <ColorSelector
        color={sectionElement.tint_color as string}
        setColor={onTintColorChange}
      />
      {sectionElement.background_image_url &&
        <ButtonSelector
          data={BACKGROUND_TINT_KINDS}
          current={sectionElement.tint_kind as string}
          setCurrent={onTintKindChange}
          wrapperClassName="mt-4"
        />}
      <StyleSlider
        label="Opacity %"
        value={[typeof sectionElement.tint_opacity === 'number' ? sectionElement.tint_opacity : 50]}
        onValueChange={onTintOpacityChange}
      />
      {sectionElement.background_image_url &&
        <StyleSlider
          label="Blur"
          value={[sectionElement.background_blur]}
          onValueChange={onBackgroundBlurChange}
          min={0}
          max={20}
        />
      }
      {sectionElement.background_image_url &&
        <>
          <PopoverSelectorTitle title="Image initial size" className="pt-6" />
          <ButtonSelector
            data={SECTION_IMAGE_SIZING_OPTIONS}
            current={sectionElement.image_size || "adjustable"}
            setCurrent={onBackgroundImageSizeChange}
          />
        </>
      }
      <PopoverSelectorTitle title="Section Width" className="pt-6" />
      <ButtonSelector
        data={SECTION_SIZING_OPTIONS}
        current={sectionElement.width as string}
        setCurrent={onSectionWidthChange}
        disabled={fixedSection}
      />
      <PopoverSelectorTitle title="Vertical Spacing" className="pt-6" />
      <ButtonSelector
        data={SECTION_SIZING_OPTIONS}
        current={sectionElement.padding as string}
        setCurrent={onVerticalSpacingChange}
      />
      <PopoverSelectorTitle title="Text Background Color" className="pt-6" />
      <ColorSelector
        color={sectionElement.card_color as string}
        setColor={onCardColorChange}
      />
      <StyleSlider
        label="Opacity %"
        value={[typeof sectionElement.card_opacity === 'number' ? sectionElement.card_opacity : 50]}
        onValueChange={onCardOpacityChange}
      />
    </>
  )
}

export default StyleOptions
