import React, { useEffect, useMemo, useState } from 'react';
import { TElement } from '@udecode/plate-common';
import { Plate } from '@udecode/plate-common/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Editor } from '../plate-components/EditorContainer/editor';
import { useAppSelector, useAppDispatch } from '../store/hooks/redux-hooks';
import { updateShadowCalcVariables } from '../store/reducers/page-shadow-store/page_shadow_store';
import useAppEditor from './PlateConfig';

const PlateEditorReadOnly = () => {
  const dispatch = useAppDispatch()
  const page_sections = useAppSelector(state => state.page_sections)
  const isTemplate = useAppSelector(state => state.page_addendums.template)
  const calc_vars = useAppSelector(state => state.page_calc_variables)
  const [shadowEditorState, setShadowEditorState] = useState(page_sections.sections as TElement[])

  useEffect(() => {
    dispatch(updateShadowCalcVariables(calc_vars))
  }, [])

  useEffect(() => {
    setShadowEditorState(page_sections.sections as TElement[])
  }, [page_sections])

  const editor = useMemo(() => useAppEditor(isTemplate, shadowEditorState), [])

  return (
    <DndProvider backend={HTML5Backend}>
      <Plate editor={editor} readOnly={true} >
        <Editor />
      </Plate>
    </DndProvider>
  );
}

export default PlateEditorReadOnly;