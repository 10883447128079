import { setLastSyncDate } from "../../page_sections/page_sections";
import { revvedupApi } from "../api";
import { PageDataAttributes, getPageDataAttributes } from "../data-attributes.util";

//  NOTES:
// We will have only one endpoint to save all the section data
export const sectionEndpoints = revvedupApi.injectEndpoints({
  endpoints: (builder) => ({
    saveSectionData: builder.mutation<any, any>({
      query: (editor_data) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/pages/${requestData.page_id}`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { editor_data: { data: editor_data } }
        }
      },
      async onQueryStarted(editor_data, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setLastSyncDate(editor_data))
          }
        } catch (err) {
          console.log('Page sections update error:', err);
        }
      }
    }),
    saveSectionDataBeforeUnmount: builder.mutation<any, { editor_data: any, requestData: PageDataAttributes | null }>({
      query: ({ editor_data, requestData }) => {
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/pages/${requestData.page_id}`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { editor_data: { data: editor_data } }
        }
      },
      async onQueryStarted({ }, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            console.log('Editor data saved before unmount');
          }
        } catch (err) {
          console.log('Editor data saving before unmount process failed', err);
        }
      }
    }),
  })
})

export const { useSaveSectionDataMutation, useSaveSectionDataBeforeUnmountMutation } = sectionEndpoints