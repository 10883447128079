import React from 'react';
import { withRef } from '@udecode/cn';
import { findNode, getParentNode, } from '@udecode/plate-common';
import { PlateLeaf, useEditorRef } from '@udecode/plate-common/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { LinkPlugin } from '@udecode/plate-link/react';

export const TextSizeLeaf = withRef<typeof PlateLeaf>(
  ({ children, ...props }, ref) => {
    const editor = useEditorRef();

    // NOTE: Deep dive into leaf nodes to find a parent, amount of levels deep is unknown
    // so we need to recursively search for the parent node
    const findKey = (obj: {}, keyToFind: string): any => {
      for (let key in obj) {
        if (key === keyToFind) return obj[key];

        if (typeof obj[key] === 'object') {
          const result = findKey(obj[key], keyToFind);
          if (result) return result;
        }
      }
      return null;
    };
    const parentNode = findKey(children, "parent")

    const parentNodeType = parentNode ? parentNode.type : null
    const parentPath = parentNode ? findNode(editor, { at: [], match: (n: any) => n.id === parentNode.id }) : null
    const topLevelParent = parentPath ? getParentNode(editor, parentPath[1]) : null

    const markSize = props.leaf?.font_size as string;

    const generalTextSizeMap: { [key: string]: string } = {
      small: 'text-sm', // 14px
      medium: 'text-base',   // 16px
      large: 'text-xl',   // 20px
    };

    const headerOneTextSizeMap: { [key: string]: string } = {
      small: 'text-2xl',   // 24px
      medium: 'text-4xl',  // 36px
      large: 'text-5xl',  // 48px
    };

    const headerTwoTextSizeMap: { [key: string]: string } = {
      small: 'text-lg',  // 18px
      medium: 'text-xl',  // 20px
      large: 'text-2xl', // 24px
    };

    const fontSizeClass = () => {
      if (parentNodeType === HEADING_KEYS.h1) {
        return headerOneTextSizeMap[markSize || 'medium']
      } else if (parentNodeType === HEADING_KEYS.h2) {
        return headerTwoTextSizeMap[markSize || 'medium']
        // NOTE: ELEMENT_LINK should inherit parent font size
      } else if (parentNodeType === LinkPlugin.key) {
        if (topLevelParent) {
          const topLevelParentNodeType = topLevelParent[0].type
          if (topLevelParentNodeType === HEADING_KEYS.h1) {
            return headerOneTextSizeMap[markSize || 'medium']
          } else if (topLevelParentNodeType === HEADING_KEYS.h2) {
            return headerTwoTextSizeMap[markSize || 'medium']
          }
        }
        return generalTextSizeMap[markSize || 'medium']
      }
      else {
        return generalTextSizeMap[markSize || 'medium']
      }
    }

    return (
      <PlateLeaf ref={ref} asChild {...props}>
        <span className={fontSizeClass()}>
          {children}
        </span>
      </PlateLeaf>
    )
  }
);
