import React from "react"
import { createDefaultButtonGroupElement } from "../../../../plate-config/Plugins/Button/ButtonGroup.plugin"
import PopoverSelectorTitle from "../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"

import Icon from "../../../../shared/Icon/Icon"
import {
  createDefaultColumnGroupElement,
  createDefaultFourColumnGroupElement,
  createDefaultThreeColumnGroupElement,
  createFourColumnsWithContentElement,
  createThreeColumnsWithImagesElement,
  createTwoColumnsWithImageAtLeftElement,
  createTwoColumnsWithImageAtRightElement
}
  from "../../../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin"
import { createDefaultBulletListElement, createDefaultH1Element, createDefaultParagraphElement } from "../../../../plate-config/Plugins/DefaultMockups/DefaultMockups"


type Props = {
  addFromLayout: (childrens: any[]) => void
}

const LayoutMenu: React.FC<Props> = ({ addFromLayout }) => {

  const menuConfig = [
    {
      sectionName: "Basic cards",
      options: [
        { title: 'Blank card', icon: 'faPlus', sectionChildrens: [createDefaultParagraphElement()] },
        { title: 'Card with title', icon: 'faPlus', sectionChildrens: [createDefaultH1Element(), createDefaultParagraphElement()] },
        { title: 'Card with title and list', icon: 'faPlus', sectionChildrens: [createDefaultH1Element(), createDefaultBulletListElement()] },
      ]
    },
    {
      sectionName: "Card with columns",
      options: [
        { title: 'Two columns', icon: 'faPlus', sectionChildrens: [createDefaultColumnGroupElement()] },
        { title: 'Three columns', icon: 'faPlus', sectionChildrens: [createDefaultThreeColumnGroupElement()] },
        { title: 'Four columns', icon: 'faPlus', sectionChildrens: [createDefaultFourColumnGroupElement()] },
      ]
    },
    {
      sectionName: "Card with actions",
      options: [
        {
          title: 'Content with button',
          icon: 'faPlus',
          sectionChildrens: [createDefaultH1Element(), createDefaultParagraphElement(), createDefaultButtonGroupElement()]
        },
        { title: 'Two columns image left', icon: 'faPlus', sectionChildrens: [createTwoColumnsWithImageAtLeftElement()] },
        { title: 'Two columns image right', icon: 'faPlus', sectionChildrens: [createTwoColumnsWithImageAtRightElement()] },
        { title: 'Three columns with images', icon: 'faPlus', sectionChildrens: [createThreeColumnsWithImagesElement()] },
        { title: 'Four columns with images', icon: 'faPlus', sectionChildrens: [createFourColumnsWithContentElement()] },
      ]
    },
  ]


  return (
    <div>
      <div className="flex flex-col gap-6">
        {menuConfig.map((menu, index) => (
          <div key={index}>
            <PopoverSelectorTitle title={menu.sectionName} className="mb-2" />
            <div className="flex gap-2 flex-wrap">
              {menu.options.map((option, index) => (
                <div key={index}
                  onClick={addFromLayout.bind(addFromLayout, option.sectionChildrens)}
                  className="flex flex-col justify-between items-center bg-white rounded-xl shadow-sm p-4 w-[120px] hover:bg-blue-100 cursor-pointer border-2 border-gray-50 hover:border-blue-300 btn-transition">
                  <Icon icon={option.icon} className="text-gray-400" />
                  <div className="text-xs pt-4 text-center text-gray-800" >{option.title}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>

  )
}

export default LayoutMenu;
