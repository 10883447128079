import React, { useEffect, useState } from 'react';
import { select, setMarks, getMarks, removeMark } from '@udecode/plate-common';
import { useEditorRef, focusEditor } from '@udecode/plate-common/react';

import { Popover, PopoverContent, PopoverTrigger } from '../../../shared/Popover/Popover';
import { TextColorLeafPlugin } from '../../../plate-config/Plugins/TextColor/TextColor.plugin';
import { TextHighlightLeafPlugin } from '../../../plate-config/Plugins/TextHighlight/TextHighlight.plugin';
import HugeIcon from '../../../shared/Icon/HugeIcon';
import { colorClassToRGB, systemColorsArray, textColorFromHex } from '../../../utils/color.util';
import { useAppSelector } from '../../../store/hooks/redux-hooks';


const Colors = () => {
  const editor = useEditorRef()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [type, setType] = useState<string>(TextColorLeafPlugin.key)
  const [color, setColor] = useState('')
  const [activeBg, setActiveBg] = useState('black')
  const { activePalette } = useAppSelector(state => state.page_colors)
  const marks = getMarks(editor)

  useEffect(() => {
    if (type === TextColorLeafPlugin.key) {
      setColor(marks?.font_color as string || '')
    }
    if (type === TextHighlightLeafPlugin.key) {
      setColor(marks?.text_highlight as string || '')
    }
  }, [type, marks])

  useEffect(() => {
    setActiveBg(color
      ? color.startsWith('landing')
        ? systemColorsArray.find(systemColour => systemColour.name === color)!.color
        : colorClassToRGB(color as string, 100, activePalette.colors)!
      : 'black')
  }, [color])

  const themeColors = [...activePalette.colors, "#000000", "#ffffff"]

  const setActiveType = (type: string) => setType(type)

  const isColorActive = (backgroundColor: string, index: number, color: string): boolean => {
    switch (true) {
      case backgroundColor === color || `color-${index}` === color:
        return true;
      case backgroundColor === "#000000" && color === "color-black":
        return true;
      case backgroundColor === "#ffffff" && color === "color-white":
        return true;
      case backgroundColor === "transparent" && color === "color-transparent":
        return true;
      default:
        return false;
    }
  }

  const colorCheck = (color: string, position: number) => {
    let colorClass: string
    switch (color) {
      case "#000000":
        colorClass = "color-black"
        break;
      case "#ffffff":
        colorClass = "color-white"
        break;
      case "transparent":
        colorClass = "color-transparent"
        break;
      default:
        colorClass = `color-${position}`
        break;
    }
    return colorClass
  }

  const onThemeColorChange = (color: string, position: number) => {
    setColor(color)
    select(editor, editor.selection!);
    focusEditor(editor);
    setMarks(editor, {
      ...(type === TextColorLeafPlugin.key && { 'font_color': colorCheck(color, position) }),
      ...(type === TextHighlightLeafPlugin.key && { 'text_highlight': colorCheck(color, position) }),
    });
    setPopoverOpen(false)
  }

  const onSystemColorChange = (color: string) => {
    setColor(color)
    select(editor, editor.selection!);
    focusEditor(editor);
    setMarks(editor, {
      ...(type === TextColorLeafPlugin.key && { 'font_color': color }),
      ...(type === TextHighlightLeafPlugin.key && { 'text_highlight': color }),
    });
    setPopoverOpen(false)
  }

  const onColorUnset = () => {
    removeMark(editor, { key: type === TextColorLeafPlugin.key ? 'font_color' : 'text_highlight' })
    setPopoverOpen(false)
    select(editor, editor.selection!);
    focusEditor(editor);
  }

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal={true}>
      <PopoverTrigger asChild>
        <div
          className={`toolbar_dropdown ${popoverOpen ? 'active' : ''}`}
          contentEditable={false}
          suppressContentEditableWarning
        >
          <div
            className={`h-[13px] w-[13px] rounded-full ring-1 ring-gray-900/20`}
            style={{ backgroundColor: activeBg }}
          />
          <HugeIcon name="arrowDown" size={"16px"} color='black' />
        </div>
      </PopoverTrigger>
      <PopoverContent align="start" sideOffset={5}  >
        <div className="toolbar_dropdown_content max-w-[202px]">
          <div className='p-1 border border-gray-700/20 rounded-lg flex items-center gap-2 w-full mb-4'>
            <button
              className={`
              w-[80px] px-3 py-2 rounded-lg text-xs font-medium transition-all duration-300 ease-in-out
              ${type === TextColorLeafPlugin.key ? "text-white bg-blue-500" : " text-black bg-transparent"} 
              `}
              onClick={setActiveType.bind(setActiveType, TextColorLeafPlugin.key)}
              contentEditable={false}
              suppressContentEditableWarning
            >Text
            </button>
            <button
              className={`
                  w-[80px] px-3 py-2 rounded-lg text-xs font-medium transition-all duration-300 ease-in-out
                  ${type === TextHighlightLeafPlugin.key ? "text-white bg-blue-500" : " text-black bg-transparent"} 
                  `}
              onClick={setActiveType.bind(setActiveType, TextHighlightLeafPlugin.key)}
              contentEditable={false}
              suppressContentEditableWarning
            >
              Highlight
            </button>
          </div>
          <div className='mb-4'>
            <span className="text-xs font-medium text-black">Theme Colors</span>
            <section className="flex gap-1 flex-wrap max-w-[202px] mt-2">
              {themeColors.map((backgroundColor, i) =>
                <div className="p-[2.5px] h-8 w-8" key={i}>
                  <button
                    onClick={onThemeColorChange.bind(onThemeColorChange, backgroundColor, i)}
                    className="w-full h-full rounded-full ring-1 ring-gray-900/20 flex items-center justify-center"
                    style={{ backgroundColor: backgroundColor }}
                  >
                    {isColorActive(backgroundColor, i, color!) &&
                      <HugeIcon name="tick" size={16} color={textColorFromHex(backgroundColor)} />}
                  </button>
                </div>
              )}
            </section>
          </div>
          <div className="w-full">
            <span className="text-xs font-medium text-black">System Colors</span>
            <section className="flex gap-1 flex-wrap max-w-[202px] mt-2">
              {systemColorsArray.map((backgroundColor, i) =>
                <div className="p-[2.5px] h-8 w-8" key={i}>
                  <button
                    onClick={onSystemColorChange.bind(onSystemColorChange, backgroundColor.name)}
                    className="w-full h-full rounded-full ring-1 ring-gray-900/20 flex items-center justify-center"
                    style={{ backgroundColor: backgroundColor.color }}
                  >
                    {backgroundColor.name === color &&
                      <HugeIcon name="tick" size={16} color={textColorFromHex(backgroundColor.color)} />}
                  </button>
                </div>
              )}
            </section>
          </div>
          {
            !!color &&
            <section className='mt-4 w-full'>
              <div className='bg-gray-900/10 h-[1px]' />
              <div onClick={onColorUnset}
                className="cursor-pointer mt-4 flex justify-center gap-1"
              >
                <HugeIcon name="arrowTurnBackward" size={16} color="black" />
                <span className='text-xs font-medium text-black'>Reset to default</span>
              </div>
            </section>
          }
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default Colors;
