import React, { useState, useEffect } from 'react';
import { withRef } from '@udecode/cn';
import { PlateElement, isEditorReadOnly } from '@udecode/plate-common/react';

import SectionMenu from "./SectionMenu/SectionMenu";
import { PlateSectionElement } from '../../plate-config/Plugins/Section/Section.plugin';
import { SectionStyleTypes } from './section.config';
import { colorClassToRGB } from '../../utils/color.util';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import BottomMenu from './BottomMenu/BottomMenu';
import SectionContent from './SectionContent';

const SectionElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {

    const isReadOnly = isEditorReadOnly(editor)
    const { activePalette } = useAppSelector(state => state.page_colors)
    const [bgImageHeight, setBgImageHeight] = useState(0);

    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const section = element as PlateSectionElement

    useEffect(() => {
      if (section.background_image_url && section.image_size === "full_screen") {
        const img = new Image();
        img.onload = () => setBgImageHeight(img.naturalHeight)
        img.src = section.background_image_url as string;
      } else (
        setBgImageHeight(0)
      )
    }, [section]);

    const isFirstSection = editor.children[0].id === section.id

    const sectionStyles: SectionStyleTypes = {
      '--section_plugin-background-color': colorClassToRGB(section.tint_color as string, section.tint_opacity as number, activePalette.colors)!,
      ...(section.background_image_url
        ? {
          '--section_plugin-background-image': `url(${section.background_image_url})`,
          '--section_plugin-background-filter': `blur(${section.background_blur}px)`,
          '--section_plugin-background-transform': `scale(${1 + (section.background_blur as number / 200)})`,
          '--section_plugin-background-blend-mode': section.tint_kind === "regular" ? 'normal' : 'overlay',
        }
        : {}),
      ...(section.background_image_url && section.image_size === "full_screen" ? { minHeight: `${bgImageHeight}px` } : {}),
    };

    return (
      <div
        className="relative group/bottom-menu"
        id={section.id}
        data-id={section.id}
      >
        <PlateElement
          ref={ref}
          id={section.id}
          editor={editor}
          element={section}
          className={`${className}
          section_plugin_wrapper flex justify-center w-full cursor-default p-4
          flex-col items-center overflow-hidden ${isFirstSection ? 'pt-20' : ''}`}
          style={sectionStyles}
          {...props}
          data-section-id={section.id}
        >
          {!isReadOnly && <SectionMenu sectionElement={section} />}
          <SectionContent section={section} children={children} />
        </PlateElement>
        {!isReadOnly && <BottomMenu sectionElement={section} />}
      </div>
    )
  })

export default SectionElement
