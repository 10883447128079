import React, { memo } from "react"

type Props = {
  htmlFor: string,
  label: string,
  value: boolean,
  setValue: (value: boolean) => void,
}


const CheckboxInput: React.FC<Props> = memo(({ htmlFor, label, value, setValue }) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.checked)
  return (
    <div className="flex items-center">
      <input
        id={htmlFor}
        type="checkbox"
        className={'form-checkbox'}
        checked={value}
        onChange={onChange}
      />
      <label htmlFor={htmlFor} className={'ml-3 mb-0'}>
        {label}
      </label>
    </div>
  )
})

export default CheckboxInput
