import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["useCase", "kindsInput", "activeCheckmark"]

  connect() {
    this.useCaseTargets.forEach(useCase => {
      const isActive = useCase.dataset.status === "true"
      this.updateUseCaseState(useCase, isActive)
    })
    this.updateKinds()
  }

  toggleUseCase(event) {
    event.preventDefault()
    const useCase = event.currentTarget
    const isActive = useCase.dataset.status === "true"

    if (!this.multiselect) {
      this.useCaseTargets.forEach(useCase => {
        this.updateUseCaseState(useCase, false)
      })
    }

    this.updateUseCaseState(useCase, !isActive)
    this.updateKinds()
  }

  updateUseCaseState(useCase, isActive) {
    const activeCheckmark = useCase.querySelector("[data-active-checkmark]")
    if (isActive) {
      activeCheckmark.classList.remove("hidden")
      useCase.dataset.status = "true"
      useCase.classList.add("active")
    } else {
      activeCheckmark.classList.add("hidden")
      useCase.dataset.status = "false"
      useCase.classList.remove("active")
    }
  }

  updateKinds() {
    const selectedUseCases = this.useCaseTargets
      .filter((useCase) => useCase.dataset.status === "true")
      .map((useCase) => useCase.dataset.useCase)

    const inputValue = this.multiselect? JSON.stringify(selectedUseCases) : selectedUseCases[0]
    this.kindsInputTarget.value = inputValue
  }

  // check the data attributes for data-allow-multiple values.
  // Return false if this data attribute is not present OR if the value is "false"
  get multiselect() {
    return this.data.get("allowMultiple") !== null && this.data.get("allowMultiple") !== "false"
  }
}
