import React from "react";
import { findNode, getParentNode, removeNodes } from "@udecode/plate-common";
import { isEditorReadOnly, PlateRenderElementProps } from "@udecode/plate-common/react";
import { useSelected } from "slate-react";

import { useAppSelector } from "../../store/hooks/redux-hooks";
import ImagePlaceholder from "./ImagePlaceholder/ImagePlaceholder";
import { PlateImageElement } from "../../plate-config/Plugins/Image/Image.plugin";
import ImagePopover from "./ImagePopover/ImagePopover";
import { ColumnPlugin } from "../../plate-config/Plugins/ColumnGroup/Column.plugin";

const ImageVoidElement = ({
  attributes,
  children,
  element,
  editor,
  className
}: PlateRenderElementProps) => {
  const isTemplate = useAppSelector(state => state.page_addendums.template)
  const isReadOnly = isEditorReadOnly(editor)
  const selected = useSelected()

  const { id, image_source, image_url } = element as PlateImageElement
  // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
  // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
  const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
  const nodePath = findNode(editor, { at: [], match: { id } })![1];
  const parentNode = getParentNode(editor, nodePath)!
  const parentNodeType = parentNode[0].type as string
  const isElColumnChild = parentNodeType === ColumnPlugin.key
  const activeSection = editor.children[nodePath[0]];
  const sectionChildren = activeSection.children.filter(child => !!child.type)
  const isFirstChild = sectionChildren[0].id === id;
  const isLastChild = sectionChildren[sectionChildren.length - 1].id === id;
  const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false

  const setUploadedImageHandler = (image_external_id: string, image_url: string) =>
    editor.setNodes(
      {
        image_external_id,
        image_source: "upload",
        image_url,
        internal_page_id: null,
        link_type: "external"
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === id }
    )

  const setImageFromPicker = (image_url: string, image_source: string, image_external_id: string) =>
    editor.setNodes(
      {
        image_external_id,
        image_source,
        image_url,
        internal_page_id: null,
        link_type: "external"
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === id }
    )

  const onImageUnset = () =>
    editor.setNodes(
      {
        image_external_id: null,
        image_source: null,
        image_url: null,
        internal_page_id: null,
        link_type: null,
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === id }
    )

  const onProgrammaticImageSet = (image_url: string, image_source: string | null) =>
    editor.setNodes(
      {
        image_external_id: null,
        image_source,
        internal_page_id: null,
        image_url,
        link_type: 'external',
        shape: 'square',
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === id }
    )

  const onAlignChange = (align: string) =>
    editor.setNodes(
      { align } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === id } }
    )

  const onShapeChange = (shape: string) =>
    editor.setNodes(
      { shape } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === id } }
    )

  const onImageSizeChange = (width: string) =>
    editor.setNodes(
      { width } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === id } }
    )

  const onImageDelete = () => removeNodes(editor, { at: [], match: (n: any) => n.id === id })

  if (isReadOnly) return image_url ?
    <div
      className={`
      ${className} p-2 rounded-lg transition-all duration-300 ease-in-out
        ${isElColumnChild
          ? isLastColElement ? "mb-0" : "mb-4"
          : `page-block image-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
        }
        ${!isReadOnly
          ? `${selected
            ? 'border-blue-500/60 border hover:border-blue-500/60'
            : 'hover:border-blue-500/30 border border-transparent'}`
          : ''
        } 
      `}
      id={element.id as string}
      contentEditable={false}
      suppressContentEditableWarning={true}
      data-id={element.id}
      {...attributes}
    >
      <ImagePopover
        data-id={element.id}
        onImageUnset={onImageUnset}
        setImageFromPicker={setImageFromPicker}
        element={element as PlateImageElement}
        onAlignChange={onAlignChange}
        onShapeChange={onShapeChange}
        onImageSizeChange={onImageSizeChange}
        isReadOnly={isReadOnly}
        isTemplate={isTemplate}
        selected={false}
      />
      {children}
    </div>
    : null

  return (
    <div
      className={`
        ${className} p-2 rounded-lg transition-all duration-300 ease-in-out
          ${isElColumnChild
          ? isLastColElement ? "mb-0" : "mb-4"
          : `page-block image-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
        }
          ${!isReadOnly
          ? `${selected
            ? 'border-blue-500/60 border hover:border-blue-500/60'
            : 'hover:border-blue-500/30 border border-transparent'}`
          : ''
        } 
        `}
      id={element.id as string}
      contentEditable={false}
      suppressContentEditableWarning={true}
      data-plate-selectable
      data-id={element.id}
      {...attributes}
    >
      {children}
      {image_url
        ?
        <ImagePopover
          onImageUnset={onImageUnset}
          setImageFromPicker={setImageFromPicker}
          element={element as PlateImageElement}
          onAlignChange={onAlignChange}
          onShapeChange={onShapeChange}
          onImageSizeChange={onImageSizeChange}
          isTemplate={isTemplate}
          selected={selected}
        />
        :
        <ImagePlaceholder
          isTemplate={isTemplate}
          setUploadedImageHandler={setUploadedImageHandler}
          setImageFromPicker={setImageFromPicker}
          onImageUnset={onImageUnset}
          onProgrammaticImageSet={onProgrammaticImageSet}
          imageSource={image_source as string}
          imageUrl={image_url as string}
          onImageDelete={onImageDelete}
          selected={selected}
        />
      }
    </div>
  )
}

export default ImageVoidElement
