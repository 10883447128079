import React, { useState } from "react";

import SwitchInput from "../../../../../../shared/Inputs/SwitchInput";
import { PricingTableLineItem } from "../../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import ButtonSelector from "../../../../../../shared/ButtonSelector/ButtonSelector";


type Props = {
  rowItem: PricingTableLineItem,
  onRowDiscountValueHandle: (value: number) => void
  onRowDiscountTypeHandle: (value: string) => void
}

const DiscountMenu = ({ rowItem, onRowDiscountTypeHandle, onRowDiscountValueHandle }: Props) => {

  const [discountActive, setDiscountActive] = useState(!!rowItem.discount_value)

  const onDiscountActiveChange = (val: boolean) => {
    setDiscountActive(val)
    !val && onRowDiscountValueHandle(0)
  }

  const DISCOUNT_OPTIONS = [
    { name: "dollar", label: "$" },
    { name: "percent", label: "%" },
  ]

  const onDiscountValueChange = (e: React.ChangeEvent<HTMLInputElement>) => onRowDiscountValueHandle(+e.target.value)

  return (
    <div className="flex flex-col p-4 gap-4">
      <SwitchInput
        label="Discount"
        value={discountActive}
        setValue={onDiscountActiveChange}
      />
      {discountActive &&
        <div>
          <label className="label p-0 font-semibold" htmlFor="discount_value">Amount</label>
          <div className="relative">
            <input
              className="input form-control"
              type="number"
              name="lineItem[discountValue]"
              id="discount_value"
              value={rowItem.discount_value}
              onChange={onDiscountValueChange}
            />
            <div className="absolute inset-y-0 right-1">
              <ButtonSelector
                btnGroupClassName="btn-inside-input"
                data={DISCOUNT_OPTIONS}
                current={rowItem.discount_type}
                setCurrent={onRowDiscountTypeHandle}
              />
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default DiscountMenu;
