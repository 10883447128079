import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["variable", "variableForm"]

  filter(event) {
    const filterText = event.target.value.toLowerCase()
    this.variableTargets.forEach(variable => {
      const variableName = variable.dataset.value.toLowerCase()
      if (variableName.includes(filterText) || filterText === "") {
        variable.classList.remove("hidden")
      } else {
        variable.classList.add("hidden")
      }
    })
  }

  newVariable() {
    this.variableFormTarget.classList.remove("hidden")
  }
}
