import React from 'react';

import DropdownOptionItem from './DropdownOptionItem';
import ToolbarDropdown from './ToolbarDropdown';

export const sizeOptions: any[] = [
  { name: 'small', label: <DropdownOptionItem iconName="sizeSmall" label="Small" />, iconName: "sizeSmall" },
  { name: 'medium', label: <DropdownOptionItem iconName="sizeMedium" label="Medium" />, iconName: "sizeMedium" },
  { name: 'large', label: <DropdownOptionItem iconName="sizeLarge" label="Large" />, iconName: "sizeLarge" },
];

type Props = {
  currentSize: string;
  onSizeChange: (value: string) => void;
}

const ToolbarSizeDropdown: React.FC<Props> = ({ currentSize, onSizeChange }) => {
  return (
    <ToolbarDropdown
      onOptionSet={onSizeChange}
      iconBeforeLabel={sizeOptions.find((option) => option.name === currentSize)!.iconName!}
      label={sizeOptions.find((option) => option.name === currentSize)!.name!}
      activeOption={currentSize}
      data={sizeOptions}
    />
  )
}

export default ToolbarSizeDropdown;