import React from 'react';

type Props = {
  onPromptChange: (prompt: string) => void
  prompt: string | null
}

const Prompt: React.FC<Props> = ({ prompt, onPromptChange }) => {
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => onPromptChange(e.target.value);

  return (
    <div className="mt-6">
      <label>Prompt</label>
      <textarea
        placeholder='Type your prompt here...'
        value={prompt || ''}
        onChange={onChange}
        className="form-control text-sm min-h-[90px] resize-y"
        rows={4}
      />
    </div>
  )
}

export default Prompt;
