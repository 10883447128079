import React, { memo } from "react";
import Slider from "./Slider";

type Props = {
  label: string;
  value: number[];
  onValueChange: (value: number[]) => void;
  min?: number;
  max?: number;
}

const StyleSlider = memo(({ label, value, min, max, onValueChange }: Props) => {
  return (
    <>
      <div className="flex justify-between uppercase font-semibold text-gray-600 text-xs pt-4">
        <span>{label}</span>
        <span>{value[0]}</span>
      </div>
      <div className="pt-2">
        <Slider
          min={min}
          max={max}
          value={value}
          onValueChange={onValueChange}
        />
      </div>
    </>
  )
})

export default StyleSlider
