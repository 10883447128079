import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["container"]
  static values = {
    startDate: String,
    endDate: String,
    url: String,
  }

  flatpickrInstance = null
  submitButton = null

  connect() {
    const defaultDates = [this.startDateValue, this.endDateValue].filter(Boolean);

    this.flatpickrInstance = flatpickr(this.containerTarget, {
      mode: "range",
      positionElement: this.containerTarget,
      // dateFormat: "m/d/Y",
      defaultDate: defaultDates.length > 0 ? defaultDates : undefined,
      closeOnSelect: false,
      onReady: (selectedDates, dateStr, instance) => {
        this.addSubmitButton(instance, selectedDates)
      },
      onChange: (selectedDates, dateStr, instance) => {
        this.updateSubmitButtonState(selectedDates)
      },
    });
  }

  disconnect() {
    this.flatpickrInstance.destroy();
  }

  openFlatpickr() {
    this.flatpickrInstance.open();
  }

  addSubmitButton(instance, selectedDates) {
    // create a div of class flex justify-end my-2
    const buttonContainer = document.createElement("div")
    buttonContainer.className = "flex justify-end my-2 mx-4";

    // create a button of class btn btn-white in the buttonContainer
    const button = document.createElement("button")
    button.textContent = "Apply";
    button.className = "btn btn-white"; // Add appropriate classes for styling
    button.addEventListener("click", () => this.applyDateRange(instance));
    this.submitButton = button
    this.updateSubmitButtonState(selectedDates);

    buttonContainer.appendChild(button);
    instance.calendarContainer.appendChild(buttonContainer);
  }

  updateSubmitButtonState(selectedDates) {
    if (selectedDates.length === 2) { // Enable button if two dates are selected
      this.submitButton.disabled = false;
    } else {
      this.submitButton.disabled = true; // Disable otherwise
    }
  }

  applyDateRange(instance) {
    instance.close();

    const startDate = instance.selectedDates[0].toISOString();
    const endDate = instance.selectedDates[1].toISOString();
    window.location.href = `${this.urlValue}?start=${startDate}&end=${endDate}&timeframe=custom`;

    window.location.href = url;
  }
}
