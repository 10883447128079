import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { PageData } from '../api/api.types'

export interface PageImages {
  profile_image: string | null
}

const initialState: PageImages = {
  profile_image: null
}

export const pageImagesSlice = createSlice({
  name: 'page_images',
  initialState,
  reducers: {
    setPageImages: (state, action: PayloadAction<PageData>) => {
      return {
        ...state,
        profile_image: action.payload.profile_image_url
      }
    },
  },
})

export const { setPageImages } = pageImagesSlice.actions

export default pageImagesSlice.reducer