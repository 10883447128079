import React, { memo } from "react";
import Icon from "../../../shared/Icon/Icon";

type Props = {
  textColor?: string
}
const EditIcon = memo(({ textColor = 'black' }: Props) => {
  return (
    <div
      className={`btn btn-clear ${textColor === "white" ? "reversed" : ""} btn-icon-large assessment-edit-button`}
    >
      <Icon icon="faPen" />
    </div>
  )
})

export default EditIcon
