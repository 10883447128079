import React from "react";
import TableCell from '@mui/material/TableCell';
import Cleave from "cleave.js/react"
import { useEditorRef, isEditorReadOnly } from "@udecode/plate-common/react";

import { PlatePricingElement } from "../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import { useAppSelector } from "../../../../../store/hooks/redux-hooks";
import { getCurrencySymbol } from "../../../../../utils/calc_plugins.util";

type Props = {
  element: PlatePricingElement
  tableIndex: number
  rowIndex: number
  isMobileView?: boolean
}

const PriceCell: React.FC<Props> = ({ element, tableIndex, rowIndex, isMobileView = false }) => {
  const editor = useEditorRef()
  const isReadOnly = isEditorReadOnly(editor)
  const currency = useAppSelector(state => state.page_addendums.currency)

  const currencySymbol = getCurrencySymbol(currency)

  const activeTable = element.pricing_tables_attributes[tableIndex]
  const activeRow = activeTable.line_items_attributes[rowIndex]

  const onPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as any
    const unit_price_cents = target.rawValue * 100
    const tableRows = [...activeTable.line_items_attributes]
    tableRows[rowIndex] = { ...tableRows[rowIndex], unit_price_cents }
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, line_items_attributes: tableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  return (
    <TableCell
      component={isMobileView ? 'div' : undefined}
      align="left"
      sx={{ padding: isMobileView ? '2px' : '10px 5px', borderBottom: 'unset', minWidth: isMobileView ? 'unset' : '100px' }}
      className="border-t border-gray-200"
    >
      <div className={`text-base ${activeTable.hide_price ? "opacity-20" : ""}`}>
        <Cleave
          options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', prefix: currencySymbol, rawValueTrimPrefix: true, numeralDecimalScale: 2 }}
          className="discrete-inline-input w-full text-sm"
          value={activeRow.unit_price_cents / 100}
          onChange={onPriceChange}
          disabled={isReadOnly}
        />
      </div>
    </TableCell>
  )
}

export default PriceCell