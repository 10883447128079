import React from "react";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';


type Props = {
  textColor: string
}

const Head: React.FC<Props> = ({ textColor }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={5} sx={{ padding: 0, borderBottom: 'unset' }} />
        <TableCell colSpan={2} sx={{ padding: 0, borderBottom: 'unset' }}>
          <div className={`header-font-family text-base py-2 text-right text-${textColor}`}>Price</div>
        </TableCell>
        <TableCell colSpan={2} sx={{ padding: 0, borderBottom: 'unset' }}>
          <div className={`header-font-family text-base py-2 text-right text-${textColor}`}>Value</div>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default Head