import React, { useEffect, useState } from "react"

import IconButton from "../../../../../shared/IconButton/IconButton"
import PopoverSelectorTitle from "../../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"
import LabeledInput from "../../../../../shared/Inputs/LabeledInput"
import { StoreCalcVariable } from "../../../../../store/reducers/page_calc_variables/page_calc_variables"
import { useUpdateCalcVariableMutation } from "../../../../../store/reducers/api/calc_variables/calc_variables.api"


type Props = {
  calc_variables: StoreCalcVariable[],
  activeVar?: StoreCalcVariable,
  onRoiLockedVariableIdChange: (roi_locked_variable_id: string) => void
}

const QuestionOptions = ({ calc_variables, activeVar, onRoiLockedVariableIdChange }: Props) => {
  const [changeVariableView, setChangeVariableView] = useState(false)
  const [varHint, setVarHint] = useState(activeVar?.hint)
  const [varPrefix, setVarPrefix] = useState(activeVar?.prefix)
  const [varSuffix, setVarSuffix] = useState(activeVar?.suffix)

  useEffect(() => {
    setVarHint(activeVar?.hint)
    setVarPrefix(activeVar?.prefix)
    setVarSuffix(activeVar?.suffix)
  }, [activeVar])

  const [updateVariable] = useUpdateCalcVariableMutation()


  const onVarHintChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setVarHint(e.target.value)
    await updateVariable({ ...activeVar!, default: activeVar!.default.toString(), hint: e.target.value })
  }

  const onVarPrefixChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setVarPrefix(e.target.value)
    await updateVariable({ ...activeVar!, default: activeVar!.default.toString(), prefix: e.target.value })
  }

  const onVarSuffixChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setVarSuffix(e.target.value)
    await updateVariable({ ...activeVar!, default: activeVar!.default.toString(), suffix: e.target.value })
  }


  const onVariableChange = (id: string) => {
    setChangeVariableView(false)
    onRoiLockedVariableIdChange(id);
  }

  return (
    <div className="w-72 border-solid border border-gray-200 rounded-xl bg-white drop-shadow z-30">
      <div className="p-5 flex flex-col gap-4">
        {changeVariableView ? (
          <>
            <div className="flex justify-between items-center">
              <PopoverSelectorTitle title="Select a Variable" />
              <IconButton
                btnClassName="btn btn-white btn-small"
                icon={'faArrowLeft'}
                iconClassName="pr-1"
                textAfter="Back"
                onClick={setChangeVariableView.bind(setChangeVariableView, false)}
              />
            </div>
            {
              calc_variables.map(el =>
                <button
                  key={`${el.id}-button-variable`}
                  className={`btn btn-block flex justify-start items-center`}
                  onClick={onVariableChange.bind(onVariableChange, el.id)}
                  disabled={activeVar?.id === el.id}
                >
                  <div className="text-gray-600 font-mono text-xs"> {"{{"}{el.handle}{"}}"}</div>
                  <div>{el.label}</div>
                </button>
              )
            }
          </>
        ) : (
          <>
            <PopoverSelectorTitle title="Question Options" />
            {activeVar &&
              <>
                <LabeledInput
                  label='Hint'
                  value={varHint}
                  onChange={onVarHintChange}
                  type='text'
                  htmlFor={`variable-hint-${activeVar.id}`}
                />
                <div className="flex gap-4">
                  <LabeledInput
                    label='Prefix'
                    value={varPrefix}
                    onChange={onVarPrefixChange}
                    type='text'
                    htmlFor={`variable-prefix-${activeVar.id}`}
                  />
                  <LabeledInput
                    label='Suffix'
                    value={varSuffix}
                    onChange={onVarSuffixChange}
                    type='text'
                    htmlFor={`variable-suffix-${activeVar.id}`}
                  />
                </div>
              </>
            }
            <div className="relative group/quest-btn">
              <div className={`hidden group-hover/quest-btn:block tooltip absolute -top-10 left-1/2 transform -translate-x-1/2 whitespace-nowrap transition-all duration-300`}>
                Use a different variable for the question
              </div>
              <button
                className="btn btn-block btn-white "
                onClick={setChangeVariableView.bind(setChangeVariableView, true)}
              >
                {activeVar ? "Change Variable" : "Select Variable"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default QuestionOptions
