import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { TemplateVariable } from '../api/api.types'


const initialState: TemplateVariable[] = []

export const templateVariablesSlice = createSlice({
  name: 'page_template_variables',
  initialState,
  reducers: {
    setTemplateVariables: (_state, action: PayloadAction<TemplateVariable[]>) => action.payload,
    addTemplateVariables: (state, action: PayloadAction<TemplateVariable>) => ([action.payload, ...state]),
  }
})

export const { setTemplateVariables, addTemplateVariables } = templateVariablesSlice.actions

export default templateVariablesSlice.reducer