import React, { useCallback, useState } from "react";
import Cleave from "cleave.js/react";
import debounce from 'lodash.debounce';

import { Popover, PopoverTrigger, PopoverPortal, PopoverContent, PopoverArrow } from "../../../../shared/Popover/Popover"
import Slider from "../../../../shared/Sliders/Slider";
import EditIcon from "../../EditIcon/EditIcon";
import SliderOptions from "./SliderOptions/SliderOptions";
import { StoreCalcVariable } from "../../../../store/reducers/page_calc_variables/page_calc_variables";
import { useUpdateCalcVariableMutation } from "../../../../store/reducers/api/calc_variables/calc_variables.api";
import { PlateAssessmentElement } from "../../../../plate-config/Plugins/Assessment/Assessment.plugin";
import { useAppDispatch } from "../../../../store/hooks/redux-hooks";
import { onShadowCalcVariableUpdate } from "../../../../store/reducers/page-shadow-store/page_shadow_store";

type Props = {
  element: PlateAssessmentElement,
  onSliderVariableIdChange: (slider_variable_id: string) => void,
  onSliderColorChange: (slider_color: string) => void,
  calc_variables: StoreCalcVariable[],
  trackAssessmentChanges: (
    field: string, variable_id: string | null, value: string | number,
    previous_value: string | number, sliderValue: number | null, variables: StoreCalcVariable[] | null
  ) => void,
  isEditorReadOnly: boolean
}

const EditorAssessmentSlider = ({
  element, onSliderColorChange, onSliderVariableIdChange,
  calc_variables, trackAssessmentChanges, isEditorReadOnly,
}: Props) => {
  const { slider_variable_id, slider_color } = element.assessment_detail_attributes
  const sliderVariable = calc_variables.find((variable) => variable.id === slider_variable_id)!

  const [sliderValue, setSliderValue] = useState([sliderVariable.default as number])
  const [updateVariable] = useUpdateCalcVariableMutation()
  const dispatch = useAppDispatch()

  const debouncedUpdate = useCallback(debounce(async (value: number) => {
    await updateVariable({ ...sliderVariable, default: value.toString() });
  }, 50), []);

  const onSliderValHandler = (value: number[]) => {
    setSliderValue(value)
    // CRITICAL: While user is in preview mode, we need to update the shadow store
    if (isEditorReadOnly) {
      dispatch(onShadowCalcVariableUpdate({ ...sliderVariable, default: value[0] }))
      trackAssessmentChanges('slider', null, value[0], sliderValue[0], value[0], null)
      return
    }
    debouncedUpdate(value[0]);
  }

  const onSliderManualChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)
    const min = sliderVariable.minimum || 0
    const max = sliderVariable.maximum || 100
    const validValue = !value ? 1 : value <= min ? 1 : value >= max ? max : value
    setSliderValue([validValue])
    if (isEditorReadOnly) {
      dispatch(onShadowCalcVariableUpdate({ ...sliderVariable, default: validValue }))
      trackAssessmentChanges('slider', null, value[0], sliderValue[0], value[0], null)
      return
    }
    await updateVariable({ ...sliderVariable, default: validValue.toString() })
    trackAssessmentChanges('slider', null, value[0], sliderValue[0], value[0], null)
  }

  return (
    <div className="relative w-full">
      <Slider
        value={sliderValue}
        onValueChange={onSliderValHandler}
        min={sliderVariable.minimum as number}
        max={sliderVariable.maximum as number}
        step={sliderVariable.step as number}
        wrapperClassName="large"
        trackClassName={"bg-gray-800/40"}
        colorClass={slider_color as string || "blue"}
      />
      <div className="absolute top-0 right-0 -translate-y-3 translate-x-14 z-10">
        <Popover>
          <PopoverTrigger>
            <EditIcon />
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent sideOffset={5}>
              <PopoverArrow />
              <SliderOptions
                calc_variables={calc_variables}
                activeVar={sliderVariable}
                onSliderVariableIdChange={onSliderVariableIdChange}
                slider_color={slider_color as string}
                onSliderColorChange={onSliderColorChange}
              />
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </div>
      {sliderVariable.label &&
        <div className='flex justify-end items-center max-w-md mx-auto mt-1 opacity-80 text-xs uppercase paragraph-font-family'>
          <Cleave
            min={sliderVariable.minimum as number}
            max={sliderVariable.maximum as number}
            options={{ numericOnly: true }}
            className="adjustable-quantity-inline-input w-8 text-sm sm:text-sm md:text-base text-center mt-0.5 paragraph-font-family"
            value={sliderValue[0]}
            onChange={onSliderManualChange}
            style={{ width: `${sliderVariable.default.toString().length + 1}ch` }}
          />
          {sliderVariable.label}
        </div>
      }
    </div>
  )
}

export default EditorAssessmentSlider
