import React from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { styled, keyframes } from '@stitches/react';

import Icon from '../Icon/Icon';


export const Accordion = RadixAccordion.Root;

const open = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const close = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const AnimatedChevronIcon = styled(() => <Icon icon="faChevronDown" />, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '[data-state=open] &': { transform: 'rotate(180deg)' },
});


const AccordionContent = styled(RadixAccordion.Content, {
  overflow: 'hidden',
  '&[data-state="open"]': { animation: `${open} 300ms ease-out` },
  '&[data-state="closed"]': { animation: `${close} 300ms ease-out` },
});

type Props = {
  children: React.ReactNode,
  title: string,
  value: string,
  identifier: string,
  itemClasses?: string,
}

const AccordionItem = ({ children, title, value, identifier, itemClasses }: Props) => {
  return (
    <RadixAccordion.Item key={`item-${identifier}`} value={value}>
      <RadixAccordion.Header
        key={`header-${identifier}`}
        className={`bg-gray-200 border border-gray-300 rounded-xl py-2 px-4 ${itemClasses}`}
      >
        <RadixAccordion.Trigger
          key={`trigger-${identifier}`}
          className="flex items-center justify-between w-full"
        >
          <div className="text-lg text-gray-700 font-bold">
            {title}
          </div>
          <div className="text-xl text-gray-700 font-bold">
            <AnimatedChevronIcon />
          </div>
        </RadixAccordion.Trigger>
      </RadixAccordion.Header>
      <AccordionContent
        key={`content-${identifier}`}
        className="px-2 py-4"
      >
        {children}
      </AccordionContent>
    </RadixAccordion.Item>
  );
}

export default AccordionItem;
