import { TElement } from '@udecode/plate-common';
import { createPlatePlugin } from "@udecode/plate-common/react"

export const HorizontalRulePlugin = createPlatePlugin({
  key: 'hr',
  node: {
    type: 'hr',
    isVoid: true,
    isElement: true,
  },
});

export interface PlateHrElement extends TElement {
  id: string
  type: typeof HorizontalRulePlugin.key
  color: string
  style: string
}

export const createDefaultHrElement = () => ({
  type: HorizontalRulePlugin.key,
  children: [{ text: '' }],
  color: 'color-black',
  style: 'straight'
}
)