import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
  dismiss() {
    console.log("Accepted cookies")
    Cookies.set('allow_cookies', true, { expires: 365 })
    this.element.remove()
  }
}
