import React, { useState } from "react"

import PopoverSelectorTitle from "../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"
import FontExample from "./FontExample/FontExample"
import FontFamilySelector from "./selectors/FontFamilySelector"
import FontWeightSelector from "./selectors/FontWeightSelector"
import { useAppSelector } from "../../../../store/hooks/redux-hooks"
import {
  useUpdatePageHeaderFontMutation,
  useUpdatePageParagraphFontMutation,
  useUpdatePageParagraphWeightMutation,
  useUpdatePageHeaderWeightMutation
} from "../../../../store/reducers/api/fonts/fonts.api"
import FontButton from "./FontButton/FontButton"

const FontSelector = () => {
  const { header_font, header_weight, paragraph_font, paragraph_weight, paragraphAvailableWeights, headerAvailableWeights } = useAppSelector(state => state.page_fonts)
  const [activeFontOption, setActiveFontOption] = useState("default")

  const [updatePageHeaderFont] = useUpdatePageHeaderFontMutation()
  const [updatePageParagraphFont] = useUpdatePageParagraphFontMutation()
  const [updatePageParagraphWeight] = useUpdatePageParagraphWeightMutation()
  const [updatePageHeaderWeight] = useUpdatePageHeaderWeightMutation()

  const onHeaderFontChange = async (header_font: string) => await updatePageHeaderFont(header_font)
  const onParagraphFontChange = async (paragraph_font: string) => await updatePageParagraphFont(paragraph_font)
  const onParagraphWeightChange = async (paragraph_weight: number) => await updatePageParagraphWeight(paragraph_weight)
  const onHeaderWeightChange = async (header_weight: number) => await updatePageHeaderWeight(header_weight)


  return (
    <div className="w-full mt-4">
      {activeFontOption === 'default' &&
        <>
          <PopoverSelectorTitle title="Header" className="mb-2" />
          <FontButton
            onClick={setActiveFontOption.bind(setActiveFontOption, "header_font")}
            title="Font"
            value={header_font}
          />
          <FontButton
            onClick={setActiveFontOption.bind(setActiveFontOption, "header_weight")}
            title="Weight"
            value={header_weight}
          />
          <PopoverSelectorTitle title="Paragraph" className="mb-2 mt-6" />
          <FontButton
            onClick={setActiveFontOption.bind(setActiveFontOption, "paragraph_font")}
            title="Font"
            value={paragraph_font}
          />
          <FontButton
            onClick={setActiveFontOption.bind(setActiveFontOption, "paragraph_weight")}
            title="Weight"
            value={paragraph_weight}
          />
        </>
      }
      <FontExample />
      {activeFontOption === "header_weight" &&
        <FontWeightSelector
          setActiveFontOption={setActiveFontOption}
          weight={header_weight}
          setWeight={onHeaderWeightChange}
          title="Header Weight"
          availableWeights={headerAvailableWeights}
        />
      }
      {activeFontOption === "header_font" &&
        <FontFamilySelector
          setActiveFontOption={setActiveFontOption}
          font={header_font}
          setFont={onHeaderFontChange}
          title="Header Font"
        />
      }
      {activeFontOption === "paragraph_weight" &&
        <FontWeightSelector
          setActiveFontOption={setActiveFontOption}
          weight={paragraph_weight}
          setWeight={onParagraphWeightChange}
          title="Paragraph Weight"
          availableWeights={paragraphAvailableWeights}
        />
      }
      {activeFontOption === "paragraph_font" &&
        <FontFamilySelector
          setActiveFontOption={setActiveFontOption}
          font={paragraph_font}
          setFont={onParagraphFontChange}
          title="Paragraph and Button Font"
        />
      }
    </div>
  )
}

export default FontSelector
