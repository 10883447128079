import React, { useState } from "react"
import * as RadixSelect from "@radix-ui/react-select"

import Icon from "../../../../../shared/Icon/Icon"
import { useUpdatePagePaletteMutation } from "../../../../../store/reducers/api/colors/colors.api"
import { PagePalette } from "../../../../../store/reducers/page_colors/page_colors"


type Props = {
  activePalette: PagePalette,
  availablePalettes: PagePalette[]
}

const ColorSourceSelector = ({ activePalette, availablePalettes }: Props) => {
  const [selectOpen, setSelectOpen] = useState(false)
  const [updatePagePalette] = useUpdatePagePaletteMutation()
  const onColorSourceChange = async (palette: string) => await updatePagePalette(palette)

  return (
    <RadixSelect.Root value={activePalette.palette} onValueChange={onColorSourceChange} onOpenChange={setSelectOpen}>
      <RadixSelect.Trigger
        className={`
        w-full flex items-center justify-between bg-white cursor-pointer text-gray-800
         pb-2 pt-3 px-3 mb-4 font-medium focus-visible:outline-0 border-gray-300 
         ${selectOpen ? "border rounded-t-md" : "border-b border-dashed"}`
        }
        aria-label="Palette Selector"
      >
        <RadixSelect.Value placeholder="Select a color palette" >
          {activePalette.label}
        </RadixSelect.Value>
        <RadixSelect.Icon className="text-gray-600">
          <Icon icon="faChevronDown" />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>
      <RadixSelect.Portal container={document.getElementById("global-color-select")}>
        <RadixSelect.Content
          className="bg-white rounded-b-md radix-select-content border border-gray-300 border-t-0"
          position="popper"
          sideOffset={0}
        >
          <RadixSelect.Viewport>
            {availablePalettes.map(data => {
              if (data.palette === 'account' && !data.uniqueAccountColors) return null
              return (
                <RadixSelect.Item
                  key={data.label}
                  value={data.palette}
                  className={`
                  text-sm font-normal flex items-center text-gray-700 hover:cursor-pointer
                  focus:cursor-pointer p-3 px-8 hover:text-gray-700 hover:bg-gray-100
                  focus-visible:outline-0`}
                >
                  <RadixSelect.ItemText>{data.label}</RadixSelect.ItemText>
                  <RadixSelect.ItemIndicator className="absolute left-2 w-6">
                    <Icon icon='faCheck' />
                  </RadixSelect.ItemIndicator>
                </RadixSelect.Item>
              )
            })}
          </RadixSelect.Viewport>
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  )
}

export default ColorSourceSelector