import React from "react"

import { Popover, PopoverArrow, PopoverContent, PopoverPortal, PopoverTrigger } from "../../../../shared/Popover/Popover"
import EditIcon from "../../EditIcon/EditIcon"
import QuestionOptions from "./QuestionOptions/QuestionOptions"
import { StoreCalcVariable } from "../../../../store/reducers/page_calc_variables/page_calc_variables"
import { PlateAssessmentElement } from "../../../../plate-config/Plugins/Assessment/Assessment.plugin"

type Props = {
  element: PlateAssessmentElement,
  onRoiLockedVariableIdChange: (roi_locked_variable_id: string) => void,
  calc_variables: StoreCalcVariable[],
  newVarValue: number | null,
  setNewVarValue: (value: number | null) => void,
  isEditorReadOnly: boolean
}

const LockScreenQuestion = ({ element, onRoiLockedVariableIdChange, calc_variables, newVarValue, setNewVarValue, isEditorReadOnly }: Props) => {
  const { roi_locked_button_text_color, roi_locked_variable_id } = element.assessment_detail_attributes
  const activeVar = calc_variables.find(el => el.id === roi_locked_variable_id)

  const onNewVarValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewVarValue(event.target.value ? Number(event.target.value) : null);
  }

  return (
    <div className="w-full">
      <div className="relative max-w-xs mx-auto">
        {activeVar?.prefix && <div className="absolute inset-y-0 left-4 flex items-center text-gray-600 text-sm">{activeVar?.prefix}</div>}
        {activeVar?.suffix && <div className="absolute inset-y-0 right-2 flex items-center text-gray-600 text-sm">{activeVar?.suffix}</div>}
        {!activeVar &&
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-red-500 w-full text-center">
            Select the variable to use
          </div>
        }
        <input
          className={`input form-control w-full ${activeVar?.prefix ? "pl-12" : ""}`}
          type="number"
          placeholder={!activeVar ? '' : "Enter a value"}
          disabled={!isEditorReadOnly ? true : !activeVar}
          value={newVarValue === null ? '' : newVarValue}
          onChange={onNewVarValueChange}

        />
        {!isEditorReadOnly && <div className="absolute top-0 right-0 translate-x-14 z-10">
          <Popover>
            <PopoverTrigger>
              <EditIcon textColor={roi_locked_button_text_color as string} />
            </PopoverTrigger>
            <PopoverPortal>
              <PopoverContent sideOffset={5}>
                <PopoverArrow />
                <QuestionOptions
                  activeVar={activeVar}
                  calc_variables={calc_variables}
                  onRoiLockedVariableIdChange={onRoiLockedVariableIdChange}
                />
              </PopoverContent>
            </PopoverPortal>
          </Popover>
        </div>}
      </div>
      {activeVar?.hint &&
        <div className={`text-sm mt-1 max-w-xs mx-auto text-center paragraph-font-family`}>{activeVar?.hint}</div>
      }
    </div>
  )
}

export default LockScreenQuestion
