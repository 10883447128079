import React from "react";

import HugeIcon, { HugeIconName } from "../Icon/HugeIcon";


type Props = {
  onClick: () => void;
  disabled?: boolean;
  iconName: HugeIconName;
  type?: 'general' | 'delete';
  isActive?: boolean;
}

const ToolbarButton: React.FC<Props> = ({
  onClick, disabled, iconName, type = 'general', isActive,
}) => {

  const onClickHandle = (e: React.MouseEvent<HTMLButtonElement>) => onClick();

  return (
    <button
      className={`toolbar_btn ${isActive ? "active" : ""}`}
      contentEditable={false}
      suppressContentEditableWarning={true}
      onClick={onClickHandle}
      disabled={disabled}
    >
      <HugeIcon name={iconName} size={16} className={`toolbar_btn_icon ${type}`} />
    </button>
  )
}

export default ToolbarButton;