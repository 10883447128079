import React, { useEffect, useState } from "react";
import TableCell from '@mui/material/TableCell';

import { PlatePricingElement } from "../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import { calculateValue, getPerDisplay } from "../../../../../utils/calc_plugins.util";
import { useAppSelector } from "../../../../../store/hooks/redux-hooks";
import { StoreCalcVariable } from "../../../../../store/reducers/page_calc_variables/page_calc_variables";

type Props = {
  element: PlatePricingElement
  tableIndex: number
  rowIndex: number
  isMobileView?: boolean
  calc_variables: StoreCalcVariable[]
}

const ValueCell: React.FC<Props> = ({ element, tableIndex, rowIndex, isMobileView = false, calc_variables }) => {
  const currency = useAppSelector(state => state.page_addendums.currency)

  const activeTable = element.pricing_tables_attributes[tableIndex]
  const activeRow = activeTable.line_items_attributes[rowIndex]

  const [perDisplay, setPerDisplay] = useState(getPerDisplay(activeRow.billing_frequency))
  const [value, setValue] = useState(calculateValue
    (activeRow.value_formula_attributes.calculation,
      activeRow.variable_id ? calc_variables.find(variable => variable.id === activeRow.variable_id)!.default : activeRow.quantity,
      calc_variables,
      currency
    ))

  useEffect(() => {
    setPerDisplay(getPerDisplay(activeRow.billing_frequency))
    setValue(calculateValue
      (activeRow.value_formula_attributes.calculation,
        activeRow.variable_id ? calc_variables.find(variable => variable.id === activeRow.variable_id)!.default : activeRow.quantity,
        calc_variables,
        currency
      ))
  }, [element, calc_variables, currency])

  return (
    <TableCell
      component={isMobileView ? 'div' : undefined}
      align="left"
      sx={{ padding: '10px 5px', borderBottom: 'unset', minWidth: isMobileView ? '75px' : '100px' }}
      className="border-t border-gray-200"
    >
      <div className={`text-sm ${activeTable.hide_value ? "opacity-20" : ""}`}>
        <div className="flex justify-center flex-wrap text-sm">
          {value}
          {activeRow.billing_frequency !== "one_time" && <div className="text-sm"> / {perDisplay} </div>}
        </div>
      </div>
    </TableCell>
  )
}

export default ValueCell