import React from "react";

import { PlateElementAiTextBlock, UnionSourceTypes } from "../../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import CheckboxInput from "../../../../shared/Inputs/CheckboxInput";

type Props = {
  element: PlateElementAiTextBlock
  onSourceChange: (source: UnionSourceTypes) => void
}

const SourcesCheckbox: React.FC<Props> = ({ element, onSourceChange }) => {
  const options = [
    { name: "source_buyer_summary", label: "Buyer Summary" },
    { name: "source_buyer_industry_challenges", label: "Buyer Industry Challenges" },
    { name: "source_seller_summary", label: "Seller Profile: Summary" },
    { name: "source_seller_benefits", label: "Seller Profile: Benefits" },
    { name: "source_seller_testimonials", label: "Seller Profile: Testimonials" },
    { name: "source_contact_role", label: "Contact Role" },
    { name: "source_contact_li_analysis", label: "Contact LinkedIn Analysis" },
    { name: "source_recording_analysis", label: "Recording Analysis" },
  ]
  return (
    <>
      <h4 className="text-sm font-medium border-b border-gray-200 pb-1 text-gray-700 mt-6">
        Sources to Use
      </h4>
      <div className="flex flex-col space-y-2 mt-2">
        {options.map((source) => (
          <React.Fragment key={source.name}>
            <CheckboxInput
              htmlFor={source.name}
              label={source.label}
              value={element.details[source.name as UnionSourceTypes]}
              setValue={onSourceChange.bind(onSourceChange, source.name as UnionSourceTypes)}
            />
          </React.Fragment>
        ))}
      </div>
    </>
  )
}

export default SourcesCheckbox;
