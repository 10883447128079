import React, { useState } from "react"

import ImagePicker from "../../../../shared/ImagePicker/ImagePicker";
import TooltipIcon from "../../../../shared/ToolTip/TooltipIcon";
import { PlateAssessmentElement } from "../../../../plate-config/Plugins/Assessment/Assessment.plugin";

type Props = {
  element: PlateAssessmentElement;
  onBackgroundImageChange: (image_url: string, image_source: string, image_external_id: string) => void;
  onImageUnset: () => void;
}

const BackgroundMenu = ({ element, onBackgroundImageChange, onImageUnset }: Props) => {
  const [showImagePicker, setShowImagePicker] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  return (
    <>
      <TooltipIcon
        tooltip="Background Image"
        side="top"
        icon="faImage"
        tooltipOpen={tooltipOpen}
        container={element.id as string}
        onClick={setShowImagePicker.bind(setShowImagePicker, true)}
        onMouseEnter={setTooltipOpen.bind(setTooltipOpen, true)}
        onMouseLeave={setTooltipOpen.bind(setTooltipOpen, false)}
        iconWrapperClassName="btn btn-small btn-white"
      />
      <ImagePicker
        backgroundImageSource={element.image_source as string}
        currentImageUrl={element.image_url as string}
        modalOpen={showImagePicker}
        onImageChange={onBackgroundImageChange}
        onImageUnset={onImageUnset}
        onModalClose={setShowImagePicker.bind(setShowImagePicker, false)}
      />
    </>
  )
}

export default BackgroundMenu
