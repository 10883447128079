import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.subjectField = document.getElementById("step-subject-field")
  }

  showSubject() {
    this.subjectField.classList.remove("hidden")
  }

  hideSubject() {
    this.subjectField.classList.add("hidden")
  }
}
