import React from "react";

import SwitchInput from "../../../../../../shared/Inputs/SwitchInput";
import { PricingTableLineItem } from "../../../../../../plate-config/Plugins/Pricing/Pricing.plugin";


type Props = {
  rowItem: PricingTableLineItem,
  onRowOptionalHandle: (val: boolean) => void,
  onRowOptionalDefaultHandle: (val: boolean) => void,
  onRowKindHandle: (val: string) => void
}

const OptionsMenu = ({ rowItem, onRowOptionalHandle, onRowOptionalDefaultHandle, onRowKindHandle }: Props) => {

  const textOnly = rowItem.kind === 'text'
  const optional = rowItem.optional

  const onKindToggle = (val: boolean) => onRowKindHandle(val ? 'text' : 'price')

  return (
    <div className="flex flex-col p-4 gap-4">
      {!textOnly &&
        <SwitchInput
          label="Optional Line Item"
          value={optional}
          setValue={onRowOptionalHandle}
        />
      }
      {!textOnly && optional &&
        <SwitchInput
          label="Selected by Default"
          value={rowItem.optional_default}
          setValue={onRowOptionalDefaultHandle}
        />
      }
      <SwitchInput
        label="Text Only"
        value={textOnly}
        setValue={onKindToggle}
      />
    </div>
  )
}

export default OptionsMenu;
