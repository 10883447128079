import React from "react";
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { SummaryCalculationResult } from "../../../../utils/calc_plugins.util";


type Props = {
  textColor: string
  summaryData: SummaryCalculationResult
  isValueHidden?: boolean
}

const Footer: React.FC<Props> = ({ textColor, summaryData, isValueHidden }) => {
  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={9} sx={{ padding: 0, paddingBottom: '10px', borderBottom: 'unset' }} >
          <div className="border-b border-gray-300 pb-3 opacity-50" />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5} sx={{ padding: 0, borderBottom: 'unset' }}>
          <span className={`header-font-family text-lg text-left text-${textColor}`}>Total Due on Signing</span>
        </TableCell>
        <TableCell colSpan={2} sx={{ padding: 0, borderBottom: 'unset' }}>
          <div className={`header-font-family  text-lg text-right text-${textColor}`}>{summaryData.total.price || 0}</div>
        </TableCell>
        {!isValueHidden && <TableCell colSpan={2} sx={{ padding: 0, borderBottom: 'unset' }}>
          <div className={`header-font-family text-lg text-right text-${textColor}`}>{summaryData.total.value || 0}</div>
        </TableCell>}
      </TableRow>
    </TableFooter>
  )
}

export default Footer