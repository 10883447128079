import React, { useState } from "react";

import { PlateElementAiTextBlock } from "../../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import LabeledInput from "../../../../shared/Inputs/LabeledInput";
import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import CheckboxInput from "../../../../shared/Inputs/CheckboxInput";
import { useCreateResourceSlotMutation } from "../../../../store/reducers/api/resources/resources.api";
import ModalSpinner from "../../../../shared/LoadingComponents/ModalSpinner";
import { getPageDataAttributes } from "../../../../store/reducers/api/data-attributes.util";

type Props = {
  element: PlateElementAiTextBlock,
  onToggleSlotResouce: (resourceId: string) => void
}

const ResourcesCheckbox: React.FC<Props> = ({ onToggleSlotResouce, element }) => {
  const { resource_slots } = useAppSelector(state => state.page_resources)
  const [createResourceSlot, { isLoading }] = useCreateResourceSlotMutation()
  const requestData = getPageDataAttributes()
  const [resourceToAdd, setResourceToAdd] = useState(false)
  const [resource, setResource] = useState('')
  const onResourceChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResource(e.target.value)
  }

  // TODO: ADD ERROR AND LOADING STATE
  const onCreateResourceSlot = async () => {
    await createResourceSlot(resource).catch((e) => console.log(e))
    setResource('')
    setResourceToAdd(false)
  }

  return (
    <section className="mt-6">
      <div className="flex justify-between border-b border-gray-200 items-center">
        <h4 className="text-sm font-medium pb-1 text-gray-700">
          Resources to Use
        </h4>
        <div className="flex gap-2">
          {resource_slots.length > 0 &&
            <a
              className={"btn btn-xs btn-clear text-blue-600 no-underline"}
              href={`/pages/${requestData?.page_id}/template_resource_slots`}
              style={{ textDecorationLine: "none" }}
            >
              Manage
            </a>
          }
          <button
            className={"btn btn-xs btn-clear text-blue-600"}
            onClick={setResourceToAdd.bind(setResourceToAdd, !resourceToAdd)}
          >
            Add Resource
          </button>
        </div>
      </div>
      <div className="flex flex-col space-y-2 mt-2">
        {resource_slots.map(resource => (
          <React.Fragment key={resource.id}>
            <CheckboxInput
              htmlFor={resource.id}
              label={resource.about}
              value={element.details.template_resource_slot_ids.includes(resource.id)}
              setValue={_checked => onToggleSlotResouce(resource.id)}
            />
          </React.Fragment>
        ))}
      </div>
      {resourceToAdd &&
        <section className="flex gap-2 items-center justify-center pt-1">
          <LabeledInput
            htmlFor="resource"
            placeholder="Resource to Add"
            wrapperClassName="flex-grow"
            value={resource}
            type="text"
            onChange={onResourceChangeHandle}
          />
          <div className="flex gap-2">
            <button
              className={"btn btn-small btn-light-gray"}
              onClick={setResourceToAdd.bind(setResourceToAdd, !resourceToAdd)}
            >
              Cancel
            </button>
            <button
              className={"btn btn-small btn-light-gray"}
              disabled={!resource.trim()}
              onClick={onCreateResourceSlot}
            >
              Save
            </button>
          </div>
        </section>
      }
      {isLoading && <ModalSpinner />}
    </section>
  )
}

export default ResourcesCheckbox
