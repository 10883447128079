import React, { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';

import { useAppSelector } from '../../../store/hooks/redux-hooks';
import { PlateSectionElement } from '../../../plate-config/Plugins/Section/Section.plugin';

type Props = {
  children: React.ReactNode;
  sectionElement: PlateSectionElement;
}

const AnimateWrapper = ({ children, sectionElement }: Props) => {
  const animationStatus = useAppSelector(state => state.page_side_effects.disableAnimation)
  const [count, setCount] = useState(0);
  const [animate, setAnimate] = useState(false);
  const {
    animate_direction,
    animate_speed,
    animate_style,
    animationCount
  } = sectionElement

  const animationFriction = animate_speed === "medium" ? 40 : animate_speed === "slow" ? 60 : 20
  const friction = animate_style === "slide" ? animationFriction : animationFriction * 7

  // animationCount counter is responsible for triggering the animation
  // setTimeout should reset animation after the animation is done (or it will be triggered again on every render)
  useEffect(() => {
    if (animationCount !== count) {
      setCount(animationCount as number)
      setAnimate(true)
      setTimeout(() => setAnimate(false), friction);
    }
  }, [animationCount])


  const animationProps = useSpring({
    config: { friction },
    from: {
      ...(animate_style === "slide" ? {
        x: animate_direction === "right" ? -100 : animate_direction === "left" ? 100 : 0,
        y: animate_direction === "up" ? 100 : animate_direction === "down" ? -100 : 0,
        opacity: 0.02,
      } : { opacity: 0 })
    },
    to: { ...(animate_style === "slide" ? { x: 0, y: 0, opacity: 1 } : { opacity: 1 }) },
    onRest: () => setAnimate(false),
    reset: animate,
    immediate: animationStatus,
  });

  return (
    <animated.div style={animationProps}>{children}</animated.div>
  );
};

export default AnimateWrapper;
