import React, { memo } from "react"

import Icon from "../../../../../shared/Icon/Icon"
import { textColorFromHex } from "../../../../../utils/color.util"
import { GLOBAL_COLOR_SELECTOR_DEFAULT_COLORS } from "../../../../../store/reducers/page_colors/page_color.config"


const DefaultColors = memo(() => {
  return (
    <>
      {GLOBAL_COLOR_SELECTOR_DEFAULT_COLORS.map((color) => {
        return (
          <div
            key={`button-color-${color}`}
            className={`h-14 w-14 rounded-full border border-gray-300 hover:ring-gray-200 hover:ring`}
            style={{ backgroundColor: color, color: textColorFromHex(color) }}
          >
            <Icon icon='faLock' wrapperClassName="w-full h-full flex justify-center items-center opacity-0 hover:opacity-100" />
          </div>
        )
      })}
    </>
  )
})

export default DefaultColors