import React, { useState } from "react";
import TableCell from '@mui/material/TableCell';
import { useEditorRef, isEditorReadOnly } from "@udecode/plate-common/react";

import EditableField from "../../../../../shared/EditableField/EditableField";
import { PlatePricingElement, PlatePricingLocalElement, PricingTableLineItem } from "../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import { CheckBoxChangeArgs, RadioChangeArgs } from "../../../PricingElement";

type Props = {
  element: PlatePricingElement
  localElement: PlatePricingLocalElement
  tableIndex: number
  rowIndex: number
  setLocalElement: React.Dispatch<React.SetStateAction<PlatePricingLocalElement | undefined>>
  isMobileView?: boolean,
  trackItemCheckBoxChange: (args: CheckBoxChangeArgs) => void
  trackItemRadioChange: (args: RadioChangeArgs) => void
}

const DescriptionCell: React.FC<Props> = ({
  element, tableIndex, rowIndex, localElement, trackItemRadioChange,
  setLocalElement, isMobileView = false, trackItemCheckBoxChange
}) => {
  const editor = useEditorRef()
  const isReadOnly = isEditorReadOnly(editor)

  const activeTable = element.pricing_tables_attributes[tableIndex]
  const activeRow = activeTable.line_items_attributes[rowIndex]

  const [description, setDescripton] = useState(activeRow.description || '')

  const localTable = localElement?.pricing_tables_attributes?.[tableIndex]
  const localRow = localTable?.line_items_attributes?.[rowIndex]

  const textOnly = activeRow.kind === 'text'
  const optionalInputs = !textOnly && activeRow.optional

  const onRowDescriptionChange = (description: string) => {
    setDescripton(description)
    const tableRows = [...activeTable.line_items_attributes]
    tableRows[rowIndex] = { ...tableRows[rowIndex], description }
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, line_items_attributes: tableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onSelectedSingleItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = e.target.checked
    let prev_line_item = {} as PricingTableLineItem
    const pricing_tables_attributes = localElement.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const line_items_attributes = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, selected }
          }
          if (row.selected) {
            prev_line_item = row
          }
          return { ...row, selected: !selected }
        })
        return { ...table, line_items_attributes }
      }
      return table
    }
    )
    setLocalElement({ ...localElement, pricing_tables_attributes })
    trackItemRadioChange({
      table_id: activeTable.id,
      line_item: activeRow,
      blockToAnalyse: { ...localElement, pricing_tables_attributes },
      prev_line_item: prev_line_item as PricingTableLineItem,
    })
  }

  const onSelectedMultipleItemsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = e.target.checked
    const pricing_tables_attributes = localElement.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const line_items_attributes = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, selected }
          }
          return row
        })
        return { ...table, line_items_attributes }
      }
      return table
    }
    )
    setLocalElement({ ...localElement, pricing_tables_attributes })
    trackItemCheckBoxChange({
      table_id: activeTable.id,
      line_item: activeRow,
      optional_selected: selected,
      blockToAnalyse: { ...localElement, pricing_tables_attributes }
    })
  }


  return (
    <TableCell
      align="left"
      sx={{ padding: '10px 5px', borderBottom: 'unset', minWidth: isMobileView ? 'unset' : '100px', display: isMobileView ? 'flex' : 'table-cell' }}
      className="border-t border-gray-200"
      colSpan={activeRow.kind !== 'price' ? 6 : 2}
      component={isMobileView ? 'div' : undefined}
    >
      <div className="w-full">
        <div className="flex gap-2 items-center">
          {optionalInputs && (
            <>
              {activeTable.select_type === "multiple"
                ?
                <input
                  type="checkbox"
                  className="form-checkbox"
                  checked={localRow ? localRow.selected : false}
                  onChange={onSelectedMultipleItemsChange}
                />
                :
                <input
                  type="radio"
                  className="form-checkbox"
                  value={''}
                  checked={localRow ? localRow.selected : false}
                  onChange={onSelectedSingleItemChange}
                />
              }
            </>
          )}
          <EditableField
            placeholder="Description..."
            value={description}
            onChange={onRowDescriptionChange}
            wrapperClassName="w-[100%]"
            inputClassName="w-full text-sm text-black paragraph-font-family"
            disabled={isReadOnly}
          />
        </div>
      </div>
    </TableCell>
  )
}

export default DescriptionCell