import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "content", "hidable", "activeButton", "collapsedButton", "header" ]
  static values = {
    startCollapsed: Boolean,
    autoHeight: Boolean
  }

  connect() {
    this.isCollapsed = this.startCollapsedValue
    this.updateUI()
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed
    this.updateUI()
  }

  updateUI() {
    if (this.isCollapsed) {
      this.collapseContent();
    } else {
      this.expandContent();
    }
  }

  collapseContent() {
    this.hidableTargets.forEach(target => target.classList.add("hidden"))
    this.contentTarget.style.height = "0px"
    this.activeButtonTarget.classList.add("hidden")
    this.collapsedButtonTarget.classList.remove("hidden")
    this.headerTarget.classList.add("content-collapsed")
  }

  expandContent() {
    this.isCollapsed = false
    this.hidableTargets.forEach(target => target.classList.remove("hidden"))
    if (this.autoHeightValue) {
      // There is some issues with setting the height of content that has hidden elements inside of it
      // An example of this is the call prep card that has tabs.
      this.contentTarget.style.height = "auto"
    } else {
      this.contentTarget.style.height = `${this.contentTarget.scrollHeight}px`
    }

    this.activeButtonTarget.classList.remove("hidden")
    this.collapsedButtonTarget.classList.add("hidden")
    this.headerTarget.classList.remove("content-collapsed")
  }
}
