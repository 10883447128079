import React, { useEffect, useState } from "react"

import Icon from "../shared/Icon/Icon"
import Branding from "./Branding"
import { useAppSelector } from "../store/hooks/redux-hooks"


const Footer = () => {
  const navPageConfig = useAppSelector(state => state.page_navigation)
  const mobileView = useAppSelector(state => state.page_addendums.isMobilePreviewActive)
  const isEditorReadOnly = useAppSelector(state => state.page_addendums.readOnly)
  const isLandingPage = useAppSelector(state => state.page_addendums.isLandingPage)

  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null)
  const [initialPageIndex, setInitialPageIndex] = useState(-1)
  const [hasInitialPage, setHasInitialPage] = useState(false)

  const isPreviewMode = isEditorReadOnly || isLandingPage
  const nav_router_without_building = navPageConfig.nav_router.filter(route => route.status !== 'building')
  const nav_router = isPreviewMode ? nav_router_without_building.filter(route => route.status === 'active') : nav_router_without_building

  const reactEl = document.getElementById("react")
  const brandingVisible = reactEl?.dataset.hideBranding !== "true"
  const linkNavVisible = hasNext || hasPrev || hasInitialPage

  const navigate = (path: string) => window.location.href = path

  useEffect(() => {
    if (navPageConfig && nav_router.length > 0) {
      const activeTab = nav_router.find(route => route.isActive)
      if (activeTab) {
        const tabIndex = nav_router.findIndex(route => route.id === activeTab.id)
        setActiveTabIndex(tabIndex)
        setHasNext(tabIndex < nav_router.length - 1)
        setHasPrev(tabIndex > 0)
      } else {
        // The page isn't an active page. We still want to show the nav but want the link to be a back to initial page
        // setInitialPage(navPageConfig.nav_router.find(route => route.id === navPageConfig.current_page_id))
        const initialPageRouteIndex = nav_router.findIndex(route =>
          route.id === navPageConfig.all_pages.find(page =>
            page.id === navPageConfig.current_page_id)?.origin_page_id
        );
        if (initialPageRouteIndex !== -1) {
          setInitialPageIndex(initialPageRouteIndex)
          setHasInitialPage(true)
        }
      }
    }
  }, [navPageConfig, nav_router, isPreviewMode])

  const handleNext = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!hasNext || activeTabIndex === null) return e.preventDefault();
    const next = nav_router[activeTabIndex + 1]
    next && navigate(isLandingPage ? next.landing_link : next.builder_link)
  }

  const handlePrev = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!hasPrev || activeTabIndex === null) return e.preventDefault();
    const prev = nav_router[activeTabIndex - 1]
    prev && navigate(isLandingPage ? prev.landing_link : prev.builder_link)
  }

  const handleGoToInitialPage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!hasInitialPage) return e.preventDefault();
    const goToInitialPage = nav_router[initialPageIndex]
    goToInitialPage && navigate(isLandingPage ? goToInitialPage.landing_link : goToInitialPage.builder_link)
  }

  return (
    <>
      {(brandingVisible || linkNavVisible) &&
        <div className="flex flex-row items-center gap-1 bg-gray-200 p-4">
          {mobileView && linkNavVisible &&
            <div className="basis-3/4 flex justify-start">
              {brandingVisible && <Branding />}
            </div>
          }
          {mobileView && !linkNavVisible && brandingVisible && <Branding />}
          {!mobileView &&
            <>
              <div className="basis-1/4"></div>
              <div className="basis-1/2">
                {brandingVisible && <Branding />}
              </div>
            </>
          }
          {linkNavVisible &&
            <div className="basis-1/4 flex justify-end gap-2">
              {hasPrev &&
                <a className="btn btn-white btn-icon" aria-disabled={!hasPrev} onClick={handlePrev} >
                  <Icon icon='faChevronLeft' />
                </a>
              }
              {hasNext &&
                <a className="btn btn-white btn-icon" aria-disabled={!hasNext} onClick={handleNext} >
                  <Icon icon='faChevronRight' />
                </a>
              }
              {hasInitialPage &&
                <a className="btn btn-white btn-icon" onClick={handleGoToInitialPage} >
                  <Icon icon='faChevronLeft' />
                </a>
              }
            </div>
          }
        </div>
      }
    </>
  )
}

export default Footer

