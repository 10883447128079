import React, { useState } from "react";
import { focusEditor, ParagraphPlugin, useEditorRef, useEditorSelector } from "@udecode/plate-common/react";
import { HEADING_KEYS } from "@udecode/plate-heading";
import { BulletedListPlugin, NumberedListPlugin } from "@udecode/plate-list/react";
import { BlockquotePlugin } from "@udecode/plate-block-quote/react";
import { getParentNode, select, TElement, toggleBlock } from "@udecode/plate-common";
import { getListRoot, toggleList, unwrapList } from "@udecode/plate-list";

import { Popover, PopoverTrigger, PopoverContent } from '../../../shared/Popover/Popover';
import HugeIcon, { HugeIconName } from "../../../shared/Icon/HugeIcon";

export const turnIntoItems = [
  {
    value: ParagraphPlugin.key,
    label: 'Paragraph',
    description: 'Paragraph',
    icon: 'paragraph'
  },
  {
    value: HEADING_KEYS.h1,
    label: 'Heading 1',
    description: 'Heading 1',
    icon: 'heading1'
  },
  {
    value: HEADING_KEYS.h2,
    label: 'Heading 2',
    description: 'Heading 2',
    icon: 'heading2'
  },
  {
    value: BulletedListPlugin.key,
    label: 'Bulleted list',
    description: 'Bulleted list',
    icon: 'listUl'
  },
  {
    value: NumberedListPlugin.key,
    label: 'Numbered list',
    description: 'Numbered list',
    icon: 'listOl'
  },
  {
    value: BlockquotePlugin.key,
    label: 'Quote',
    description: 'Quote',
    icon: 'quote'
  },
];

const TurnIntoDefaultNodes: React.FC = () => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const editor = useEditorRef();

  const itemsToDisplay = turnIntoItems.filter((item) => item.value !== BulletedListPlugin.key && item.value !== NumberedListPlugin.key);
  const defaultItem = turnIntoItems.find((item) => item.value === ParagraphPlugin.key)!;
  const selectedItem = useEditorSelector((editor) => {
    const entry = getParentNode(editor, editor.selection as any)?.[0] as TElement
    if (entry) {
      return turnIntoItems.find((item) => {
        if (entry && entry.type === 'lic') {
          const listRootElement = getListRoot(editor);
          if (listRootElement) {
            return listRootElement[0].type === item.value;
          }
        }
        if (entry) {
          return item.value === entry.type;
        }
        return item.value === ParagraphPlugin.key;
      }) || defaultItem
    } else {
      return defaultItem;
    }
  }, []);
  const { icon: SelectedItemIcon, label: selectedItemLabel } = selectedItem;

  const preventDefault = (e: Event) => e.preventDefault();

  const onValueHandle = (type: string) => {
    if (type === BulletedListPlugin.key || type === NumberedListPlugin.key) {
      toggleList(editor, { type });
    } else {
      unwrapList(editor);
      toggleBlock(editor, { type });
    }
    select(editor, editor.selection!);
    focusEditor(editor);
    setPopoverOpen(false)
  }

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal={true}>
      <PopoverTrigger >
        <div
          className={`toolbar_dropdown ${popoverOpen ? 'active' : ''}`}
          contentEditable={false}
          suppressContentEditableWarning
        >
          <HugeIcon name={SelectedItemIcon as HugeIconName} size={16} className="text-black" />
          <span className="text-xs font-normal text-left text-black"> {selectedItemLabel} </span>
          <HugeIcon name="arrowDown" size={16} className="text-black" />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="center"
        sideOffset={10}
        onOpenAutoFocus={preventDefault}
        onCloseAutoFocus={preventDefault}
        contentEditable={false}
        suppressContentEditableWarning={true}
      >
        <div className="toolbar_dropdown_content">
          {itemsToDisplay.map((item, i) =>
            <button
              key={i}
              onClick={onValueHandle.bind(onValueHandle, item.value)}
              className={`toolbar_dropdown_content-btn  ${item.label === selectedItemLabel ? 'active' : ''}`}
            >
              <div className="w-full p-2 rounded-lg flex gap-[6px] items-center">
                <HugeIcon name={item.icon as HugeIconName} size={16} color='black' />
                <span className='text-xs font-normal text-left text-black'>
                  {item.label}
                </span>
              </div>
            </button>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};


export default TurnIntoDefaultNodes;