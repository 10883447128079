import React, { useState, useEffect } from "react"
import { createApi } from 'unsplash-js'

import InfiniteScroll from "react-infinite-scroll-component";

import TextWithSpinner from "../../LoadingComponents/TextWithSpinner";
import ImagePickerCollectionsCard from "./ImagePickerCollectionsCard";
import { UnsplashCollection, UnsplashCollectionsResponse } from "../unsplash.types";

type Props = {
  scrollableContentId: string;
  setActiveCollection: (collection: UnsplashCollection) => void;
  unsplashClient: ReturnType<typeof createApi>;
}

const ImagePickerCollections = ({
  scrollableContentId,
  setActiveCollection,
  unsplashClient
}: Props) => {

  const [collections, setCollections] = useState<UnsplashCollection[]>([])
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage] = useState(20)

  useEffect(() => {
    getCollections()
  }, [])

  useEffect(() => {
    if (page !== 1 && collections.length < 20) {
      getCollections()
    }
  }, [collections, page])

  const getCollections = async () => {
    await unsplashClient.collections.list({ page, perPage })
      .then(response => {
        if (response) {
          const data = response as UnsplashCollectionsResponse;
          // We need filter out collections that are prepaid or not public
          const collectionData = data.response.results;
          const filteredCollections = collectionData.filter(collection => {
            // This is id for Unsplash+ Collections
            if (collection.user.id === 'iwi9-4OXLYY') {
              return false
            }
            // Another way to filter out Unsplash+ Collections
            if (collection.user.links.self.includes('unsplashplus')) {
              return false
            }
            // Another way to filter out Unsplash+ Collections
            if (collection.user.first_name === 'Unsplash+' || collection.user.last_name === 'Collections' || collection.user.name === 'Unsplash+ Collections') {
              return false
            }
            if (collection.private) {
              return false
            }
            if (collection.total_photos === 0) {
              return false
            }
            return true
          })
          const totalCollections = data.response.total;
          const updatedCollections = [...collections, ...filteredCollections];
          setCollections(updatedCollections);
          if (page !== totalCollections / perPage) {
            setPage(page + 1)
            setHasMore(true)
          } else {
            setHasMore(false)
          }
        }
      })
      .catch(error => {
        console.log('Unsplash loading collections error:', error)
        setCollections([])
        setHasMore(false)
      })
  }

  return (
    <InfiniteScroll
      dataLength={collections.length}
      next={getCollections}
      hasMore={hasMore}
      loader={
        <TextWithSpinner wrapperClassNames="pt-4 mb-12 pb-8" />
      }
      endMessage={
        <div className="pt-4 mb-12 pb-8 text-center text-gray-600">All collections loaded</div>
      }
      scrollableTarget={scrollableContentId}
    >
      <div className="p-4 pr-0">
        <div className="flex flex-wrap">
          {collections.map(collection => (
            <ImagePickerCollectionsCard
              key={collection.id}
              collection={collection}
              setActiveCollection={setActiveCollection}
            />
          ))}
        </div>
      </div>
    </InfiniteScroll>
  )
}

export default ImagePickerCollections
