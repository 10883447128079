import React from "react";

import { BrandImage } from "../../../store/reducers/api/api.types";
import ImagePickerMediaCard from "./ImagePickerMediaCard";

type Props = {
  images: BrandImage[];
  onBrandImagesListClose: () => void;
  onImageChange: (image: BrandImage) => void;
}

const ImagePickerMediaBrandList = ({
  images,
  onBrandImagesListClose,
  onImageChange,
}: Props) => {
  return (
    <div className="flex flex-wrap">
      {images.map(image =>
        <ImagePickerMediaCard
          key={image.id}
          brandImage={image}
          onBrandImageChange={onImageChange}
        />
      )}
      <div className="basis-1/2 pr-4 pb-4 md:basis-1/3 lg:basis-1/4">
        <div
          className="relative image-card bg-cover bg-center rounded-xl transition ease-in-out duration-500 border border-gray-300"
          onClick={onBrandImagesListClose}
        >
          <div className="rounded-xl absolute inset-0 w-full h-full cursor-pointer transition-all ease-in-out duration-500 hover:bg-gray-800 hover:bg-opacity-50 text-gray-700 hover:text-white">
            <div className="flex items-center justify-center w-full h-full">
              <h3 className="text-2xl font-bold">Go Back</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImagePickerMediaBrandList
