import React from "react"

import { RecordingConversation } from "../../../components/plate-config/Plugins/Recording/Recording.plugin"
import Icon from "../../../components/shared/Icon/Icon"

type Props = {
  conversation: RecordingConversation[]
  playbackTime: number
  handleUtteranceClick: (time: number) => void
}


const RecordingTranscript: React.FC<Props> = ({ conversation, playbackTime, handleUtteranceClick }) => {

  const speakerColors = ["bg-blue-500", "bg-emerald-500", "bg-red-400", "bg-gray-400"]

  return (
    <div className="mt-4 flex flex-col gap-6 transcript-text">
      {
        conversation.map((conversationItem, index) => {
          return (
            <div key={`conversation-${index}`} className="flex flex-row gap-6">
              <div>
                <Icon
                  icon="faUser"
                  className="opacity-70 text-white"
                  wrapperClassName={`flex items-center justify-center rounded-full h-7 w-7 ${speakerColors[conversationItem.speaker.number]}`}
                />
              </div>
              <div>
                <div className="flex gap-2 text-sm">
                  <div className="font-bold mb-1">
                    {conversationItem.speaker.name}
                  </div>
                  <span className="text-xs self-center">·</span>
                  <div className="text-xs self-center">
                    {conversationItem.display_start_time}
                  </div>
                </div>
                {conversationItem.uterrances.map(utterance => (
                  <span
                    key={`utterance-${utterance.id}`}
                    id={utterance.id}
                    className={`
                      mr-1 no-underline hover:underline underline-offset-4 cursor-pointer decoration-1
                      ${+utterance.start_time <= playbackTime && playbackTime < +utterance.end_time ? "bg-yellow-200" : ""}
                      `}
                    onClick={handleUtteranceClick.bind(handleUtteranceClick, +utterance.start_time)}
                  >
                    {utterance.text}
                  </span>
                )
                )}
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default RecordingTranscript;
