import React, { memo } from "react"

import Icon from "../Icon/Icon"
import Tooltip from "./Tooltip"

type Props = {
  tooltip: string
  side: "top" | "right" | "bottom" | "left" | undefined
  container: string
  icon: string
  path: string
  linkClassName?: string
  iconClassName?: string
  openInNewTab?: boolean
  onClick?: () => void
}

const TooltipLink: React.FC<Props> = memo(({ tooltip, side, container, icon, linkClassName, path, iconClassName, openInNewTab = false, onClick }) => {
  return (
    <Tooltip content={tooltip} side={side} container={container}>
      <a
        className={linkClassName}
        onClick={onClick} href={path}
        {...openInNewTab ? { target: "_blank", rel: "noopener noreferrer" } : { target: "_self" }}
      >
        <Icon icon={icon} className={iconClassName} />
      </a>
    </Tooltip>
  )
})

export default TooltipLink