import { evaluate } from 'mathjs';

import { StoreCalcVariable } from '../store/reducers/page_calc_variables/page_calc_variables';
import { PlatePricingLocalElement } from '../plate-config/Plugins/Pricing/Pricing.plugin';

export interface AssessmentStyleTypes extends React.CSSProperties {
  '--assessment-plugin-background-color': string;
  '--assessment-plugin-background-image'?: string;
  '--assessment-plugin-background-filter'?: string;
  '--assessment-plugin-background-transform'?: string;
  '--assessment-plugin-background-blend-mode'?: string;
}

export const RECURRING_BILLING_OPTIONS = [
  { value: "one_time", label: "One Time", perDisplay: "" },
  { value: "weekly", label: "Weekly", perDisplay: "week" },
  { value: "monthly", label: "Monthly", perDisplay: "month" },
  { value: "quarterly", label: "Quarterly", perDisplay: "quarter" },
  { value: "semi_annually", label: "Semi-Annually", perDisplay: "twice a year" },
  { value: "annually", label: "Annually", perDisplay: "year" },
  { value: "every_two_years", label: "Every Two Years", perDisplay: "every two years" },
  { value: "every_three_years", label: "Every Three Years", perDisplay: "every three years" },
]

type CalculatorInput = {
  formula: string;
  variables: { handle: string; default: number | string }[];
  sliderValue: number;
  type: 'currency' | 'percent' | 'number';
  currency?: string;
};

export const calculateAndFormat = ({
  formula,
  variables,
  sliderValue,
  type,
  currency
}: CalculatorInput): string => {
  let expression = formula.replace(/{{A}}/g, `${sliderValue}`);
  variables.forEach(({ handle, default: defaultValue }) => {
    expression = expression.replace(new RegExp(`{{${handle}}}`, 'g'), `${defaultValue}`);
  });

  try {
    const evalResult: number = evaluate(expression);
    switch (type) {
      case 'currency':
        return currency ? formatCurrency(evalResult, currency) : '--';
      case 'percent':
        return `${evalResult}%`;
      case 'number':
      default:
        return new Intl.NumberFormat('en-US').format(evalResult);
    }
  } catch (error) {
    return '--';
  }
};

export const formatCurrency = (value: number, currency = "USD", locale = "en-US") => {
  const subtotalDigits = (value) % 1 === 0 ? 0 : 2
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: subtotalDigits,
    maximumFractionDigits: subtotalDigits,
  })
  return formatter.format(value)
}

export const getCurrencySymbol = (currencyCode: string) => {
  var format = new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode });
  var parts = format.formatToParts(123);
  var symbol = parts.find(m => m.type === 'currency')?.value;
  return symbol;
}

export function calculateSubtotal(unitPriceCents: number, quantity: number, discountType: string, discountValue: number, currency: string): string {
  const unitPrice = unitPriceCents / 100;
  let rawDiscountAmount;
  if (discountType === "percent") {
    rawDiscountAmount = unitPrice * quantity * (discountValue / 100);
  } else {
    rawDiscountAmount = discountValue / 100;
  }
  const discountAmount = Math.min(unitPrice * quantity, rawDiscountAmount);
  const rawSubtotal = Math.max(0, unitPrice * quantity - discountAmount);
  return formatCurrency(rawSubtotal, currency);
}

export function calculateRawSubtotal(unitPriceCents: number, quantity: number, discountType: string, discountValue: number): number {
  const unitPrice = unitPriceCents / 100;
  let rawDiscountAmount;
  if (discountType === "percent") {
    rawDiscountAmount = unitPrice * quantity * (discountValue / 100);
  } else {
    rawDiscountAmount = discountValue / 100;
  }
  const discountAmount = Math.min(unitPrice * quantity, rawDiscountAmount);
  const rawSubtotal = Math.max(0, unitPrice * quantity - discountAmount);
  return rawSubtotal;
}

export function calculateDiscountAmount(unitPriceCents: number, quantity: number, discountType: string, discountValue: number, currency: string): string {
  const unitPrice = unitPriceCents / 100;
  let rawDiscountAmount;
  if (discountType === "percent") {
    rawDiscountAmount = unitPrice * quantity * (discountValue / 100);
  } else {
    rawDiscountAmount = discountValue / 100;
  }
  const discountAmount = Math.min(unitPrice * quantity, rawDiscountAmount);
  return formatCurrency(discountAmount, currency);
}

export function getPerDisplay(billingFrequency: string): string {
  const option = RECURRING_BILLING_OPTIONS.find(option => option.value === billingFrequency);
  return option ? option.perDisplay : "";
}

export function calculateSubtotalBeforeDiscount(unitPriceCents: number, quantity: number, currency: string): string {
  const unitPrice = unitPriceCents / 100;
  return formatCurrency(unitPrice * quantity, currency);
}

export function calculateValue(
  formula: string,
  quantity: number,
  variables: StoreCalcVariable[],
  currency: string
): string {
  let result = formula.replace(/{{QTY}}/g, `${quantity}`);
  for (const variable of variables) {
    result = result.replace(new RegExp(`{{${variable.handle}}}`, 'g'), `${variable.default}`);
  }
  try {
    const evalResult = evaluate(result);
    return formatCurrency(evalResult, currency);
  } catch (err) {
    return "--";
  }
}

export function calculatedValue(
  formula: string,
  quantity: number,
  variables: StoreCalcVariable[],
): string {
  let result = formula.replace(/{{QTY}}/g, `${quantity}`);
  for (const variable of variables) {
    result = result.replace(new RegExp(`{{${variable.handle}}}`, 'g'), `${variable.default}`);
  }
  try {
    const evalResult = evaluate(result);
    return evalResult;
  } catch (err) {
    return "--";
  }
}

export interface SummaryCalculationResult {
  total: {
    price: string;
    value: string;
  };
  recurringBillingGroups: {
    price: number;
    displayPrice: string;
    value: number;
    displayValue: string;
    perDisplay: string;
    fullPriceDisplay: string;
    fullValueDisplay: string;
  }[];
}

interface BillingGroup {
  price: number;
  value: number;
}

export const getItemsForCalculation = (element: PlatePricingLocalElement, currency: string, pageVars: StoreCalcVariable[]): SummaryCalculationResult => {
  const filteredTables = element ? element.pricing_tables_attributes.map(table => {
    return table.line_items_attributes.filter((lineItem) => {
      if (lineItem.kind !== "price") { return false }
      if (!lineItem.optional) { return true }
      if (lineItem.optional && lineItem.selected) { return true }
    })
  }).flat() : []

  const rowsWithCalculations = filteredTables.map((lineItem) => {
    const quantity = !lineItem.variable_id ? lineItem.quantity : pageVars.find(variable => variable.id === lineItem.variable_id)?.default
    const price = calculateRawSubtotal(lineItem.unit_price_cents, quantity!, lineItem.discount_type, lineItem.discount_value)
    const value = calculatedValue(lineItem.value_formula_attributes.calculation, quantity!, pageVars)
    return {
      ...lineItem,
      price,
      value,
    }
  })

  const linesByBillingGroups: { [key: string]: BillingGroup } = rowsWithCalculations.reduce((result, lineItem) => {
    const { billing_frequency, price, value } = lineItem;
    if (!result[billing_frequency]) {
      result[billing_frequency] = {
        price: 0,
        value: 0,
      };
    }
    result[billing_frequency] = {
      price: result[billing_frequency].price + price,
      value: result[billing_frequency].value + value,
    }
    return result;
  }, {})

  const recurringBillingGroups = RECURRING_BILLING_OPTIONS.filter((option) => {
    return !!linesByBillingGroups[option.value]
  }).map((option) => {
    const price = linesByBillingGroups[option.value].price
    const displayPrice = formatCurrency(price, currency)
    const value = linesByBillingGroups[option.value].value
    const displayValue = formatCurrency(value, currency)
    const perDisplay = option.perDisplay.length > 0 ? ` / ${option.perDisplay}` : ""

    return {
      price: price,
      displayPrice: displayPrice,
      value: value,
      displayValue: displayValue,
      perDisplay: option.perDisplay,
      fullPriceDisplay: `${displayPrice}${perDisplay}`,
      fullValueDisplay: `${displayValue}${perDisplay}`
    }
  })

  const total = {
    price: formatCurrency(Object.values(linesByBillingGroups).reduce((a, b) => a + b.price, 0) as number, currency),
    value: formatCurrency(Object.values(linesByBillingGroups).reduce((a, b) => a + b.value, 0) as number, currency),
  }

  return {
    total,
    recurringBillingGroups
  }
}
