import React from "react"

import PopoverSelectorTitle from "../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"
import { Popover, PopoverTrigger, PopoverPortal, PopoverContent, PopoverArrow } from "../../../../shared/Popover/Popover"
import EditIcon from "../../EditIcon/EditIcon"
import SwitchInput from "../../../../shared/Inputs/SwitchInput"
import FormulaInput from "../../../../shared/FormulaInput/FormulaInput";
import { StoreCalcVariable } from "../../../../store/reducers/page_calc_variables/page_calc_variables"
import { PlateAssessmentElement } from "../../../../plate-config/Plugins/Assessment/Assessment.plugin"
import { calculateAndFormat } from "../../../../utils/calc_plugins.util"
import { useAppSelector } from "../../../../store/hooks/redux-hooks"

type Props = {
  element: PlateAssessmentElement
  onDisplayPriceChange: (value: boolean) => void
  onPriceFormulaChange: (formula: string) => void
  calc_variables: StoreCalcVariable[]
  isEditorReadOnly: boolean
  textColor: string
}

const PriceCalc = ({
  element, onDisplayPriceChange, onPriceFormulaChange, calc_variables,
  isEditorReadOnly, textColor
}: Props) => {
  const currency = useAppSelector(state => state.page_addendums.currency)

  const { price_formula_attributes, slider_variable_id, hide_price } = element.assessment_detail_attributes
  const sliderVar = calc_variables.find((variable) => variable.id === slider_variable_id)?.default || 0

  return (
    <div className={`w-48 relative p-2 assessment-dollar-stat text-${textColor}`}>
      <div className="text-center text-sm uppercase paragraph-font-family">Price</div>
      <div className="text-center text-3xl header-font-family hover:text-4xl h-12 transition-all duration-300 ease-in-out">
        {calculateAndFormat({ formula: price_formula_attributes.calculation, variables: calc_variables, sliderValue: sliderVar, type: 'currency', currency })}
      </div>
      {!isEditorReadOnly &&
        <div className="absolute top-0 -right-2 z-20 font-base">
          <Popover>
            <PopoverTrigger>
              <EditIcon />
            </PopoverTrigger>
            <PopoverPortal>
              <PopoverContent sideOffset={5}>
                <PopoverArrow />
                <div className="w-80 border-solid border border-gray-200 rounded-xl bg-white drop-shadow z-30">
                  <div className="p-5 flex flex-col gap-4">
                    <PopoverSelectorTitle title="Price Options" />
                    <SwitchInput
                      label="Display Price"
                      value={!hide_price}
                      setValue={onDisplayPriceChange}
                    />
                    <FormulaInput
                      formula={price_formula_attributes.calculation}
                      setFormula={onPriceFormulaChange}
                      calc_variables={calc_variables}
                    />
                  </div>
                </div>
              </PopoverContent>
            </PopoverPortal>
          </Popover>
        </div>
      }
    </div>
  )
}

export default PriceCalc
