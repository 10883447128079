import React from "react"
import { addRangeMarks, findNode } from "@udecode/plate-common"
import { useEditorRef } from "@udecode/plate-common/react"

import ButtonSelector from "../../../../../../shared/ButtonSelector/ButtonSelector"
import PopoverSelectorTitle from "../../../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"
import { FONT_ALIGNMENT_OPTIONS, FONT_SIZING_OPTIONS } from "../../../../section.config"
import ColorSelector from "../../../../../../shared/ColorSelector/ColorSelector"
import { PlateSectionElement } from "../../../../../../plate-config/Plugins/Section/Section.plugin"
import { findSectionTextNodes } from "../../../../../../utils/plate.util"

type Props = {
  sectionElement: PlateSectionElement
}

const FontOptions = ({ sectionElement }: Props) => {
  const editor = useEditorRef()

  const fixedSection = sectionElement.fixed_kind === "recording"

  const onFontColorChange = (font_color: string) => {
    editor.setNodes(
      { font_color } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    const textNodes = findSectionTextNodes(sectionElement)
    textNodes.forEach((node: any) => {
      const nodeData = findNode(editor, { at: [], match: (n: any) => n.id === node.id })!
      addRangeMarks(editor, { font_color }, { at: nodeData[1] })
    })
  }

  const onFontSizeChange = (font_size: string) => {
    editor.setNodes(
      { font_size } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    const textNodes = findSectionTextNodes(sectionElement)
    textNodes.forEach((node: any) => {
      const nodeData = findNode(editor, { at: [], match: (n: any) => n.id === node.id })!
      addRangeMarks(editor, { font_size }, { at: nodeData[1] })
    })
  }

  const onFontAlignmentChange = (font_alignment: string) => {
    editor.setNodes(
      { font_alignment } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    const textNodes = findSectionTextNodes(sectionElement)
    textNodes.forEach((node: any) => {
      editor.setNodes(
        { align: font_alignment } as Partial<Node>,
        { at: [], match: (n: any) => n.id === node.id }
      )
    })
  }


  return (
    <>
      <PopoverSelectorTitle title="Color" />
      <ColorSelector
        color={sectionElement.font_color || ""}
        setColor={onFontColorChange}
      />
      <PopoverSelectorTitle title="Font Size" className="pt-6" />
      <ButtonSelector
        data={FONT_SIZING_OPTIONS}
        current={sectionElement.font_size || "medium"}
        setCurrent={onFontSizeChange}
      />
      <PopoverSelectorTitle title="Alignment" className="pt-6" />
      <ButtonSelector
        data={FONT_ALIGNMENT_OPTIONS}
        current={sectionElement.font_alignment || "left"}
        setCurrent={onFontAlignmentChange}
        disabled={fixedSection}
      />
      <div className="mt-6 p-4 bg-gray-50 rounded-xl text-sm text-gray-900">
        <div className="font-semibold pb-1">Need more options?</div>
        <div className="font-light">
          Highlight any text to bring up the toolbar
        </div>
      </div>
    </>
  )
}

export default FontOptions
