import { Controller } from "@hotwired/stimulus"
import { IntegrationAppClient } from '@integration-app/sdk'

const integrationApp = new IntegrationAppClient({
  async fetchToken() {
    try {
      const response = await fetch('/integrations/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
      })
      const data = await response.json()
      return data.token
    } catch (error) {
      console.error('Error fetching token:', error)
      throw error
    }
  },
})

export default class extends Controller {
  static values = {
    redirectUrl: String,
    integration: String
  }

  async open() {
    try {
      const connection = await integrationApp
        .integration(this.integrationValue)
        .connect()
      window.location.href = this.redirectUrlValue
    } catch (error) {
      console.error('Error establishing connection:', error)
    }
  }
}
