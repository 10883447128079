import React, { useState, useEffect } from "react"
import { createApi } from 'unsplash-js'
import InfiniteScroll from "react-infinite-scroll-component";

import TextWithSpinner from "../../LoadingComponents/TextWithSpinner"
import { UnsplashImage, UnsplashPhotoResponse } from "../unsplash.types";
import ImagePickerCard from "../ImagePickerCard/ImagePickerCard";

type Props = {
  setImageFromCollection: (image: UnsplashImage) => void;
  scrollableContentId: string;
  query: string;
  unsplashClient: ReturnType<typeof createApi>;
}

const ImagePickerSearch = ({
  setImageFromCollection,
  scrollableContentId,
  query,
  unsplashClient
}: Props) => {

  const [images, setImages] = useState<UnsplashImage[]>([])
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage] = useState(20)

  useEffect(() => {
    getPhotos()
  }, [query])

  const getPhotos = async () => {
    await unsplashClient.search.getPhotos({ query, page: 1, perPage })
      .then(response => {
        if (response) {
          const data = response as UnsplashPhotoResponse;
          const totalImages = data.response.total;
          const updatedImages = [...images, ...data.response.results];
          setImages(updatedImages);
          if (updatedImages.length < totalImages) {
            setPage(2)
            setHasMore(true)
          } else {
            setHasMore(false)
          }
        }
      })
      .catch(error => {
        console.log('Unsplash loading images error:', error)
        setImages([])
        setHasMore(false)
      })
  }

  const getMorePhotos = async () => {
    await unsplashClient.search.getPhotos({ query, page, perPage })
      .then(response => {
        if (response) {
          const data = response as UnsplashPhotoResponse;
          const totalImages = data.response.total;
          const updatedImages = [...images, ...data.response.results];
          setImages(updatedImages);
          if (updatedImages.length < totalImages) {
            setPage(page + 1)
            setHasMore(true)
          } else {
            setHasMore(false)
          }
        }
      })
      .catch(error => {
        console.log('Unsplash loading images error:', error)
        setImages([])
        setHasMore(false)
      })
  }


  return (
    <InfiniteScroll
      dataLength={images.length}
      next={getMorePhotos}
      hasMore={hasMore}
      loader={
        <TextWithSpinner wrapperClassNames="pt-4 mb-12 pb-8" />
      }
      endMessage={
        <div className="pt-4 mb-12 pb-8 text-center text-gray-600">All collections loaded</div>
      }
      scrollableTarget={scrollableContentId}
    >
      <div className="p-4 pr-0">
        <div className="flex flex-wrap">
          {images.map(image => (
            <ImagePickerCard
              unsplashClient={unsplashClient}
              key={image.id}
              image={image}
              setImageFromCollection={setImageFromCollection}
            />
          ))}
        </div>
      </div>
    </InfiniteScroll>
  )
}

export default ImagePickerSearch
