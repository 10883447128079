import React from "react";

import SwitchInput from "../../../../../../shared/Inputs/SwitchInput";
import { PricingTableLineItem } from "../../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import { RECURRING_BILLING_OPTIONS } from "../../../../../../utils/calc_plugins.util";

type Props = {
  rowItem: PricingTableLineItem,
  onRowBillingFrequencyHandle: (billing_frequency: string) => void,
  onRowTermsHandle: (terms: number | null) => void
}

const RecurringMenu = ({ rowItem, onRowBillingFrequencyHandle, onRowTermsHandle }: Props) => {
  const isTermsAvailable = rowItem.billing_frequency !== "one_time"
  const onRowBillingFrequencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => onRowBillingFrequencyHandle(e.target.value)
  const onRowTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => onRowTermsHandle(+e.target.value)
  const onTermsAvailableToggle = (val: boolean) => onRowTermsHandle(val ? 0 : null)

  return (
    <div className="flex flex-col p-4 gap-4">
      {isTermsAvailable &&
        <SwitchInput
          label="Limit Terms"
          value={rowItem.terms !== null}
          setValue={onTermsAvailableToggle}
        />
      }
      <select
        className="select"
        name="lineItem[recurring]"
        value={rowItem.billing_frequency}
        onChange={onRowBillingFrequencyChange}
      >
        {RECURRING_BILLING_OPTIONS.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>

      {isTermsAvailable && rowItem.terms !== null &&
        <div>
          <label className="label p-0 font-semibold" htmlFor="line_item_terms">Terms</label>
          <input
            className="input form-control"
            type="number"
            name="lineItem[terms]"
            id="line_item_terms"
            value={rowItem.terms}
            onChange={onRowTermsChange}
          />
          <p className="form-hint text-xs">Stop billing after this many payments.</p>
        </div>
      }
    </div>
  )
}

export default RecurringMenu;
