import React from 'react'

import { useUpdateCustomColorsMutation } from "../../../../../store/reducers/api/colors/colors.api";
import { useAppSelector } from "../../../../../store/hooks/redux-hooks"
import PopoverSelectorTitle from "../../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"
import { prepareColorAttributesForOverride } from "../../../../../store/reducers/page_colors/page_color.config";


const ColorPaletteWarning = () => {
  const { activePalette, availablePalettes } = useAppSelector(state => state.page_colors);
  const [updateCustomColors] = useUpdateCustomColorsMutation();

  const customPalette = availablePalettes.find(palette => palette.palette === 'custom');
  const customColors = customPalette?.colors || [];

  const onOverrideHandle = async () => await updateCustomColors(prepareColorAttributesForOverride(activePalette, availablePalettes))

  return (
    <div className="w-19em border-solid border border-gray-200 rounded-xl bg-white drop-shadow z-30">
      <div className="p-4">
        <PopoverSelectorTitle title={'Warning'} className="mb-1" />
        <div className="text-gray-500 text-sm mb-4">
          {`The following custom colors will be replaced with ${activePalette.palette === "buyer" ? "the prospect's" : "your brand's"} colors`}
        </div>
        <div className="w-full">
          <div className="flex flex-wrap text-center gap-2 mt-1">
            {customColors.map((backgroundColor, index) =>
              <div
                key={index}
                className="h-10 w-10 rounded-full border border-gray-300 hover:ring-gray-200 hover:ring"
                style={{ backgroundColor }}
              />
            )}
          </div>
        </div>
        <button
          className="btn btn-small btn-primary btn-block mt-6"
          onClick={onOverrideHandle}
        >
          Continue
        </button>
      </div>
    </div>
  )
}

export default ColorPaletteWarning
