import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom';

import PlateEditor from './components/plate-config/PlateEditor'
import NavigationBar from './components/NavigationBar/NavigationBar'
import Footer from './components/Footer/Footer'
import { useAppDispatch, useAppSelector } from './components/store/hooks/redux-hooks'
import PageIndicators from './components/PageIndicators/PageIndicators'
import { useGetPageDataQuery } from './components/store/reducers/api/api'
import ModalSpinner from './components/shared/LoadingComponents/ModalSpinner'
import { useLazyGetTemplateVariablesQuery } from './components/store/reducers/api/template_variables/template_variables.api'
import HeaderPortal from './components/HeaderPortal/HeaderPortal'
import { useGetCalcVariablesQuery } from './components/store/reducers/api/calc_variables/calc_variables.api';
import { useGetPageContactsQuery } from './components/store/reducers/api/contacts/contacts.api';
import { setLandingPageMode } from './components/store/reducers/page_addendums/page_addendums';
import { useSaveSectionDataBeforeUnmountMutation } from './components/store/reducers/api/sections/sections';
import { setAnalyticsStatus, setAnimationStatus } from './components/store/reducers/page_side_effects/page_side_effects';
import PlateEditorReadOnly from './components/plate-config/PlateEditorReadOnly';

type Props = {
  isLandingPage?: boolean
}

const App: React.FC<Props> = ({ isLandingPage }) => {
  const dispatch = useAppDispatch()
  const mobileView = useAppSelector(state => state.page_addendums.isMobilePreviewActive)
  const navPageConfig = useAppSelector(state => state.page_navigation)
  const readOnly = useAppSelector(state => state.page_addendums.readOnly)

  const { isFetching: isPageDataLoading, data, refetch: refetchPageData, isSuccess } = useGetPageDataQuery()
  const { isFetching: isPageCalcVarsLoading, refetch: refetchPageCalcVars } = useGetCalcVariablesQuery()
  const { isFetching: isPageContactsLoading, refetch: refetchPageContacts } = useGetPageContactsQuery()
  const [getTemplateData, { isLoading: isTemplateDataLoading }] = useLazyGetTemplateVariablesQuery()
  const [saveDataBeforeUnmount] = useSaveSectionDataBeforeUnmountMutation()

  // ---------------------------- SAVE EDITOR DATA TO API BEFORE APP UNMOUNT ----------------------------
  //  CRITICAL: This is not only one place where we save data to API.
  //  Second place to check saving process is in PlateEditor.tsx
  const editorData = useAppSelector(state => state.page_sections.sections)
  const requestAttributes = useAppSelector(state => state.page_addendums.requestAttributes)
  const editorDataRef = useRef(editorData);
  const requestAttributesRef = useRef(requestAttributes);
  const editorModeRef = useRef(readOnly);

  useEffect(() => {
    editorModeRef.current = readOnly;
  })
  useEffect(() => {
    editorDataRef.current = editorData;
  })
  useEffect(() => {
    requestAttributesRef.current = requestAttributes;
  })
  useEffect(() => {
    return () => {
      if (!editorModeRef.current) {
        saveDataBeforeUnmount({ editor_data: editorDataRef.current, requestData: requestAttributesRef.current })
      }
    }
  }, [])
  // ---------------------------- SAVE EDITOR DATA TO API BEFORE APP UNMOUNT ----------------------------

  // ---------------------------- ANALYTICS OBSERVER ----------------------------
  useEffect(() => {
    const targetNode = document.getElementById('react');
    if (!targetNode) {
      dispatch(setAnalyticsStatus(false))
      return;
    }
    const analyticsStatus = targetNode.getAttribute('data-analytics');
    if (!!analyticsStatus && analyticsStatus === 'true') {
      dispatch(setAnalyticsStatus(true))
    } else {
      (setAnalyticsStatus(false))
    }
  }, [])
  // ---------------------------- ANALYTICS OBSERVER ----------------------------

  // ---------------------------- SCREENSHOOT OBSERVER ----------------------------
  useEffect(() => {
    let timeoutId: number | null = null;
    if (isSuccess) {
      const targetNode = document.getElementById('react');
      if (!targetNode) {
        dispatch(setAnimationStatus(false))
        return;
      }
      const screenshootStatus = targetNode.getAttribute('data-screenshot');
      if (!!screenshootStatus && screenshootStatus === 'true') {
        timeoutId = window.setTimeout(() => {
          targetNode.setAttribute('data-screenshot', 'ready');
        }, 3000);
      }
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    }
  }, [isSuccess])

  useEffect(() => {
    const targetNode = document.getElementById('react');
    if (!targetNode) {
      dispatch(setAnimationStatus(false))
      return;
    }
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-screenshot') {
          const target = mutation.target as HTMLElement;
          const newValue = target.getAttribute('data-screenshot');
          if (newValue === 'ready') {
            dispatch(setAnimationStatus(true))
          }
          if (!newValue) {
            return dispatch(setAnimationStatus(false))
          }
        }
      });
    });

    observer.observe(targetNode, {
      attributes: true,
      attributeFilter: ['data-screenshot'],
    });
    return () => {
      observer.disconnect();
    };
  }, []);
  // ---------------------------- SCREENSHOOT OBSERVER ----------------------------

  useEffect(() => {
    refetchPageData()
    refetchPageCalcVars()
    refetchPageContacts()
    dispatch(setLandingPageMode(!!isLandingPage))
  }, [])

  useEffect(() => {
    if (data?.template) {
      getTemplateData()
    }
  }, [data])

  // NOTE: The z-10's are needed to ensure section background colors and images show correctly.
  const sectionClasses = `flex w-full justify-center relative z-10 min-h-[100vh] ${mobileView ? 'bg-white' : "bg-gray-50"}`
  const containerClasses = `
  flex flex-col justify-between w-96 overflow-y-scroll relative z-10
   ${mobileView ? "mt-8 mb-8 shadow-xl max-h-[840px] rounded-t-lg" : 'w-full mt-0 mb-0'}
   `
  const headerTarget = document.getElementById('react-header-buttons');

  const isLoading = isPageDataLoading || isTemplateDataLoading || isPageCalcVarsLoading || isPageContactsLoading

  return (
    <>
      {isLoading
        ?
        <ModalSpinner />
        :
        <>
          {headerTarget && !isLandingPage && ReactDOM.createPortal(<HeaderPortal />, headerTarget)}
          <section className={sectionClasses}>
            <div className={containerClasses}>
              {navPageConfig.tab_nav_active && <NavigationBar />}
              {/* CRITICAL: To use separate state we should actually re-render Editor component.
               Note: doesn't work locally by changing initial data */}
              {readOnly || isLandingPage ? <PlateEditorReadOnly /> : <PlateEditor />}
              <Footer />
            </div>
            <PageIndicators />
          </section>
        </>
      }
    </>
  )
}

export default App
