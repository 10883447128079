import React from "react";

import IconButton from "../../../../../../shared/IconButton/IconButton";
import { PricingTable } from "../../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import TooltipButton from "../../../../../../shared/ToolTip/TooltipButton";


type Props = {
  setItemViewHandle: (view: string) => void
  onMoveRowUpHandle: () => void
  onRowDeleteHandle: () => void
  onMoveRowDownHandle: () => void
  onRowDuplicateHandle: () => void
  rowIndex: number
  activeTable: PricingTable
  valueIssue: boolean
}

const ToolbarOptions = ({
  setItemViewHandle, rowIndex, onMoveRowUpHandle, onRowDeleteHandle,
  onMoveRowDownHandle, onRowDuplicateHandle, activeTable, valueIssue
}: Props) => {

  return (
    <>
      <div className="flex gap-1 border-b border-gray-200 p-1">
        <TooltipButton
          tooltip="Move Row Up"
          side="top"
          container="row-toolbar-tooltip-anchor"
          icon="faArrowUp"
          onClick={onMoveRowUpHandle}
          disabled={rowIndex === 0}
          btnClassName="btn btn-small btn-clear w-10 flex justify-center text-gray-900"
        />
        <TooltipButton
          tooltip="Move Row Down"
          side="top"
          container="row-toolbar-tooltip-anchor"
          icon="faArrowDown"
          onClick={onMoveRowDownHandle}
          disabled={!(activeTable.line_items_attributes.length - 1 > rowIndex)}
          btnClassName="btn btn-small btn-clear w-10 flex justify-center text-gray-900"
        />
        <TooltipButton
          tooltip="Duplicate Row"
          side="top"
          container="row-toolbar-tooltip-anchor"
          icon="faCopy"
          onClick={onRowDuplicateHandle}
          btnClassName="btn btn-small btn-clear w-10 flex justify-center text-gray-900"
        />
        <TooltipButton
          tooltip="Delete Row"
          side="top"
          container="row-toolbar-tooltip-anchor"
          icon="faTrash"
          onClick={onRowDeleteHandle}
          btnClassName="btn btn-small btn-clear w-10 flex justify-center text-gray-900"
        />
      </div>
      <div className="flex flex-col">
        <IconButton
          btnClassName="flex justify-between items-center py-2 px-4 hover:bg-gray-100 text-sm font-semibold text-gray-700 cursor-pointer"
          icon="faAngleRight"
          onClick={setItemViewHandle.bind(setItemViewHandle, "options")}
          iconClassName="text-xs"
          textBefore="Item Options"
        />
        <IconButton
          btnClassName="flex justify-between items-center py-2 px-4 hover:bg-gray-100 text-sm font-semibold text-gray-700 cursor-pointer"
          icon="faAngleRight"
          onClick={setItemViewHandle.bind(setItemViewHandle, "quantity")}
          iconClassName="text-xs"
          textBefore="Quantity Options"
        />
        <IconButton
          btnClassName="flex justify-between items-center py-2 px-4 hover:bg-gray-100 text-sm font-semibold text-gray-700 cursor-pointer"
          icon="faAngleRight"
          onClick={setItemViewHandle.bind(setItemViewHandle, "recurring")}
          iconClassName="text-xs"
          textBefore="Recurring Billing"
        />
        <IconButton
          btnClassName="flex justify-between items-center py-2 px-4 hover:bg-gray-100 text-sm font-semibold text-gray-700 cursor-pointer"
          icon="faAngleRight"
          onClick={setItemViewHandle.bind(setItemViewHandle, "discount")}
          iconClassName="text-xs"
          textBefore="Discount"
        />
        <IconButton
          btnClassName={`
          flex justify-between items-center py-2 px-4 hover:bg-gray-100 
          text-sm font-semibold text-gray-700 cursor-pointer
          ${valueIssue ? "bg-red-50 text-red-600 hover:bg-red-100" : ""}
          `}
          icon="faAngleRight"
          onClick={setItemViewHandle.bind(setItemViewHandle, "value")}
          iconClassName="text-xs"
          textBefore="Value"
        />
      </div>
    </>
  )
}

export default ToolbarOptions;