import { ColorAttributes } from "../../page_colors/page_color.config";
import { setActivePalette, setCustomColors } from "../../page_colors/page_colors";
import { setDataSaved, setDataSaving } from "../../page_indicators/page_indicators";
import { revvedupApi } from "../api";
import { ColorData } from "../api.types";
import { getPageDataAttributes } from "../data-attributes.util";


export const colorEndpoints = revvedupApi.injectEndpoints({
  endpoints: (builder) => ({
    updatePagePalette: builder.mutation<any, string>({
      query: (palette) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { palette } },
        }
      },
      async onQueryStarted(palette, { dispatch, queryFulfilled }) {
        dispatch(setDataSaving())
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setActivePalette(palette))
          }
        } catch (err) {
          console.log('Update active palette error:', err);
        }
        dispatch(setDataSaved())
      }
    }),
    updateCustomColors: builder.mutation<any, ColorAttributes[]>({
      query: (colors_attributes) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { colors_attributes } },
        }
      },
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        dispatch(setDataSaving())
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setCustomColors({ colorData: data.colors as ColorData[] }))
          }
        } catch (err) {
          console.log('Update custom colors error:', err);
        }
        dispatch(setDataSaved())
      }
    }),
  })
})

export const { useUpdatePagePaletteMutation, useUpdateCustomColorsMutation } = colorEndpoints
