import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { StoreCalcVariable } from '../page_calc_variables/page_calc_variables'
import { PageVariable } from '../api/api.types'


//  Main purpose of this store is to store data that are used locally on landing pages/preview mode
type ShadowStore = {
  calculation_variables: StoreCalcVariable[]
}

const initialState: ShadowStore = {
  calculation_variables: []
}

export const pageShadowStoreSlice = createSlice({
  name: 'page_shadow_store',
  initialState,
  reducers: {
    setShadowCalcVariables: (state, action: PayloadAction<PageVariable[]>) => {
      const calculation_variables = action.payload.map(variable => {
        return {
          ...variable,
          default: parseInt(variable.default)
        }
      })
      return { ...state, calculation_variables }
    },
    updateShadowCalcVariables: (state, action: PayloadAction<StoreCalcVariable[]>) => ({ ...state, calculation_variables: action.payload }),
    onShadowCalcVariableUpdate: (state, action: PayloadAction<StoreCalcVariable>) => {
      const calculation_variables = state.calculation_variables.map(variable => {
        if (variable.id === action.payload.id) {
          return action.payload
        }
        return variable
      })
      return { ...state, calculation_variables }
    },
  }
})

export const { setShadowCalcVariables, onShadowCalcVariableUpdate, updateShadowCalcVariables } = pageShadowStoreSlice.actions

export default pageShadowStoreSlice.reducer