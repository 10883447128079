import React from "react";
import TimeAgo from 'react-timeago'
import timeAgoEnStrings from 'react-timeago/lib/language-strings/en'
import timeAgoBuildFormatter from 'react-timeago/lib/formatters/buildFormatter'

import { useAppDispatch, useAppSelector } from "../store/hooks/redux-hooks";
import { useSaveSectionDataMutation } from "../store/reducers/api/sections/sections";
import { toggleEditorMode, toggleMobilePreview } from "../store/reducers/page_addendums/page_addendums";
import ModalSpinner from "../shared/LoadingComponents/ModalSpinner";


const HeaderPortal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isMobilePreviewActive, readOnly } = useAppSelector(state => state.page_addendums);
  const { sections, lastUpdated } = useAppSelector(state => state.page_sections)
  const [saveEditorStateToApi, { isLoading }] = useSaveSectionDataMutation()

  const onMobilePreviewChange = () => dispatch(toggleMobilePreview())
  const onEditorModeChange = () => dispatch(toggleEditorMode())
  // TODO: ADD LOADING AND ERROR HANDLING
  const syncWithAPI = async () => await saveEditorStateToApi(sections)

  const timeAgoFormatter = timeAgoBuildFormatter(timeAgoEnStrings)

  return (
    <>
      {!readOnly && (
        <a className="dropdown-item" onClick={onMobilePreviewChange}>
          {isMobilePreviewActive ? 'Hide Mobile preview' : 'Show Mobile preview'}
        </a>
      )}
      <a className="dropdown-item" onClick={onEditorModeChange}>
        {readOnly ? 'Edit mode' : 'Preview mode'}
      </a>
      <a className="dropdown-item" onClick={syncWithAPI}>
        <div className="flex flex-col gap-1">
          <div>Save Page</div>
          <div className="text-xs text-gray-500">
            <span className="mr-1">Last Saved:</span>
            <TimeAgo
              live={true}
              date={lastUpdated}
              formatter={timeAgoFormatter}
            />
          </div>
        </div>
      </a>
      {isLoading && <ModalSpinner />}
    </>
  );
}

export default HeaderPortal;
