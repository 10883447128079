import { createSlatePlugin, TElement } from '@udecode/plate-common';
import { withTriggerCombobox } from '@udecode/plate-combobox';

export const EmojiInputPlugin = createSlatePlugin({
  key: 'emoji_input',
  node: { isElement: true, isInline: true, isVoid: true },
});

export const EmojiPlugin = createSlatePlugin({
  key: 'emoji_command',
  options: {
    createComboboxInput: () => ({
      children: [{ text: '' }],
      type: EmojiInputPlugin.key,
    }),
    trigger: ':',
    triggerPreviousCharPattern: /^\s?$/,
  },
  plugins: [EmojiInputPlugin],
  extendEditor: withTriggerCombobox,
});

export const createDefaultEmojiElement = (): TElement => ({
  type: EmojiInputPlugin.key,
  children: [{ text: '' }],
})
