import React, { memo } from "react"

import { SectionStyleConfigType } from "../../../../section.config"

type Props = {
  options: SectionStyleConfigType[],
  current: string,
  setCurrent: (event: React.ChangeEvent<HTMLInputElement>) => void
}


const AnimateOptionsSelector = memo(({ options, current, setCurrent }: Props) => {
  return (
    <div className="w-full mt-1">
      <form>
        {options.map(({ name, label }) => {
          return (
            <div
              key={`radio-button-${name}`}
              className="flex gap-2 mt-2"
              onChange={setCurrent}
            >
              <input
                type="radio"
                id={name}
                name="animate-type"
                value={name}
                defaultChecked={name === current}
                className="flex-none mt-2"
              />
              <label htmlFor={name} className="label radio-item-label flex-auto">
                {label}
              </label>
            </div>
          )
        })}
      </form>
    </div>
  )
})

export default AnimateOptionsSelector
