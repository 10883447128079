import React from "react";
import TableCell from '@mui/material/TableCell';
import { useEditorRef, isEditorReadOnly } from "@udecode/plate-common/react";

import { PlatePricingElement } from "../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import EditableField from "../../../../../shared/EditableField/EditableField";
import IconButton from "../../../../../shared/IconButton/IconButton";
import { useUpdateCalcVariableMutation } from "../../../../../store/reducers/api/calc_variables/calc_variables.api";
import { StoreCalcVariable } from "../../../../../store/reducers/page_calc_variables/page_calc_variables";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { onShadowCalcVariableUpdate } from "../../../../../store/reducers/page-shadow-store/page_shadow_store";
import { QuantityChangeArgs } from "../../../PricingElement";

type Props = {
  element: PlatePricingElement
  tableIndex: number
  rowIndex: number
  isMobileView?: boolean,
  calc_variables: StoreCalcVariable[]
  trackQuantityChange: (args: QuantityChangeArgs) => void
}

const QuantityCell: React.FC<Props> = ({
  element, tableIndex, rowIndex, isMobileView = false, calc_variables, trackQuantityChange
}) => {
  const editor = useEditorRef()
  const isReadOnly = isEditorReadOnly(editor)
  const dispatch = useAppDispatch()
  const [updateVariable] = useUpdateCalcVariableMutation()

  const activeTable = element.pricing_tables_attributes[tableIndex]
  const activeRow = activeTable.line_items_attributes[rowIndex]
  const activeVariable = calc_variables.find(variable => variable.id === activeRow.variable_id)

  const onUnitDescriptorChange = (unit_descriptor: string) => {
    const tableRows = [...activeTable.line_items_attributes]
    tableRows[rowIndex] = { ...tableRows[rowIndex], unit_descriptor }
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, line_items_attributes: tableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onRowItemQuantityChange = (quantity: number) => {
    const tableRows = [...activeTable.line_items_attributes]
    tableRows[rowIndex] = { ...tableRows[rowIndex], quantity }
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, line_items_attributes: tableRows }
      }
      return table
    })
    trackQuantityChange({
      quantity,
      table_id: activeTable.id,
      line_item: activeRow,
      variables: calc_variables
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onQuantityChangeUpClick = (_e: React.MouseEvent<HTMLButtonElement>) => onRowItemQuantityChange(activeRow.quantity + 1)
  const onQuantityChangeDownClick = (_e: React.MouseEvent<HTMLButtonElement>) => onRowItemQuantityChange(activeRow.quantity - 1)
  const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)
    if (activeRow.adjustable_quantity && activeRow.min_quantity && value < activeRow.min_quantity) {
      return onRowItemQuantityChange(activeRow.min_quantity)
    } else if (activeRow.adjustable_quantity && activeRow.max_quantity && value > activeRow.max_quantity) {
      return onRowItemQuantityChange(activeRow.max_quantity)
    }
    return onRowItemQuantityChange(!value ? 1 : value)
  }

  const onActiveVariableValueChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) {
      const updatedVariables = calc_variables.map(variable => {
        if (variable.id === activeVariable!.id) {
          return { ...variable, default: +e.target.value }
        }
        return variable
      }
      )
      trackQuantityChange({
        quantity: null,
        table_id: activeTable.id,
        line_item: activeRow,
        variables: updatedVariables
      })
      return dispatch(onShadowCalcVariableUpdate({ ...activeVariable!, default: +e.target.value }))
    }
    await updateVariable({ ...activeVariable!, default: e.target.value })
  }
  const onActiveVariableValueChangeUpClick = async (_e: React.MouseEvent<HTMLButtonElement>) => {
    if (isReadOnly) {
      const updatedVariables = calc_variables.map(variable => {
        if (variable.id === activeVariable!.id) {
          return { ...variable, default: (variable.default + (variable.step || 1)) }
        }
        return variable
      }
      )
      trackQuantityChange({
        quantity: null,
        table_id: activeTable.id,
        line_item: activeRow,
        variables: updatedVariables
      })
      return dispatch(onShadowCalcVariableUpdate({ ...activeVariable!, default: (activeVariable!.default + (activeVariable?.step || 1)) }))
    }
    await updateVariable({ ...activeVariable!, default: (activeVariable!.default + (activeVariable?.step || 1)).toString() })
  }
  const onActiveVariableValueChangeDownClick = async (_e: React.MouseEvent<HTMLButtonElement>) => {
    if (isReadOnly) {
      const updatedVariables = calc_variables.map(variable => {
        if (variable.id === activeVariable!.id) {
          return { ...variable, default: (variable.default - (variable.step || 1)) }
        }
        return variable
      }
      )
      trackQuantityChange({
        quantity: null,
        table_id: activeTable.id,
        line_item: activeRow,
        variables: updatedVariables
      })
      return dispatch(onShadowCalcVariableUpdate({ ...activeVariable!, default: (activeVariable!.default - (activeVariable?.step || 1)) }))
    }
    await updateVariable({ ...activeVariable!, default: (activeVariable!.default - (activeVariable?.step || 1)).toString() })
  }

  return (
    <TableCell
      component={isMobileView ? 'div' : undefined}
      align="left"
      sx={{ padding: isMobileView ? '2px' : '10px 5px', borderBottom: 'unset', minWidth: isMobileView ? 'unset' : '100px' }}
      className="border-t border-gray-200">
      <div className={`${activeTable.hide_quantity ? "opacity-20" : ""}`}>
        <div className="flex gap-1">
          <div className="flex">
            <input
              className={`${activeRow.adjustable_quantity ? "adjustable-quantity-inline-input" : "discrete-inline-input"} w-full text-sm text-center`}
              value={activeVariable ? activeVariable.default : activeRow.quantity}
              onChange={activeVariable ? onActiveVariableValueChange : onQuantityChange}
              type="number"
              min={1}
            />
            {activeRow.adjustable_quantity &&
              <div className="flex flex-col">
                <IconButton
                  btnClassName="pricing-quantity-button"
                  icon="faChevronUp"
                  disabled={activeVariable
                    ? activeVariable.default >= activeVariable.maximum!
                    : !!activeRow.max_quantity ? activeRow.quantity >= activeRow.max_quantity : false
                  }
                  onClick={activeVariable ? onActiveVariableValueChangeUpClick : onQuantityChangeUpClick}
                />
                <IconButton
                  btnClassName="pricing-quantity-button"
                  icon="faChevronDown"
                  disabled={activeVariable
                    ? activeVariable.default <= activeVariable.minimum!
                    : !!activeRow.min_quantity ? activeRow.quantity <= activeRow.min_quantity : false
                  }
                  onClick={activeVariable ? onActiveVariableValueChangeDownClick : onQuantityChangeDownClick}
                />
              </div>
            }
          </div>
          <EditableField
            value={activeRow.unit_descriptor}
            multiline={false}
            placeholder="Unit"
            onChange={onUnitDescriptorChange}
            inputClassName="w-full text-sm text-black paragraph-font-family"
          />
        </div>
      </div>
    </TableCell>
  )
}

export default QuantityCell