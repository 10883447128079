import React, { memo, useState, useRef } from "react"
import { useEditorRef, focusEditor } from "@udecode/plate-common/react"
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { findNode } from "@udecode/plate-common";

import { PlateSectionElement, createDefaultSectionElement } from "../../../plate-config/Plugins/Section/Section.plugin"
import LayoutMenu from "./LayoutMenu/LayoutMenu";
import TooltipIcon from "../../../shared/ToolTip/TooltipIcon";
import { useAppSelector } from "../../../store/hooks/redux-hooks";

type Props = {
  sectionElement: PlateSectionElement,
}

const BottomMenu = memo(({ sectionElement }: Props) => {
  const editor = useEditorRef()
  // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
  // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
  const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
  // ----------------------------------------------Custom layout menu handlers start ----------------------------------------------
  //  This allow to ignore parent hover and let the menu stay open.
  const [open, setOpen] = useState(false);
  const timerRef = useRef<number | null>(null);

  const handleMouseEnter = () => {
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setOpen(true);
  };

  const handleMouseLeave = () => {
    timerRef.current = window.setTimeout(() => {
      setOpen(false);
    }, 500);
  };
  // ----------------------------------------------Custom layout menu handlers end ----------------------------------------------
  const node = findNode(editor, { at: [], match: { id: sectionElement.id } })!
  const nodePath = node[1][0]

  const onSectionAdd = () =>
    editor.insertNodes(createDefaultSectionElement(), { at: [nodePath + 1], select: true })
  const addFromLayout = (children: any[]) => {
    setOpen(false)
    editor.insertNodes({ ...createDefaultSectionElement(), children }, { at: [nodePath + 1], select: true })
    focusEditor(editor)
  }

  return (
    <>
      <div contentEditable={false} suppressContentEditableWarning className="flex justify-center absolute bottom-0 w-full z-[0] border-b border-gray-400 ">
        <div className="line-buttons relative h-4 top-2 w-full hover:bg-blue-400/25 cursor-pointer transition-all duration-300 opacity-0 group-hover/bottom-menu:opacity-100">
          <div className="btn-group flex justify-center shadow-none absolute top-[-10px] left-0 right-0">
            <TooltipIcon
              tooltip='New Blank Section'
              side='top'
              container={sectionElement.id}
              icon='faPlus'
              onClick={onSectionAdd}
              iconWrapperClassName="btn btn-small btn-white first-child"
            />
            <div>
              <PopoverPrimitive.Root open={open} onOpenChange={setOpen}>
                <PopoverPrimitive.Trigger contentEditable={false} suppressContentEditableWarning>
                  <TooltipIcon
                    tooltip="Create From Layout"
                    side="top"
                    container={sectionElement.id}
                    icon="faTableColumns"
                    iconWrapperClassName="btn btn-small btn-white last-child"
                  />
                </PopoverPrimitive.Trigger>
                <PopoverPrimitive.Portal>
                  <PopoverPrimitive.Content
                    contentEditable={false}
                    suppressContentEditableWarning
                    onOpenAutoFocus={e => e.preventDefault()}
                    onCloseAutoFocus={e => e.preventDefault()}
                    onFocusOutside={e => e.preventDefault()}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    sideOffset={5}
                    align="center"
                    alignOffset={-40}
                    className="w-full max-h-96 max-w-[410px] overflow-auto border-solid border p-4 border-gray-200 rounded-xl bg-gray-200 drop-shadow z-30"
                  >
                    <LayoutMenu addFromLayout={addFromLayout} />
                  </PopoverPrimitive.Content>
                </PopoverPrimitive.Portal>
              </PopoverPrimitive.Root>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default BottomMenu
