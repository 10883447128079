import React from "react"

import { Popover, PopoverArrow, PopoverContent, PopoverPortal, PopoverTrigger } from "../../../../shared/Popover/Popover"
import EditIcon from "../../EditIcon/EditIcon"
import PopoverSelectorTitle from "../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"
import LabeledInput from "../../../../shared/Inputs/LabeledInput"
import FormulaInput from "../../../../shared/FormulaInput/FormulaInput";
import { StoreCalcVariable } from "../../../../store/reducers/page_calc_variables/page_calc_variables"
import { calculateAndFormat } from "../../../../utils/calc_plugins.util"
import { PlateAssessmentElement } from "../../../../plate-config/Plugins/Assessment/Assessment.plugin"


type Props = {
  element: PlateAssessmentElement,
  calc_variables: StoreCalcVariable[]
  onStatPrefixChange: (stat_prefix: string) => void
  onStatSuffixChange: (stat_suffix: string) => void
  onStatFormulaChange: (calculation: string) => void
  isEditorReadOnly: boolean
  textColor: string
}

const HeadCalcValue = ({
  element, calc_variables, onStatPrefixChange, onStatSuffixChange,
  onStatFormulaChange, isEditorReadOnly, textColor
}: Props) => {

  const { stat_prefix, stat_suffix, stat_formula_attributes } = element.assessment_detail_attributes

  const sliderVar = calc_variables.find(variable => variable.id === element.assessment_detail_attributes.slider_variable_id)!

  const onStatPrefixHandler = (e: React.ChangeEvent<HTMLInputElement>) => onStatPrefixChange(e.target.value)

  const onStatSuffixHandler = (e: React.ChangeEvent<HTMLInputElement>) => onStatSuffixChange(e.target.value)

  return (
    <div className="relative flex justify-center items-baseline mb-4">
      <div className="relative">
        <div className={`text-7xl header-font-family text-${textColor}`}>
          {stat_prefix && <span className="text-3xl align-top self-start mr-1">{stat_prefix}</span>}
          {calculateAndFormat({ formula: stat_formula_attributes.calculation, variables: calc_variables, sliderValue: sliderVar.default, type: 'number' })}
          {stat_suffix && <span className="text-3xl ml-1">{stat_suffix}</span>}
        </div>
        {!isEditorReadOnly && <div className="absolute top-4 -right-3 translate-x-full z-20 font-base">
          <Popover>
            <PopoverTrigger>
              <EditIcon />
            </PopoverTrigger>
            <PopoverPortal>
              <PopoverContent sideOffset={5}>
                <PopoverArrow />
                <div className="w-80 border-solid border border-gray-200 rounded-xl bg-white drop-shadow z-30">
                  <div className="p-5 flex flex-col gap-4">
                    <PopoverSelectorTitle title="Statistic Options" />
                    <div className="flex gap-4">
                      <LabeledInput
                        type="text"
                        htmlFor="stat-prefix"
                        label="Prefix"
                        value={stat_prefix}
                        onChange={onStatPrefixHandler}
                      />
                      <LabeledInput
                        type="text"
                        htmlFor="stat-suffix"
                        label="Suffix"
                        value={stat_suffix}
                        onChange={onStatSuffixHandler}
                      />
                    </div>
                    <FormulaInput
                      formula={stat_formula_attributes.calculation}
                      setFormula={onStatFormulaChange}
                      calc_variables={calc_variables}
                    />
                  </div>
                </div>
              </PopoverContent>
            </PopoverPortal>
          </Popover>
        </div>}
      </div>
    </div>
  )
}

export default HeadCalcValue