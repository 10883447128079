import { setDataSaved, setDataSaving } from "../../page_indicators/page_indicators";
import { onPageCalcVariableAdd, onPageCalcVariableRemove, onPageCalcVariableUpdate, setPageCalcVariables } from "../../page_calc_variables/page_calc_variables";
import { revvedupApi } from "../api";
import { PageVariable } from "../api.types";
import { getPageDataAttributes } from "../data-attributes.util";
import { setShadowCalcVariables } from "../../page-shadow-store/page_shadow_store";

export interface CreateCalcVariableInput {
  default: number
  label: string | null
  hint: string | null
  prefix: string | null
  suffix: string | null
  maximum: number | null
  minimum: number | null
}

export const variableEndpoints = revvedupApi.injectEndpoints({
  endpoints: (builder) => ({
    getCalcVariables: builder.query<PageVariable[], void>({
      query: () => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/pages/${requestData?.page_id}/formula_variables`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setPageCalcVariables(data))
            dispatch(setShadowCalcVariables(data))
          }
        } catch (err) {
          console.log('Calc variables loading error:', err);
        }
      }
    }),
    createCalcVariable: builder.mutation<PageVariable, CreateCalcVariableInput>({
      query: (variable) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/pages/${requestData?.page_id}/formula_variables`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: {
            variable: {
              ...variable,
              default: variable.default.toString()
            }
          },
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageCalcVariableAdd(data))
          }
        } catch (err) {
          console.log('Create calc variable error:', err);
        }
      }
    }),
    updateCalcVariable: builder.mutation<PageVariable, PageVariable>({
      query: ({ id, ...variable }) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/pages/${requestData?.page_id}/formula_variables/${id}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { variable },
        }
      },
      async onQueryStarted(variable, { dispatch, queryFulfilled }) {
        dispatch(setDataSaving())
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageCalcVariableUpdate({ ...variable, default: parseInt(variable.default) }))
          }
        } catch (err) {
          console.log('Update calc variable error:', err);
        }
        dispatch(setDataSaved())
      }
    }),
    deleteCalcVariable: builder.mutation<PageVariable, string>({
      query: (variableId) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/pages/${requestData?.page_id}/formula_variables/${variableId}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
        }
      },
      async onQueryStarted(variableId, { dispatch, queryFulfilled }) {
        dispatch(setDataSaving())
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageCalcVariableRemove(variableId))
          }
        } catch (err) {
          console.log('Delete calc variable error:', err);
        }
        dispatch(setDataSaved())
      }
    }),
  })
})

export const {
  useGetCalcVariablesQuery,
  useUpdateCalcVariableMutation,
  useDeleteCalcVariableMutation,
  useCreateCalcVariableMutation,
} = variableEndpoints
