import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import 'datatables.net';

export default class extends Controller {
  static values = {
    paging: { type: Boolean, default: false },
    info: { type: Boolean, default: false },
    searching: { type: Boolean, default: false }
  }

  myTable = null
  isDisconnecting = false

  connect() {
    if (!this.myTable) {
      const tableElement = this.element.matches('table')
        ? this.element
        : this.element.querySelector('table');
      this.myTable = $(tableElement).DataTable(this.tableOptions());
    }
  }

  disconnect() {
    if (this.isDisconnecting) return;
    this.isDisconnecting = true;

    if (this.myTable) {
      this.myTable.destroy();
      this.myTable = null;
    }
  }

  // We can change the order by using the default HTML5 behavior for datatables.
  // table.table.w-full data-controller="table" data-order='[[0, "desc"]]'
  tableOptions() {
    const columnDefs = Array.from(this.element.querySelectorAll('th')).map((th, index) => {
      return { targets: index, orderable: th.dataset.sortable !== 'false' };
    });

    // console.log("columnDefs")
    // console.log(columnDefs)

    return (
      {
        paging: this.pagingValue,
        info: this.infoValue,
        searching: this.searchingValue,
        dom: "rtip",
        order: [[0, 'asc']],
        columnDefs: columnDefs,
      }
    )
  }

  search(event) {
    console.log("standard tableController search")
    return this.myTable.search(event.target.value).draw()
  }
}
