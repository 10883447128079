import React from "react";
import { withRef } from '@udecode/cn';
import { findNode, getParentNode } from "@udecode/plate-common";
import { isEditorReadOnly, PlateElement } from "@udecode/plate-common/react";
import { useSelected } from 'slate-react';

import { Popover, PopoverContent, PopoverTrigger, PopoverPortal } from "../../shared/Popover/Popover";
import { PlateHrElement } from "../../plate-config/Plugins/HrLine/HrLine.plugin";
import { colorClassToRGB } from "../../utils/color.util";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import { ColumnPlugin } from "../../plate-config/Plugins/ColumnGroup/Column.plugin";
import ToolbarSeparator from "../../shared/ToolbarControls/ToolbarSeparator";
import ToolbarButton from "../../shared/ToolbarControls/ToolbarButton";
import ToolbarDropdown from "../../shared/ToolbarControls/ToolbarDropdown";
import DropdownOptionItem from "../../shared/ToolbarControls/DropdownOptionItem";
import ToolbarColorDropdown from '../../shared/ToolbarControls/ToolbarColorDropdown'


export const HrElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const block = element as PlateHrElement

    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const selected = useSelected();
    const isReadOnly = isEditorReadOnly(editor);
    const { activePalette } = useAppSelector(state => state.page_colors)

    const lineColor = block.color || 'color-black'
    const nodePath = findNode(editor, { at: [], match: { id: block.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === block.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === block.id;
    const parentNode = getParentNode(editor, nodePath)!
    const parentNodeType = parentNode[0].type as string
    const isElColumnChild = parentNodeType === ColumnPlugin.key
    const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === block.id : false

    const preventDefault = (e: Event) => e.preventDefault()
    const onColorChange = (color: string) => editor.setNodes({ color } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
    const onElementDelete = () => editor.removeNodes({ at: [], match: (n: any) => n.id === block.id })
    const onStyleChange = (style: string) => editor.setNodes({ style } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
    const onColorReset = () => editor.setNodes({ color: null } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

    const lineOptions = [
      { name: 'straight', label: <DropdownOptionItem iconName="solidLine" label="Straight" /> },
      { name: 'dash', label: <DropdownOptionItem iconName="dashedLine" label="Dash" /> },
    ]
    const lineStyles = block.style === 'straight' ? { backgroundColor: colorClassToRGB(lineColor, 100, activePalette.colors)! } : {
      borderColor: colorClassToRGB(lineColor, 100, activePalette.colors)!,
      borderWidth: '0 0 2px 0',
      borderStyle: 'dashed',
    }
    const lineClassName = `${isReadOnly ? 'cursor-default' : 'cursor-pointer'}  w-full ${block.style === 'straight' ? 'h-[2px] border-none' : ''}`

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={element}
        className={`
        ${className} 
        divider-block p-2 transition-all duration-300 ease-in-out rounded-lg
        ${isFirstChild ? 'first-section-child' : ""} 
        ${isLastChild ? 'last-section-child' : ""}
        ${isElColumnChild
            ? isLastColElement ? "mb-0" : `mb-4`
            : `page-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
          }
        ${!isReadOnly
            ? `${selected
              ? 'border-blue-500/60 border hover:border-blue-500/60'
              : 'hover:border-blue-500/30 border border-transparent'}`
            : ''
          } 
        `}
        contentEditable={false}
        suppressContentEditableWarning
        data-id={block.id}
        {...props}
      >
        <Popover open={isReadOnly ? false : selected}>
          <PopoverTrigger asChild>
            <hr style={lineStyles} className={lineClassName} />
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent
              onOpenAutoFocus={preventDefault}
              onCloseAutoFocus={preventDefault}
              side='top'
              sideOffset={10}
              align='center'
              className="block_toolbar"
            >
              <ToolbarDropdown
                onOptionSet={onStyleChange}
                label={block.style === 'straight' ? 'Straight' : 'Dash'}
                iconBeforeLabel={block.style === 'straight' ? 'solidLine' : 'dashedLine'}
                activeOption={block.style}
                data={lineOptions}
              />
              <ToolbarSeparator />
              <ToolbarColorDropdown
                color={block.color}
                onColorChange={onColorChange}
                onColorReset={onColorReset}
                colorLabel="Color"
              />
              <ToolbarSeparator />
              <ToolbarButton
                onClick={onElementDelete}
                iconName='delete'
                type='delete'
              />
            </PopoverContent>
          </PopoverPortal>
        </Popover>
        {children}
      </PlateElement>
    );
  }
);

export default HrElement
