import React from 'react';

type Props = {
  counter: number
}

const FeedbackCounter: React.FC<Props> = ({ counter }) => {
  return (
    <span
      contentEditable={false}
      suppressContentEditableWarning
      className={`
      absolute bottom-2 right-0 transform translate-x-1/2 translate-y-1/2
      bg-red-500 text-white text-xs rounded-full w-[18px] h-[18px] leading-0
      text-center text-[10px]
      `}>
      {counter}
    </span>
  )
}

export default FeedbackCounter;