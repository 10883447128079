import { TElement, TNodeEntry } from "@udecode/plate-common"
import { createPlatePlugin, PlateEditor } from "@udecode/plate-common/react";
import { v4 as uuidv4 } from 'uuid';

// CRITICAL NOTE: In case of removing this and assessment plugin, also remove shadow store related to variables!

const extendEditor = ({ editor }) => {
  const { normalizeNode } = editor as PlateEditor;
  editor.normalizeNode = ([node, path]: TNodeEntry) => {
    if (node.type === PricingPlugin.key) {
      const pricingElement = node as PlatePricingElement;
      if (pricingElement.pricing_tables_attributes.length < 1) {
        return editor.removeNodes({ at: path });
      }
    }
    normalizeNode([node, path]);
  };
  return editor;
};

export const PricingPlugin = createPlatePlugin({
  key: 'pricing',
  node: {
    type: 'pricing',
    isVoid: true,
    isElement: true,
  },
  extendEditor: extendEditor
})


export interface PricingTableLineItem {
  id: string
  adjustable_quantity: boolean
  billing_frequency: string
  description: string | null
  discount_type: string
  discount_value: number
  kind: string
  max_quantity: number
  min_quantity: number
  optional: boolean
  optional_default: boolean
  quantity: number
  terms: number
  unit_descriptor: string
  unit_price_cents: number
  value_formula_attributes: {
    calculation: string
    notes: string | null
  }
  variable_id: string | null
}

export interface PricingTable {
  id: string
  hide_price: boolean
  hide_quantity: boolean
  hide_value: boolean
  select_type: string
  title: string | null
  line_items_attributes: PricingTableLineItem[]
}

export interface PlatePricingElement extends TElement {
  id: string,
  type: string,
  primary_color: string,
  secondary_color: string,
  style: string | null,
  pricing_tables_attributes: PricingTable[]
}

export const createDefaultTableRow = (): PricingTableLineItem => ({
  id: uuidv4(),
  adjustable_quantity: false,
  billing_frequency: "one_time",
  description: null,
  discount_type: "dollar",
  discount_value: 0,
  kind: "price",
  max_quantity: 10,
  min_quantity: 1,
  optional: false,
  optional_default: false,
  quantity: 1,
  terms: 0,
  unit_descriptor: "Unit",
  unit_price_cents: 100,
  value_formula_attributes: {
    calculation: "{{QTY}} * 100",
    notes: null,
  },
  variable_id: null
})

export const createDefaultTable = (): PricingTable => ({
  id: uuidv4(),
  hide_price: false,
  hide_quantity: false,
  hide_value: false,
  select_type: 'multiple',
  title: null,
  line_items_attributes: [createDefaultTableRow()]
})

export const createDefaultPricingElement = () => ({
  type: PricingPlugin.key,
  primary_color: '#ffffff',
  secondary_color: '#ffffff',
  style: null,
  pricing_tables_attributes: [createDefaultTable()],
  children: [{ text: '' }],
})

// Local version of the plugin interface to control selected items
//  Note difference in line_items_attributes -> selected property
export interface PlatePricingLocalElement extends TElement {
  id: string,
  type: string,
  primary_color: string,
  secondary_color: string,
  pricing_tables_attributes: {
    id: string
    hide_price: boolean
    hide_quantity: boolean
    hide_value: boolean
    select_type: string
    title: string | null
    line_items_attributes: {
      id: string
      adjustable_quantity: boolean
      billing_frequency: string
      description: string | null
      discount_type: string
      discount_value: number
      kind: string
      max_quantity: number
      min_quantity: number
      optional: boolean
      optional_default: boolean
      quantity: number
      terms: number
      unit_descriptor: string
      unit_price_cents: number
      value_formula_attributes: {
        calculation: string
        notes: string | null
      }
      variable_id: string | null
      selected: boolean
    }[]
  }[]
}