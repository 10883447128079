import React from 'react';
import {
  Layout3ColumnIcon, Layout2ColumnIcon, Delete02Icon, ArrowDown01Icon, DragDropVerticalIcon,
  AlignBoxMiddleCenterIcon, Image01Icon, Tick02Icon, ArrowReloadHorizontalIcon, MoreHorizontalCircle01Icon,
  AlignBoxTopCenterIcon, AlignBoxBottomCenterIcon, SolidLine01Icon, DashedLine01Icon, AlignBoxMiddleLeftIcon,
  AlignBoxMiddleRightIcon, SquareIcon, CircleIcon, TriangleIcon, StarIcon, LinkSquare01Icon, PencilEdit01Icon,
  ParagraphIcon, Heading01Icon, Heading02Icon, QuoteUpIcon, LeftToRightListBulletIcon, TextBoldIcon,
  LeftToRightListNumberIcon, TextItalicIcon, TextUnderlineIcon, TextStrikethroughIcon, ArrowTurnBackwardIcon,
  Link01Icon, Globe02Icon, PencilEdit02Icon, Alert01Icon, Unlink03Icon, SmileIcon, Video01Icon, FileImportIcon,
  MagicWand03Icon, VariableIcon, BootstrapIcon
} from 'hugeicons-react';

import { ellipse, Layout4ColumnIcon, parallelogram, sizeSmall, sizeLarge, sizeMedium } from './CustomIcons';

export type HugeIconName =
  'twoColumn' | 'threeColumn' | 'delete' | 'arrowDown' | 'dragDrop' | 'menu' |
  'alignBoxMiddleCenter' | 'image' | 'tick' | 'moreHorizontal' | 'arrowReloadHorizontal' |
  'alignBoxTopCenter' | 'alignBoxBottomCenter' | 'fourColumn' | 'solidLine' | 'dashedLine' |
  'alignBoxMiddleLeft' | 'alignBoxMiddleRight' | 'square' | 'circle' | 'triangle' | 'star' |
  'parallelogram' | 'ellipse' | 'linkSquare' | 'pencilEdit' | 'sizeSmall' | 'sizeMedium' |
  'sizeLarge' | 'paragraph' | 'heading1' | 'heading2' | 'quote' | 'listUl' | 'listOl' |
  'bold' | 'italic' | 'underline' | 'strikethrough' | 'arrowTurnBackward' | 'link' | 'globe' |
  'pencil02Edit' | 'alert' | 'unlink' | 'smile' | 'solidLine' | 'video' | 'fileImport' | 'magicWand' |
  'variable' | 'bootstrap';


interface IconProps {
  name: HugeIconName;
  size?: number | string;
  color?: string;
  className?: string;
  strokeWidth?: string | number;
}

const ICONS = {
  twoColumn: Layout2ColumnIcon, threeColumn: Layout3ColumnIcon, delete: Delete02Icon,
  arrowDown: ArrowDown01Icon, dragDrop: DragDropVerticalIcon, alignBoxMiddleCenter: AlignBoxMiddleCenterIcon,
  image: Image01Icon, tick: Tick02Icon, moreHorizontal: MoreHorizontalCircle01Icon,
  arrowReloadHorizontal: ArrowReloadHorizontalIcon, alignBoxTopCenter: AlignBoxTopCenterIcon,
  alignBoxBottomCenter: AlignBoxBottomCenterIcon, fourColumn: Layout4ColumnIcon,
  solidLine: SolidLine01Icon, dashedLine: DashedLine01Icon, alignBoxMiddleLeft: AlignBoxMiddleLeftIcon,
  alignBoxMiddleRight: AlignBoxMiddleRightIcon, square: SquareIcon, circle: CircleIcon,
  triangle: TriangleIcon, star: StarIcon, parallelogram: parallelogram, ellipse: ellipse,
  linkSquare: LinkSquare01Icon, pencilEdit: PencilEdit01Icon, sizeSmall: sizeSmall, sizeMedium: sizeMedium,
  sizeLarge: sizeLarge, paragraph: ParagraphIcon, heading1: Heading01Icon, heading2: Heading02Icon,
  quote: QuoteUpIcon, listUl: LeftToRightListBulletIcon, listOl: LeftToRightListNumberIcon,
  bold: TextBoldIcon, italic: TextItalicIcon, underline: TextUnderlineIcon, strikethrough: TextStrikethroughIcon,
  arrowTurnBackward: ArrowTurnBackwardIcon, link: Link01Icon, globe: Globe02Icon, pencil02Edit: PencilEdit02Icon,
  alert: Alert01Icon, unlink: Unlink03Icon, smile: SmileIcon, video: Video01Icon, fileImport: FileImportIcon,
  magicWand: MagicWand03Icon, variable: VariableIcon, bootstrap: BootstrapIcon
};

const HugeIcon: React.FC<IconProps> = ({ name, color, size, className, strokeWidth }) => {
  const IconComponent = ICONS[name];
  if (!IconComponent) return null;
  return <IconComponent aria-hidden className={`pointer-events-none ${className}`} size={size} color={color} strokeWidth={strokeWidth} />;
};

export default HugeIcon;
