import React, { memo } from "react"
import { twMerge } from "tailwind-merge"

import Icon from "../Icon/Icon"
import Tooltip from "./Tooltip"

type Props = {
  tooltip: string
  side: "top" | "right" | "bottom" | "left" | undefined
  container: string
  icon?: string
  onClick: (e?: any) => void
  disabled?: boolean
  iconClassName?: string
  btnClassName?: string
  title?: string
}

const TooltipButton: React.FC<Props> = memo(({ tooltip, side, container, icon, onClick, disabled, iconClassName, btnClassName, title }) => {
  return (
    <Tooltip content={tooltip} open={!tooltip.trim() ? false : undefined} side={side} container={container}>
      <button
        className={twMerge('', btnClassName)}
        onClick={onClick}
        disabled={disabled}
      >
        {icon && <Icon icon={icon} className={iconClassName} />}
        {title && title}
      </button>
    </Tooltip>
  )
})

export default TooltipButton