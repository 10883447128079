import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { Resizable } from "re-resizable";
import { PlateElement, useEditorRef } from '@udecode/plate-common/react';
import { useSelected } from 'slate-react';
import { findNode, getParentNode } from '@udecode/plate-common';

import { Popover, PopoverContent, PopoverTrigger, PopoverPortal } from "../../../shared/Popover/Popover"
import { PlateVideoElement } from '../../../plate-config/Plugins/Video/Video.plugin';
import { ColumnPlugin } from '../../../plate-config/Plugins/ColumnGroup/Column.plugin';
import ToolbarButton from '../../../shared/ToolbarControls/ToolbarButton';
import ToolbarSeparator from '../../../shared/ToolbarControls/ToolbarSeparator';
import DropdownOptionItem from '../../../shared/ToolbarControls/DropdownOptionItem';
import ToolbarDropdown from '../../../shared/ToolbarControls/ToolbarDropdown';
import ToolbarAlignDropdown from '../../../shared/ToolbarControls/ToolbarAlignDropdown';

type Props = {
  element: PlateVideoElement
  ref: any,
  className?: string,
  isFirstChild: boolean,
  isLastChild: boolean,
  setEditMode: (mode: boolean) => void
  plateElProps: any
  children: React.ReactNode;
  isReadOnly: boolean;
}

const VideoPlayer: React.FC<Props> = ({
  ref, element, className, isFirstChild, isLastChild,
  setEditMode, plateElProps, isReadOnly, children
}) => {
  const editor = useEditorRef();
  const selected = useSelected()

  const [size, setSize] = useState({ width: element.width ? element.width : '100%' });
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(selected)
  }, [selected])

  const onSizeChange = (width: string) =>
    editor.setNodes(
      { width } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === element.id } }
    )

  const onAlignChange = (align: string) =>
    editor.setNodes(
      { align } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === element.id } }
    )

  const onEditClick = () => setEditMode(true)

  const onElementDelete = () => editor.removeNodes({ at: [], match: (n: any) => n.id === element.id })

  const alignOptions: any[] = [
    { name: 'left', label: <DropdownOptionItem iconName="alignBoxMiddleLeft" />, iconName: "alignBoxMiddleLeft" },
    { name: 'center', label: <DropdownOptionItem iconName="alignBoxMiddleCenter" />, iconName: "alignBoxMiddleCenter" },
    { name: 'right', label: <DropdownOptionItem iconName="alignBoxMiddleRight" />, iconName: "alignBoxMiddleRight" },
  ]
  const currentAlign = element.align ? element.align : 'left'

  const url = element.url as string
  const isLoomVideo = /loom\.com/i.test(url)
  const isVidyardVideo = /vidyard\.com/i.test(url)
  const isActive = !isReadOnly ? open : false
  const nodePath = findNode(editor, { at: [], match: { id: element.id } })![1];
  const parentNode = getParentNode(editor, nodePath)!
  const parentNodeType = parentNode[0].type as string
  const isElColumnChild = parentNodeType === ColumnPlugin.key
  const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false

  return (
    <PlateElement
      ref={ref}
      id={element.id}
      editor={editor}
      data-id={element.id}
      element={element}
      className={`
        ${className} p-2 rounded-lg transition-all duration-300 ease-in-out
         ${isElColumnChild
          ? isLastColElement ? "mb-0" : "mb-4"
          : `page-block video-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
        }
         ${!isReadOnly
          ? `${selected
            ? 'border-blue-500/60 border hover:border-blue-500/60'
            : 'hover:border-blue-500/30 border border-transparent'}`
          : ''
        } 
        `}
      data-plate-selectable
      contentEditable={false}
      suppressContentEditableWarning
      {...plateElProps}
    >
      <Popover open={isActive} onOpenChange={setOpen}>
        <PopoverTrigger className="w-full block">
          <div
            className={`flex 
          ${element.align === 'left' ? 'justify-start' : ''}
          ${element.align === 'center' ? 'justify-center' : ''}   
          ${element.align === 'right' ? 'justify-end' : ''}
          `}>
            <Resizable
              defaultSize={{ width: size.width, height: '100%' }}
              onResize={(_e, _direction, ref) => setSize({ width: ref.style.width })}
              onResizeStop={(_e, _direction, ref) => onSizeChange(ref.style.width)}
              minWidth={'30%'}
              maxWidth={'100%'}
              className={`video-block-wrapper resizer-wrapper ${isActive && selected ? 'active' : ''}`}
              handleClasses={{
                top: 'pointer-events-none', right: 'pointer-events-none',
                bottom: 'pointer-events-none', left: 'pointer-events-none',
              }}
              handleComponent={{
                top: <div className="resizer_border top" />,
                bottom: <div className="resizer_border bottom" />,
                right: <div className="resizer_border right" />,
                left: <div className="resizer_border left" />,
                bottomRight: <div className="resizer_dot bottom-right" />,
                bottomLeft: <div className="resizer_dot bottom-left" />,
                topRight: <div className="resizer_dot top-right" />,
                topLeft: <div className="resizer_dot top-left" />,
              }}
              enable={isReadOnly ? false : selected ? undefined : false}
            >
              <div className="video-player-wrapper aspect-video">
                {isLoomVideo || isVidyardVideo
                  ? <iframe
                    src={isLoomVideo ? url.replace(/share/i, "embed") : url}
                    allowFullScreen
                    style={{
                      position: "absolute", top: "0", left: "0",
                      width: "100%", height: "100%",
                    }}
                  />
                  : <ReactPlayer url={url} className="react-player" width="100%" height="100%" />
                }
              </div>
            </Resizable>
            {children}
          </div>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent
            side="top"
            align="center"
            className="block_toolbar"
          >
            <ToolbarAlignDropdown onAlignChange={onAlignChange} currentAlign={currentAlign} />
            <ToolbarSeparator />
            <ToolbarButton
              onClick={onEditClick}
              iconName='pencilEdit'
            />
            <ToolbarSeparator />
            <ToolbarButton
              onClick={onElementDelete}
              iconName='delete'
              type="delete"
            />
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PlateElement>
  )
}

export default VideoPlayer