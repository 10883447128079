import React from "react";
import { PlateRenderElementProps } from "@udecode/plate-common/react";

import { useAppSelector } from "../../store/hooks/redux-hooks";
import { PlateTemplateVariableElement } from "../../plate-config/Plugins/TemplateVariableCommand/TemplateVariableCommand.plugin";

const TemplateVariableElement = ({
  attributes,
  children,
  element,
}: PlateRenderElementProps) => {
  const template_variables = useAppSelector(state => state.page_template_variables)
  const isTemplate = useAppSelector(state => state.page_addendums.template)
  if (!isTemplate) return <>{children}</>

  const block = element as PlateTemplateVariableElement
  const variable = template_variables.find((variable) => variable.value === block.variableId)

  return (
    <span
      data-id={block.id}
      className="inline-block cursor-pointer rounded-md bg-gray-200 px-1.5 py-0.5 align-baseline font-medium mx-1"
      contentEditable={false}
      suppressContentEditableWarning={true}
      {...attributes}
    >
      <span>{"{{"}</span>{variable?.name}<span>{"}}"}</span>
      {children}
    </span>
  )
}

export default TemplateVariableElement

