import { setDataSaved, setDataSaving } from "../../page_indicators/page_indicators";
import { onPageNavBgColorChange, onPageNavLogoVisibleChange, onPageNavTextColorChange, onPageNavVisibilityChange } from "../../page_navigation/page_navigation";
import { revvedupApi } from "../api";
import { getPageDataAttributes } from "../data-attributes.util";


export const navBarEndpoints = revvedupApi.injectEndpoints({
  endpoints: (builder) => ({
    updateNavDisplayLogoAttribute: builder.mutation<any, boolean>({
      query: (nav_display_logo) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { nav_display_logo } },
        }
      },
      async onQueryStarted(nav_display_logo, { dispatch, queryFulfilled }) {
        dispatch(setDataSaving())
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageNavLogoVisibleChange(nav_display_logo))
          }
        } catch (err) {
          console.log('Update nav display logo attribute error:', err);
        }
        dispatch(setDataSaved())
      }
    }),
    updateNavVisibilityAttribute: builder.mutation<any, boolean>({
      query: (nav_enabled) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { nav_enabled } },
        }
      },
      async onQueryStarted(nav_enabled, { dispatch, queryFulfilled }) {
        dispatch(setDataSaving())
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageNavVisibilityChange(nav_enabled))
          }
        } catch (err) {
          console.log('Update nav visibility attribute error:', err);
        }
        dispatch(setDataSaved())
      }
    }),
    updateNavBgColorAttribute: builder.mutation<any, string>({
      query: (nav_background_color) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { nav_background_color } },
        }
      },
      async onQueryStarted(nav_background_color, { dispatch, queryFulfilled }) {
        dispatch(setDataSaving())
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageNavBgColorChange(nav_background_color))
          }
        } catch (err) {
          console.log('Update nav bg color attribute error:', err);
        }
        dispatch(setDataSaved())
      }
    }),
    updateNavTextColorAttribute: builder.mutation<any, string>({
      query: (nav_text_color) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { nav_text_color } },
        }
      },
      async onQueryStarted(nav_text_color, { dispatch, queryFulfilled }) {
        dispatch(setDataSaving())
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageNavTextColorChange(nav_text_color))
          }
        } catch (err) {
          console.log('Update nav bg color attribute error:', err);
        }
        dispatch(setDataSaved())
      }
    }),
  })
})

export const {
  useUpdateNavDisplayLogoAttributeMutation,
  useUpdateNavVisibilityAttributeMutation,
  useUpdateNavBgColorAttributeMutation,
  useUpdateNavTextColorAttributeMutation
} = navBarEndpoints

