import React, { useState } from 'react';
import EmojiPicker, { EmojiClickData, EmojiStyle } from 'emoji-picker-react';
import { withRef } from '@udecode/cn';
import { findNode, insertNodes } from '@udecode/plate-common';
import { PlateElement, isEditorReadOnly } from '@udecode/plate-common/react';

import {
  InlineCombobox,
  InlineComboboxContent,
  InlineComboboxEmojiInput,
  InlineEmojiComboboxItem,
} from '../InlineCombobox/InlineCombobox';


export const EmojiInputElement = withRef<typeof PlateElement>(
  ({ className, ...props }, ref) => {
    const { children, editor, element } = props;
    const isReadOnly = isEditorReadOnly(editor)
    const [close, setClose] = useState(false)
    if (isReadOnly) return <>{children}</>

    const slashNode = findNode(editor, { at: [], match: (n: any) => n.id === props.element.id })!
    const onEmojiClick = (emojiObject: EmojiClickData) => {
      setClose(true)
      insertNodes(editor, { text: emojiObject.emoji }, { at: slashNode[1] })
    }


    return (
      <PlateElement
        as="span"
        data-slate-value={element.value}
        className={className}
        ref={ref}
        data-id={element.id}
        {...props}
      >
        <InlineCombobox element={element} trigger=":">
          <InlineComboboxEmojiInput />
          <InlineComboboxContent>
            <InlineEmojiComboboxItem
              value={'dummy value'}
              className='p-0'
              removeInputOnClick={close}
            >
              <EmojiPicker
                autoFocusSearch={true}
                emojiStyle={EmojiStyle.NATIVE}
                lazyLoadEmojis={true}
                skinTonesDisabled={true}
                onEmojiClick={onEmojiClick}
                height={'420px'}
                width={'350px'}
              />
            </InlineEmojiComboboxItem>
          </InlineComboboxContent>
        </InlineCombobox>
        {children}
      </PlateElement>
    );
  }
);
