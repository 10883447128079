import React, { ChangeEvent, useState } from "react";

import { PopoverArrow, PopoverPortal, Popover, PopoverContent, PopoverTrigger } from "../../../shared/Popover/Popover";
import Icon from "../../../shared/Icon/Icon";
import TemplateVariableItem from "./TemplateVariableItem";
import TemplateVariableForm from "./TemplateVariableForm";
import { useAppSelector } from "../../../store/hooks/redux-hooks";
import TooltipIcon from "../../../shared/ToolTip/TooltipIcon";
import { useAddTemplateVariableMutation } from "../../../store/reducers/api/template_variables/template_variables.api";

type Props = {
  buttonClasses: string
}


const TemplateVariablePopover = ({ buttonClasses }: Props) => {
  const template_variables = useAppSelector(state => state.page_template_variables);
  const [search, setSearch] = useState("");
  const [showVariableInput, setShowVariableInput] = useState(false);
  const [addTemplateVariable, { isLoading }] = useAddTemplateVariableMutation();

  const createTemplateVarHandle = async (variableName: string) => await addTemplateVariable(variableName).unwrap().then(() => setShowVariableInput(false))


  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch(e.target.value);
  }

  return (
    <Popover onOpenChange={(open: boolean) => !open && setSearch('')}>
      <PopoverTrigger>
        <TooltipIcon
          tooltip="Template Variables"
          side="bottom"
          container="tooltip-anchor-navBar"
          icon="faCode"
          iconWrapperClassName={buttonClasses}
        />
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          sideOffset={5}
          align="end"
        >
          <PopoverArrow />
          <div
            id="variable-popover"
            className="w-96 border-solid border border-gray-200 rounded-xl bg-white drop-shadow z-30"
          >
            {isLoading && (
              <div className="absolute inset-0 bg-gray-900 bg-opacity-50 flex flex-col items-center justify-center z-50">
                <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500" />
              </div>
            )}
            <div className="p-4 border-b border-gray-200">
              <div className="flex flex-row justify-between items-center">
                <h3 className="font-semibold text-gray-800 text-lg">Variables</h3>
                <button
                  className="btn btn-small btn-light-gray"
                  onClick={setShowVariableInput.bind(setShowVariableInput, !showVariableInput)}
                >
                  New Variable
                </button>
              </div>
              <div className="relative w-full mt-2">
                <Icon icon="faMagnifyingGlass" wrapperClassName="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none text-gray-400 text-sm" />
                <input
                  id="variable-search-input"
                  type="text"
                  value={search}
                  className="form-control mt-0 pl-8 text-gray-700"
                  placeholder="Type to filter variables"
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            {showVariableInput && <TemplateVariableForm createTemplateVarHandle={createTemplateVarHandle} />}
            <div className="max-h-96 overflow-y-scroll">
              <div className="flex flex-col">
                {!!search.trim()
                  ?
                  template_variables
                    .filter((variable) => variable.name.toLowerCase().includes(search.toLowerCase()))
                    .map((variable) =>
                      <TemplateVariableItem
                        key={`variable-item-${variable.value}`}
                        variable={variable}
                      />
                    )
                  :
                  template_variables.map((variable) =>
                    <TemplateVariableItem
                      key={`variable-item-${variable.value}`}
                      variable={variable}
                    />
                  )}
              </div>
            </div>
          </div>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  )
}

export default TemplateVariablePopover
