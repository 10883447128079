import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

interface StyledProps {
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  fontFamily?: string;
  fontColor?: string;
  textAlign?: string;
  focusStyles?: boolean;
}

type EditableParagraphFieldProps = StyledProps & React.ComponentProps<typeof TextField>;

const EditableParagraphField = styled(TextField, {
  shouldForwardProp: (prop) => {
    const key: string = prop as string;
    return !['fontSize', 'lineHeight', 'fontWeight', 'fontFamily', 'fontColor', 'textAlign', 'focusStyles'].includes(key);
  },
})<EditableParagraphFieldProps>(({ textAlign, focusStyles = true }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '0.375rem',
    padding: 'unset',
    color: 'unset',
    fontSize: 'unset',
    lineHeight: 'unset',
    fontWeight: 'unset',
    fontFamily: 'unset',
    textAlign: 'unset',
    border: 'unset',
    ...(focusStyles && {
      ':focus-within': {
        border: "1px solid rgb(209 213 219)",
        borderRadius: '0.375rem',
      },
    }),
  },
  '&.Mui-disabled, & .MuiInputBase-root.Mui-disabled': {
    color: 'unset !important',
    cursor: 'default !important',
  },
  '& .MuiFormControl-root': {
    border: 'unset',
  },
  '& .MuiInputBase-input': {
    color: 'unset',
    padding: '0.25rem',
    border: 'none',
    textAlign: textAlign || 'left',
    outline: 'none',
    boxShadow: 'none',
    '&:hover': {
      borderRadius: 'unset',
      border: "unset",
    },
    '&:disabled': {
      'WebkitTextFillColor': 'unset'
    },
  },
}));

type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  wrapperClassName?: string;
  styleOptions?: StyledProps
  multiline?: boolean;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  suppressCreatingNewLines?: boolean;
  inputClassName?: string;
  disabled?: boolean;
};

const EditableField: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  wrapperClassName,
  inputClassName,
  styleOptions,
  multiline = true,
  rows,
  maxRows,
  minRows,
  suppressCreatingNewLines = true,
  disabled
}) => {

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Is needed to prevent creating new lines on Enter press
    if (e.key === 'Enter' && suppressCreatingNewLines) {
      e.preventDefault();
    }
  }

  const onValueChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(e.target.value);
  }

  return (
    <div className={`${wrapperClassName}`} contentEditable={false} suppressContentEditableWarning >
      <EditableParagraphField
        {...styleOptions}
        id={Math.random().toString()}
        multiline={multiline}
        style={{ width: '100%' }}
        variant="standard"
        InputProps={{ disableUnderline: true }}
        value={value}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onChange={onValueChange}
        rows={rows}
        minRows={minRows}
        maxRows={maxRows}
        className={inputClassName}
        disabled={disabled}
      />
    </div>
  );
};

export default EditableField;