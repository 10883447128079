import { Controller } from "@hotwired/stimulus"
import { createRoot } from 'react-dom/client';
import React from "react"

import HexColorPicker from "../components/NavigationBar/NavBar/GlobalConfigModal/GlobalColorSelector/HexColorPicker/HexColorPicker"

export default class extends Controller {
  static targets = ["hexInput", "picker", "swatch"]
  static values = { isOpen: Boolean }

  connect() {
    this.isOpenValue = false
    this.colorRoot = createRoot(this.pickerTarget)
    document.addEventListener('click', this.handleOutsideClick.bind(this));

    // New color picker selected
    if (this.color === "") {
      setTimeout(() => {
        this.displaySelector()
        this.isOpenValue = true
      }, 50)
    }
  }

  disconnect() {
    document.removeEventListener('click', this.handleOutsideClick.bind(this))
  }

  handleOutsideClick(event) {
    if (this.isOpenValue && !this.element.contains(event.target)) {
      this.toggle();
    }
  }

  toggle() {
    this.isOpenValue = !this.isOpenValue

    if (this.isOpenValue) {
      this.displaySelector()
    } else {
      this.closeSelector()
    }
  }

  // For whatever reason, this was getting lost when it got to this function
  toggleColor(color, input, swatch) {
    input.value = color
    swatch.style.backgroundColor = color
    this.closeSelector()
  }

  displaySelector() {
    this.colorRoot.render(
      <div className="w-19em absolute z-10 border-solid border border-gray-200 rounded-xl bg-white drop-shadow popover-container p-4">

        <HexColorPicker
          initialColor={this.color}
          handleUpdateColor={(color) => this.toggleColor(color, this.hexInputTarget, this.swatchTarget)}
          goBackHandle={() => this.closeSelector()}
        />
      </div>
    )
  }

  closeSelector() {
    this.colorRoot.render(null)
  }

  get color() {
    return this.hexInputTarget.value
  }
}
