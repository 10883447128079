import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = ["searchField"]

  connect() {
    this.debounceSubmit = debounce(this.submitForm.bind(this), 300)
  }

  search() {
    this.debounceSubmit()
  }

  submitForm() {
    this.searchFieldTarget.form.requestSubmit()
  }
}
