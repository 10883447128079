import React, { useCallback, useEffect, useState } from "react";
import { findNode, getParentNode } from "@udecode/plate-common";
import { PlateRenderElementProps, isEditorReadOnly, useEditorRef } from "@udecode/plate-common/react";
import ahoy from "ahoy.js"
import { useSelected } from "slate-react";

import { PlatePricingElement, PlatePricingLocalElement, PricingTableLineItem } from "../../plate-config/Plugins/Pricing/Pricing.plugin";
import Table from "./Table/Table";
import Summary from "./Summary/Summary";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import { calculateSubtotal, calculateValue, getItemsForCalculation } from "../../utils/calc_plugins.util";
import { StoreCalcVariable } from "../../store/reducers/page_calc_variables/page_calc_variables";
import { ColumnPlugin } from "../../plate-config/Plugins/ColumnGroup/Column.plugin";

export interface QuantityChangeArgs {
  table_id: string
  quantity: number | null
  line_item: PricingTableLineItem,
  variables: StoreCalcVariable[]
}

export interface CheckBoxChangeArgs {
  table_id: string
  line_item: PricingTableLineItem,
  blockToAnalyse: PlatePricingLocalElement
  optional_selected: boolean
}


export interface RadioChangeArgs {
  table_id: string
  line_item: PricingTableLineItem,
  blockToAnalyse: PlatePricingLocalElement
  prev_line_item: PricingTableLineItem,
}


const PricingVoidElement = ({
  attributes,
  children,
  element,
  className,
}: PlateRenderElementProps) => {

  const [localBlock, setLocalBlock] = useState<PlatePricingLocalElement>()
  const editor = useEditorRef()
  const isReadOnly = isEditorReadOnly(editor)
  const selected = useSelected()

  const page_calc_variables = useAppSelector(state => state.page_calc_variables)
  const analyticsEnabled = useAppSelector(state => state.page_side_effects.analyticsEnabled)
  const shadow_store_variables = useAppSelector(state => state.page_shadow_store.calculation_variables)
  const calc_variables = isReadOnly ? shadow_store_variables : page_calc_variables
  const { pageId, currency, account_id } = useAppSelector(state => state.page_addendums)

  const block = element as PlatePricingElement
  const node = findNode(editor, { at: [], match: { id: block.id } })!
  const section = editor.children[node[1][0]]
  const parentNode = getParentNode(editor, node[1])!
  const parentNodeType = parentNode[0].type as string
  const isElColumnChild = parentNodeType === ColumnPlugin.key
  const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false
  const sectionChildren = section.children.filter(child => !!child.type)
  const isFirstChild = sectionChildren[0].id === block.id;
  const isLastChild = sectionChildren[sectionChildren.length - 1].id === block.id;

  const trackQuantityChange = useCallback(({
    table_id, line_item, quantity, variables
  }: QuantityChangeArgs) => {
    if (!analyticsEnabled) return
    const previous_line_item_total = calculateSubtotal(
      line_item.unit_price_cents,
      quantity === null ? shadow_store_variables.find(variable => variable.id === line_item.variable_id)!.default : line_item.quantity,
      line_item.discount_type,
      line_item.discount_value,
      currency
    )
    const line_item_total = calculateSubtotal(
      line_item.unit_price_cents,
      quantity === null ? variables.find(variable => variable.id === line_item.variable_id)!.default : quantity,
      line_item.discount_type,
      line_item.discount_value,
      currency
    )
    const previous_line_item_value = calculateValue(
      line_item.value_formula_attributes.calculation,
      quantity === null ? shadow_store_variables.find(variable => variable.id === line_item.variable_id)!.default : line_item.quantity,
      shadow_store_variables,
      currency
    )
    const line_item_value = calculateValue(
      line_item.value_formula_attributes.calculation,
      quantity === null ? variables.find(variable => variable.id === line_item.variable_id)!.default : quantity,
      variables,
      currency
    )
    const blockToAnalyse = quantity === null ? localBlock : {
      ...localBlock, pricing_tables_attributes: localBlock!.pricing_tables_attributes.map(table => {
        if (table.id === table_id) {
          return {
            ...table, line_items_attributes: table.line_items_attributes.map(item => {
              if (item.id === line_item.id) {
                return { ...item, quantity }
              }
              return item
            })
          }
        }
        return table
      }
      )
    }

    const totals = getItemsForCalculation(blockToAnalyse as PlatePricingLocalElement, currency, quantity === null ? variables : shadow_store_variables).total
    const previous_totals = getItemsForCalculation(localBlock as PlatePricingLocalElement, currency, shadow_store_variables).total
    console.log("Price table line quantity changed", {
      account_id,
      page_id: pageId,
      section_id: section.id,
      block_id: block.id,
      table_id,
      line_item_id: line_item.id,
      type: "pricing_change",
      subtype: "line_item_quantity",
      field: line_item.description,
      line_item_total,
      line_item_value,
      totals,
      previous_line_item_total,
      previous_line_item_value,
      previous_totals,
      quantity: quantity === null ? variables.find(variable => variable.id === line_item.variable_id)!.default : quantity,
      previous_quantity: line_item.variable_id ? shadow_store_variables.find(variable => variable.id === line_item.variable_id)!.default : line_item.quantity,
    });
    return ahoy.track("page action", {
      account_id,
      page_id: pageId,
      section_id: section.id,
      block_id: block.id,
      table_id,
      line_item_id: line_item.id,
      type: "pricing_change",
      subtype: "line_item_quantity",
      field: line_item.description,
      line_item_total,
      line_item_value,
      totals,
      previous_line_item_total,
      previous_line_item_value,
      previous_totals,
      quantity: quantity === null ? variables.find(variable => variable.id === line_item.variable_id)!.default : quantity,
      previous_quantity: line_item.variable_id ? shadow_store_variables.find(variable => variable.id === line_item.variable_id)!.default : line_item.quantity,
    });
  }, [block, localBlock, currency, shadow_store_variables, account_id]);

  const trackItemCheckBoxChange = useCallback(({
    table_id, line_item, blockToAnalyse, optional_selected
  }: CheckBoxChangeArgs) => {
    if (!analyticsEnabled) return
    const line_item_total = calculateSubtotal(
      line_item.unit_price_cents,
      line_item.variable_id ? shadow_store_variables.find(variable => variable.id === line_item.variable_id)!.default : line_item.quantity,
      line_item.discount_type,
      line_item.discount_value,
      currency
    )
    const line_item_value = calculateValue(
      line_item.value_formula_attributes.calculation,
      line_item.variable_id ? shadow_store_variables.find(variable => variable.id === line_item.variable_id)!.default : line_item.quantity,
      shadow_store_variables,
      currency
    )

    const totals = getItemsForCalculation(blockToAnalyse as PlatePricingLocalElement, currency, shadow_store_variables).total
    const previous_totals = getItemsForCalculation(localBlock as PlatePricingLocalElement, currency, shadow_store_variables).total
    console.log("Price table line optional selected", {
      account_id,
      page_id: pageId,
      section_id: section.id,
      block_id: block.id,
      table_id,
      line_item_id: line_item.id,
      type: "pricing_change",
      subtype: "line_item_checkbox",
      field: line_item.description,
      line_item_total,
      line_item_value,
      totals,
      previous_line_item_total: line_item_total,
      previous_line_item_value: line_item_value,
      previous_totals,
      optional_selected
    });
    return ahoy.track("page action", {
      account_id,
      page_id: pageId,
      section_id: section.id,
      block_id: block.id,
      table_id,
      line_item_id: line_item.id,
      type: "pricing_change",
      subtype: "line_item_checkbox",
      field: line_item.description,
      line_item_total,
      line_item_value,
      totals,
      previous_line_item_total: line_item_total,
      previous_line_item_value: line_item_value,
      previous_totals,
      optional_selected
    });
  }, [block, localBlock, currency, shadow_store_variables, account_id]);

  const trackItemRadioChange = useCallback(({
    table_id, line_item, blockToAnalyse, prev_line_item
  }: RadioChangeArgs) => {
    if (!analyticsEnabled) return
    const line_item_total = calculateSubtotal(
      line_item.unit_price_cents,
      line_item.variable_id ? shadow_store_variables.find(variable => variable.id === line_item.variable_id)!.default : line_item.quantity,
      line_item.discount_type,
      line_item.discount_value,
      currency
    )
    const line_item_value = calculateValue(
      line_item.value_formula_attributes.calculation,
      line_item.variable_id ? shadow_store_variables.find(variable => variable.id === line_item.variable_id)!.default : line_item.quantity,
      shadow_store_variables,
      currency
    )

    const totals = getItemsForCalculation(blockToAnalyse as PlatePricingLocalElement, currency, shadow_store_variables).total
    const previous_totals = getItemsForCalculation(localBlock as PlatePricingLocalElement, currency, shadow_store_variables).total
    console.log("Price table line single selected", {
      account_id,
      page_id: pageId,
      section_id: section.id,
      block_id: block.id,
      table_id,
      line_item_id: line_item.id,
      type: "pricing_change",
      subtype: "line_item_radio",
      field: line_item.description,
      line_item_total,
      line_item_value,
      totals,
      previous_line_item_total: line_item_total,
      previous_line_item_value: line_item_value,
      previous_totals,
      previous_line_item_id: prev_line_item?.id || null,
      previous_field: prev_line_item?.description || null,
    });
    return ahoy.track("page action", {
      account_id,
      page_id: pageId,
      section_id: section.id,
      block_id: block.id,
      table_id,
      line_item_id: line_item.id,
      type: "pricing_change",
      subtype: "line_item_radio",
      field: line_item.description,
      line_item_total,
      line_item_value,
      totals,
      previous_line_item_total: line_item_total,
      previous_line_item_value: line_item_value,
      previous_totals,
      previous_line_item_id: prev_line_item?.id || null,
      previous_field: prev_line_item?.description || null,
    });
  }, [block, localBlock, currency, shadow_store_variables, account_id]);

  //  We make a local copy of the block to keep track of the selected line items
  useEffect(() => {
    if (block) {
      const localBlockTables = block.pricing_tables_attributes.map((table, tableIndex) => {
        if (
          !localBlock ||
          localBlock.pricing_tables_attributes.length !== block.pricing_tables_attributes.length ||
          localBlock.pricing_tables_attributes[tableIndex].line_items_attributes.length !== table.line_items_attributes.length
        ) {
          const updatedLineItems = table.line_items_attributes.map((item, index) => {
            return { ...item, selected: item.optional_default }
          })
          return { ...table, select_type: 'single', line_items_attributes: updatedLineItems }
        }

        const updatedLineItems = table.line_items_attributes.map((item, index) => {
          const localTable = localBlock.pricing_tables_attributes[tableIndex]
          const localItem = localTable.line_items_attributes[index]
          return { ...item, selected: localItem.selected }
        })
        return { ...table, select_type: 'single', line_items_attributes: updatedLineItems }
      })
      const blockData = { ...block, pricing_tables_attributes: localBlockTables }
      setLocalBlock(blockData)
    }
  }, [block])

  return (
    <div
      className={`
      ${className} p-2 rounded-lg transition-all duration-300 ease-in-out
       ${isElColumnChild
          ? isLastColElement ? "mb-0" : "mb-4"
          : `page-block pricing-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
        }
       ${!isReadOnly
          ? `${selected
            ? 'border-blue-500/60 border hover:border-blue-500/60'
            : 'hover:border-blue-500/30 border border-transparent'}`
          : ''
        } 
      `}
      id={block.id}
      data-id={block.id}
      contentEditable={false}
      suppressContentEditableWarning={true}
      data-plate-selectable
      {...attributes}
    >
      {children}
      {
        block.pricing_tables_attributes.map((_table, index) =>
          <Table
            key={index}
            localElement={localBlock as PlatePricingLocalElement}
            setLocalElement={setLocalBlock}
            element={block}
            tableIndex={index}
            isEditorReadOnly={isReadOnly}
            calc_variables={calc_variables}
            trackQuantityChange={trackQuantityChange}
            trackItemCheckBoxChange={trackItemCheckBoxChange}
            trackItemRadioChange={trackItemRadioChange}
            section={section}
          />
        )
      }
      <Summary
        localBlock={localBlock as PlatePricingLocalElement}
        calc_variables={calc_variables}
      />
    </div>
  )
}

export default PricingVoidElement
