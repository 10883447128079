import React, { useState } from "react"
import { faker } from '@faker-js/faker';

import { PlateElementAiTextBlock } from "../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin"

type Props = {
  element: PlateElementAiTextBlock
  onActiveViewChange: (view: string) => void
  isEditorReadOnly: boolean
  textColor: string
  markerColor: string
  selected: boolean
}

const AiTextBlockPreview: React.FC<Props> = ({
  element, onActiveViewChange, isEditorReadOnly, textColor, markerColor, selected
}) => {

  const alignmentClass = element.details.alignment === "left"
    ? "text-left"
    : element.details.alignment === "center" ? "text-center" : "text-right"

  const [listItems] = useState(Array.from({ length: faker.number.int({ min: element.details.list_items_min!, max: element.details.list_items_max! }) }))

  const [randomWordsAmount] = useState(faker.lorem.sentence({ min: element.details.length_min!, max: element.details.length_max! }))
  const [randomSentencesAmount] = useState(faker.lorem.sentences({ min: element.details.length_min!, max: element.details.length_max! }))
  const [randomParagraphsAmount] = useState(faker.lorem.lines({ min: element.details.length_min!, max: element.details.length_max! }))


  const listAsParagraphs = element.details.kind === "list" && (
    element.details.list_kind === 'bolded_summary_paragraph' ||
    element.details.list_kind === 'objection' ||
    element.details.list_kind === 'feature_benefit'
  )
  const alignedList = element.details.kind === "list" && (
    element.details.list_kind === "feature_benefit" ||
    element.details.list_kind === "objection" ||
    element.details.list_kind === "bolded_summary_paragraph"
  )

  interface ListStyleTypes extends React.CSSProperties {
    '--ai-text-marker-color': string;
  }
  const listStyles: ListStyleTypes = {
    '--ai-text-marker-color': markerColor as string,
  }


  return (
    <div className={`relative group`} data-id={element.id}>
      <div>
        <div className={`absolute inset-0 w-full h-full opacity-0
          ${isEditorReadOnly ? 'cursor-auto' : ` cursor-pointer`}
          ${selected && !isEditorReadOnly ? `  opacity-100 bg-gray-800 bg-opacity-50 rounded-xl` : ''}
          `}
        >
          <div className="flex items-center justify-center w-full h-full">
            <button
              className={`btn btn-primary shadow-xl z-40 ${isEditorReadOnly ? 'cursor-auto' : ` cursor-pointer`}`}
              onClick={isEditorReadOnly || !selected ? undefined : onActiveViewChange.bind(onActiveViewChange, 'summary')}
            >
              AI Text Settings
            </button>
          </div>
        </div>
        <div className="mx-auto">
          {element.details.kind === "header" &&
            <h1 className={`font-heading ${alignmentClass} ${textColor}`}>
              🪄 {
                element.details.length_type === "words" && randomWordsAmount ||
                element.details.length_type === "sentences" && randomSentencesAmount
              }
            </h1>
          }
          {element.details.kind === "paragraph" &&
            <div className={`slate-p ${alignmentClass} ${textColor}`}>
              🪄 {
                element.details.length_type === "words" && randomWordsAmount ||
                element.details.length_type === "sentences" && randomSentencesAmount ||
                element.details.length_type === "paragraphs" && randomParagraphsAmount
              }
            </div>
          }
          {listAsParagraphs &&
            <>
              {listItems.map((_item, i) =>
                <ListParagraph
                  key={`ai-text-li-item-${i}`}
                  element={element}
                  alignedList={alignedList}
                  alignmentClass={alignmentClass}
                  textColor={textColor}
                />
              )}
            </>
          }
          {!listAsParagraphs && element.details.kind === "list" &&
            <ul
              className={`
              m-0 ps-6 slate-ul-wrapper list-outside [&_ul]:list-[circle] [&_ul_ul]:list-[square]
              ${element.details.list_style === "ordered" ? "list-decimal" : "list-disc"}
              `}
              style={listStyles}
            >
              {listItems.map((_item, i) =>
                <ListItem
                  key={`ai-text-li-item-${i}`}
                  element={element}
                  alignedList={alignedList}
                  alignmentClass={alignmentClass}
                  textColor={textColor}
                />
              )}
            </ul>
          }
        </div>
      </div>
    </div>
  )
}

export default AiTextBlockPreview


type ListItemProps = {
  element: PlateElementAiTextBlock
  alignedList: boolean
  alignmentClass: string
  textColor: string
}

export const ListItem: React.FC<ListItemProps> = ({ element, alignedList, alignmentClass, textColor }) => {
  const [randomText] = useState({
    wordsAmountV1: faker.lorem.sentence({ min: element.details.length_min!, max: element.details.length_max! }),
    wordsAmountV2: faker.lorem.sentence({ min: element.details.length_min!, max: element.details.length_max! }),
    wordsAmountV3: faker.lorem.sentence({ min: element.details.length_min!, max: element.details.length_max! }),
    sentencesAmount: faker.lorem.sentences({ min: element.details.length_min!, max: element.details.length_max! }),
    paragraphsAmount: faker.lorem.lines({ min: element.details.length_min!, max: element.details.length_max! }),
  });

  return (
    <li className={`ai-text-li-item ${textColor} ${alignedList ? alignmentClass : ''}`} >
      <div className="relative">
        🪄
        {element.details.list_kind === 'bolded_summary' && <span className="font-semibold mr-1">{randomText.wordsAmountV1} - </span>}
        {element.details.list_kind === 'question_answer'
          &&
          <><b> {randomText.wordsAmountV2} </b> <br /></>
        }
        {
          element.details.length_type === "words" && randomText.wordsAmountV3 ||
          element.details.length_type === "sentences" && randomText.sentencesAmount ||
          element.details.length_type === "paragraphs" && randomText.paragraphsAmount
        }
      </div>
    </li>
  );
};

export const ListParagraph: React.FC<ListItemProps> = ({ element, alignedList, alignmentClass, textColor }) => {
  const [randomText] = useState({
    wordsAmountV1: faker.lorem.sentence({ min: element.details.length_min!, max: element.details.length_max! }),
    wordsAmountV2: faker.lorem.sentence({ min: element.details.length_min!, max: element.details.length_max! }),
    wordsAmountV3: faker.lorem.sentence({ min: element.details.length_min!, max: element.details.length_max! }),
    wordsAmountV4: faker.lorem.sentence({ min: element.details.length_min!, max: element.details.length_max! }),
    sentencesAmountV1: faker.lorem.sentences({ min: element.details.length_min!, max: element.details.length_max! }),
    sentencesAmountV2: faker.lorem.sentences({ min: element.details.length_min!, max: element.details.length_max! }),
    paragraphsAmount: faker.lorem.lines({ min: element.details.length_min!, max: element.details.length_max! }),
  });

  return (
    <div className="relative group">
      <div className={`slate-p py-1 ${textColor} ${alignedList ? alignmentClass : ''}`}>
        🪄
        {
          (element.details.list_kind === 'objection' ||
            element.details.list_kind === 'bolded_summary_paragraph'
          ) &&
          <>
            <b>{randomText.wordsAmountV1}</b> <br />
          </>
        }
        {
          element.details.list_kind === 'feature_benefit' &&
          <>
            <b>{randomText.wordsAmountV2}</b> <br />
            {randomText.sentencesAmountV1} <br />
          </>
        }
        {
          element.details.length_type === "words" && randomText.wordsAmountV3 ||
          element.details.length_type === "sentences" && randomText.sentencesAmountV2 ||
          element.details.length_type === "paragraphs" && randomText.paragraphsAmount
        }
        {
          element.details.list_kind === 'objection' &&
          <>
            <br /><i>{randomText.wordsAmountV4}</i> <br />
          </>
        }
        {element.details.list_extra_spacing && <div className="mt-8" />}
      </div>
    </div>
  );
};