import React, { useState } from "react";

import { Popover, PopoverTrigger, PopoverContent } from '../Popover/Popover';
import HugeIcon, { HugeIconName } from "../Icon/HugeIcon";

type Props = {
  label?: string;
  iconAfterLabel?: HugeIconName;
  iconBeforeLabel?: HugeIconName;
  data: {
    name: string,
    label: string | React.ReactElement,
    iconName?: HugeIconName
  }[],
  onOptionSet: (value: any) => void;
  activeOption?: string | null;
  disabled?: boolean;
}

const ToolbarDropdown: React.FC<Props> = ({
  label, data, onOptionSet, activeOption, disabled, iconAfterLabel, iconBeforeLabel
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false)

  const preventDefault = (e: Event) => e.preventDefault();

  const onValueHandle = (value: string) => {
    onOptionSet(value)
    setPopoverOpen(false)
  }

  return (
    <Popover open={disabled ? false : popoverOpen} onOpenChange={setPopoverOpen} modal={true}>
      <PopoverTrigger >
        <div
          className={`toolbar_dropdown ${popoverOpen ? 'active' : ''} ${disabled ? 'cursor-not-allowed' : ''}`}
          contentEditable={false}
          suppressContentEditableWarning
        >
          {iconBeforeLabel && <HugeIcon name={iconBeforeLabel} size={16} className="text-black" />}
          {label && <span className="text-xs font-normal text-left text-black capitalize"> {label} </span>}
          {iconAfterLabel && <HugeIcon name={iconAfterLabel} size={16} className="text-black" />}
          <HugeIcon name="arrowDown" size={16} className="text-black" />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="center"
        sideOffset={10}
        onOpenAutoFocus={preventDefault}
        onCloseAutoFocus={preventDefault}
        contentEditable={false}
        suppressContentEditableWarning={true}
      >
        <div className="toolbar_dropdown_content">
          {data.map((item, i) =>
            <button
              key={i}
              onClick={onValueHandle.bind(onValueHandle, item.name)}
              className={`toolbar_dropdown_content-btn  ${item.name === activeOption ? 'active' : ''}`}
            >
              {item.label}
            </button>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};


export default ToolbarDropdown;