import React from 'react'

import { PlateElementAiTextBlock } from "../../../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import ButtonSelector from "../../../../../shared/ButtonSelector/ButtonSelector";
import LabeledInput from "../../../../../shared/Inputs/LabeledInput";


type Props = {
  element: PlateElementAiTextBlock,
  list_items_min: number
  list_items_max: number
  onListStyleChange: (list_style: string) => void
  onMinListItemsChange: (list_items_max: number) => void
  onMaxListItemsChange: (list_items_min: number) => void
}

const ListTypeAndLength: React.FC<Props> = ({
  element, onListStyleChange, onMaxListItemsChange, onMinListItemsChange,
  list_items_min, list_items_max
}) => {

  const listOptions = [
    { name: "unordered", label: "Bulleted" },
    { name: "ordered", label: "Numbered" }
  ]

  const onMinHandle = (e: React.ChangeEvent<HTMLInputElement>) => onMinListItemsChange(+e.target.value)
  const onMaxHandle = (e: React.ChangeEvent<HTMLInputElement>) => onMaxListItemsChange(+e.target.value)

  const onChangeMinValidator = (min: number | null, max: number | null) => {
    if (typeof min !== 'number' && typeof max === 'number') {
      return `Must be less than ${max}`
    }
    if (typeof min === 'number' && typeof max === 'number' && min > max) {
      return `Must be less than ${max}`
    }
    if (typeof min === 'number' && min < 1) {
      return `Must be greater than 0`
    }
  }

  const onChangeMaxValidator = (min: number | null, max: number | null) => {
    if (typeof min === 'number' && typeof max !== 'number') {
      return `Must be greater than ${min}`
    }
    if (typeof min === 'number' && typeof max === 'number' && min > max) {
      return `Must be greater than ${min}`
    }
    if (typeof max === 'number' && max < 1) {
      return `Must be greater than 0`
    }
  }

  return (
    <>
      <div className="w-64 mt-6">
        <ButtonSelector
          data={listOptions}
          current={element.details.list_style}
          setCurrent={onListStyleChange}
        />
      </div>
      <div className="flex w-full gap-4 mt-4">
        <LabeledInput
          wrapperClassName="w-full"
          htmlFor='min-number-of-words'
          label={`Min # of ${element.details.list_style === 'unordered' ? 'Bulleted' : 'Numbered'} items`}
          type="number"
          min={1}
          value={list_items_min}
          onChange={onMinHandle}
          error={onChangeMinValidator(list_items_min, list_items_max)}
        />
        <LabeledInput
          wrapperClassName="w-full"
          min={1}
          htmlFor='max-number-of-words'
          label={`Max # of ${element.details.list_style === 'unordered' ? 'Bulleted' : 'Numbered'} items`}
          type="number"
          value={list_items_max}
          onChange={onMaxHandle}
          error={onChangeMaxValidator(list_items_min, list_items_max)}
        />
      </div>
    </>
  )
}

export default ListTypeAndLength;