import { TElement } from "@udecode/plate-common"
import { createPlatePlugin, PlateEditor } from "@udecode/plate-common/react";

// Make sure that element is non-deleteable
const extendEditor = ({ editor }) => {
  const { apply } = editor as PlateEditor;

  editor.apply = (unit: any) => {
    if (unit.type === 'remove_node' && unit.node.type === RecordingPlugin.key) {
      return
    }
    return apply(unit);
  };

  return editor
}

export const RecordingPlugin = createPlatePlugin({
  key: 'recording',
  node: {
    type: 'recording',
    isVoid: true,
    isElement: true,
  },
  extendEditor: extendEditor,
})

export interface RecordingConversation {
  speaker: {
    id: string
    number: number
    name: string
  }
  display_start_time: string
  uterrances: {
    id: string
    position: number
    start_time: string
    end_time: string
    text: string
  }[]
}


export interface PlateRecordingElement extends TElement {
  id: string
  type: typeof RecordingPlugin.key
  recording_file: string
  recording_kind: string
  speakers: {
    id: string
    number: number
    name: string
    role: string
  }[]
  conversation: RecordingConversation[]
}