import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = ["headerFont", "paragraphFont", "headerWeight", "paragraphWeight"]

  connect() {
    const linkHref = `https://fonts.googleapis.com/css2?${this.fontNames}&display=swap`
    const link = document.createElement('link')
    link.href = linkHref
    link.rel = 'stylesheet'

    document.head.appendChild(link)
  }

  get paragraphFont() {
    return this.element.dataset.paragraphFont?.replace(/ /g, '+') || 'Libre+Franklin'
  }

  get paragraphWeight() {
    return this.element.dataset.paragraphWeight || 400
  }

  get headerFont() {
    return this.element.dataset.headerFont?.replace(/ /g, '+') || 'Libre+Franklin'
  }

  get headerWeight() {
    return this.element.dataset.headerWeight || 700
  }

  get fontNames() {
    if (this.paragraphFont === this.headerFont) {
      const weights = this.fontWeights([this.paragraphWeight, this.headerWeight])
      return `family=${this.paragraphFont}:wght@${weights}`
    } else {
      const headerWeights = this.fontWeights([this.headerWeight])
      const paragraphWeights = this.fontWeights([this.paragraphWeight])
      const hFont = `family=${this.headerFont}:wght@${headerWeights}`
      const pFont = `family=${this.paragraphFont}:wght@${paragraphWeights}`

      return `${hFont}&${pFont}`
    }
  }

  fontWeights(weights) {
    const newWeights = weights.map(w => w.toString())
    newWeights.push('700')
    return newWeights.filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a - b)
      .join(';')
  }
}
