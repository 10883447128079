import React from 'react';

import DropdownOptionItem from './DropdownOptionItem';
import ToolbarDropdown from './ToolbarDropdown';

type Props = {
  currentAlign: string;
  onAlignChange: (value: string) => void;
  variant?: 'horizontal' | 'vertical';
  labeled?: boolean;
}

const ToolbarAlignDropdown: React.FC<Props> = ({ currentAlign, onAlignChange, variant = "horizontal", labeled = true }) => {
  const horizontalnOptions: any[] = [
    { name: 'left', label: <DropdownOptionItem iconName="alignBoxMiddleLeft" label={labeled ? "Left" : undefined} />, iconName: "alignBoxMiddleLeft" },
    { name: 'center', label: <DropdownOptionItem iconName="alignBoxMiddleCenter" label={labeled ? "Center" : undefined} />, iconName: "alignBoxMiddleCenter" },
    { name: 'right', label: <DropdownOptionItem iconName="alignBoxMiddleRight" label={labeled ? "Right" : undefined} />, iconName: "alignBoxMiddleRight" },
  ]
  const verticalOptions = [
    { name: 'top', label: <DropdownOptionItem iconName="alignBoxTopCenter" label={labeled ? "Top" : undefined} />, iconName: "alignBoxTopCenter" },
    { name: 'center', label: <DropdownOptionItem iconName="alignBoxMiddleCenter" label={labeled ? "Center" : undefined} />, iconName: "alignBoxMiddleCenter" },
    { name: 'bottom', label: <DropdownOptionItem iconName="alignBoxBottomCenter" label={labeled ? "Bottom" : undefined} />, iconName: "alignBoxBottomCenter" },
  ]
  return (
    <ToolbarDropdown
      onOptionSet={onAlignChange}
      iconBeforeLabel={
        variant === 'horizontal'
          ? horizontalnOptions.find((option) => option.name === currentAlign)!.iconName!
          : verticalOptions.find((option) => option.name === currentAlign)!.iconName!
      }
      activeOption={currentAlign}
      data={variant === 'horizontal' ? horizontalnOptions : verticalOptions}
    />
  )
}

export default ToolbarAlignDropdown;