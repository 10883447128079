import { Controller } from "@hotwired/stimulus"

import { initTribute } from "../site/init_tribute"

// // NOTE: #page-id needs to be added to the page
export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    this.tribute = initTribute(this.inputTarget);
  }

  disconnect() {
    this.tribute.detach(this.inputTarget);
  }
}
