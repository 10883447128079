import React, { useState, useRef } from "react"

import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";
import IconButton from "../IconButton/IconButton";
import Icon from "../Icon/Icon";
import { openGlobalVarModal } from "../../store/reducers/page_side_effects/page_side_effects";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { StoreCalcVariable } from "../../store/reducers/page_calc_variables/page_calc_variables";

type Props = {
  formula: string,
  setFormula: (value: string) => void,
  additionalVariables?: Array<{ name: string, value: string }>,
  calc_variables: StoreCalcVariable[]
}


const FormulaInput = ({
  formula,
  setFormula,
  additionalVariables = [],
  calc_variables,
}: Props) => {

  const dispatch = useAppDispatch()
  const textAreaRef = useRef(null);
  const [cursor, setCursor] = useState(null)
  const [formulaState, setFormulaState] = useState(formula)

  const formulaVariables = calc_variables.map((variable) => {
    return {
      name: `(${variable.handle}) ${variable.label || ''}`,
      value: `{{${variable.handle}}}`,
    }
  }).concat(additionalVariables)
  const onOpenGlobalVarModal = () => dispatch(openGlobalVarModal())

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormulaState(event.target.value)
    setFormula(event.target.value)
  }
  const handleTextAreaSelect = (event: any) => {
    setCursor(event.target.selectionStart)
  }

  const addToFormula = (symbol: string) => {
    if (cursor !== null) {
      const formulaBeforeCursor = formulaState.slice(0, cursor).replace(/\s+$/, "")
      const formulaAfterCursor = formulaState.slice(cursor).replace(/^\s+/, "")
      const leadingSpace = cursor === 0 ? "" : " "
      setFormulaState(`${formulaBeforeCursor}${leadingSpace}${symbol} ${formulaAfterCursor}`)
      setFormula(`${formulaBeforeCursor}${leadingSpace}${symbol} ${formulaAfterCursor}`)
    } else {
      const formulaWithoutLastWhitespace = formulaState.replace(/\s+$/, "")
      setFormulaState(`${formulaWithoutLastWhitespace} ${symbol}`)
      setFormula(`${formulaWithoutLastWhitespace} ${symbol}`)
    }
  }

  const toolbar = [
    { icon: 'faPlus', signToAdd: '+' },
    { icon: 'faMinus', signToAdd: '-' },
    { icon: 'faXmark', signToAdd: '*' },
    { icon: 'faDivide', signToAdd: '/' },
  ]

  return (
    <div className="form-group mb-0">
      <label>Formula</label>
      <textarea
        ref={textAreaRef}
        className="input form-control resize-none rounded-b-none font-mono"
        value={formulaState}
        onChange={handleTextAreaChange}
        onSelect={handleTextAreaSelect}
        rows={3}
      />
      <div className="btn-group w-full flex">
        <Popover>
          <PopoverTrigger asChild>
            <div className='grow btn btn-small btn-white rounded-t-none border-t-0 no-top-rounding'>
              Variable <Icon icon="faCaretDown" className="ml-1" />
            </div>
          </PopoverTrigger>
          <PopoverContent>
            <div className="w-48 border-solid border border-gray-200 rounded-xl bg-white z-30">
              <div className="flex flex-col">
                {formulaVariables.map((variable) =>
                  <div
                    className={`
                      p-2 border-solid border border-gray-200 hover:bg-gray-200 cursor-pointer text-sm font-semibold text-gray-700
                      ${variable.value === "{{A}}" ? "rounded-t-xl" : ""}
                    `}
                    key={`variable-option-${variable.value}`}
                    onClick={() => addToFormula(variable.value)}
                  >
                    {variable.name}
                  </div>
                )}
                <div
                  className="rounded-b-xl p-2 border-solid border border-gray-200 hover:bg-gray-200 cursor-pointer text-sm font-semibold text-gray-700"
                  onClick={onOpenGlobalVarModal}
                >
                  Edit Variables
                </div>
              </div>
            </div>
          </PopoverContent>
        </Popover>
        {toolbar.map((item) =>
          <IconButton
            key={item.icon}
            btnClassName="btn btn-small btn-white rounded-t-none border-t-0 no-top-rounding"
            icon={item.icon}
            onClick={addToFormula.bind(addToFormula, item.signToAdd)}
          />
        )}
      </div>
      <p className="form-hint text-center">
        Learn <a href="#" target="_blank">how formulas work</a>.
      </p>
    </div>
  )
}

export default FormulaInput
