import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { PageVariable } from '../api/api.types'

export interface StoreCalcVariable extends Omit<PageVariable, 'default'> {
  default: number
}

const initialState: StoreCalcVariable[] = []

export const pageCalcVariablesSlice = createSlice({
  name: 'page_calc_variables',
  initialState,
  reducers: {
    onPageCalcVariableUpdate: (state, action: PayloadAction<StoreCalcVariable>) => {
      const updatedState = state.map(variable => {
        if (variable.id === action.payload.id) {
          return action.payload
        }
        return variable
      })
      return updatedState
    },
    onPageCalcVariableAdd: (state, action: PayloadAction<PageVariable>) => {
      const newVariable = {
        ...action.payload,
        default: parseInt(action.payload.default),
      }
      return [...state, newVariable]
    },
    onPageCalcVariableRemove: (state, action: PayloadAction<string>) => {
      const updatedState = state.filter(variable => variable.id !== action.payload)
      return updatedState
    },
    setPageCalcVariables: (_state, action: PayloadAction<PageVariable[]>) => {
      return action.payload.map(variable => {
        return {
          ...variable,
          default: parseInt(variable.default)
        }
      })
    },
  }
})

export const { onPageCalcVariableUpdate, onPageCalcVariableAdd, onPageCalcVariableRemove, setPageCalcVariables } = pageCalcVariablesSlice.actions

export default pageCalcVariablesSlice.reducer