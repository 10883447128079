import { Controller } from "@hotwired/stimulus"
import ProgressBar from 'progressbar.js'

export default class extends Controller {
  connect() {
    console.log("Me Countdown")
    this.createCountdown()

    this.element.addEventListener('input', () => {
      this.updateCountdown()
    })
  }

  disconnect() {
    this.countdown.destroy()

    // Remove the event listener
    this.element.removeEventListener('input', () => {
      this.updateCountdown()
    })
  }

  createCountdown() {
    var container = document.createElement('div')
    container.id = 'countdown'
    container.classList.add("float-right", "w-6", "h-6", "mr-2", "-mt-8")
    this.element.after(container)

    this.countdown = new ProgressBar.Circle(container, {
      strokeWidth: 10,
      easing: 'easeInOut',
      duration: 300,
      color: '#007fc3',
      trailColor: '#f1f1f2',
      trailWidth: 10,
      svgStyle: null,
    })

    this.updateCountdown()
  }

  updateCountdown() {
    var max = this.element.getAttribute('data-max-length')
    var charsCount = this.element.value.length
    var remaining = max - charsCount
    var percentComplete = (max - remaining) / max

    if (percentComplete > 1) {
      this.countdown.animate(1)
      this.countdown.path.setAttribute('stroke', '#e63946')
    } else {
      this.countdown.animate(percentComplete)
      this.countdown.path.setAttribute('stroke', '#007fc3')
    }
  }
}
