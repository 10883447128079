import React, { useEffect, useState } from "react";

import AccordionItem, { Accordion } from "../../../shared/Accordion/Accordion";
import GlobalColorSelector from "./GlobalColorSelector/GlobalColorSelector";
import FontSelector from "./GlobalFontSelector/FontSelector";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/redux-hooks";
import { closeGlobalVarModal } from "../../../store/reducers/page_side_effects/page_side_effects";
import TooltipIcon from "../../../shared/ToolTip/TooltipIcon";
import IconButton from "../../../shared/IconButton/IconButton";

type Props = {
  textColor: string;
}

const GlobalConfigModal = ({ textColor }: Props) => {
  const [activeAccordionKey, setActiveAccordionKey] = useState<string | undefined>("colors");
  const [globalStylesDialog, setGlobalStylesDialog] = useState(false);
  const sideTrigger = useAppSelector(state => state.page_side_effects.openGloablVarModal)
  const dispatch = useAppDispatch()

  const onAccordionChange = (value: string) => setActiveAccordionKey(value)
  const onGlobalStylesDialogChange = () => {
    setGlobalStylesDialog(!globalStylesDialog)
    sideTrigger && dispatch(closeGlobalVarModal())
  }

  useEffect(() => {
    if (sideTrigger) {
      setGlobalStylesDialog(true)
      setActiveAccordionKey("variables")
    }
  }, [sideTrigger])


  return (
    <>
      <TooltipIcon
        tooltip="Variables & Styles"
        side="bottom"
        container="tooltip-anchor-navBar"
        icon="faEarthAmericas"
        iconWrapperClassName={`btn-nav-link ${textColor}`}
        onClick={onGlobalStylesDialogChange}
      />
      {globalStylesDialog && (
        <div className="fixed top-30 h-[80%] bottom-4 right-4 w-96 z-50 overflow-y-hidden">
          <div className="absolute w-96 max-w-full h-full bg-white rounded-xl shadow-xl border border-gray-300 overflow-hidden">
            <div className="overflow-y-hidden h-full">
              <div className="flex justify-between items-center p-5 rounded-t border-b border-gray-200">
                <div className="flex items-baseline gap-6">
                  <h3 className="text-xl font-medium text-gray-900">Variables & Styles</h3>
                </div>
                <div className="flex right gap-4 items-center">
                  <IconButton
                    icon="faX"
                    btnClassName="text-gray-900"
                    onClick={onGlobalStylesDialogChange}
                  />
                </div>
              </div >
              <div className="overflow-y-scroll h-full">
                <div className="p-4">
                  <Accordion
                    key="global-modal-accordion"
                    type="single"
                    onValueChange={onAccordionChange}
                    value={activeAccordionKey}
                  >
                    <AccordionItem
                      key="accordion-item-colors"
                      identifier="accordion-item-colors"
                      value="colors"
                      title="Global Colors"
                    >
                      <GlobalColorSelector />
                    </AccordionItem>
                    <AccordionItem
                      key="accordion-item-fonts"
                      identifier="accordion-item-fonts"
                      value="fonts"
                      title="Global Font"
                      itemClasses="mt-6"
                    >
                      <FontSelector />
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default GlobalConfigModal;