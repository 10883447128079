import React from 'react';
import { withRef } from '@udecode/cn';
import { PortalBody, useComposedRef, useEditorReadOnly, useEditorRef } from '@udecode/plate-common/react';
import {
  flip,
  FloatingToolbarState,
  offset,
  useFloatingToolbar,
  useFloatingToolbarState,
} from '@udecode/plate-floating';
import * as ToolbarPrimitive from '@radix-ui/react-toolbar';
import { BoldPlugin, ItalicPlugin, StrikethroughPlugin, UnderlinePlugin } from '@udecode/plate-basic-marks/react';

import Align from './Align/Align';
import ToolbarSeparator from '../../shared/ToolbarControls/ToolbarSeparator';
import TurnIntoDefaultNodes from './TurnInto/TurnIntoDefaultNodes';
import FontSize from './FontSize/FontSize';
import TurnIntoUl from './TurnInto/TurnIntoUl';
import TurnIntoOl from './TurnInto/TurnIntoOl';
import BasicMarks from './BasicMarks/BasicMarks';
import Colors from './Colors/Colors';
import Link from './Link/Link';

export const FloatingToolbar = withRef<
  typeof ToolbarPrimitive.Root,
  {
    portalElement?: Element
    state?: FloatingToolbarState;
  }
>(({ state, portalElement, children, ...props }, componentRef) => {
  const editor = useEditorRef();
  const readOnly = useEditorReadOnly();
  const floatingToolbarState = useFloatingToolbarState({
    editorId: editor.id,
    focusedEditorId: editor.id,
    ...state,
    floatingOptions: {
      placement: 'top',
      middleware: [
        offset(12),
        flip({
          padding: 12,
          fallbackPlacements: [
            'top-start',
            'top-end',
            'bottom-start',
            'bottom-end',
          ],
        }),
      ],
      ...state?.floatingOptions,
    },
  });

  const {
    ref: floatingRef,
    props: rootProps,
    hidden,
  } = useFloatingToolbar(floatingToolbarState);

  const ref = useComposedRef<HTMLDivElement>(componentRef, floatingRef);

  if (hidden || readOnly) return null;

  return (
    <PortalBody element={portalElement}>
      <div
        ref={ref}
        className="absolute z-50 whitespace-nowrap opacity-100 block_toolbar"
        {...rootProps}
        {...props}
      >
        <TurnIntoDefaultNodes />
        <FontSize />
        <ToolbarSeparator />
        <Align />
        <Colors />
        <ToolbarSeparator />
        <TurnIntoUl />
        <TurnIntoOl />
        <ToolbarSeparator />
        <BasicMarks mark={BoldPlugin.key} iconName='bold' />
        <BasicMarks mark={ItalicPlugin.key} iconName='italic' />
        <BasicMarks mark={UnderlinePlugin.key} iconName='underline' />
        <BasicMarks mark={StrikethroughPlugin.key} iconName='strikethrough' />
        <ToolbarSeparator />
        <Link />
      </div>
    </PortalBody>
  );
});