import { setHeaderFont, setHeaderWeight, setParagraphFont, setParagraphWeight } from "../../page_fonts/page_fonts";
import { setDataSaved, setDataSaving } from "../../page_indicators/page_indicators";
import { revvedupApi } from "../api";
import { getPageDataAttributes } from "../data-attributes.util";


export const fontEndpoints = revvedupApi.injectEndpoints({
  endpoints: (builder) => ({
    updatePageHeaderFont: builder.mutation<any, string>({
      query: (header_font) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { header_font } },
        }
      },
      async onQueryStarted(header_font, { dispatch, queryFulfilled }) {
        dispatch(setDataSaving())
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setHeaderFont(header_font))
          }
        } catch (err) {
          console.log('Update header font error:', err);
        }
        dispatch(setDataSaved())
      }
    }),
    updatePageHeaderWeight: builder.mutation<any, number>({
      query: (header_weight) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { header_weight } },
        }
      },
      async onQueryStarted(header_weight, { dispatch, queryFulfilled }) {
        dispatch(setDataSaving())
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setHeaderWeight(header_weight))
          }
        } catch (err) {
          console.log('Update header weight error:', err);
        }
        dispatch(setDataSaved())
      }
    }),
    updatePageParagraphFont: builder.mutation<any, string>({
      query: (paragraph_font) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { paragraph_font } },
        }
      },
      async onQueryStarted(paragraph_font, { dispatch, queryFulfilled }) {
        dispatch(setDataSaving())
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setParagraphFont(paragraph_font))
          }
        } catch (err) {
          console.log('Update page font error:', err);
        }
        dispatch(setDataSaved())
      }
    }),
    updatePageParagraphWeight: builder.mutation<any, number>({
      query: (paragraph_weight) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { paragraph_weight } },
        }
      },
      async onQueryStarted(paragraph_weight, { dispatch, queryFulfilled }) {
        dispatch(setDataSaving())
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setParagraphWeight(paragraph_weight))
          }
        } catch (err) {
          console.log('Update paragraph weight error:', err);
        }
        dispatch(setDataSaved())
      }
    }),
  })
})

export const {
  useUpdatePageHeaderFontMutation,
  useUpdatePageHeaderWeightMutation,
  useUpdatePageParagraphFontMutation,
  useUpdatePageParagraphWeightMutation
} = fontEndpoints
