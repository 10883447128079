import React, { memo } from "react"

import { SectionStyleConfigType } from "../../../../section.config"

type Props = {
  options: SectionStyleConfigType[],
  current: string,
  setCurrent: (event: React.MouseEvent<HTMLButtonElement>, value: string) => void
}


const AnimateButtonSelector = memo(({ options, current, setCurrent }: Props) => {
  return (
    <div className='w-full border border-gray-200 rounded-xl bg-gray-200 mt-1'>
      <div className="flex btn-group text-center gap-1">
        {options.map(({ name, label }) =>
          <button
            key={`${name}-button-item`}
            onClick={(e) => setCurrent(e, name)}
            className={`flex-1 btn btn-gray justify-center ${name === current ? "active" : ""}`}
          >
            {label}
          </button>
        )}
      </div>
    </div>
  )
})

export default AnimateButtonSelector
