import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { PageContacts } from '../api/api.types'

const initialState: PageContacts = {
  contacts: [],
  users: [],
  pageId: '',
  current_contact_id: null,
  current_user_id: ''
}


export const pageContactsSlice = createSlice({
  name: 'page_contacts',
  initialState,
  reducers: {
    setPageContacts: (state, action: PayloadAction<PageContacts>) => {
      return {
        ...state,
        ...action.payload
      }
    },
  },
})

export const { setPageContacts } = pageContactsSlice.actions

export default pageContactsSlice.reducer