import { TElement, TNodeEntry, addRangeMarks, findNode } from '@udecode/plate-common';
import { createPlatePlugin, PlateEditor } from '@udecode/plate-common/react';

import { textNodeTypes } from '../../../utils/plate.util';
import { SectionPlugin } from '../Section/Section.plugin';


const extendEditor = ({ editor }) => {
  const { normalizeNode } = editor as PlateEditor;
  editor.normalizeNode = ([node, path]: TNodeEntry) => {
    // If section node has font_color property we need to add font_color marks to all text nodes inside the section
    if (textNodeTypes.includes(node.type as string)) {
      const textNode = node as TElement;
      const section = findNode(editor, { at: [path[0]], match: { type: SectionPlugin.key } });
      if (section) {
        if (section[0].font_color) {
          textNode.children.forEach((child, index) => {
            // we need to check if child is one of inline plugins, like link
            //  if it has it's own font color we should not override it
            if (child?.type) {
              const inlineElement = child as TElement
              if (inlineElement?.children[0]?.font_color) {
                return
              }
            } else {
              if (child.font_color) {
                return;
              }
            }
            addRangeMarks(editor, { font_color: section[0].font_color }, { at: [...path, index] });
          });
        }
      }
    }
    normalizeNode([node, path]);
  };
  return editor
}

export const TextColorLeafPlugin = createPlatePlugin({
  key: 'font_color',
  node: {
    isLeaf: true,
  },
  extendEditor: extendEditor,
});