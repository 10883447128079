import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = { html: { type: Boolean, default: false }, dependentSelectId: { type: String, default: "" } }

  connect() {
    // Check first if this element is a select element. If yes, return that.
    const selectElement = this.element.tagName === "SELECT" ? this.element : this.element.querySelector("select");
    const filteredOptions = Array.from(selectElement.options).filter(o => !o.hidden);
    selectElement.disabled = filteredOptions.length <= 1;

    let options = {
      maxOptions: null,
      allowEmptyOption: true,
      options: filteredOptions,
    };

    if (this.htmlValue) {
      options.render = {
        option: function (data, _escape) {
          return '<div class="option">' + data.text + '</div>';
        },
        item: function (data, escape) {
          return '<div class="item">' + data.text + '</div>';
        }
      };
    }

    this.select = new TomSelect(selectElement, options)
  }

  updateDependentOptions(e) {
    const dependentSelect = document.getElementById(this.dependentSelectIdValue);
    const options = Array.from(dependentSelect.options);

    options.map(o => o.hidden = (o.dataset?.object !== e.target.value));

    dependentSelect.disabled = false;
    dependentSelect.tomselect.enable();
    dependentSelect.tomselect.clear();
    dependentSelect.tomselect.clearOptions();
    dependentSelect.value = '';
    dependentSelect.tomselect.addOptions(options.filter(o => !o.hidden));
    dependentSelect.tomselect.open();

    if (options.filter(o => !o.hidden).length <= 1) {
      dependentSelect.disabled = true;
      dependentSelect.tomselect.disable();
    }
  }

  disconnect() {
    this.select.destroy()
  }

  clear() {
    console.log("Clear the tom select")
    this.select.clear();
  }
}
