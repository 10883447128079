import React, { useState } from "react"
import { findNodePath, useEditorRef } from "@udecode/plate-common/react"

import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from "../../../../shared/Popover/Popover"
import PopoverSelectorTitle from "../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"
import IconButton from "../../../../shared/IconButton/IconButton"
import { PlateSectionElement } from "../../../../plate-config/Plugins/Section/Section.plugin"
import TooltipIcon from "../../../../shared/ToolTip/TooltipIcon"

type Props = {
  sectionElement: PlateSectionElement
}
const NameAndPositionMenu = ({ sectionElement }: Props) => {
  const editor = useEditorRef()
  const [popoverOpen, setPopoverOpen] = useState(false)

  const currentSectionPath = findNodePath(editor, sectionElement);

  const fixedSection = sectionElement.fixed_kind === "recording"

  const sectionsLength = editor.children.filter((node: any) => node.type === 'section').length
  const currentIndex = editor.children.findIndex((node: any) => node.type === 'section' && node.id === sectionElement.id)

  const isLastChild = sectionsLength === 1;
  const isFirst = currentIndex === 0;
  const isLast = currentIndex === sectionsLength - 1;


  const onSectionNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    editor.setNodes(
      { name: e.target.value } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
  }

  const onSectionDeleteHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (window.confirm('Are you sure you would like to delete this section?')) {
      editor.setNodes(
        { delete: true } as Partial<Node>,
        { at: [], match: (n: any) => n.id === sectionElement.id }
      )
      editor.removeNodes({ at: [], match: (n: any) => n.id === sectionElement.id })
      setPopoverOpen(false)
    }
  }

  const onSectionDuplicateHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    editor.insertNodes(
      sectionElement,
      { at: [currentIndex + 1] }
    )
  }

  const onSectionMoveUpHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    editor.moveNodes({ at: currentSectionPath, to: [currentIndex - 1] })
    setPopoverOpen(false)
  }

  const onSectionMoveDownHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    editor.moveNodes({ at: currentSectionPath, to: [currentIndex + 1] })
    setPopoverOpen(false)
  }


  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger>
        <TooltipIcon
          tooltip="Name & Position"
          side="bottom"
          container={sectionElement.id as string}
          icon="faEllipsisVertical"
          iconWrapperClassName="btn-small btn-white w-9 btn last-child flex justify-center items-center"
        />
      </PopoverTrigger>
      <PopoverContent sideOffset={5} align="start" alignOffset={-40}>
        <PopoverArrow />
        <div className="w-56 border-solid border border-gray-200 rounded-xl bg-white drop-shadow z-30">
          <div className="p-4">
            <PopoverSelectorTitle title="Section Name" />
            <div className="relative mb-0">
              <input
                type="text"
                className="form-control"
                placeholder="Enter a name for this section"
                onChange={onSectionNameChange}
                value={sectionElement.name as string || ''}
              />
            </div>
          </div>
          <div className="btn-group btn-bottom-inlay flex w-full">
            <IconButton
              btnClassName="btn-small btn-white btn grow flex justify-center py-2 border-l-0"
              icon="faArrowUp"
              onClick={onSectionMoveUpHandle}
              disabled={isFirst}
            />
            <IconButton
              btnClassName="btn-small btn-white btn grow flex justify-center py-2"
              icon="faArrowDown"
              onClick={onSectionMoveDownHandle}
              disabled={isLast}
            />
            <IconButton
              btnClassName="btn-small btn-white btn grow flex justify-center py-2"
              icon="faCopy"
              onClick={onSectionDuplicateHandle}
              disabled={fixedSection}
            />
            <IconButton
              btnClassName="btn-small btn-white btn grow flex justify-center py-2 border-r-0"
              icon="faTrashCan"
              onClick={onSectionDeleteHandle}
              disabled={fixedSection ? true : isLastChild}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default NameAndPositionMenu

