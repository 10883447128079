import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectedCount", "leadSelector", "processButton"]

  connect() {
    this.updateSelectedCount()
  }

  updateSelectedCount() {
    this.selectedCountTarget.textContent = this.selectedLeads.length

    if (this.selectedLeads.length > 0) {
      this.processButtonTarget.disabled = false
    } else {
      this.processButtonTarget.disabled = true
    }
  }

  toggleLead(_event) {
    console.log("Lead selected")
    this.updateSelectedCount()
  }

  selectAll() {
    this.leadSelectorTargets.forEach((leadSelector) => {
      leadSelector.checked = true
    })
    this.updateSelectedCount()
  }

  selectNone() {
    this.leadSelectorTargets.forEach((leadSelector) => {
      leadSelector.checked = false
    })
    this.updateSelectedCount()
  }

  toggleAllCheckbox(event) {
    if (event.target.checked) {
      this.selectAll()
    } else {
      this.selectNone()
    }
  }

  get selectedLeads() {
    return this.leadSelectorTargets.filter((leadSelector) => leadSelector.checked)
  }
}
