import React, { memo } from "react";

const FontExample = memo(() => {
  return (
    <div className="bg-gray-50 border border-gray-200 rounded-xl mt-4 p-4 shadow-sm xl:p-6">
      <h1 className="font-heading mt-0 pt-0">
        Example Text
      </h1>
      <div className="paragraph-font-family cdx-block">
        This is how your text will look
      </div>
    </div>
  )
})

export default FontExample
