import React, { forwardRef, memo } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPalette, faGear, faPlus, faChevronLeft, faChevronRight, faEarthAmericas,
    faMobileScreen, faEye, faBars, faSliders, faCode, faMagnifyingGlass, faCopy,
    faX, faChevronDown, faArrowLeft, faCheck, faLock, faTrash, faPaintRoller,
    faPen, faArrowRight, faArrowUp, faArrowDown, faAlignLeft, faAlignCenter,
    faImage, faAngleRight, faEllipsisVertical, faTriangleExclamation, faMobile, faLaptop,
    faGripVertical, faTableColumns, faHeading, faQuoteRight, faParagraph, faWindowMinimize,
    faFileImport, faListUl, faListOl, faBold, faItalic, faUnderline, faStrikethrough, faComments,
    faAlignJustify, faAlignRight, faVideo, faAdd, faArrowsRotate, faEllipsis, faLinkSlash, faLink,
    faArrowUpRightFromSquare, faFont, faUpRightAndDownLeftFromCenter, faShapes, faCircleNodes,
    faImages, faFaceSmile, faCalculator, faLockOpen, faMinus, faXmark, faDivide, faCaretDown,
    faInfoCircle, faDollarSign, faCog, faEyeSlash, faChevronUp, faRightLeft, faCircleQuestion,
    faComment, faFaceGrinStars, faPaperPlane, faCommentMedical, faCircleCheck, faReplyAll,
    faUser, faWandMagicSparkles, faUserTie, faCopyright, faFileArrowUp, IconDefinition,
    faSpellCheck, faMinimize, faLayerGroup, faUndo, faCloudArrowUp, faBuilding, faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'
import { faFileImage } from '@fortawesome/free-regular-svg-icons'
import { faUnsplash } from '@fortawesome/free-brands-svg-icons'
import { faFolderOpen, faTrashCan, faSquare, faCircle, faStar } from '@fortawesome/free-regular-svg-icons'

type Props = {
    icon: string,
    className?: string,
    wrapperClassName?: string
    onClick?: () => void
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    shouldBeHidden?: boolean
}

const Icon = memo(forwardRef<HTMLDivElement | SVGSVGElement, Props>((props, ref) => {
    const { icon, className, wrapperClassName, onClick, onMouseEnter, onMouseLeave, shouldBeHidden } = props;
    let iconToDisplay: null | IconDefinition = null;

    const DoubleColumnOutlined = () => (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                clipRule="evenodd"
                d="M8.5 3H13V13H8.5V3ZM7.5 2H8.5H13C13.5523 2 14 2.44772 14 3V13C14 13.5523 13.5523 14 13 14H8.5H7.5H3C2.44772 14 2 13.5523 2 13V3C2 2.44772 2.44772 2 3 2H7.5ZM7.5 13H3L3 3H7.5V13Z"
                fill="#595E6F"
                fillRule="evenodd"
            />
        </svg>
    );

    const ThreeColumnOutlined = () => (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                clipRule="evenodd"
                d="M9.25 3H6.75V13H9.25V3ZM9.25 2H6.75H5.75H3C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H5.75H6.75H9.25H10.25H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2H10.25H9.25ZM10.25 3V13H13V3H10.25ZM3 13H5.75V3H3L3 13Z"
                fill="#4C5161"
                fillRule="evenodd"
            />
        </svg>
    );

    const FourColumnOutlined = () => (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                clipRule="evenodd"
                d="M2,2 L2,14 L14,14 L14,2 L2,2 Z M3,3 L5.25,3 L5.25,13 L3,13 L3,3 Z M6.5,3 L8.75,3 L8.75,13 L6.5,13 L6.5,3 Z M10,3 L12.25,3 L12.25,13 L10,13 L10,3 Z"
                fill="#4C5161"
                fillRule="evenodd"
            />
        </svg>
    );
    const EllipseOutlined: React.FC = () => (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="8"
                cy="8"
                rx="7"
                ry="4"
                stroke="#595E6F"
                strokeWidth="1.5"
            />
        </svg>
    );

    const ParallelogramOutlined: React.FC = () => (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 3L6 2L14 3V13L10 14L2 13V3Z"
                stroke="#595E6F"
                strokeWidth="1.5"
            />
        </svg>
    );

    const TriangleOutlined: React.FC = () => (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <polygon
                points="8,2 2,14 14,14"
                stroke="#595E6F"
                strokeWidth="1.5"
                fill="transparent"
            />
        </svg>
    );

    const DropIcon: React.FC = () => (
        <div className="w-[20px] h-[20px]">
            <svg width={'100%'} height={'100%'} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path fill-rule="nonzero" d="M14 6h2v2h5a1 1 0 0 1 1 1v7.5L16 13l.036 8.062 2.223-2.15L20.041 22H9a1 1 0 0 1-1-1v-5H6v-2h2V9a1 1 0 0 1 1-1h5V6zm8 11.338V21a1 1 0 0 1-.048.307l-1.96-3.394L22 17.338zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4 0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z" />
                </g>
            </svg>
        </div>

    );

    switch (icon) {
        case 'dropIcon':
            return <DropIcon />;
        case 'twoColumn':
            return <DoubleColumnOutlined />;
        case 'threeColumn':
            return <ThreeColumnOutlined />;
        case 'fourColumn':
            return <FourColumnOutlined />;
        case 'ellipse':
            return <EllipseOutlined />;
        case 'parallelogram':
            return <ParallelogramOutlined />;
        case 'triangle':
            return <TriangleOutlined />;
        case 'faPalette':
            iconToDisplay = faPalette
            break;
        case 'faGear':
            iconToDisplay = faGear
            break;
        case 'faPlus':
            iconToDisplay = faPlus
            break;
        case 'faChevronLeft':
            iconToDisplay = faChevronLeft
            break;
        case 'faChevronRight':
            iconToDisplay = faChevronRight
            break;
        case 'faEarthAmericas':
            iconToDisplay = faEarthAmericas
            break;
        case 'faMobileScreen':
            iconToDisplay = faMobileScreen
            break;
        case 'faEye':
            iconToDisplay = faEye
            break;
        case 'faBars':
            iconToDisplay = faBars
            break;
        case 'faSliders':
            iconToDisplay = faSliders
            break;
        case 'faCode':
            iconToDisplay = faCode
            break;
        case 'faMagnifyingGlass':
            iconToDisplay = faMagnifyingGlass
            break;
        case 'faCopy':
            iconToDisplay = faCopy
            break;
        case 'faX':
            iconToDisplay = faX
            break;
        case 'faChevronDown':
            iconToDisplay = faChevronDown
            break;
        case 'faArrowLeft':
            iconToDisplay = faArrowLeft
            break;
        case 'faCheck':
            iconToDisplay = faCheck
            break;
        case 'faLock':
            iconToDisplay = faLock
            break;
        case 'faTrash':
            iconToDisplay = faTrash
            break;
        case 'faPaintRoller':
            iconToDisplay = faPaintRoller
            break;
        case 'faPen':
            iconToDisplay = faPen
            break;
        case 'faArrowRight':
            iconToDisplay = faArrowRight
            break;
        case 'faArrowUp':
            iconToDisplay = faArrowUp
            break;
        case 'faArrowDown':
            iconToDisplay = faArrowDown
            break;
        case 'faAlignLeft':
            iconToDisplay = faAlignLeft
            break;
        case 'faAlignCenter':
            iconToDisplay = faAlignCenter
            break;
        case 'faImage':
            iconToDisplay = faImage
            break;
        case 'faUnsplash':
            iconToDisplay = faUnsplash
            break;
        case 'faFolderOpen':
            iconToDisplay = faFolderOpen
            break;
        case 'faAngleRight':
            iconToDisplay = faAngleRight
            break;
        case 'faEllipsisVertical':
            iconToDisplay = faEllipsisVertical
            break;
        case 'faTrashCan':
            iconToDisplay = faTrashCan
            break;
        case 'faTriangleExclamation':
            iconToDisplay = faTriangleExclamation
            break;
        case 'faMobile':
            iconToDisplay = faMobile
            break;
        case 'faLaptop':
            iconToDisplay = faLaptop
            break;
        case 'faGripVertical':
            iconToDisplay = faGripVertical
            break;
        case 'faTableColumns':
            iconToDisplay = faTableColumns
            break;
        case 'faHeading':
            iconToDisplay = faHeading
            break;
        case 'faQuoteRight':
            iconToDisplay = faQuoteRight
            break;
        case 'faParagraph':
            iconToDisplay = faParagraph
            break;
        case 'faWindowMinimize':
            iconToDisplay = faWindowMinimize
            break;
        case 'faFileImport':
            iconToDisplay = faFileImport
            break;
        case 'faListUl':
            iconToDisplay = faListUl
            break;
        case 'faListOl':
            iconToDisplay = faListOl
            break;
        case 'faBold':
            iconToDisplay = faBold
            break;
        case 'faItalic':
            iconToDisplay = faItalic
            break;
        case 'faUnderline':
            iconToDisplay = faUnderline
            break;
        case 'faStrikethrough':
            iconToDisplay = faStrikethrough
            break;
        case 'faComments':
            iconToDisplay = faComments
            break;
        case 'faAlignJustify':
            iconToDisplay = faAlignJustify
            break;
        case 'faAlignRight':
            iconToDisplay = faAlignRight
            break;
        case 'faVideo':
            iconToDisplay = faVideo
            break;
        case 'faAdd':
            iconToDisplay = faAdd
            break;
        case 'faArrowsRotate':
            iconToDisplay = faArrowsRotate
            break;
        case 'faEllipsis':
            iconToDisplay = faEllipsis
            break;
        case 'faLinkSlash':
            iconToDisplay = faLinkSlash
            break;
        case 'faLink':
            iconToDisplay = faLink
            break;
        case 'faArrowUpRightFromSquare':
            iconToDisplay = faArrowUpRightFromSquare
            break;
        case 'faFont':
            iconToDisplay = faFont
            break;
        case 'faUpRightAndDownLeftFromCenter':
            iconToDisplay = faUpRightAndDownLeftFromCenter
            break;
        case 'faSquare':
            iconToDisplay = faSquare
            break;
        case 'faCircle':
            iconToDisplay = faCircle
            break;
        case 'faShapes':
            iconToDisplay = faShapes
            break;
        case 'faImages':
            iconToDisplay = faImages
            break;
        case 'faCircleNodes':
            iconToDisplay = faCircleNodes
            break;
        case 'faStar':
            iconToDisplay = faStar
            break;
        case 'faFaceSmile':
            iconToDisplay = faFaceSmile
            break;
        case 'faCalculator':
            iconToDisplay = faCalculator
            break;
        case 'faLockOpen':
            iconToDisplay = faLockOpen
            break;
        case 'faMinus':
            iconToDisplay = faMinus
            break;
        case 'faXmark':
            iconToDisplay = faXmark
            break;
        case 'faDivide':
            iconToDisplay = faDivide
            break;
        case 'faCaretDown':
            iconToDisplay = faCaretDown
            break;
        case 'faInfoCircle':
            iconToDisplay = faInfoCircle
            break;
        case 'faDollarSign':
            iconToDisplay = faDollarSign
            break;
        case 'faCog':
            iconToDisplay = faCog
            break;
        case 'faEyeSlash':
            iconToDisplay = faEyeSlash
            break;
        case 'faChevronUp':
            iconToDisplay = faChevronUp
            break;
        case 'faRightLeft':
            iconToDisplay = faRightLeft
            break;
        case 'faCircleQuestion':
            iconToDisplay = faCircleQuestion
            break;
        case 'faComment':
            iconToDisplay = faComment
            break;
        case 'faFaceGrinStars':
            iconToDisplay = faFaceGrinStars
            break;
        case 'faPaperPlane':
            iconToDisplay = faPaperPlane
            break;
        case 'faCommentMedical':
            iconToDisplay = faCommentMedical
            break;
        case 'faCircleCheck':
            iconToDisplay = faCircleCheck
            break;
        case 'faReplyAll':
            iconToDisplay = faReplyAll
            break;
        case 'faUser':
            iconToDisplay = faUser
            break;
        case 'faWandMagicSparkles':
            iconToDisplay = faWandMagicSparkles
            break;
        case 'faUserTie':
            iconToDisplay = faUserTie
            break;
        case 'faCopyright':
            iconToDisplay = faCopyright
            break;
        case 'faFileArrowUp':
            iconToDisplay = faFileArrowUp
            break;
        case 'faSpellCheck':
            iconToDisplay = faSpellCheck
            break;
        case 'faMinimize':
            iconToDisplay = faMinimize
            break;
        case 'faLayerGroup':
            iconToDisplay = faLayerGroup
            break;
        case 'faUndo':
            iconToDisplay = faUndo
            break;
        case 'faCloudArrowUp':
            iconToDisplay = faCloudArrowUp
            break;
        case 'faFileImage':
            iconToDisplay = faFileImage
            break;
        case 'faBuilding':
            iconToDisplay = faBuilding
            break;
        case 'faExclamationTriangle':
            iconToDisplay = faExclamationTriangle
            break;
        default:
            iconToDisplay = null

    }
    if (!iconToDisplay) return null;

    return (
        <>
            {wrapperClassName
                ?
                <div
                    ref={ref as React.Ref<HTMLDivElement>}
                    onMouseEnter={onMouseEnter ? onMouseEnter : undefined}
                    onMouseLeave={onMouseLeave ? onMouseLeave : undefined}
                    onClick={onClick ? onClick : undefined}
                    className={wrapperClassName || ''}
                >
                    <FontAwesomeIcon icon={iconToDisplay} className={className || ''} aria-hidden={shouldBeHidden ? "true" : undefined} />
                </div>
                :
                <FontAwesomeIcon
                    ref={ref as React.Ref<SVGSVGElement>}
                    onMouseEnter={onMouseEnter ? onMouseEnter : undefined}
                    onMouseLeave={onMouseLeave ? onMouseLeave : undefined}
                    onClick={onClick ? onClick : undefined}
                    icon={iconToDisplay}
                    className={className || ""}
                    aria-hidden={shouldBeHidden ? "true" : undefined}
                />
            }
        </>
    );
}));

export default Icon;
