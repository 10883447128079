import React from 'react'

import { PlateElementAiTextBlock } from "../../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import ButtonSelector from "../../../../shared/ButtonSelector/ButtonSelector";
import LabeledInput from "../../../../shared/Inputs/LabeledInput";


type Props = {
  element: PlateElementAiTextBlock,
  length_min: number,
  length_max: number,
  onTypeChange: (length_type: string) => void
  onMaxLengthChange: (length_max: number) => void
  onMinLengthChange: (length_min: number) => void
}

const TextTypeAndLength: React.FC<Props> = ({ element, onTypeChange, onMaxLengthChange, onMinLengthChange, length_max, length_min }) => {

  const textOptions = [
    { name: "words", label: "Words" },
    { name: "sentences", label: "Sentences" },
    ...(element.details.kind !== 'header' ? [{ name: "paragraphs", label: "Paragraphs" }] : [])
  ]

  const onMinHandle = (e: React.ChangeEvent<HTMLInputElement>) => onMinLengthChange(+e.target.value)
  const onMaxHandle = (e: React.ChangeEvent<HTMLInputElement>) => onMaxLengthChange(+e.target.value)

  const onChangeMinValidator = (min: number | null, max: number | null) => {
    if (typeof min !== 'number' && typeof max === 'number') {
      return `Must be less than ${max}`
    }
    if (typeof min === 'number' && typeof max === 'number' && min > max) {
      return `Must be less than ${max}`
    }
    if (typeof min === 'number' && min < 1) {
      return `Must be greater than 0`
    }
  }

  const onChangeMaxValidator = (min: number | null, max: number | null) => {
    if (typeof min === 'number' && typeof max !== 'number') {
      return `Must be greater than ${min}`
    }
    if (typeof min === 'number' && typeof max === 'number' && min > max) {
      return `Must be greater than ${min}`
    }
    if (typeof max === 'number' && max < 1) {
      return `Must be greater than 0`
    }
  }

  return (
    <>
      <div className="mt-6 w-96">
        <ButtonSelector
          data={textOptions}
          current={element.details.length_type}
          setCurrent={onTypeChange}
        />
      </div>
      <div className="flex w-full gap-4 mt-4">
        <LabeledInput
          wrapperClassName="w-full"
          htmlFor='min-number-of-words'
          label={`Min # of ${element.details.length_type === 'words' ? 'Words' : element.details.length_type === 'sentences' ? 'Sentences' : "Paragraphs"}`}
          type="number"
          min={1}
          value={length_min}
          onChange={onMinHandle}
          error={onChangeMinValidator(length_min, length_max)}
        />
        <LabeledInput
          wrapperClassName="w-full"
          min={1}
          htmlFor='max-number-of-words'
          label={`Max # of ${element.details.length_type === 'words' ? 'Words' : element.details.length_type === 'sentences' ? 'Sentences' : "Paragraphs"}`}
          type="number"
          value={length_max}
          onChange={onMaxHandle}
          error={onChangeMaxValidator(length_min, length_max)}
        />
      </div>
    </>
  )
}

export default TextTypeAndLength;