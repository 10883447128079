import React from 'react';
import { FacebookEmbed, InstagramEmbed, XEmbed, LinkedInEmbed, TikTokEmbed } from 'react-social-media-embed';
import { InlineWidget } from "react-calendly";

import { EmbedConfig, EmbedPlatform } from '../../config/config';
import { PlateElementEmbed } from '../../../../plate-config/Plugins/Embed/Embed.plugin';

type Props = {
    platform: EmbedConfig
    element: PlateElementEmbed
}

const EmbedViewController = ({ platform, element }: Props) => {
    switch (platform.name) {
        case EmbedPlatform.Facebook:
            return <FacebookEmbed url={platform.link as string} width={platform.widthMode![element.width_mode || 'large']} />
        case EmbedPlatform.Instagram:
            return <InstagramEmbed url={platform.link as string} width={"100%"} />
        case EmbedPlatform.Twitter:
            return <XEmbed url={platform.link as string} width={platform.widthMode![element.width_mode || 'large']} />
        case EmbedPlatform.LinkedIn:
            return <LinkedInEmbed url={platform.link as string} width={"100%"} />
        case EmbedPlatform.TikTok:
            return <TikTokEmbed url={platform.link as string} width={"100%"} style={{ minWidth: '325px', maxWidth: '605px' }} />
        case EmbedPlatform.Pitch:
            return <iframe className="w-full pitch-embed-iframe" src={platform.link} title={platform.name} />
        case EmbedPlatform.Miro:
            return <iframe className="w-full miro-embed-iframe" src={platform.link} title={platform.name} />
        case EmbedPlatform.GoogleDocs:
            const docsUrl = platform.link?.endsWith('?embedded=true') ? platform.link : platform.link + '?embedded=true'
            return <iframe className="w-full google-docs-embed-iframe" src={docsUrl} title={platform.name} />
        case EmbedPlatform.GoogleSheets:
            const sheetsUrl = platform.link?.endsWith("pubhtml") ? platform.link + '?widget=true&amp;headers=false' : platform.link
            return <iframe className="w-full google-sheets-embed-iframe" src={sheetsUrl} title={platform.name} />
        case EmbedPlatform.GoogleSlides:
            const slidesUrl = platform.link?.replace("pub?", "embed?")
            return <iframe
                className="w-full google-slides-embed-iframe"
                src={slidesUrl}
                title={platform.name}
                allowFullScreen
            />
        case EmbedPlatform.GoogleForms:
            return <iframe className="w-full google-forms-embed-iframe" src={platform.link} title={platform.name} />
        case EmbedPlatform.Calendly:
            // NOTE: Don't set width: '100%' it will add ugly margins on top and on bottom 
            // they are not removable, because we can't interact with styles inside of iframe document
            return <InlineWidget url={platform.link as string} styles={{ width: '99%', height: '500px' }} />
        case EmbedPlatform.HubSpotForms:
            return <iframe className="w-full hubspot-forms-embed-iframe" src={platform.link} title={platform.name} />
        default:
            return null;
    }
}

export default EmbedViewController;


