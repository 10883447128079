import React, { memo } from "react"

import IconButton from "../../../../../../shared/IconButton/IconButton"

type Props = {
  itemView: string
  goBack: () => void
}

const MenuHeader = memo(({ goBack, itemView }: Props) => {
  return (
    <div className="flex gap-1 justify-between items-center border-b border-gray-200 p-1 pl-4">
      <span className="text-xs font-semibold text-gray-700 uppercase">
        {itemView === "options" && "Item Options"}
        {itemView === "recurring" && "Recurring Billing"}
        {itemView === "quantity" && "Quantity Options"}
        {itemView === "variables" && "Quantity Variable"}
        {itemView === "discount" && "Discount"}
        {itemView === "value" && "Value"}
      </span>
      <IconButton
        btnClassName="btn btn-small btn-light-gray flex gap-1 justify-center text-gray-700"
        icon="faArrowLeft"
        onClick={goBack}
        textAfter="Back"
      />
    </div>
  )
})

export default MenuHeader;
