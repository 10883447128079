import React, { memo } from "react";

import { BrandImage, UploadedImage } from "../../../store/reducers/api/api.types";

type Props = {
  uploadedImage?: UploadedImage;
  brandImage?: BrandImage;
  onUploadedImageChange?: (image: UploadedImage) => void;
  onBrandImageChange?: (image: BrandImage) => void;
}

const ImagePickerMediaCard = memo(({
  uploadedImage,
  brandImage,
  onUploadedImageChange,
  onBrandImageChange,
}: Props) => {

  const uploadedImageHandle = () => onUploadedImageChange && onUploadedImageChange(uploadedImage!)
  const brandImageHandle = () => onBrandImageChange && onBrandImageChange(brandImage!)

  return (
    <div className="basis-1/2 pr-4 pb-4 md:basis-1/3 lg:basis-1/4">
      <div
        className="relative image-card bg-cover bg-center rounded-xl transition ease-in-out duration-500"
        onClick={uploadedImage ? uploadedImageHandle : brandImageHandle}
        style={{ backgroundImage: `url(${uploadedImage ? uploadedImage.urls.small : brandImage?.url})` }}
      >
        <div className="rounded-xl absolute inset-0 w-full h-full opacity-0 cursor-pointer transition-all ease-in-out duration-500 hover:bg-gray-800 hover:bg-opacity-50 hover:opacity-100">
          <div className="flex items-center justify-center w-full h-full">
            <div className="btn btn-white btn-small shadow-lg transition-all ease-in-out duration-500 with-hover-ring">
              Select Image
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default ImagePickerMediaCard;