import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["input", "button"]

  // We have to use TomSelect for the input element
  connect() {
    this.select = new TomSelect(this.inputTarget)
  }

  disconnect() {
    this.select.destroy()
  }

  submit(event) {
    if (event.detail.success) {
      this.startPolling()

      // Dispatch event to close the modal
      this.dispatch("close-modal")
    }
  }

  startPolling() {
    var intervalId = setInterval(() => {
      var newElement = document.getElementById("new-modal-association-added")
      if (newElement) {
        clearInterval(intervalId)
        const newId = newElement.dataset.id
        const newName = newElement.dataset.name

        // ADD THE OPTION TO THE TOM SELECT
        const newOption = this.select.addOption({ value: newId, text: newName })
        this.select.addItem(newOption);
        this.select.lock();

        // This was the code for non TOM SELECT
        // Go through all options and mark them all as disabled
        // this.inputTarget.querySelectorAll("option").forEach((option) => {
        //   option.disabled = true
        // })

        // Input target is a select. We need to add the new option to it, select it and make the input disabled so it can't be adjusted further.
        // this.inputTarget.insertAdjacentHTML("beforeend", `<option value="${newId}" selected>${newName}</option>`)
        // this.inputTarget.classList.add("disabled")
        // this.buttonTarget.classList.add("hidden")
      }
    }, 100)
  }
}
