import { setPageContacts } from "../../page_contacts/page_contacts";
import { revvedupApi } from "../api";
import { getPageDataAttributes } from "../data-attributes.util";



export const contactEndpoints = revvedupApi.injectEndpoints({
  endpoints: (builder) => ({
    getPageContacts: builder.query<any, void>({
      query: () => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/pages/${requestData?.page_id}/contacts`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setPageContacts(data))
          }
        } catch (err) {
          console.log('Page contacts loading error:', err);
        }
      }
    }),
  })
})

export const {
  useGetPageContactsQuery,
} = contactEndpoints
