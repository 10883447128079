import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { TElement } from "@udecode/plate-common";

import { PlatePricingElement, PlatePricingLocalElement } from "../../../plate-config/Plugins/Pricing/Pricing.plugin";
import Header from "./Header/Header";
import Description from "./Header/Description/Description";
import Row from "./Row/Row";
import Footer from "./Footer/Footer";
import { StoreCalcVariable } from "../../../store/reducers/page_calc_variables/page_calc_variables";
import { CheckBoxChangeArgs, QuantityChangeArgs, RadioChangeArgs } from "../PricingElement";


type Props = {
  element: PlatePricingElement
  localElement: PlatePricingLocalElement
  setLocalElement: React.Dispatch<React.SetStateAction<PlatePricingLocalElement | undefined>>
  tableIndex: number
  isEditorReadOnly: boolean,
  calc_variables: StoreCalcVariable[]
  trackQuantityChange: (args: QuantityChangeArgs) => void
  trackItemCheckBoxChange: (args: CheckBoxChangeArgs) => void
  trackItemRadioChange: (args: RadioChangeArgs) => void
  section: TElement
}

const PricingTable: React.FC<Props> = ({
  element, tableIndex, localElement, setLocalElement, isEditorReadOnly, calc_variables,
  trackQuantityChange, trackItemCheckBoxChange, trackItemRadioChange, section
}) => {
  const table = element.pricing_tables_attributes[tableIndex]
  const isWideContent = section.width === 'large'
  return (
    <>
      <TableContainer
        data-id={table.id}
        contentEditable={false}
        suppressContentEditableWarning
        component={'section'}
        id={table.id}
        sx={{ overflowX: "visible", paddingTop: isEditorReadOnly ? '2rem' : 0, paddingBottom: isEditorReadOnly ? '2rem' : 0 }}
      >
        <Table
          sx={{
            borderCollapse: 'separate',
            ...(element.style === 'wide' && !isWideContent ? { position: 'relative', left: "50%", transform: 'translateX(-50%)', width: "1022px", maxWidth: "98vw" } : {})
          }}
          className={`shadow rounded-xl bg-gray-100`}
          aria-label={`Pricing table ${tableIndex}`}
          contentEditable={false}
          suppressContentEditableWarning
        >
          <TableHead>
            <Header tableIndex={tableIndex} element={element} />
            <Description tableIndex={tableIndex} element={element} />
          </TableHead>
          <TableBody>
            {table.line_items_attributes.map((_lineItem, index) =>
              <Row
                key={index}
                rowIndex={index}
                setLocalElement={setLocalElement}
                localElement={localElement}
                element={element}
                tableIndex={tableIndex}
                calc_variables={calc_variables}
                trackQuantityChange={trackQuantityChange}
                trackItemCheckBoxChange={trackItemCheckBoxChange}
                trackItemRadioChange={trackItemRadioChange}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!isEditorReadOnly && <Footer tableIndex={tableIndex} element={element} />}
    </>
  )
}

export default PricingTable
