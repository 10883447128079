import React, { useEffect, useState } from "react"
import { useEditorRef } from "@udecode/plate-common/react"

import { PlateImageElement } from "../../../plate-config/Plugins/Image/Image.plugin"
import { Popover, PopoverContent, PopoverTrigger, PopoverPortal } from "../../../shared/Popover/Popover"
import ImageResizableBox from "./ImageResizableBox/ImageResizableBox"
import ImagePicker from "../../../shared/ImagePicker/ImagePicker"
import { useAppSelector } from "../../../store/hooks/redux-hooks"
import ToolbarDropdown from "../../../shared/ToolbarControls/ToolbarDropdown"
import DropdownOptionItem from "../../../shared/ToolbarControls/DropdownOptionItem"
import ToolbarSeparator from "../../../shared/ToolbarControls/ToolbarSeparator"
import ToolbarButton from "../../../shared/ToolbarControls/ToolbarButton"
import ToolbarLink from "../../../shared/ToolbarControls/ToolbarLink"
import ToolbarAlignDropdown from "../../../shared/ToolbarControls/ToolbarAlignDropdown"
import ToolbarLinkDropdown from "../../../shared/ToolbarControls/ToolbarLinkDropdown"

type Props = {
  element: PlateImageElement,
  onImageUnset: () => void
  setImageFromPicker: (image_url: string, image_source: string, image_external_id: string) => void
  onAlignChange: (align: string) => void
  onShapeChange: (shape: string) => void
  onImageSizeChange: (width: string) => void
  isReadOnly?: boolean,
  isTemplate: boolean
  selected: boolean
}

const ProgrammaticImagePopover = ({
  element,
  onImageUnset,
  setImageFromPicker,
  onAlignChange,
  onShapeChange,
  onImageSizeChange,
  isReadOnly,
  isTemplate,
  selected
}: Props) => {
  const editor = useEditorRef()
  const [open, setOpen] = useState(false)
  const [showImagePicker, setShowImagePicker] = useState(false)
  const { pages } = useAppSelector(state => state.page_addendums)
  const { resources } = useAppSelector(state => state.page_resources)
  const isLandingPage = useAppSelector(state => state.page_addendums.isLandingPage)

  useEffect(() => {
    setOpen(selected)
  }, [selected])

  const { image_url, image_source } = element
  const matchingPage = pages?.find(page => page.id === element.internal_page_id)
  const matchingResource = resources?.find(resource => resource.id === element.internal_resource_id)
  const externalLinkPath = element.url && element.url.length > 0 ? element.url : null
  const internalLinkPath = matchingPage ? isLandingPage ? matchingPage.landing_link : matchingPage.builder_link : null
  const resourceLinkPath = matchingResource ? matchingResource.goto_url : null
  const goToLink = element.link_type === "external" ? externalLinkPath :
    element.link_type === "internal" ? internalLinkPath :
      element.link_type === "resource" ? resourceLinkPath : null
  const shapeOptions: any[] = [
    { name: 'square', label: <DropdownOptionItem iconName="square" />, iconName: "square" },
    { name: 'circle', label: <DropdownOptionItem iconName="circle" />, iconName: "circle" },
    { name: 'ellipse', label: <DropdownOptionItem iconName="ellipse" />, iconName: "ellipse" },
    { name: 'parallelogram', label: <DropdownOptionItem iconName="parallelogram" />, iconName: "parallelogram" },
    { name: 'triangle', label: <DropdownOptionItem iconName="triangle" />, iconName: "triangle" },
    { name: 'star', label: <DropdownOptionItem iconName="star" />, iconName: "star" },
  ];
  const currentShape = element.shape ? element.shape : 'square'
  const currentAlign = element.align ? element.align : 'left'

  const onExternalUrlChange = (url: string) =>
    editor.setNodes(
      {
        url,
        link_type: 'external',
        internal_page_id: null,
        internal_resource_id: null,
        internal_template_resource_slot_id: null
      } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const onInternalPageIdChange = (internal_page_id: string) =>
    editor.setNodes(
      {
        url: null,
        link_type: 'internal',
        internal_page_id,
        internal_resource_id: null,
        internal_template_resource_slot_id: null
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onInternalTemplateResourceSlotIdChange = (internal_template_resource_slot_id: string) =>
    editor.setNodes(
      {
        url: null,
        link_type: 'resource',
        internal_template_resource_slot_id,
        internal_resource_id: null,
        internal_page_id: null,
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onInternalResourceIdChange = (internal_resource_id: string) =>
    editor.setNodes(
      {
        url: null,
        link_type: 'resource',
        internal_resource_id,
        internal_template_resource_slot_id: null,
        internal_page_id: null,
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onDynamicUrlChange = (url: string) =>
    editor.setNodes(
      {
        url,
        link_type: 'dynamic',
        internal_resource_id: null,
        internal_template_resource_slot_id: null,
        internal_page_id: null,
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )


  return (
    <>
      <Popover open={!isReadOnly ? open : false} onOpenChange={setOpen} >
        <PopoverTrigger className="w-full block">
          <ImageResizableBox
            isPopoverOpen={open}
            element={element}
            onImageSizeChange={onImageSizeChange}
            isReadOnly={isReadOnly}
            goToLink={goToLink}
            selected={!isReadOnly ? open : false}
          />
        </PopoverTrigger>
        <PopoverPortal >
          <PopoverContent
            side="top"
            align="center"
            id="image-element-tooltip-anchor"
            className="block_toolbar"
          >
            <ToolbarLinkDropdown
              linkType={element.link_type}
              externalUrl={element.url}
              onExternalUrlChange={onExternalUrlChange}
              internal_page_id={element.internal_page_id}
              onInternalPageIdChange={onInternalPageIdChange}
              internal_template_resource_slot_id={element.internal_template_resource_slot_id}
              onInternalTemplateResourceSlotIdChange={onInternalTemplateResourceSlotIdChange}
              internal_resource_id={element.internal_resource_id}
              onInternalResourceIdChange={onInternalResourceIdChange}
              onDynamicUrlChange={onDynamicUrlChange}
            />
            {goToLink &&
              <ToolbarLink
                iconName="linkSquare"
                path={goToLink}
                openInNewTab={(element.link_type === 'external' || element.link_type === 'resource')}
              />
            }
            <ToolbarSeparator />
            <ToolbarAlignDropdown onAlignChange={onAlignChange} currentAlign={currentAlign} />
            <ToolbarDropdown
              onOptionSet={onShapeChange}
              iconBeforeLabel={shapeOptions.find((option) => option.name === currentShape)!.iconName!}
              activeOption={currentShape}
              data={shapeOptions}
            />
            <ToolbarSeparator />
            <ToolbarButton
              onClick={setShowImagePicker.bind(setShowImagePicker, true)}
              iconName='image'
            />
            <ToolbarSeparator />
            <ToolbarButton
              onClick={onImageUnset}
              iconName='delete'
              type="delete"
            />
          </PopoverContent>
        </PopoverPortal>
      </Popover>
      <ImagePicker
        backgroundImageSource={image_source as string}
        currentImageUrl={image_url as string}
        modalOpen={showImagePicker}
        onImageChange={setImageFromPicker}
        onImageUnset={onImageUnset}
        onModalClose={setShowImagePicker.bind(setShowImagePicker, false)}
        brandImages={!isTemplate}
      />
    </>
  )
}

export default ProgrammaticImagePopover