import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

// Updates the css variables on the page
export default class extends Controller {
  static values = { id: String }

  connect() {
    // console.log("Branding controller.js connected")
    this.watchForComplete();
  }

  watchForComplete() {
    // console.log(`Watching for branding updates`)
    consumer.subscriptions.create(
      { channel: "BrandChannel", id: this.idValue },
      {
        received: (data) => {
          // update the style attribute on #app-settings with the data.styles attribute
          document.getElementById("app-settings").setAttribute("style", data.styles)
        },
      }
    )
  }
}
