import React, { useState, useEffect, useMemo } from "react"
import { createApi } from "unsplash-js";
import debounce from "lodash.debounce"

import ScrollableDialog from "../ScrollableDialog/ScrollableDialog";
import ImagePickerHeader from "./ImagePickerHeader/ImagePickerHeader";
import ImagePickerCollections from "./ImagePickerCollections/ImagePickerCollections";
import ImagePickerCollection from "./ImagePickerCollection/ImagePickerCollection";
import { UnsplashCollection, UnsplashImage } from "./unsplash.types";
import ImagePickerSearch from "./ImagePickerSearch/ImagePickerSearch";
import ImagePickerMedia from "./ImagePickerMedia/ImagePickerMedia";
import { BrandImage, UploadedImage } from "../../store/reducers/api/api.types";
import { useAddUserImageMutation } from "../../store/reducers/api/images/images";


type Props = {
  backgroundImageSource: string;
  currentImageUrl: string;
  onImageChange: (image: string, imageSource: string, imageId: string) => void;
  onImageUnset: () => void;
  onModalClose: () => void;
  brandImages?: boolean;
  modalOpen: boolean;
}


const ImagePicker = ({ backgroundImageSource, brandImages = false, currentImageUrl, onImageChange, onModalClose, modalOpen, onImageUnset }: Props) => {

  const [pageType, setPageType] = useState((backgroundImageSource === "upload" || backgroundImageSource === "brand") ? "media" : "collections")
  const [searchTerm, setSearchTerm] = useState("")
  const [activeCollection, setActiveCollection] = useState<UnsplashCollection>()

  const [uploadUserImage] = useAddUserImageMutation()

  const unsplashClient = createApi({
    accessKey: '1cmGaycBs6l-UIo9QpwT7DtIdbfEkUNApioW4VdAfpg'
  })

  useEffect(() => {
    setSearchTerm("")
    setPageType((backgroundImageSource === "upload" || backgroundImageSource === "brand") ? "media" : "collections")
  }, [onModalClose])


  const searchImages = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value
    if (!!text.trim()) {
      setPageType("search")
      setSearchTerm(text)
    } else {
      setPageType("collections")
      setSearchTerm("")
    }
  }

  const debouncedSearchHandler = useMemo(() => debounce(searchImages, 300), [])

  const setActiveCollectionHandler = (collection: UnsplashCollection) => {
    setActiveCollection(collection)
    setPageType("collection")
  }

  const setImageFromUnsplash = (image: UnsplashImage) => {
    onImageChange(image.urls.regular, "unsplash", image.id)
    onModalClose()
  }

  const setImageFromBrand = (image: BrandImage) => {
    onImageChange(image.url, "brand", image.id)
    onModalClose()
  }

  const setImageFromMedia = (image: UploadedImage) => {
    onImageChange(image.urls.regular, "upload", image.id)
    onModalClose()
  }

  const onImageRemove = () => {
    onImageUnset()
    onModalClose()
  }

  const setUploadedImage = async (file: File) => {
    const formData = new FormData()
    formData.append("image[uploaded_image]", file)
    await uploadUserImage(formData).then((response) => {
      if ("data" in response) {
        onImageChange(response.data.urls.regular, "upload", response.data.id)
      }
    })
    onModalClose()
  }

  const ScrollableContent = () => {
    switch (pageType) {
      case "collections":
        return <ImagePickerCollections
          unsplashClient={unsplashClient}
          setActiveCollection={setActiveCollectionHandler}
          scrollableContentId={'scrollable-dialog-content-id'}
        />
      case "collection":
        return <ImagePickerCollection
          unsplashClient={unsplashClient}
          setImageFromCollection={setImageFromUnsplash}
          collection={activeCollection!}
          scrollableContentId={'scrollable-dialog-content-id'}
        />
      case "search":
        return <ImagePickerSearch
          unsplashClient={unsplashClient}
          setImageFromCollection={setImageFromUnsplash}
          scrollableContentId={'scrollable-dialog-content-id'}
          query={searchTerm}
        />
      case "media":
        return <ImagePickerMedia
          brandImages={brandImages}
          scrollableContentId={'scrollable-dialog-content-id'}
          setImageFromBrand={setImageFromBrand}
          setImageFromMedia={setImageFromMedia}
        />
      default:
        return null
    }
  }

  return (
    <ScrollableDialog
      dialogHeader={
        <ImagePickerHeader
          setPageType={setPageType}
          pageType={pageType}
          activeCollection={activeCollection}
          setActiveCollection={setActiveCollection}
          searchTerm={searchTerm}
          onSearchHandle={debouncedSearchHandler}
          currentImageUrl={currentImageUrl}
          onImageChange={setUploadedImage}
          onImageRemove={onImageRemove}
          onModalClose={onModalClose}
        />
      }
      dialogOpen={modalOpen}
      onDialogClose={onModalClose}
      scrollableContent={<ScrollableContent />}
      scrollableContentId="scrollable-dialog-content-id"
    />

  )
}

export default ImagePicker