import { Controller } from "@hotwired/stimulus"

// We have a form where the user could enter one field or the other. One of them is required
export default class extends Controller {
  static targets = ["primary", "secondary"];
  static values = { current: String, primaryDefault: String, secondaryDefault: String }

  connect() {
    this.currentValue = this.element.dataset.startingValue
    this.primaryDefaultValue = this.element.dataset.primaryDefault
    this.secondaryDefaultValue = this.element.dataset.secondaryDefault
    this.adjustView()
  }

  toggle(e) {
    e.preventDefault()
    if (this.currentValue === "primary") {
      this.currentValue = "secondary"
    } else {
      this.currentValue = "primary"
    }
    this.adjustView()
  }

  adjustView() {
    if (this.currentValue === "primary") {
      this.primaryTargets.forEach((el) => {
        el.classList.remove("hidden")
        this.setInputValue(el, this.primaryDefaultValue)
      })
      this.secondaryTargets.forEach((el) => {
        el.classList.add("hidden")
        this.setInputValue(el, "")
      })
    } else {
      this.primaryTargets.forEach((el) => {
        el.classList.add("hidden")
        this.setInputValue(el, "")
      })
      this.secondaryTargets.forEach((el) => {
        el.classList.remove("hidden")
        this.setInputValue(el, this.secondaryDefaultValue)
      })
    }
  }

  setInputValue(el, value) {
    const input = el.querySelector("input")
    if (input) {
      input.value = value
    }
  }
}
