import React, { memo } from "react"
import { twMerge } from 'tailwind-merge'

import Icon from "../Icon/Icon"

type Props = {
  btnClassName: string,
  icon: string,
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  iconClassName?: string
  disabled?: boolean
  textAfter?: string
  textBefore?: string
  btnStyles?: React.CSSProperties
}
const IconButton = memo(({ btnClassName, icon, onClick, iconClassName, disabled = false, textAfter, textBefore, btnStyles }: Props) => {
  return (
    <button
      className={twMerge('', btnClassName)}
      onClick={onClick}
      disabled={disabled}
      style={btnStyles}
    >
      {textBefore && textBefore}
      <Icon icon={icon} className={twMerge('pointer-events-none', iconClassName)} />
      {textAfter && textAfter}
    </button>
  )
})

export default IconButton