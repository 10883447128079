import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["showSpeakerButton", "showAllSpeakersButton"]

  changeRole(event) {
    // The event target is a child potentially of the element that was clicked.
    // find the a element that was clicked. Then get the data-role-name attribute
    // of the parent element.
    const dataset = event.target.closest("a").dataset
    const roleName = dataset.roleName
    const defaultName = dataset.defaultName

    // For all of the data-speaker~=speaker elements, go through them and find the
    // data-target="speakerRole" element and set this to `${(roleName)}`
    document.querySelectorAll("[data-speaker~=speaker]").forEach(speaker => {
      speaker.querySelector("[data-target=speakerRole]").innerText = ` (${roleName}) `
    })

    this.changeName(defaultName)
  }

  // Select dropdown
  handleChangeName(event) {
    // console.log("Name changed!", event)
    // The event target is the select element. Asking for the value gets the ID of the selected record
    // We need to get the display value out of this.
    let name = event.target.options[event.target.selectedIndex].text
    this.changeName(name)
  }

  // Text input
  handleEnteredName(event) {
    // console.log(`Name entered: ${event.target.value}`)
    this.changeName(event.target.value)
  }

  changeName(name) {
    // Use name if it is given. If it is not given, go get the default name
    if (!name || name === undefined) { name = this.element.dataset.defaultName }

    document.querySelectorAll("[data-speaker~=speaker]").forEach(speaker => {
      speaker.querySelector("[data-target=speakerName]").innerText = name
    })
  }

  showSpeaker(event) {
    event.preventDefault()
    this.showSpeakerButtonTarget.classList.add("hidden")
    this.showAllSpeakersButtonTarget.classList.remove("hidden")
    document.querySelectorAll("[data-speaker~=other]").forEach(speaker => {
      speaker.classList.add("hidden")
    })
    document.querySelectorAll("[data-speaker~=speaker]").forEach(speaker => {
      speaker.classList.remove("bg-yellow-50")
    })
  }

  showAllSpeakers(event) {
    event.preventDefault()
    this.showSpeakerButtonTarget.classList.remove("hidden")
    this.showAllSpeakersButtonTarget.classList.add("hidden")
    document.querySelectorAll("[data-speaker~=other]").forEach(speaker => {
      speaker.classList.remove("hidden")
    })
    document.querySelectorAll("[data-speaker~=speaker]").forEach(speaker => {
      speaker.classList.add("bg-yellow-50")
    })
  }
}
