import { styled } from '@stitches/react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

export const Popover = PopoverPrimitive.Root;
export const PopoverAnchor = PopoverPrimitive.Anchor;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverPortal = PopoverPrimitive.Portal;
export const PopoverContent = styled(PopoverPrimitive.Content, {
  "z-index": 50,
});
export const PopoverArrow = styled(PopoverPrimitive.Arrow, {
  fill: '#e4e5e3',
});
export const PopoverClose = PopoverPrimitive.Close;
