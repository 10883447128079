import React from "react";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { Provider as StoreProvider } from 'react-redux'

import { store } from "../store/store";
import GlobalStylesLoader from "../GlobalStylesLoader/GlobalStylesLoader";

type Props = {
  children: React.ReactNode
}

const GlobalProviders = ({ children }: Props) => {
  return (
    <StoreProvider store={store}>
      <TooltipProvider
        disableHoverableContent
        delayDuration={200}
        skipDelayDuration={0}
      >
        <GlobalStylesLoader>
          {children}
        </GlobalStylesLoader>
      </TooltipProvider>
    </StoreProvider>

  )
}

export default GlobalProviders;