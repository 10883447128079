import React, { memo } from 'react';

import HugeIcon, { HugeIconName } from '../Icon/HugeIcon';

type Props = {
  iconName: HugeIconName
  label?: string;
}

const DropdownOptionItem: React.FC<Props> = memo(({ iconName, label }) =>
  <div className="w-full p-2 rounded-lg flex gap-[6px] items-center">
    <HugeIcon name={iconName} size={16} color='black' />
    {label && <span className='text-xs font-normal text-left text-black'>
      {label}
    </span>}
  </div>)


export default DropdownOptionItem;
