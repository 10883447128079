import posthog from 'posthog-js'

const bodyData = document.body.dataset
if (bodyData.admin === 'false') {
  posthog.init('phc_oYRR4wx5u6WZM47jWbIWEa7U6SLVFJRoJ8Z60XTpIv0', {
    api_host: 'https://app.posthog.com',
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      }
    }
  })

  posthog.identify(bodyData.userId,
    { },
    {
      accountId: bodyData.accountId,
      userEmail: bodyData.userEmail,
      accountName: bodyData.accountName,
    }
  )

  posthog.people.set_once(bodyData.userEmail)
}
