// Options:
//
// Use data-pricing-active="yearly" to select yearly by default

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "toggleSection", "input" ]

  connect() {
    // Classes toggle on the plan switcher items
    this.activeToggleClass = "bg-white shadow-sm text-black hover:text-black".split(" ")
    this.inactiveToggleClass = "hover:text-gray-900".split(" ")

    // Classes toggle on the plans
    this.activeSectionClass   = "block"
    this.inactiveSectionClass = "hidden"

    // Set the default toggle state
    const defaultState = this.element.dataset.active;

    if (defaultState == "true") {
      this.selectYes()
    } else if (defaultState == "false") {
      this.selectNo()
    }
  }

  selectYes() {
    this.toggleSectionTargets.forEach(target => {
      target.classList.add(this.activeSectionClass)
      target.classList.remove(this.inactiveSectionClass)
    })

    this.toggleTargets.forEach(target => {
      if (target.dataset.value === "no") {
        this.makeInactive(target)
      } else if (target.dataset.value === "yes") {
        this.makeActive(target)
      }
    })

    this.inputTarget.value = "true"
  }

  selectNo() {
    this.toggleSectionTargets.forEach(target => {
      target.classList.add(this.inactiveSectionClass)
      target.classList.remove(this.activeSectionClass)
    })

    this.toggleTargets.forEach(target => {
      if (target.dataset.value === "no") {
        this.makeActive(target)
      } else if (target.dataset.value === "yes") {
        this.makeInactive(target)
      }
    })

    this.inputTarget.value = "false"
  }

  makeActive(target) {
    target.classList.add(...this.activeToggleClass)
    target.classList.remove(...this.inactiveToggleClass)
  }

  makeInactive(target) {
    target.classList.remove(...this.activeToggleClass)
    target.classList.add(...this.inactiveToggleClass)
  }
}
