import React from "react";

import Tooltip from "../../shared/ToolTip/Tooltip";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import TooltipLink from "../../shared/ToolTip/TooltipLink";
import NavBarActions from "./NavBarActions/NavBarActions";
import GlobalConfigModal from "./GlobalConfigModal/GlobalConfigModal";
import TemplateVariablePopover from "./TemplateVariablesPopover/TemplateVariablePopover";

type Props = {
  onBackgroundColorChange: (color: string) => void,
  onTextColorChange: (color: string) => void,
  onLogoVisibleChange: (value: boolean) => void,
  onNavbarVisibilityChange: (value: boolean) => void,
}


const NavBar: React.FC<Props> = ({
  onBackgroundColorChange, onTextColorChange, onLogoVisibleChange, onNavbarVisibilityChange,
}) => {
  const navPageConfig = useAppSelector(state => state.page_navigation)
  const isEditorReadOnly = useAppSelector(state => state.page_addendums.readOnly)
  const isLandingPage = useAppSelector(state => state.page_addendums.isLandingPage)

  const { nav_text_color, nav_enabled, template, room_slug } = navPageConfig
  const isPreviewMode = isEditorReadOnly || isLandingPage

  const nav_router = isPreviewMode ? navPageConfig.nav_router.filter(route => route.status === 'active') : navPageConfig.nav_router

  const isRoutesShown = nav_router.length > 1 && nav_enabled

  const addPagePath = room_slug ? `/rooms/${room_slug}/new_page/templates` : '/'

  return (
    <div className="flex justify-between items-center gap-4">
      {isRoutesShown && nav_router.map(route =>
        <React.Fragment key={route.id}>
          {route.status === 'draft' &&
            <Tooltip content="Page is draft. Will be hidden from your prospects." side="bottom" container='tooltip-anchor-navBar'>
              <a
                onClick={() => window.location.href = isLandingPage ? route.landing_link : route.builder_link}
                className={`nav-tab-link relative ${nav_text_color ? nav_text_color : "color-black"} ${route.isActive ? 'active' : ''}`}
              >
                <div>
                  {route.name}
                  <div className="absolute top-2 right-2 w-2 h-2 rounded-full bg-red-500" />
                </div>
              </a>
            </Tooltip>
          }
          {route.status === 'building' &&
            <Tooltip content="Page is still building. Refresh page to see if page is ready." side="bottom" container='tooltip-anchor-navBar'>
              <a className={`nav-tab-link relative ${nav_text_color ? nav_text_color : "color-black"} ${route.isActive ? 'active' : ''}`} >
                <div>
                  {route.name}
                  <div className="absolute top-2 right-2 w-2 h-2 rounded-full bg-red-500" />
                </div>
              </a>
            </Tooltip>
          }
          {route.status !== 'draft' && route.status !== 'building' &&
            <a
              onClick={() => window.location.href = isLandingPage ? route.landing_link : route.builder_link}
              className={`nav-tab-link relative ${nav_text_color ? nav_text_color : "color-black"} ${route.isActive ? 'active' : ''}`}
            >
              {route.name}
            </a>
          }
        </React.Fragment>
      )}
      {!isEditorReadOnly && !isLandingPage &&
        <div className='flex justify-between items-center gap-2' >
          {!template &&
            <TooltipLink
              tooltip='Add page'
              side='bottom'
              container='tooltip-anchor-navBar'
              icon='faPlus'
              path={addPagePath}
              linkClassName={`btn-nav-link ${nav_text_color ? nav_text_color : "color-black"}`}
            />
          }
          <NavBarActions
            navPageConfig={navPageConfig}
            onBackgroundColorChange={onBackgroundColorChange}
            onTextColorChange={onTextColorChange}
            onLogoVisibleChange={onLogoVisibleChange}
            onNavbarVisibilityChange={onNavbarVisibilityChange}
          />
          <GlobalConfigModal textColor={nav_text_color ? nav_text_color : "color-black"} />
          {template &&
            <TemplateVariablePopover
              buttonClasses={`btn-nav-link ${nav_text_color ? nav_text_color : "color-black"}`}
            />
          }
        </div>}
    </div>
  )
}

export default NavBar;