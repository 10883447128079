import { Controller } from "@hotwired/stimulus"

// I didn't do this all via hotwire because I couldn't have a form within a form.
// This was my workaround.
export default class extends Controller {
  static targets = ["textDetails", "listDetails", "lengthTypeWord", "resourceInput",
                    "resourceInputField", "resourceSlots"]
  static values = { url: String, initialKind: String, initialLengthType: String }

  connect() {
    this.kindValue = this.initialKindValue;
    this.lengthTypeValue = this.initialLengthTypeValue;
    this.initializeForm();
  }

  initializeForm() {
    this.setKindFields();
    this.changeLengthType(this.lengthTypeValue);
  }

  adjustKind(event) {
    let kind = event.target.dataset.value
    this.kindValue = kind
    this.setKindFields()
    this.changeLengthType()
  }

  setKindFields() {
    if (this.kindValue === "text") {
      this.textDetailsTarget.classList.remove("hidden")
      this.listDetailsTarget.classList.add("hidden")
    } else {
      this.textDetailsTarget.classList.add("hidden")
      this.listDetailsTarget.classList.remove("hidden")
    }
  }

  adjustLengthType(event) {
    let word = event.target.dataset.value
    this.lengthTypeValue = word
    this.changeLengthType()
  }

  changeLengthType() {
    if (this.kindValue === "text") {
      // Hacky to just slap the s at the end, but we're moving fast here
      let word = this.lengthTypeValue.charAt(0).toUpperCase() + this.lengthTypeValue.slice(1) + "s"
      this.lengthTypeWordTargets.forEach(target => {
        target.textContent = word
      })
    } else {
      this.lengthTypeWordTargets.forEach(target => {
        target.textContent = "List Items"
      })
    }
  }

  addResource(event) {
    event.preventDefault()
    this.resourceInputTarget.classList.remove("hidden")
  }

  cancelResource(event) {
    event.preventDefault()
    this.closeInput()
  }

  closeInput() {
    this.resourceInputFieldTarget.value = ""
    this.resourceInputTarget.classList.add("hidden")
  }

  saveResource(event) {
    event.preventDefault()
    const resourceValue = this.resourceInputFieldTarget.value
    if (resourceValue.trim() === "") {
      console.log("No resource to save!")
      return
    }

    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({ about: resourceValue })
    })
      .then(response => response.json())
      .then(data => {
        // console.log("Resource saved!", data)
        this.updateResourceList(data)
        this.closeInput()
      })
      .catch(error => console.error("Error saving resource:", error))
  }

  // Update the DOM with the new resource
  // This would typically involve appending a new checkbox or similar to your form
  updateResourceList(resource) {
    // Create the container div
    const containerDiv = document.createElement("div")
    containerDiv.className = "flex items-center"

    // Create the checkbox input
    const checkbox = document.createElement("input")
    checkbox.setAttribute("type", "checkbox")
    checkbox.className = "form-checkbox check_boxes optional"
    checkbox.value = resource.id
    checkbox.name = "ai_snippet[template_resource_slot_ids][]"
    checkbox.id = `ai_snippet_template_resource_slot_ids_${resource.id}`

    // Create the label
    const label = document.createElement("label")
    label.className = "my-1 ml-3 block text-sm font-medium text-gray-600 collection_check_boxes"
    label.setAttribute("for", `ai_snippet_template_resource_slot_ids_${resource.id}`)
    label.textContent = resource.about

    // Append checkbox and label to container div
    containerDiv.appendChild(checkbox)
    containerDiv.appendChild(label)

    // Append the container div to the resource list
    this.resourceSlotsTarget.appendChild(containerDiv)
  }
}
