import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]

  startDownload() {
    this.buttonTarget.textContent = "Processing..."

    // Use setTimeout to delay disabling the button otherwise it interferes with the form submission
    setTimeout(() => {
      this.buttonTarget.disabled = true
      this.buttonTarget.classList.add("disabled")
    }, 10)
  }
}
