import React, { useState } from "react";
import validator from 'validator';

import { Popover, PopoverTrigger, PopoverContent } from '../Popover/Popover';
import HugeIcon from "../Icon/HugeIcon";
import { useAppSelector } from "../../store/hooks/redux-hooks";

type Props = {
  linkType: string | null;
  externalUrl: string | null;
  onExternalUrlChange: (url: string) => void;
  internal_page_id: string | null;
  onInternalPageIdChange: (pageId: string) => void;
  onDynamicUrlChange: (value: string) => void;
  internal_template_resource_slot_id: string | null;
  onInternalTemplateResourceSlotIdChange: (resourceSlotId: string) => void;
  internal_resource_id: string | null;
  onInternalResourceIdChange: (resourceId: string) => void;
}

const ToolbarLinkDropdown: React.FC<Props> = ({
  linkType, externalUrl, onExternalUrlChange, internal_page_id, internal_template_resource_slot_id, internal_resource_id,
  onInternalPageIdChange, onDynamicUrlChange, onInternalTemplateResourceSlotIdChange, onInternalResourceIdChange
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const { resource_slots, resources } = useAppSelector(state => state.page_resources)
  const { template, pages } = useAppSelector(state => state.page_addendums)
  const currentPageId = useAppSelector(state => state.page_addendums.pageId)
  const [linkVariant, setLinkVariant] = useState(linkType || "external")
  const [url, setUrl] = useState(externalUrl || "")
  const [error, setError] = useState<string | null>(null)

  const activePages = pages.filter(page => page.status === 'active');
  const LINK_OPTIONS = [
    { name: "external", label: "External" },
    ...(template ? [{ name: "dynamic", label: "Dynamic" }] : [{ name: "internal", label: "Page in Room" }]),
    ...(resources.length > 0 ? [{ name: "resource", label: "Resource" }] : []),
    ...(template && resource_slots.length > 0 ? [{ name: "resource", label: "Resource" }] : [])
  ]
  const DYNAMIC_LINKS = [
    { key: "transcript", value: "{{LINKS.TRANSCRIPT}}", button: "Transcript Page" },
    { key: "book-meetings", value: "{{LINKS.BOOK_MEETINGS}}", button: "Book Meetings URL" },
  ]

  const preventDefault = (e: Event) => e.preventDefault();

  const onExternalUrlChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null)
    setUrl(event.target.value)
  }

  const onExternalUrlAcceptHandler = () => {
    if (validator.isURL(url, { require_protocol: true })) {
      setError(null)
      onExternalUrlChange(url as string)
      setPopoverOpen(false)
    } else {
      setError("Invalid URL")
    }
  }

  const onInternalPageIdChangeHandler = (pageId: string) => {
    setUrl('')
    onInternalPageIdChange(pageId)
  }

  const onDynamicUrlChangeHandler = (value: string) => {
    setUrl(value)
    onDynamicUrlChange(value)
  }

  const onInternalTemplateResourceSlotIdChangeHandler = (resourceSlotId: string) => {
    setUrl('')
    onInternalTemplateResourceSlotIdChange(resourceSlotId)
  }

  const onInternalResourceIdChangeHandler = (resourceId: string) => {
    setUrl('')
    onInternalResourceIdChange(resourceId)
  }

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal={true}>
      <PopoverTrigger >
        <div
          className={`toolbar_dropdown ${popoverOpen ? 'active' : ''}`}
          contentEditable={false}
          suppressContentEditableWarning
        >
          <HugeIcon name="link" size={16} className="text-black" />
          <HugeIcon name="arrowDown" size={16} className="text-black" />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="center"
        sideOffset={10}
        onOpenAutoFocus={preventDefault}
        onCloseAutoFocus={preventDefault}
        contentEditable={false}
        suppressContentEditableWarning={true}
      >
        <div className="toolbar_dropdown_content min-w-[300px]">
          <div className='p-1 border border-gray-700/20 rounded-lg flex items-center gap-2 w-full mb-4'>
            {
              LINK_OPTIONS.map((item, i) =>
                <button
                  key={i}
                  onClick={setLinkVariant.bind(setLinkVariant, item.name)}
                  className={`
                  w-1/2 px-3 py-2 rounded-lg text-xs font-medium transition-all duration-300 ease-in-out
                  ${linkVariant === item.name ? "text-white bg-blue-500" : " text-black bg-transparent"} 
                `}
                >
                  {item.label}
                </button>
              )
            }
          </div>
          {linkVariant === "external" && (
            <>
              <div className="flex flex-row items-center gap-4 w-full">
                <input
                  type='text'
                  className='form-control'
                  placeholder="https://example.com"
                  onChange={onExternalUrlChangeHandler}
                  value={url as string}
                />
                <button
                  onClick={onExternalUrlAcceptHandler}
                  className="btn bg-blue-500 only-child"
                >
                  <HugeIcon name='tick' size={16} color='white' />
                </button>
              </div>
              {error && (
                <div className="flex w-full items-center text-sm text-red-500 mt-2 animate-fadeIn bg-red-50 border-l-4 border-red-500 p-2">
                  <HugeIcon name='alert' size={16} className='text-red-500 mr-2' />
                  {error}
                </div>
              )}
            </>
          )}
          {linkVariant === "internal" && (
            <div className="flex flex-col gap-4 w-full">
              {activePages.map((page, i) =>
                <button
                  key={i}
                  className={`btn ${page.id === internal_page_id ? "btn-primary" : "btn-white"} only-child`}
                  onClick={onInternalPageIdChangeHandler.bind(onInternalPageIdChangeHandler, page.id)}
                  disabled={page.id === currentPageId}
                >
                  <span className={`${page.id === internal_page_id ? "font-bold" : ""} flex flex-row items-center gap-4`}>
                    {page.id === internal_page_id && <HugeIcon name='tick' size={16} color='white' />}
                    {page.name}
                  </span>
                </button>
              )}
            </div>
          )}
          {linkVariant === "dynamic" &&
            <div className="flex flex-col gap-4 w-full">
              {DYNAMIC_LINKS.map((dynamicLink, i) =>
                <button
                  key={i}
                  className={`btn ${url == dynamicLink.value ? "btn-primary" : "btn-white"} only-child`}
                  onClick={onDynamicUrlChangeHandler.bind(onDynamicUrlChangeHandler, dynamicLink.value)}
                >
                  <span className={`${url == dynamicLink.value ? "font-bold" : ""} flex flex-row items-center gap-4`}>
                    {url == dynamicLink.value && <HugeIcon name='tick' size={16} color='white' />}
                    {dynamicLink.button}
                  </span>
                </button>
              )}
            </div>
          }
          {
            linkVariant === "resource" &&
            <div className="flex flex-col gap-4 w-full">
              <>
                {template && resource_slots.length > 0 &&
                  <>
                    {resource_slots.map((resourceSlot) => (
                      <button
                        key={`resource-slot-link-${resourceSlot.id}`}
                        className={`btn ${internal_template_resource_slot_id == resourceSlot.id ? "btn-primary" : "btn-white"} only-child`}
                        onClick={onInternalTemplateResourceSlotIdChangeHandler.bind(onInternalTemplateResourceSlotIdChangeHandler, resourceSlot.id)}
                      >
                        <span className={`${internal_template_resource_slot_id == resourceSlot.id ? "font-bold" : ""} flex flex-row items-center gap-4`}>
                          {internal_template_resource_slot_id == resourceSlot.id && <HugeIcon name='tick' size={16} color='white' />}
                          {resourceSlot.about}
                        </span>
                      </button>
                    ))}
                  </>
                }
                {
                  !template && resources.length > 0 &&
                  <>
                    {resources.map((resource) => (
                      <button
                        key={`resource-link-${resource.id}`}
                        className={`btn ${internal_resource_id == resource.id ? "btn-primary" : "btn-white"} only-child`}
                        onClick={onInternalResourceIdChangeHandler.bind(onInternalResourceIdChangeHandler, resource.id)}
                      >
                        <span className={`${internal_resource_id == resource.id ? "font-bold" : ""} flex flex-row items-center gap-4`}>
                          {internal_resource_id == resource.id && <HugeIcon name='tick' size={16} color='white' />}
                          {resource.name}
                        </span>
                      </button>
                    ))}
                  </>
                }
              </>
            </div>
          }
        </div>
      </PopoverContent>
    </Popover>
  );
};


export default ToolbarLinkDropdown;