import React from "react";
import { isEditorReadOnly, PlateElement, useEditorRef } from "@udecode/plate-common/react";
import { useSelected } from "slate-react";
import { findNode, getParentNode } from "@udecode/plate-common";

import { PlateElementEmbed } from "../../../plate-config/Plugins/Embed/Embed.plugin";
import IconButton from "../../../shared/IconButton/IconButton";
import embedUrlPatterns from "../config/config";
import SourceItem from "./SourceItem/SourceItem";
import { ColumnPlugin } from "../../../plate-config/Plugins/ColumnGroup/Column.plugin";
import HugeIcon from "../../../shared/Icon/HugeIcon";

type Props = {
  element: PlateElementEmbed
  ref: any,
  className?: string,
  isFirstChild: boolean,
  isLastChild: boolean,
  plateElProps: any
  children: React.ReactNode;
  onSelectorConfirm: () => void
  onSelectorInputChanged: (e: React.ChangeEvent<HTMLInputElement>) => void
  onElementDeleteHandle: () => void
  error: string | null
  url: string
}

const SourceSelector: React.FC<Props> = ({
  ref, element, className, isFirstChild, isLastChild, onElementDeleteHandle, error, url,
  plateElProps, children, onSelectorConfirm, onSelectorInputChanged
}) => {
  const editor = useEditorRef();
  const selected = useSelected()
  const isReadOnly = isEditorReadOnly(editor)

  const nodePath = findNode(editor, { at: [], match: { id: element.id } })![1];
  const parentNode = getParentNode(editor, nodePath)!
  const parentNodeType = parentNode[0].type as string
  const isElColumnChild = parentNodeType === ColumnPlugin.key
  const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false

  return (
    <PlateElement
      ref={ref}
      id={element.id}
      editor={editor}
      data-id={element.id}
      element={element}
      className={`
        ${className} p-2 rounded-lg transition-all duration-300 ease-in-out
         ${isElColumnChild
          ? isLastColElement ? "mb-0" : "mb-4"
          : `page-block embed-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
        }
         ${!isReadOnly
          ? `${selected
            ? 'border-blue-500/60 border hover:border-blue-500/60'
            : 'hover:border-blue-500/30 border border-transparent'}`
          : ''
        } 
        `}
      data-plate-selectable
      contentEditable={false}
      suppressContentEditableWarning
      {...plateElProps}
    >
      <div className="w-full min-h-96 bg-gray-200/80 border-gray-400 rounded-xl shadow-sm text-center p-8 pt-[37px]">
        <div
          className='btn-small flex items-center rounded-lg hover:bg-red-100 absolute right-[14px] top-[12px]'
          onClick={onElementDeleteHandle}
        >
          <HugeIcon name={'delete'} size={16} className='text-red-500' />
        </div>
        <div className={`form-group mb-3 ${error ? "error" : ""}`}>
          <div className="flex gap-2">
            <input
              type="text"
              className="form-control flex-grow w-full text-xs sm:text-xs md:text-base text-gray-700"
              placeholder="Insert embed link"
              onChange={onSelectorInputChanged}
              value={url}
            />
            <IconButton
              btnClassName='btn btn-primary only-child'
              icon='faCheck'
              onClick={onSelectorConfirm}
            />
          </div>
          {error && <div className="form-hint text-left error">{error}</div>}
        </div>
        <div className="flex items-center justify-center">
          <div className="text-gray-800">
            <h3 className="mb-2">Embed content from:</h3>
            <ul className="list-none text-base">
              {embedUrlPatterns.map((platform, i) => <SourceItem platform={platform} key={i} />)}
            </ul>
          </div>
        </div>
      </div>
      {children}
    </PlateElement>
  )
}

export default SourceSelector