import React, { useState } from "react";
import { TElement } from "@udecode/plate-common";
import { useEditorRef } from "@udecode/plate-common/react";

import IconButton from "../../../../shared/IconButton/IconButton";
import Icon from "../../../../shared/Icon/Icon";

type Props = {
  sectionElement: TElement
}

const Warnings = ({
  sectionElement
}: Props) => {
  const editor = useEditorRef()
  const [warningOpen, setWarningOpen] = useState(false)

  const handleRemoveColor = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    editor.setNodes(
      { tint_opacity: 0 } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    setWarningOpen(false)
  }

  const handleRemoveImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    editor.setNodes(
      { background_image_url: null } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    setWarningOpen(false)
  }

  return (
    <>
      {warningOpen
        ?
        <div className="relative">
          <div className="w-96 bg-red-100 border border-red-700 text-gray-800 px-3 py-2 rounded-xl drop-shadow z-30 absolute">
            <div className="flex justify-between items-center mb-2">
              <div className="flex gap-2 text-sm items-center font-semibold">
                <Icon icon="faTriangleExclamation" /> 1 Issue
              </div>
              <IconButton
                icon="faX"
                onClick={setWarningOpen.bind(setWarningOpen, false)}
                btnClassName="text-sm text-gray-800"
              />
            </div>
            <p className="text-sm leading-normal mb-4">The background color hides the background image.</p>
            <div className="flex justify-end gap-4">
              <button className="btn btn-small btn-danger btn-outline btn-block" onClick={handleRemoveColor} >
                Remove Color
              </button>
              <button className="btn btn-small btn-danger btn-outline btn-block" onClick={handleRemoveImage}>
                Remove Image
              </button>
            </div>
          </div>
        </div>
        :
        <IconButton
          icon='faTriangleExclamation'
          btnClassName="btn btn-small btn-danger flex gap-2"
          onClick={setWarningOpen.bind(setWarningOpen, !warningOpen)}
          textAfter="1 Issue"
        />
      }
    </>)
}

export default Warnings
