import React from "react"

import { PageFonts } from "../store/reducers/page_fonts/page_fonts"
import { PagePalette } from "../store/reducers/page_colors/page_colors"

export interface SectionFontTypes extends React.CSSProperties {
  "--paragraph-font": string,
  "--paragraph-weight": number,
  "--header-font": string,
  "--header-weight": number,
}

export const generateGlobalFontVariables = (page_fonts: PageFonts): SectionFontTypes => {
  return {
    "--paragraph-font": page_fonts.paragraph_font,
    "--paragraph-weight": page_fonts.paragraph_weight,
    "--header-font": page_fonts.header_font,
    "--header-weight": page_fonts.header_weight,
  }
}

export const generateGlobalColorVariables = (palette: PagePalette): React.CSSProperties => {
  return palette.colors.reduce((acc, color, index) => {
    acc[`--color-${index}`] = color;
    return acc;
  }, {} as Record<string, string>) as React.CSSProperties
}