import React, { memo } from "react";

import ButtonSelector from "../ButtonSelector/ButtonSelector";

type Props = {
  prop: string
  onPropChange: (kind: string) => void
  options: {
    name: any,
    label: string | React.ReactElement,
  }[],
  label: string
  wrapperClassName: string
}

const PropsSelector: React.FC<Props> = memo(({ prop, onPropChange, options, label, wrapperClassName }) => {
  return (
    <div className={wrapperClassName}>
      <div className="label">{label}</div>
      <ButtonSelector data={options} current={prop} setCurrent={onPropChange} />
    </div>
  )
})

export default PropsSelector;