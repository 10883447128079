import React from 'react'

import PopoverSelectorTitle from '../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle';
import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from "../../../shared/Popover/Popover";
import ButtonSelector from '../../../shared/ButtonSelector/ButtonSelector';
import ColorSelector from '../../../shared/ColorSelector/ColorSelector';
import { PageNavigation } from '../../../store/reducers/page_navigation/page_navigation';
import TooltipIcon from '../../../shared/ToolTip/TooltipIcon';

type NavBarActionProps = {
  navPageConfig: PageNavigation;
  onBackgroundColorChange: (color: string) => void,
  onTextColorChange: (color: string) => void,
  onLogoVisibleChange: (value: boolean) => void,
  onNavbarVisibilityChange: (value: boolean) => void,
}

const NavBarActions = ({
  navPageConfig: {
    seller_id,
    nav_display_logo,
    nav_text_color = 'color-black',
    nav_enabled,
    nav_background_color = 'color-white',
  },
  onBackgroundColorChange,
  onTextColorChange,
  onLogoVisibleChange,
  onNavbarVisibilityChange
}: NavBarActionProps) => {
  const changeLogoPath = seller_id ? `/settings/profiles/${seller_id}/edit` : '/settings/profiles';

  return (
    <Popover>
      <PopoverTrigger>
        <TooltipIcon
          tooltip='Config'
          icon='faGear'
          container='tooltip-anchor-navBar'
          side='bottom'
          iconWrapperClassName={`btn-nav-link ${nav_text_color}`}
          iconWrapperStyle={{ border: "0 solid" }}
        />
      </PopoverTrigger>
      <PopoverContent sideOffset={5} align="start" alignOffset={-40}>
        <PopoverArrow />
        <div className="w-72 border-solid border border-gray-200 rounded-xl bg-white drop-shadow z-30">
          <div className="p-4">
            <PopoverSelectorTitle title="Navigation bar" />
            <ButtonSelector
              data={[
                { name: true, label: "Show" },
                { name: false, label: "Hide" },
              ]}
              current={nav_enabled}
              setCurrent={onNavbarVisibilityChange}
            />
            {nav_enabled &&
              <>
                <div className="pt-6">
                  <PopoverSelectorTitle title="Background color" />
                  <ColorSelector color={nav_background_color!} setColor={onBackgroundColorChange} transparent={true} />
                </div>
                <div className="pt-6">
                  <PopoverSelectorTitle title="Text color" />
                  <ColorSelector color={nav_text_color!} setColor={onTextColorChange} />
                </div>
                <div className="pt-6">
                  <div className="flex justify-between items-center">
                    <PopoverSelectorTitle title="Logo" />
                    <a
                      href={changeLogoPath}
                      className="font-semibold text-xs text-gray-700 mr-1"
                    >
                      Change
                    </a>
                  </div>
                  <ButtonSelector
                    data={[
                      { name: true, label: "Show" },
                      { name: false, label: "Hide" },
                    ]}
                    current={nav_display_logo}
                    setCurrent={onLogoVisibleChange}
                  />
                </div>
              </>
            }
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default NavBarActions
