import { BlockquotePlugin } from "@udecode/plate-block-quote/react";
import { ParagraphPlugin } from "@udecode/plate-common/react";
import { HEADING_KEYS } from "@udecode/plate-heading";
import { BulletedListPlugin, ListItemContentPlugin, ListItemPlugin, NumberedListPlugin } from "@udecode/plate-list/react";

// This is the default mockup's for build in elements

export const createDefaultParagraphElement = () => ({
  type: ParagraphPlugin.key,
  children: [{ text: '' }],
})

export const createDefaultH1Element = () => ({
  type: HEADING_KEYS.h1,
  children: [{ text: '' }],
})

export const createDefaultH2Element = () => ({
  type: HEADING_KEYS.h2,
  children: [{ text: '' }],
});

export const createDefaultBlockQuoteElement = () => ({
  type: BlockquotePlugin.key,
  children: [{ text: '' }],
})

export const createDefaultUlElement = () => ({
  type: BulletedListPlugin.key,
  children: [{ text: '' }]
})

export const createDefaultOlElement = () => ({
  type: NumberedListPlugin.key,
  children: [{ text: '' }]
})

export const createDefaultBulletListElement = () => ({
  type: BulletedListPlugin.key, children: [
    { type: ListItemPlugin.key, children: [{ type: ListItemContentPlugin.key, children: [{ text: '' }] }] },
    { type: ListItemPlugin.key, children: [{ type: ListItemContentPlugin.key, children: [{ text: '' }] }] },
    { type: ListItemPlugin.key, children: [{ type: ListItemContentPlugin.key, children: [{ text: '' }] }] },
  ]
})