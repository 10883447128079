import React, { memo } from "react";

import { UnsplashCollection } from "../unsplash.types";

type Props = {
  collection: UnsplashCollection;
  setActiveCollection: (collection: UnsplashCollection) => void;
}

const ImagePickerCollectionsCard = memo(({ collection, setActiveCollection }: Props) => {
  const { title, total_photos, cover_photo } = collection;
  return (
    <div className="basis-1/2 pr-4 pb-4 md:basis-1/3 lg:basis-1/4">
      <div className="pl-2 pb-1 text-xs">
        <span className="text-gray-700 font-semibold"> {title}</span>
        <span className="text-gray-500 ml-1">{total_photos}</span>
      </div>
      <div
        className="relative image-card bg-cover rounded-xl bg-center transition ease-in-out duration-500"
        onClick={setActiveCollection.bind(setActiveCollection, collection)}
        style={{ backgroundImage: `url(${cover_photo.urls.small})` }}
      >
        <div className="flex rounded-xl absolute inset-0 w-full h-full items-center justify-center opacity-0 cursor-pointer transition-all ease-in-out duration-500 hover:bg-gray-800 hover:bg-opacity-50 hover:opacity-100">
          <div className="btn btn-white btn-small shadow-lg transition-all ease-in-out duration-500 with-hover-ring">
            View Collection
          </div>
        </div>
      </div>
    </div>
  )
})

export default ImagePickerCollectionsCard
