import React, { useState, useEffect } from "react"
import InfiniteScroll from "react-infinite-scroll-component";

import TextWithSpinner from "../../LoadingComponents/TextWithSpinner";
import { useLazyGetBrandImagesQuery, useLazyGetUploadedImagesQuery } from "../../../store/reducers/api/images/images";
import { BrandImage, UploadedImage } from "../../../store/reducers/api/api.types";
import ImagePickerMediaCard from "./ImagePickerMediaCard";
import ImagePickerMediaBrandFolder from "./ImagePickerMediaBrandFolder";
import ImagePickerMediaBrandList from "./ImagePickerMediaBrandList";

type Props = {
  setImageFromMedia: (image: UploadedImage) => void;
  setImageFromBrand: (image: BrandImage) => void;
  scrollableContentId: string;
  brandImages: boolean;
}

const ImagePickerMedia = ({
  setImageFromMedia,
  setImageFromBrand,
  scrollableContentId,
  brandImages
}: Props) => {

  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([])
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState(1)

  const [accountImages, setAccountImages] = useState<BrandImage[]>([])
  const [buyerImages, setBuyerImages] = useState<BrandImage[]>([])
  const [showBrandImages, setShowBrandImages] = useState<string | null>(null)
  const onBrandImagesListClose = () => setShowBrandImages(null)
  const onBrandFolderPick = (type: string) => setShowBrandImages(type)

  const [getUploadedImages] = useLazyGetUploadedImagesQuery()
  const [getBrandImages] = useLazyGetBrandImagesQuery()

  useEffect(() => {
    if (brandImages) {
      fetchBrandImages()
    }
  }, [brandImages])

  const fetchBrandImages = async () =>
    await getBrandImages()
      .then(({ data }) => {
        if (data) {
          setAccountImages(data.account_images.images)
          setBuyerImages(data.buyer_images.images)
        }
      })
      .catch((error) => console.log(`Error fetching brand images: ${error}`))

  useEffect(() => {
    fetchUploadedImages()
  }, [])

  const fetchUploadedImages = async () =>
    await getUploadedImages(page.toString())
      .then(({ data }) => {
        if (data) {
          const totalImages = data.total_images;
          const updatedImages = [...uploadedImages, ...data.images];
          setUploadedImages(updatedImages);
          if (updatedImages.length < totalImages) {
            setPage(page + 1)
            setHasMore(true)
          } else {
            setHasMore(false)
          }
        }
      }).catch((error) => console.log(`Error fetching uploaded images: ${error}`))



  return (
    <InfiniteScroll
      dataLength={uploadedImages.length}
      next={fetchUploadedImages}
      hasMore={hasMore}
      loader={
        <TextWithSpinner wrapperClassNames="pt-4 mb-12 pb-8" />
      }
      endMessage={
        <div className="pt-4 mb-12 pb-8 text-center text-gray-600">All images loaded</div>
      }
      scrollableTarget={scrollableContentId}
    >
      <div className="p-4 pr-0">
        {!showBrandImages
          ?
          (<div className="flex flex-wrap">
            {brandImages &&
              <>
                {accountImages.length > 0 &&
                  <ImagePickerMediaBrandFolder
                    text="Your Brand"
                    images={accountImages}
                    type="account"
                    setShowBrandImages={onBrandFolderPick}
                  />
                }
                {buyerImages.length > 0 &&
                  <ImagePickerMediaBrandFolder
                    text="Buyer Brand"
                    images={buyerImages}
                    type="buyer"
                    setShowBrandImages={onBrandFolderPick}
                  />
                }
              </>
            }
            {uploadedImages.map(image =>
              image.urls.small &&
              <ImagePickerMediaCard
                key={image.id}
                uploadedImage={image}
                onUploadedImageChange={setImageFromMedia}
              />
            )}
          </div>)
          :
          <ImagePickerMediaBrandList
            images={showBrandImages === "account" ? accountImages : buyerImages}
            onBrandImagesListClose={onBrandImagesListClose}
            onImageChange={setImageFromBrand}
          />
        }
      </div>
    </InfiniteScroll>
  )
}

export default ImagePickerMedia
