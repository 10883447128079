import React, { memo } from "react"

import { WEIGHT_BY_FAMILY } from "../../../../../store/reducers/page_fonts/page_fonts.config"
import IconButton from "../../../../../shared/IconButton/IconButton"
import PopoverSelectorTitle from "../../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"


type Props = {
  setActiveFontOption: (value: string) => void
  font: string
  setFont: (value: string) => void
  title: string
}

const FontFamilySelector = memo(({ setActiveFontOption, title, font, setFont }: Props) => {

  const fontFamilies = Object.keys(WEIGHT_BY_FAMILY)

  return (
    <>
      <IconButton
        icon="faArrowLeft"
        onClick={setActiveFontOption.bind(setActiveFontOption, 'default')}
        btnClassName="float-right mt-4 mr-4 btn btn-white btn-small"
        textAfter="Back"
        iconClassName="mr-2"
      />
      <div className="p-4">
        <PopoverSelectorTitle title={title} className="mt-6 mb-2" />
        <div className="flex flex-col gap-1">
          {fontFamilies.map((fontFamily) => {
            const activeClass = font === fontFamily ? "bg-gray-300" : ""
            const fontKey = fontFamily.toLowerCase().replace(/ /g, "-")
            return (
              <button
                key={fontKey}
                onClick={setFont.bind(setFont, fontFamily)}
                className={`flex justify-between hover:bg-gray-200 rounded-md p-2 w-full text-gray-700 text-left ${activeClass}`}
              >
                {fontFamily}
              </button>
            )
          })}
        </div>
        <IconButton
          icon="faCheck"
          onClick={setActiveFontOption.bind(setActiveFontOption, 'default')}
          btnClassName="btn btn-white btn-block mt-6"
          textAfter="Done"
          iconClassName="mr-2"
        />
      </div>
    </>
  )
})

export default FontFamilySelector
