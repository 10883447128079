import React, { useState } from "react"

import PopoverSelectorTitle from "../../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"
import LabeledInput from "../../../../../shared/Inputs/LabeledInput"
import ColorSelector from "../../../../../shared/ColorSelector/ColorSelector"
import IconButton from "../../../../../shared/IconButton/IconButton"
import { StoreCalcVariable } from "../../../../../store/reducers/page_calc_variables/page_calc_variables"
import { useUpdateCalcVariableMutation } from "../../../../../store/reducers/api/calc_variables/calc_variables.api"

type Props = {
  calc_variables: StoreCalcVariable[],
  activeVar: StoreCalcVariable,
  onSliderVariableIdChange: (slider_variable_id: string) => void,
  slider_color: string,
  onSliderColorChange: (color: string) => void,
}

const SliderOptions = ({ calc_variables, activeVar, onSliderVariableIdChange, slider_color, onSliderColorChange }: Props) => {
  const [changeVariableView, setChangeVariableView] = useState(false)
  const [updateVariable] = useUpdateCalcVariableMutation()

  const [varDefaultValue, setVarDefaultValue] = useState(activeVar.default)
  const [varMinimum, setVarMinimum] = useState(activeVar.minimum)
  const [varMaximum, setVarMaximum] = useState(activeVar.maximum)
  const [varStep, setVarStep] = useState(activeVar.step)
  const [varLabel, setVarLabel] = useState(activeVar.label)

  const onVariableIdChange = (id: string) => {
    onSliderVariableIdChange(id)
    setChangeVariableView(false)
  }


  const onVarLabelHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setVarLabel(e.target.value)
    await updateVariable({ ...activeVar, default: activeVar.default.toString(), label: e.target.value })
  }

  const onVarDefaultValueHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setVarDefaultValue(Number(e.target.value))
    await updateVariable({ ...activeVar, default: e.target.value })
  }

  const onVarMaximumHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setVarMaximum(Number(e.target.value))
    await updateVariable({ ...activeVar, default: activeVar.default.toString(), maximum: Number(e.target.value) })
  }

  const onVarMinimumHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setVarMinimum(Number(e.target.value))
    await updateVariable({ ...activeVar, default: activeVar.default.toString(), minimum: Number(e.target.value) })
  }

  const onVarStepHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setVarStep(Number(e.target.value))
    await updateVariable({ ...activeVar, default: activeVar.default.toString(), step: Number(e.target.value) })
  }




  const onChangeDefaultValueValidator = (min: number | null, max: number | null, val: number) => {
    if (!min || !val || !max) return null
    if (min && max && min > max) return null
    if (val < min || val > max) {
      return `Must be between ${min} and ${max}`
    }
  }

  const onChangeMinValidator = (min: number | null, max: number | null) => {
    if (!max || !min) {
      return null
    }
    if (min > max) {
      return `Must be less than ${max}`
    }
  }

  const onChangeMaxValidator = (min: number | null, max: number | null) => {
    if (!min) {
      return null
    }
    if (max! < min) {
      return `Must be greater than ${min}`
    }
  }

  const onChangeStepValidator = (min: number | null, max: number | null, step: number | null) => {
    if (!step || step < min! || step > max!) {
      return `Must be between ${min} and ${max}`
    }
  }
  return (
    <div className="w-72 border-solid border border-gray-200 rounded-xl bg-white drop-shadow z-30">
      <div className="p-5 flex flex-col gap-4">
        {changeVariableView
          ?
          <>
            <div className="flex justify-between items-center">
              <PopoverSelectorTitle title="Select a Variable" />
              <IconButton
                btnClassName="btn btn-white btn-small"
                iconClassName="mr-2"
                icon={'faArrowLeft'}
                onClick={setChangeVariableView.bind(setChangeVariableView, false)}
                textAfter="Back"
              />
            </div>
            {
              calc_variables.map(el =>
                <button
                  key={`${el.id}-button-variable`}
                  className={`btn btn-block flex justify-start items-center`}
                  onClick={onVariableIdChange.bind(onVariableIdChange, el.id)}
                  disabled={activeVar?.id === el.id}
                >
                  <div className="text-gray-600 font-mono text-xs"> {"{{"}{el.handle}{"}}"}</div>
                  <div>{el.label}</div>
                </button>
              )
            }
          </>
          :
          <>
            <PopoverSelectorTitle title="Slider Options" />
            <LabeledInput
              type='text'
              htmlFor="slider-label"
              label="Label"
              value={varLabel}
              onChange={onVarLabelHandler}
            />
            <LabeledInput
              type='number'
              htmlFor="slider-handle"
              label="Default Value"
              error={onChangeDefaultValueValidator(varMinimum, varMaximum, varDefaultValue)}
              value={varDefaultValue}
              onChange={onVarDefaultValueHandler}
            />
            <div className="flex gap-4">
              <LabeledInput
                htmlFor="slider-minimum"
                label="Minimum"
                value={varMinimum}
                onChange={onVarMinimumHandler}
                type="number"
                min={1}
                error={onChangeMinValidator(varMinimum, varMaximum)}
              />
              <LabeledInput
                htmlFor="slider-maximum"
                label="Maximum"
                value={varMaximum}
                onChange={onVarMaximumHandler}
                type="number"
                min={1}
                error={onChangeMaxValidator(varMinimum, varMaximum)}
              />
            </div>
            <LabeledInput
              htmlFor="slider-step"
              label="Increment"
              value={varStep}
              type="number"
              onChange={onVarStepHandler}
              error={onChangeStepValidator(varMinimum, varMaximum, varStep)}
            />
            <div className="form-group mb-0">
              <label> Color </label>
              <ColorSelector color={slider_color} setColor={onSliderColorChange} />
            </div>
            <div className="relative group/slider-btn-anchor">
              <div className={`hidden group-hover/slider-btn-anchor:block tooltip absolute -top-10 left-1/2 transform -translate-x-1/2 whitespace-nowrap transition-all duration-300`}>
                Use a different variable for the slider
              </div>
              <button
                className="btn btn-block btn-white"
                onClick={setChangeVariableView.bind(setChangeVariableView, true)}
              >
                Change Variable
              </button>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default SliderOptions
