import React, { useState } from "react";

import { Popover, PopoverTrigger, PopoverContent } from '../Popover/Popover';
import HugeIcon from "../Icon/HugeIcon";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import { colorClassToRGB, textColorFromHex } from "../../utils/color.util";
import Slider from "../Sliders/Slider";

type Props = {
  color?: string | null;
  colorLabel: string;
  onColorChange: (color: string) => void;
  onColorReset?: () => void;
  opacity?: number | null;
  onOpacityChange?: (opacity: number) => void;
  secondaryColor?: string | null;
  secondaryColorLabel?: string;
  onSecondaryColorChange?: (color: string) => void;
  onSecondaryColorReset?: () => void;
}

const ToolbarColorDropdown: React.FC<Props> = ({
  color, onColorChange, onOpacityChange, opacity, colorLabel, onColorReset,
  secondaryColor, onSecondaryColorChange, secondaryColorLabel, onSecondaryColorReset
}) => {
  const { activePalette } = useAppSelector(state => state.page_colors)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [opacityValue, setOpacityValue] = useState(typeof opacity === 'number' ? [opacity] : [50])

  const colors = [...activePalette.colors, "#000000", "#ffffff"]

  const isColorActive = (backgroundColor: string, index: number, color: string): boolean => {
    switch (true) {
      case backgroundColor === color || `color-${index}` === color:
        return true;
      case backgroundColor === "#000000" && color === "color-black":
        return true;
      case backgroundColor === "#ffffff" && color === "color-white":
        return true;
      case backgroundColor === "transparent" && color === "color-transparent":
        return true;
      default:
        return false;
    }
  }

  const colorCheck = (color: string, position: number) => {
    let colorClass: string
    switch (color) {
      case "#000000":
        colorClass = "color-black"
        break;
      case "#ffffff":
        colorClass = "color-white"
        break;
      case "transparent":
        colorClass = "color-transparent"
        break;
      default:
        colorClass = `color-${position}`
        break;
    }
    return colorClass
  }

  const onColorChangeHandle = (color: string, position: number) => onColorChange(colorCheck(color, position))
  const onSecondaryColorChangeHandle = (color: string, position: number) => onSecondaryColorChange && onSecondaryColorChange(colorCheck(color, position))

  const preventDefault = (e: Event) => e.preventDefault();
  const onOpacityChangeHandle = (value: number[]) => {
    setOpacityValue(value)
    onOpacityChange && onOpacityChange(value[0])
  }

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal={true}>
      <PopoverTrigger >
        <div
          className={`toolbar_dropdown ${popoverOpen ? 'active' : ''}`}
          contentEditable={false}
          suppressContentEditableWarning
        >
          {
            color
              ?
              <div
                className={`h-[13px] w-[13px] rounded-full ring-1 ring-gray-900/20`}
                style={{ backgroundColor: colorClassToRGB(color as string, 100, activePalette.colors)! }}
              />
              :
              <div className="chessboard-background h-[13px] w-[13px] rounded-full ring-1 ring-gray-900/20">
                <div className="w-[1px] h-full bg-red-500 transform rotate-45 rounded-full relative right-[-45%]" />
              </div>
          }
          <HugeIcon name="arrowDown" size={16} color='black' />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="center"
        sideOffset={10}
        onOpenAutoFocus={preventDefault}
        onCloseAutoFocus={preventDefault}
        contentEditable={false}
        suppressContentEditableWarning={true}
      >
        <div className="toolbar_dropdown_content max-w-[202px]">
          <div className={`${typeof opacity === 'number' ? 'pb-4' : ""}`}>
            <span className="text-xs text-black">{colorLabel}</span>
            <section className="flex gap-1 flex-wrap max-w-[202px] mt-2">
              {
                onColorReset &&
                <div className="p-[2.5px] h-8 w-8">
                  <button
                    onClick={onColorReset}
                    className="w-full h-full chessboard-background rounded-full ring-1 ring-gray-900/20 flex items-center justify-center"
                  > <div className="w-[1px] h-full bg-red-500 transform rotate-45 rounded-full" /></button>
                </div>
              }
              {colors.map((backgroundColor, i) =>
                <div className="p-[2.5px] h-8 w-8" key={i}>
                  <button
                    onClick={onColorChangeHandle.bind(onColorChangeHandle, backgroundColor, i)}
                    className="w-full h-full rounded-full ring-1 ring-gray-900/20 flex items-center justify-center"
                    style={{ backgroundColor: backgroundColor }}
                  >
                    {isColorActive(backgroundColor, i, color!) &&
                      <HugeIcon name="tick" size={16} color={textColorFromHex(backgroundColor)} />}
                  </button>
                </div>
              )}
            </section>
          </div>
          {typeof opacity === "number" && <div className="w-full">
            <div className="flex text-xs text-black justify-between">
              <span>Opacity</span>
              <span>{opacityValue}%</span>
            </div>
            <Slider
              min={0}
              max={100}
              value={opacityValue}
              onValueChange={onOpacityChangeHandle}
              wrapperClassName="mt-2"
              colorClass="blue-500"
            />
          </div>}
          {secondaryColorLabel &&
            <div className="pt-4">
              <span className="text-xs text-black">{secondaryColorLabel}</span>
              <section className="flex gap-1 flex-wrap max-w-[202px] mt-2">
                {
                  onSecondaryColorReset &&
                  <div className="p-[2.5px] h-8 w-8">
                    <button
                      onClick={onSecondaryColorReset}
                      className="w-full h-full chessboard-background rounded-full ring-1 ring-gray-900/20 flex items-center justify-center"
                    > <div className="w-[1px] h-full bg-red-500 transform rotate-45 rounded-full" /></button>
                  </div>
                }
                {colors.map((backgroundColor, i) =>
                  <div className="p-[2.5px] h-8 w-8" key={i}>
                    <button
                      onClick={onSecondaryColorChangeHandle.bind(onSecondaryColorChangeHandle, backgroundColor, i)}
                      className="w-full h-full rounded-full ring-1 ring-gray-900/20 flex items-center justify-center"
                      style={{ backgroundColor: backgroundColor }}
                    >
                      {isColorActive(backgroundColor, i, secondaryColor!) &&
                        <HugeIcon name="tick" size={16} color={textColorFromHex(backgroundColor)} />}
                    </button>
                  </div>
                )}
              </section>
            </div>}
        </div>
      </PopoverContent>
    </Popover>
  );
};


export default ToolbarColorDropdown;