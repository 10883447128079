import React from "react";
import { focusEditor, ParagraphPlugin, useEditorRef, useEditorSelector } from "@udecode/plate-common/react";
import { BulletedListPlugin, NumberedListPlugin } from "@udecode/plate-list/react";
import { getParentNode, select, TElement, toggleBlock } from "@udecode/plate-common";
import { getListRoot, toggleList, unwrapList } from "@udecode/plate-list";

import ToolbarButton from '../../../shared/ToolbarControls/ToolbarButton';
import { turnIntoItems } from "./TurnIntoDefaultNodes";


const TurnIntoUl: React.FC = () => {
  const editor = useEditorRef();

  const defaultItem = turnIntoItems.find((item) => item.value === ParagraphPlugin.key)!;
  const selectedItem = useEditorSelector((editor) => {
    const entry = getParentNode(editor, editor.selection as any)?.[0] as TElement
    if (entry) {
      return turnIntoItems.find((item) => {
        if (entry && entry.type === 'lic') {
          const listRootElement = getListRoot(editor);
          if (listRootElement) {
            return listRootElement[0].type === item.value;
          }
        }
        if (entry) {
          return item.value === entry.type;
        }
        return item.value === ParagraphPlugin.key;
      }) || defaultItem
    } else {
      return defaultItem;
    }
  }, []);

  const isSelectedItemUl = selectedItem.value === BulletedListPlugin.key;

  const onValueHandle = () => {
    if (isSelectedItemUl) {
      unwrapList(editor)
      toggleBlock(editor, { type: ParagraphPlugin.key })
      select(editor, editor.selection!);
      focusEditor(editor);
      return
    }
    if (selectedItem.value === NumberedListPlugin.key) {
      toggleList(editor, { type: BulletedListPlugin.key });
      select(editor, editor.selection!);
      focusEditor(editor);
      return
    }
    toggleBlock(editor, { type: BulletedListPlugin.key });
    select(editor, editor.selection!);
    focusEditor(editor);
  }

  return <ToolbarButton onClick={onValueHandle} iconName="listUl" isActive={isSelectedItemUl} />
}

export default TurnIntoUl;