import React from 'react';

import { PageNavigation } from "../../store/reducers/page_navigation/page_navigation";
import { useAppSelector } from '../../store/hooks/redux-hooks';

type Props = {
  navPageConfig: PageNavigation;
}

const NavBarLogo = ({
  navPageConfig: {
    logo_url,
    nav_display_logo,
    nav_text_color = 'color-black',
    template,
    seller_id,
    nav_enabled
  }
}: Props) => {
  const isEditorReadOnly = useAppSelector(state => state.page_addendums.readOnly)
  const isLandingPage = useAppSelector(state => state.page_addendums.isLandingPage)

  const changeLogoPath = seller_id ? `/settings/profiles/${seller_id}/edit` : '/settings/profiles'

  const isPreviewMode = isEditorReadOnly || isLandingPage

  if (isPreviewMode && !logo_url || isPreviewMode && !nav_display_logo) {
    return null
  }

  return (
    <>
      {
        nav_enabled
          ?
          <div className="max-h-8 overflow-hidden">
            {logo_url && nav_display_logo
              ? <img src={logo_url} alt="page-logo" className="h-full w-full object-contain max-h-8" />
              : !nav_display_logo
                ? <div className={`italic hidden-logo-text ${nav_text_color}`}>Logo Hidden</div>
                : template
                  ? <a className={`btn-nav-link font-base ${nav_text_color}`} href={changeLogoPath}>Your Logo Here</a>
                  : <a className={`btn-nav-link italic font-base ${nav_text_color}`} href={changeLogoPath}>Add Missing Logo</a>
            }
          </div>
          :
          <div className="text-sm italic text-gray-700 bg-gray-200 h-8 px-4 rounded-lg inline-flex text-center items-center">
            Navigation Bar Disabled
          </div>
      }
    </>
  )
}

export default NavBarLogo