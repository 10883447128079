import React from 'react';
import { select, getNodeParent } from '@udecode/plate-common';
import { useEditorRef, focusEditor } from '@udecode/plate-common/react';
import { setAlign, Alignment, } from '@udecode/plate-alignment';
import { ListItemContentPlugin } from '@udecode/plate-list/react';
import { getListItemEntry } from '@udecode/plate-list';

import ToolbarAlignDropdown from '../../../shared/ToolbarControls/ToolbarAlignDropdown';

const Align = () => {
  const editor = useEditorRef();

  const nodeParent = editor.selection?.anchor?.path ? getNodeParent(editor, editor.selection?.anchor.path) : null;
  const nodeParentTypeCheck = !!nodeParent
    ? nodeParent.type === ListItemContentPlugin.key
      ? getListItemEntry(editor, { at: editor.selection?.anchor?.path })?.listItem[0]
      : nodeParent
    : null;
  const currentAlign = nodeParentTypeCheck ? nodeParentTypeCheck.align ? nodeParentTypeCheck.align as string : 'left' : 'left';

  const onAlignChange = (align: string) => {
    setAlign(editor, { value: align as Alignment });
    select(editor, editor.selection!);
    focusEditor(editor);
  };

  return <ToolbarAlignDropdown onAlignChange={onAlignChange} currentAlign={currentAlign} />
}

export default Align;