import React from 'react';
import { cn, withRef } from '@udecode/cn';
import { findNode, getParentNode } from '@udecode/plate-common';
import { isEditorReadOnly, PlateElement } from '@udecode/plate-common/react';
import { useSelected } from 'slate-react';

import { ColumnPlugin } from '../../plate-config/Plugins/ColumnGroup/Column.plugin';

export const BlockquoteElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const isReadOnly = isEditorReadOnly(editor)
    const selected = useSelected();

    const nodePath = findNode(editor, { at: [], match: { id: element.id } })![1];
    const parentNode = getParentNode(editor, nodePath)!
    const parentNodeType = parentNode[0].type as string
    const isElColumnChild = parentNodeType === ColumnPlugin.key
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;
    const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false

    return (
      <PlateElement
        ref={ref}
        id={element.id as string}
        editor={editor}
        element={element}
        data-id={element.id}
        asChild
        className={cn(`
        p-2 rounded-lg italic text-base flex w-full transition-all duration-300 ease-in-out
        ${!isReadOnly && !selected ? 'hover:bg-blue-500/5' : ''}
        ${!isReadOnly && selected ? 'bg-blue-500/10' : ''}
        ${isElColumnChild
            ? isLastColElement ? 'mb-0' : "mb-4"
            : `page-block quote-block  ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
          }
        `, className)}
        {...props}
      >
        <div>
          <blockquote className='relative pl-4 h-full w-full'>
            <div
              contentEditable={false}
              suppressContentEditableWarning
              className='absolute h-full w-1 left-0 bg-sky-500 rounded-[20px]'
            />
            {children}
          </blockquote>
        </div>
      </PlateElement>
    );
  }
);
