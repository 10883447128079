import React from 'react'
import { useForm, SubmitHandler } from "react-hook-form"

type Props = {
  createTemplateVarHandle: (variableName: string) => Promise<void>
}

interface IFormInput {
  varName: string
}

const TemplateVariableForm = ({ createTemplateVarHandle }: Props) => {
  const { register, handleSubmit, formState: { errors }, setError } = useForm<IFormInput>({
    defaultValues: {
      varName: "",
    }
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => await createTemplateVarHandle(data.varName).catch((error) => {
    setError('varName', {
      type: 'manual',
      message: error?.data?.errors[0] || "Oops! Something went wrong. Please try again."
    })
  });

  const varNameValidation = (value: string, formValues: IFormInput) => {
    if (!value || value.trim().length < 1) return "Name can't be blank";
    return true
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-4 border-b border-gray-200">
      <div className="flex flex-grow gap-2">
        <div className='flex-1'>
          <label>New Variable:</label>
          <input
            {...register("varName", { required: 'Name is required', validate: varNameValidation })}
            type="text"
            placeholder='Enter variable name'
            className="input form-control uppercase"
          />
          {errors.varName && <p className="mt-2 form-hint error">{errors.varName.message}</p>}
        </div>
        <button className="btn btn-primary btn-small shrink mt-7">
          Add
        </button>
      </div>
    </form>
  )
}

export default TemplateVariableForm
