type WeightByFamily = {
  [key: string]: number[];
};

export const WEIGHT_BY_FAMILY: WeightByFamily = {
  "Andada Pro": [400, 500, 600, 700, 800],
  "Archivo": [100, 200, 300, 400, 500, 600, 700, 800, 900],
  "BioRhyme": [200, 300, 400, 700, 800],
  "Cormorant": [300, 400, 500, 600, 700],
  "Encode Sans": [100, 200, 300, 400, 500, 600, 700, 800, 900],
  "Epilogue": [100, 200, 300, 400, 500, 600, 700, 800, 900],
  "Hahmlet": [100, 200, 300, 400, 500, 600, 700, 800, 900],
  "Inter": [100, 200, 300, 400, 500, 600, 700, 800, 900],
  "JetBrains Mono": [100, 200, 300, 400, 500, 600, 700, 800],
  "Lato": [100, 300, 400, 700, 900],
  "Libre Franklin": [100, 200, 300, 400, 500, 600, 700, 800, 900],
  "Lora": [400, 500, 600, 700],
  "Manrope": [200, 300, 400, 500, 600, 700, 800],
  "Montserrat": [100, 200, 300, 400, 500, 600, 700, 800, 900],
  "Nunito": [200, 300, 400, 500, 600, 700, 800, 900],
  "Old Standard TT": [400, 700],
  "Open Sans": [300, 400, 500, 600, 700, 800],
  "Oswald": [200, 300, 400, 500, 600, 700],
  "Oxygen": [300, 400, 700],
  "Playfair Display": [400, 500, 600, 700, 800, 900],
  "Poppins": [100, 200, 300, 400, 500, 600, 700, 800, 900],
  "Raleway": [100, 200, 300, 400, 500, 600, 700, 800, 900],
  "Roboto": [100, 300, 400, 500, 700, 900],
  "Sora": [100, 200, 300, 400, 500, 600, 700, 800],
  "Space Grotesk": [300, 400, 500, 600, 700],
  "Source Sans Pro": [200, 300, 400, 600, 700, 900],
  "Spectral": [200, 300, 400, 500, 600, 700, 800],
  "Work Sans": [100, 200, 300, 400, 500, 600, 700, 800, 900],
}

export function getWeights(font: string): number[] | undefined {
  return WEIGHT_BY_FAMILY[font];
}

type Weight = {
  value: number;
  title: string;
  fontClass: string;
};

export const WEIGHTS: Weight[] = [
  { value: 100, title: "Thin", fontClass: "font-thin" },
  { value: 200, title: "Extra Light", fontClass: "font-extralight" },
  { value: 300, title: "Light", fontClass: "font-light" },
  { value: 400, title: "Normal", fontClass: "font-normal" },
  { value: 500, title: "Medium", fontClass: "font-medium" },
  { value: 600, title: "Semi Bold", fontClass: "font-semibold" },
  { value: 700, title: "Bold", fontClass: "font-bold" },
  { value: 800, title: "Extra Bold", fontClass: "font-extrabold" },
  { value: 900, title: "Black", fontClass: "font-black" },
]