import React, { memo } from "react"

type Props = {
  className?: string,
  title: string
}

const PopoverSelectorTitle = memo(({ className, title }: Props) => {
  return (
    <div className={`uppercase font-semibold text-sm text-left text-gray-700 ${className}`}>
      {title}
    </div>
  )
})

export default PopoverSelectorTitle