import React from "react";
import { focusEditor, useEditorRef } from "@udecode/plate-common/react";
import { getMarks, select, toggleMark } from "@udecode/plate-common";

import ToolbarButton from '../../../shared/ToolbarControls/ToolbarButton';
import { HugeIconName } from "../../../shared/Icon/HugeIcon";

type Props = {
  mark: string;
  iconName: HugeIconName;
}

const BasicMarks: React.FC<Props> = ({ mark, iconName }) => {
  const editor = useEditorRef()
  const marks = getMarks(editor)

  const onMarkChange = () => {
    toggleMark(editor, { key: mark });
    select(editor, editor.selection!);
    focusEditor(editor);
  }

  return <ToolbarButton onClick={onMarkChange} iconName={iconName} isActive={!!marks?.[mark]} />
}

export default BasicMarks;