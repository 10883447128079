import React from "react"
import { useEditorRef } from "@udecode/plate-common/react"

import BackgroundMenu from "./BackgroundMenu/BackgroundMenu"
import LockMenu from "./LockMenu/LockMenu"
import StyleMenu from "./StyleMenu/StyleMenu"
import { PlateAssessmentElement } from "../../../plate-config/Plugins/Assessment/Assessment.plugin"

type Props = {
  element: PlateAssessmentElement,
  lockScreenToggle: (val: boolean) => void
}

const AssessmentToolbar = ({ element, lockScreenToggle }: Props) => {
  const editor = useEditorRef()

  const onPrimaryColorChange = (primary_color: string) =>
    editor.setNodes(
      { primary_color } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onBgOpacityChange = (tint_opacity: number) =>
    editor.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          tint_opacity
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onBgBlurChange = (background_blur: number) =>
    editor.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          background_blur
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onDisplayPriceChange = (hide_price: boolean) =>
    editor.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          hide_price: !hide_price
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onDisplayValueChange = (hide_value: boolean) =>
    editor.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          hide_value: !hide_value
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onRoiLockChange = (roi_locked: boolean) =>
    editor.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          roi_locked
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onBackgroundImageChange = (image_url: string, image_source: string, image_external_id: string) =>
    editor.setNodes(
      {
        image_url, image_source, image_external_id, assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          tint_opacity: typeof element.assessment_detail_attributes.tint_opacity === 'number' ? element.assessment_detail_attributes.tint_opacity : 0,
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onImageUnset = () =>
    editor.setNodes(
      { image_url: null, image_source: null, image_external_id: null } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  return (
    <div contentEditable={false} suppressContentEditableWarning className="flex gap-4 justify-end py-5 pr-5">
      <div className="btn-group">
        {element.assessment_detail_attributes.roi_locked &&
          <LockMenu element={element} onLockScreenToggle={lockScreenToggle} />
        }
        <StyleMenu
          element={element}
          onPrimaryColorChange={onPrimaryColorChange}
          onBgOpacityChange={onBgOpacityChange}
          onBgBlurChange={onBgBlurChange}
          onDisplayPriceChange={onDisplayPriceChange}
          onDisplayValueChange={onDisplayValueChange}
          onRoiLockChange={onRoiLockChange}
        />
        <BackgroundMenu
          element={element}
          onBackgroundImageChange={onBackgroundImageChange}
          onImageUnset={onImageUnset}
        />
      </div>
    </div>
  )
}

export default AssessmentToolbar