import { Controller } from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input";
import Cleave from 'cleave.js';
import "cleave.js/dist/addons/cleave-phone.i18n";

export default class extends Controller {
  static targets = ["input"];
  connect() {
    this.preparePhoneInput();
    // TODO: this line was causing the phone input to be initialized twice
    // this.listenForTurboLoad()
  }

  disconnect() {
    // this.removeTurboLoadListener();
  }

  // listenForTurboLoad() {
  //   document.addEventListener('turbo:render', () => {
  //     this.preparePhoneInput();
  //   });
  // }

  // removeTurboLoadListener() {
  //   document.removeEventListener('turbo:load', this.handleTurboLoad);
  // }

  // The way intTelInput get reinitialized is a headache for TURBO_STREAM format.
  // Going to circumvent this.
  preparePhoneInput() {
    this.telInputInstance = intlTelInput(this.inputTarget, {
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.1.0/build/js/utils.js",
      initialCountry: "auto",
      formatOnDisplay: true,
      nationalMode: true,
      separateDialCode: true,
      strictMode: true,
      geoIpLookup: callback => {
        fetch("http://ip-api.com/json")
          .then(res => res.json())
          .then(data => {
            callback(data.countryCode)
            this.updateCleaveRegionCode(data.countryCode)
          })
          .catch(() => callback("us"));
      },
      hiddenInput: () => {
        return {
          phone: this.inputTarget.getAttribute("name")
        }
      },
    });

    this.inputTarget.addEventListener("countrychange", () => {
      const selectedCountryCode = this.telInputInstance.getSelectedCountryData().iso2.toUpperCase();
      this.updateCleaveRegionCode(selectedCountryCode);
    });

    this.cleave = new Cleave(this.inputTarget, {
      phone: true,
      phoneRegionCode: "US",
    });
  }

  updateCleaveRegionCode(countryCode) {
    this.cleave.properties.phoneRegionCode = countryCode;
    this.cleave.initPhoneFormatter();
  }
}
