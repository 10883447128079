import React from "react";

import HugeIcon, { HugeIconName } from "../Icon/HugeIcon";


type Props = {
  path: string
  linkClassName?: string
  iconClassName?: string
  openInNewTab?: boolean
  onClick?: () => void
  iconName: HugeIconName;
}

const ToolbarLink: React.FC<Props> = ({
  onClick, path, iconName, openInNewTab, linkClassName
}) => {

  const onClickHandle = (e: React.MouseEvent<HTMLAnchorElement>) => onClick && onClick();

  return (
    <a
      className={`toolbar_btn ${linkClassName}`}
      onClick={onClickHandle}
      href={path}
      {...openInNewTab ? { target: "_blank", rel: "noopener noreferrer" } : { target: "_self" }}
    >
      <HugeIcon name={iconName} size={16} className="toolbar_btn_icon" />
    </a>
  )
}

export default ToolbarLink;