import React, { useRef } from "react"
import { isEditorReadOnly, useEditorRef } from "@udecode/plate-common/react"
import { findNode } from "@udecode/plate-common"

import { useAppSelector } from "../../store/hooks/redux-hooks"
import AnimateWrapper from "./AnimateWrapper/AnimateWrapper"
import { PlateSectionElement } from "../../plate-config/Plugins/Section/Section.plugin"
import { SECTION_PADDING, SECTION_WIDTH } from "./section.config"
import { colorClassToRGB } from "../../utils/color.util"
import { nonSlashCommandsBlocks } from "../../utils/plate.util"
import { createDefaultParagraphElement } from "../../plate-config/Plugins/DefaultMockups/DefaultMockups"

type Props = {
  section: PlateSectionElement
  children: React.ReactNode
}

const SectionContent = ({ section, children }: Props) => {
  const editor = useEditorRef()
  const isReadOnly = isEditorReadOnly(editor)
  const { activePalette } = useAppSelector(state => state.page_colors)
  const { isMobilePreviewActive } = useAppSelector(state => state.page_addendums)
  // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
  // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
  const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
  const containerRef = useRef<HTMLDivElement>(null);

  const contentPaddingStyles = `${SECTION_PADDING[section.padding || 'medium']}`
  const node = findNode(editor, { at: [], match: { id: section.id } })!

  const isFixedSection = section.fixed_kind === 'recording'
  const fixedSectionWidthStyles = `${isMobilePreviewActive ? 'w-full width-mobile-preview' : 'w-full'}`
  const sectionWidthStyles = `${isMobilePreviewActive ? 'w-full width-mobile-preview' : SECTION_WIDTH[section.width || 'medium']}`
  const contentWidthStyles = isFixedSection ? fixedSectionWidthStyles : sectionWidthStyles
  const layoutPaddingStyles = isMobilePreviewActive ? 'p-4' : ''

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isReadOnly) return
    if (!containerRef.current) return;
    const style = window.getComputedStyle(containerRef.current);
    const paddingBottom = parseInt(style.paddingBottom, 10);
    const rect = containerRef.current.getBoundingClientRect();
    const bottomEdge = rect.bottom;
    const clickYPosition = e.clientY;
    if (clickYPosition > (bottomEdge - paddingBottom)) {
      const lastSectionChild = section.children[section.children.length - 1];
      const lastSectionChildType = lastSectionChild.type as string
      if (nonSlashCommandsBlocks.includes(lastSectionChildType)) {
        editor.insertNodes(createDefaultParagraphElement(), { at: [...node[1], section.children.length], select: true });
      }
    }
    return
  };

  return (
    <>
      {section.animate_type === 'animate'
        ?
        <AnimateWrapper sectionElement={section} >
          <div
            onClick={handleClick}
            ref={containerRef}
            style={{ backgroundColor: colorClassToRGB(section.card_color as string, section.card_opacity as number, activePalette.colors)! }}
            className={`${contentPaddingStyles} ${contentWidthStyles} ${layoutPaddingStyles} rounded-xl relative editor-container`}
          >
            {children}
          </div>
        </AnimateWrapper>
        :
        <div
          style={{ backgroundColor: colorClassToRGB(section.card_color as string, section.card_opacity as number, activePalette.colors)! }}
          className={`${contentPaddingStyles} ${contentWidthStyles} ${layoutPaddingStyles} rounded-xl relative editor-container`}
          onClick={handleClick}
          ref={containerRef}
        >
          {children}
        </div>
      }
    </>
  )
}

export default SectionContent
