import React, { memo } from "react"

const Branding = memo(() => {
  return (
    <div className="flex justify-center items-center gap-1 w-full">
      <div className="text-gray-600 italic">Powered by</div>
      <a className="mb-0" href="https://revvedup.ai" rel="nofollow" target="_blank">
        <img src="/revvedup-logo.svg" alt="RevvedUp" className="footer-logo" />
      </a>
    </div>
  )
})

export default Branding