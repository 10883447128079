import React, { memo } from "react"

type Props = {
  onClick: () => void
  title: string,
  value: string | number
}

const FontButton = memo(({ onClick, title, value }: Props) => {
  return (
    <button
      className="w-full flex justify-between hover:bg-gray-200 rounded-md p-2"
      onClick={onClick}
    >
      <span className="text-gray-700">{title}</span>
      <span className="font-light text-gray-500">{value}</span>
    </button>
  )
})

export default FontButton