import React, { useEffect, useRef, forwardRef } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';


const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


type Props = {
  dialogOpen: boolean;
  onDialogClose: () => void;
  dialogHeader: React.ReactNode;
  scrollableContent: React.ReactNode;
  scrollableContentId: string;
}


const ScrollableDialog = ({
  dialogOpen,
  onDialogClose,
  dialogHeader,
  scrollableContent,
  scrollableContentId
}: Props) => {


  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (dialogOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [dialogOpen]);

  return (
    <Dialog
      open={dialogOpen}
      onClose={onDialogClose}
      TransitionComponent={Transition}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      style={{ margin: '20px', marginTop: '70px' }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "100%",
            height: '100%',
            maxHeight: '100%',
            margin: '0',
          },
          "& .MuiDialog-paper": {
            borderRadius: "1rem",
          },
        },
      }}
    >
      {dialogHeader}
      <DialogContent
        dividers={true}
        id={scrollableContentId}
      >
        {scrollableContent}
      </DialogContent>
    </Dialog>
  );
}

export default ScrollableDialog;
