import React, { HTMLInputTypeAttribute, ChangeEvent } from "react"
import { twMerge } from "tailwind-merge"

type Props = {
  htmlFor: string,
  label?: string,
  inputPrefix?: string | null,
  inputSuffix?: string | null,
  error?: string | null,
  type: HTMLInputTypeAttribute,
  value: any,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  min?: number,
  max?: number,
  wrapperClassName?: string,
  placeholder?: string
}

const LabeledInput = ({
  htmlFor,
  label,
  inputPrefix,
  inputSuffix,
  error,
  type,
  value,
  onChange,
  onKeyDown,
  min,
  max,
  wrapperClassName,
  placeholder
}: Props) => {
  return (
    <div className={twMerge('form-group mb-0', wrapperClassName)} >
      <label htmlFor={htmlFor}>{label}</label>
      <div className="relative">
        {inputPrefix &&
          <div className="absolute inset-y-0 left-4 flex items-center text-gray-600 text-sm">
            {inputPrefix}
          </div>
        }
        {inputSuffix &&
          <div className="absolute inset-y-0 right-2 flex items-center text-gray-600 text-sm">
            {inputSuffix}
          </div>
        }
        <input
          id={htmlFor}
          className={`input form-control ${error ? "error" : ""} ${inputPrefix ? "pl-12" : ""}`}
          type={type}
          value={value || ''}
          onChange={onChange}
          onKeyDown={onKeyDown}
          min={min}
          max={max}
          placeholder={placeholder}
        />
      </div>
      {error && <div className="form-hint error">{error}</div>}
    </div>
  )
}

export default LabeledInput