import React from "react"

import ColorSourceSelector from "./ColorSourceSelector/ColorSourceSelector"
import AddColorPopover from "./AddColorPopover/AddColorPopover"
import ExistingColorsPopover from "./ExistingColorsPopover/ExistingColorsPopover"
import { textColorFromHex } from "../../../../utils/color.util"
import { useAppSelector } from "../../../../store/hooks/redux-hooks"
import DefaultColors from "./DefaultColors/DefaultColors"


const GlobalColorSelector = () => {
  const { activePalette, availablePalettes } = useAppSelector(state => state.page_colors)
  const colors = activePalette.colors
  const canAddNewColor = colors.length < 8

  return (
    <div className="w-full" id="global-color-select">
      <div className="flex flex-wrap gap-2">
        <ColorSourceSelector activePalette={activePalette} availablePalettes={availablePalettes} />
        {colors.map((color, index) =>
          <ExistingColorsPopover
            key={`color-popover-existing-${index}`}
            color={color}
            textColor={textColorFromHex(color)}
            colors={colors}
            activePalette={activePalette}
          />
        )}
        <DefaultColors />
        {canAddNewColor
          ?
          <AddColorPopover activePalette={activePalette} />
          :
          <div className="w-full text-center text-gray-600 text-sm mt-2">
            Color limit reached
          </div>
        }
      </div>
    </div>
  )
}

export default GlobalColorSelector
