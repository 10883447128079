import React, { useState } from "react"

import { Popover, PopoverTrigger } from "../../../../shared/Popover/Popover"
import TooltipIcon from "../../../../shared/ToolTip/TooltipIcon"
import { PlateAssessmentElement } from "../../../../plate-config/Plugins/Assessment/Assessment.plugin"


type Props = {
  element: PlateAssessmentElement,
  onLockScreenToggle: (val: boolean) => void
}

const LockMenu = ({ element, onLockScreenToggle }: Props) => {
  const [open, setOpen] = useState(false)

  const onOpenChange = (val: boolean) => {
    setOpen(val)
    onLockScreenToggle(val)
  }

  return (
    <Popover onOpenChange={onOpenChange}>
      <PopoverTrigger>
        <TooltipIcon
          tooltip={open ? 'Lock' : 'Unlock'}
          side="top"
          container={element.id as string}
          icon={open ? 'faLockOpen' : 'faLock'}
          iconWrapperClassName="btn btn-small btn-white first-child"
        />
      </PopoverTrigger>
    </Popover>
  )
}

export default LockMenu
