import React, { memo, useState } from "react";
import { HexColorPicker as ReactColorfulPicker } from 'react-colorful';

import PopoverSelectorTitle from "../../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle";
import IconButton from "../../../../../shared/IconButton/IconButton";

type Props = {
  initialColor: string | null,
  handleUpdateColor: (color: string) => void,
  goBackHandle: () => void,
}

const HexColorPicker = memo(({
  initialColor,
  handleUpdateColor,
  goBackHandle,
}: Props) => {

  const [color, setColor] = useState(initialColor || "#008dd9")

  const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setColor(e.target.value)
  }

  const handleColorSelect = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    handleUpdateColor(color)
  }


  return (
    <>
      <div className="flex justify-between items-center">
        <PopoverSelectorTitle title="Select Custom Color" />
        <IconButton
          btnClassName="btn btn-small btn-light-gray flex gap-1 justify-center text-gray-700"
          icon="faArrowLeft"
          onClick={goBackHandle}
          textAfter="Back"
        />
      </div>
      <div className="my-4">
        <ReactColorfulPicker
          color={color}
          onChange={setColor}
        />
      </div>
      <div className="flex justify-between items-center gap-2">
        <input
          id="custom-hex-input"
          className="form-control w-full text-xs sm:text-xs md:text-base text-center m-0"
          value={color}
          onChange={handleInputValueChange}
        />
        <button
          className="btn btn-primary"
          onClick={handleColorSelect}
        >
          Select
        </button>
      </div>
    </>
  )
})

export default HexColorPicker
