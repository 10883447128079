import React, { memo } from "react";

import Icon from "../../../../../../shared/Icon/Icon";

type Props = {
  name: string
  isActive: boolean
  children: React.ReactNode
  onCardClick: () => void
}

const OptionCardWrapper: React.FC<Props> = memo(({ isActive, onCardClick, name, children }) => {
  return (
    <div
      className={`bg-white rounded-xl use-case ${isActive ? "active" : ""}`}
      onClick={onCardClick}
    >
      <div className={`flex justify-between items-center p-4 pb-2 border-b ${isActive ? "border-gray-200/25" : "border-gray-200"}`}>
        <div className="font-semibold mt-2">{name}</div>
        {isActive && <Icon icon="faCircleCheck" wrapperClassName="absolute top-4 right-4" />}
      </div>
      <div className={`relative p-4 text-xs ${isActive ? "text-white/75" : "text-gray-600"}`}>
        {children}
      </div>
    </div>
  )
})

export default OptionCardWrapper;