import { setImageProcessed, setImageProcessing } from "../../page_indicators/page_indicators";
import { revvedupApi } from "../api";
import { GetBrandImagesResponse, GetUploadedImagesResult } from "../api.types";
import { getPageDataAttributes } from "../data-attributes.util";

export const imagesEndpoints = revvedupApi.injectEndpoints({
  endpoints: (builder) => ({
    getUploadedImages: builder.query<GetUploadedImagesResult, string>({
      query: (page) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/images?page=${page}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
        }
      }
    }),
    getBrandImages: builder.query<GetBrandImagesResponse, void>({
      query: () => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/pages/${requestData.page_id}/brand_images`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
        }
      }
    }),
    addUserImage: builder.mutation<any, FormData>({
      query: (formData) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/images`,
          method: 'POST',
          headers: {
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: formData,
        }
      },
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        dispatch(setImageProcessing())
        try {
          await queryFulfilled

        } catch (err) {
          console.log('User image upload error:', err);
        }
        dispatch(setImageProcessed())
      }
    }),
  }),
})

export const { useLazyGetUploadedImagesQuery, useLazyGetBrandImagesQuery, useAddUserImageMutation } = imagesEndpoints