import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["url", "attachment", "uppy"]
  static values = { content: String }

  connect() {
    this.formButton = document.getElementById("resource-submit-button")
    switch (this.contentValue) {
      case 'url':
        this.url();
        break;
      case 'attachment':
        this.attachment();
        break;
      case 'uppy':
        this.uppy();
        break;
      default:
        console.error("Invalid content type");
    }
  }

  url() {
    this.attachmentTarget.classList.add("hidden")
    this.urlTarget.classList.remove("hidden")
    this.uppyTarget.classList.add("hidden")
    this.formButton.disabled = false;
  }

  attachment() {
    this.urlTarget.classList.add("hidden")
    this.attachmentTarget.classList.remove("hidden")
    this.uppyTarget.classList.add("hidden")
    this.formButton.disabled = false;
  }

  uppy() {
    this.urlTarget.classList.add("hidden")
    this.attachmentTarget.classList.add("hidden")
    this.uppyTarget.classList.remove("hidden")
    this.formButton.disabled = true;
  }
}
