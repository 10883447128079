import { setDataSaved, setDataSaving } from "../../page_indicators/page_indicators";
import { addTemplateVariables, setTemplateVariables } from "../../page_template_variables/page_template_variables";
import { revvedupApi } from "../api";
import { TemplateVariable } from "../api.types";
import { getPageDataAttributes } from "../data-attributes.util";

export const templateVariableEndpoints = revvedupApi.injectEndpoints({
  endpoints: (builder) => ({
    getTemplateVariables: builder.query<TemplateVariable[], void>({
      query: () => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/pages/${requestData.page_id}/template_variables`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
        }
      },
      async onQueryStarted(_page_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setTemplateVariables(data))
          }
        } catch (err) {
          console.log('Template variables load error:', err);
        }
      }
    }),
    addTemplateVariable: builder.mutation<TemplateVariable, string>({
      query: (name) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/pages/${requestData.page_id}/template_variables`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { variable: { name } }
        }
      },
      async onQueryStarted({ }, { dispatch, queryFulfilled }) {
        dispatch(setDataSaving())
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(addTemplateVariables(data))
          }
        } catch (err) {
          console.log('Update variable error:', err);
        }
        dispatch(setDataSaved())
      }
    }),

  }),
})

export const { useGetTemplateVariablesQuery, useLazyGetTemplateVariablesQuery, useAddTemplateVariableMutation } = templateVariableEndpoints