import { Controller } from '@hotwired/stimulus'
import { Uppy, debugLogger } from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import Transloadit, { COMPANION_URL, COMPANION_ALLOWED_HOSTS } from '@uppy/transloadit';
import Zoom from '@uppy/zoom';
import GoogleDrive from '@uppy/google-drive';
import OneDrive from '@uppy/onedrive';
// import Url from '@uppy/url';

export default class extends Controller {
  connect() {
    const dataset = this.element.dataset
    const note = dataset.note
    const field_name = dataset.fieldName
    const template = dataset.template
    const transloaditKey = dataset.transloaditKey
    const transloaditExpires = dataset.transloaditExpires
    const transloaditSignature = dataset.transloaditSignature

    // There's a CSS breakpoint around height of 335 that we want to hit.
    this.uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: ["audio/*", "video/*"],
      }
      // debug: true,
      // logger: debugLogger,
    })

    this.uppy.use(Dashboard, {
      inline: true,
      allowMultipleUploads: false,
      singleFileFullScreen: true,
      showProgressDetails: true,
      proudlyDisplayPoweredByUppy: false,
      note: note,
      target: this.element,
      width: "100%",
      height: 335,
      locale: {
        strings: {
          dropPasteFiles: 'Drop your recording here or %{browseFiles}',
          dropPasteFolders: 'Drop your recording here or %{browseFolders}',
          dropPasteBoth: 'Drop your recording here, %{browseFiles} or %{browseFolders}',
          dropPasteImportFiles: 'Drop your recording here, %{browseFiles} or import from:',
          dropPasteImportFolders: 'Drop your recording here, %{browseFolders} or import from:',
          dropPasteImportBoth: 'Drop your recording here, %{browseFiles}, %{browseFolders} or import from:',
          uploadComplete: "File ready",
        },
      },
    })

    this.uppy.use(Transloadit, {
      waitForEncoding: true,
      assemblyOptions: {
        params: {
          auth: { key: transloaditKey, expires: transloaditExpires },
          template_id: template,
        },
        signature: transloaditSignature,
      }
    })

    // Configuration for our own zoom oauth. This requires a review by zoom.
    // Needs to be live on the app store in order to use.
    // this.uppy.use(Zoom, {
    //   target: Dashboard,
    //   companionUrl: COMPANION_URL,
    //   companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    //   companionKeysParams: {
    //     key: transloaditKey,
    //     credentialsName: 'revvedup-zoom',
    //   }
    // })

    this.uppy.use(Zoom, {
      target: Dashboard,
      companionUrl: COMPANION_URL,
      companionAllowedHosts: COMPANION_ALLOWED_HOSTS
    })

    // This was leading to lots of people uploading their zoom URL and ultimately causing errors.
    // It's removed now.
    // this.uppy.use(Url, {
    //   target: Dashboard,
    //   companionUrl: COMPANION_URL,
    //   companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    // })

    // Configuration for my own google drive oauth. This requires a long review process by google.
    // Google wants me to verify transloadit.com domain, so I would have to stand up my own
    // transloadit servers for this to work.
    // this.uppy.use(GoogleDrive, {
    //   target: Dashboard,
    //   companionUrl: COMPANION_URL,
    //   companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    //   companionKeysParams: {
    //     key: transloaditKey,
    //     credentialsName: 'revvedup-drive',
    //   }
    // })

    this.uppy.use(GoogleDrive, {
      target: Dashboard,
      companionUrl: COMPANION_URL,
      companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    })

    this.uppy.use(OneDrive, {
      target: Dashboard,
      companionUrl: COMPANION_URL,
      companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
      companionKeysParams: {
        key: transloaditKey,
        credentialsName: 'revvedup-onedrive',
      }
    })

    this.uppy.on('transloadit:complete', (assembly) => {
      assembly.results.filter.forEach(result => {
        this.appendUploadedFile(result, field_name)
      })
      const recordingSubmitButton = document.getElementById("recording-submit-button")
      if (recordingSubmitButton) {
        recordingSubmitButton.disabled = false;
        recordingSubmitButton.click();
      }

      const resourceSubmitButton = document.getElementById("resource-submit-button")
      if (resourceSubmitButton) {
        resourceSubmitButton.disabled = false;
      }
    });
  }

  disconnect() {
    this.uppy.close()
  }

  appendUploadedFile(result, field_name) {
    // Create a hidden field for the checksum
    const fileDataField = document.createElement('input')
    fileDataField.setAttribute('type', 'hidden')
    fileDataField.setAttribute('name', `${field_name}[checksum]`)
    fileDataField.setAttribute('value', result.md5hash)
    this.element.appendChild(fileDataField)

    const fileKeyField = document.createElement('input')
    fileKeyField.setAttribute('type', 'hidden')
    fileKeyField.setAttribute('name', `${field_name}[key]`)
    fileKeyField.setAttribute('value', result.id)
    this.element.appendChild(fileKeyField)

    // Create a hidden field for the filename
    const filenameField = document.createElement('input')
    filenameField.setAttribute('type', 'hidden')
    filenameField.setAttribute('name', `${field_name}[filename]`)
    filenameField.setAttribute('value', result.name)
    this.element.appendChild(filenameField)

    // Create a hidden field for the content type
    const contentTypeField = document.createElement('input')
    contentTypeField.setAttribute('type', 'hidden')
    contentTypeField.setAttribute('name', `${field_name}[content_type]`)
    contentTypeField.setAttribute('value', result.mime)
    this.element.appendChild(contentTypeField)

    // Create a hidden field for the byte size
    const byteSizeField = document.createElement('input')
    byteSizeField.setAttribute('type', 'hidden')
    byteSizeField.setAttribute('name', `${field_name}[byte_size]`)
    byteSizeField.setAttribute('value', result.size)
    this.element.appendChild(byteSizeField)
  }
}
