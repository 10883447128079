import React, { useEffect, useState } from "react";
import TableCell from '@mui/material/TableCell';

import { PlatePricingElement } from "../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import { calculateSubtotal, calculateSubtotalBeforeDiscount, getPerDisplay, calculateDiscountAmount } from "../../../../../utils/calc_plugins.util";
import { useAppSelector } from "../../../../../store/hooks/redux-hooks";
import { StoreCalcVariable } from "../../../../../store/reducers/page_calc_variables/page_calc_variables";

type Props = {
  element: PlatePricingElement
  tableIndex: number
  rowIndex: number
  isMobileView?: boolean
  calc_variables: StoreCalcVariable[]
}

const SubtotalCell: React.FC<Props> = ({ element, rowIndex, tableIndex, isMobileView, calc_variables }) => {
  const currency = useAppSelector(state => state.page_addendums.currency)

  const activeTable = element.pricing_tables_attributes[tableIndex]
  const activeRow = activeTable.line_items_attributes[rowIndex]
  const activeVariable = calc_variables.find(variable => variable.id === activeRow.variable_id)!

  const [subtotal, setSubtotal] = useState(calculateSubtotal(activeRow.unit_price_cents, activeRow.variable_id ? activeVariable.default : activeRow.quantity, activeRow.discount_type, activeRow.discount_value, currency))
  const [perDisplay, setPerDisplay] = useState(getPerDisplay(activeRow.billing_frequency))
  const [subtotalBeforeDiscount, setSubtotalBeforeDiscount] = useState(calculateSubtotalBeforeDiscount(activeRow.unit_price_cents, activeRow.variable_id ? activeVariable.default : activeRow.quantity, currency))
  const [discountAmount, setDiscountAmount] = useState(calculateDiscountAmount(activeRow.unit_price_cents, activeRow.variable_id ? activeVariable.default : activeRow.quantity, activeRow.discount_type, activeRow.discount_value, currency))

  useEffect(() => {
    setSubtotal(calculateSubtotal(activeRow.unit_price_cents, activeRow.variable_id ? activeVariable.default : activeRow.quantity, activeRow.discount_type, activeRow.discount_value, currency))
    setPerDisplay(getPerDisplay(activeRow.billing_frequency))
    setSubtotalBeforeDiscount(calculateSubtotalBeforeDiscount(activeRow.unit_price_cents, activeRow.variable_id ? activeVariable.default : activeRow.quantity, currency))
    setDiscountAmount(calculateDiscountAmount(activeRow.unit_price_cents, activeRow.variable_id ? activeVariable.default : activeRow.quantity, activeRow.discount_type, activeRow.discount_value, currency))
  }, [element, activeVariable])

  return (
    <TableCell
      component={isMobileView ? 'div' : undefined}
      align="left"
      sx={{ padding: '10px 5px', borderBottom: 'unset', minWidth: isMobileView ? '75px' : '100px' }}
      className="border-t border-gray-200"
    >
      <div className="flex justify-center text-sm">
        {subtotal}
        {activeRow.billing_frequency !== "one_time" && <div className="text-sm whitespace-nowrap"> / {perDisplay} </div>}
      </div>
      {
        !!activeRow.terms && <p className="text-xs text-center" > Stops after {activeRow.terms} payments </p>
      }
      {
        !!activeRow.discount_value &&
        <div className="flex  text-xs whitespace-nowrap justify-center" >
          <span className="line-through mr-1"> {subtotalBeforeDiscount} </span>
          <span className="text-emerald-600 header-font-family">
            {activeRow.discount_type === "percent" && `${activeRow.discount_value}% off`}
            {activeRow.discount_type === "dollar" && `${discountAmount} off`}
          </span>
        </div>
      }
    </TableCell>
  )
}

export default SubtotalCell