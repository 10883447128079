import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "input" ]

  select(event) {
    const value = event.target.dataset.value
    this.inputTarget.value = value
    this.buttonTargets.forEach(target => {
      if (target.dataset.value === value) {
        target.classList.add("active")
      } else {
        target.classList.remove("active")
      }
    })
  }
}
