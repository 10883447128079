import React, { useState } from "react"

import Icon from "../../../../../shared/Icon/Icon"
import { Popover, PopoverContent, PopoverTrigger } from "../../../../../shared/Popover/Popover"
import { PagePalette } from "../../../../../store/reducers/page_colors/page_colors"
import { useUpdateCustomColorsMutation } from "../../../../../store/reducers/api/colors/colors.api"
import { prepareColorAttributesForUpdate, prepareColorAttributesForDelete, HEX_SELECTOR_INIT_COLORS } from "../../../../../store/reducers/page_colors/page_color.config"
import ColorPaletteWarning from "../ColorPaletteWarning/ColorPaletteWarning"
import HexColorPicker from "../HexColorPicker/HexColorPicker"
import PopoverSelectorTitle from "../../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"
import IconButton from "../../../../../shared/IconButton/IconButton"
import { textColorFromHex } from "../../../../../utils/color.util"

type Props = {
  color: string,
  textColor: string,
  colors: string[],
  activePalette: PagePalette,
}

const ExistingColorsPopover = ({
  color,
  textColor,
  colors,
  activePalette,
}: Props) => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [isColorSelectOpen, setIsColorSelectOpen] = useState(false)
  const [updateCustomColors] = useUpdateCustomColorsMutation();

  const initialColors = HEX_SELECTOR_INIT_COLORS.filter(hex_init_color => {
    if (hex_init_color === color) { return true }
    return !colors.includes(color)
  })
  const colorIndex = colors.findIndex(color_to_find => color_to_find === color)

  const onColorPaletteChange = async (color: string) => {
    setIsColorSelectOpen(false)
    setPopoverOpen(false)
    await updateCustomColors(prepareColorAttributesForUpdate(color, colorIndex, activePalette.colorData || []))
  }

  const onColorPaletteDelete = async (color: string) => {
    setIsColorSelectOpen(false)
    await updateCustomColors(prepareColorAttributesForDelete(color, activePalette.colorData || []))
  }


  return (
    <Popover
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
    >
      <PopoverTrigger>
        <div
          className="h-14 w-14 rounded-full border border-gray-300 hover:ring-gray-200 hover:ring"
          style={{ backgroundColor: color, color: textColor }}
        >
          <Icon icon='faPen' wrapperClassName="w-full h-full flex justify-center items-center opacity-0 hover:opacity-100" />
        </div>
      </PopoverTrigger>
      <PopoverContent sideOffset={10} side="bottom" align="center">
        {activePalette.palette === "custom"
          ?
          <div className="w-19em border-solid border border-gray-200 rounded-xl bg-white drop-shadow z-30 popover-container">
            <div className="p-4">
              {isColorSelectOpen
                ? (
                  <HexColorPicker
                    initialColor={color}
                    handleUpdateColor={onColorPaletteChange}
                    goBackHandle={setIsColorSelectOpen.bind(setIsColorSelectOpen, false)}
                  />
                )
                : (
                  <>
                    <div className="flex justify-between">
                      <PopoverSelectorTitle title="Edit color" />
                      {color &&
                        <IconButton
                          btnClassName="btn btn-small btn-light-gray focus-visible:outline-0 focus:outline-0 focus:ring-0 focus:ring-transparent"
                          icon="faTrash"
                          onClick={onColorPaletteDelete.bind(onColorPaletteDelete, color)}
                        />
                      }
                    </div>
                    <div className="w-full">
                      <div className="flex flex-wrap text-center gap-2 mt-1">
                        {initialColors.map((change_color, index) =>
                          <button
                            key={index}
                            onClick={onColorPaletteChange.bind(onColorPaletteChange, change_color)}
                            className="h-10 w-10 rounded-full border border-gray-300 hover:ring-gray-200 hover:ring"
                            style={{ backgroundColor: color, color: textColorFromHex(color) }}
                          >
                            {color === change_color && <Icon icon="faCheck" />}
                          </button>
                        )}
                        <IconButton
                          btnClassName=" btn h-10 w-10 p-0 flex justify-center rounded-full border border-gray-300 hover:ring-gray-200 hover:ring text-gray-700 background-gray-200"
                          icon="faPaintRoller"
                          onClick={setIsColorSelectOpen.bind(setIsColorSelectOpen, true)}
                        />
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
          :
          <ColorPaletteWarning />
        }
      </PopoverContent>
    </Popover>
  )
}

export default ExistingColorsPopover
