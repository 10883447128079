import React from 'react'
import { useEditorRef } from '@udecode/plate-common/react'

import PopoverSelectorTitle from '../../../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle'
import RadioSelector from './AnimateOptionsSelector'
import AnimateButtonSelector from './AnimateButtonSelector'
import { ANIMATION_DIRECTIONS, ANIMATION_SPEEDS, ANIMATION_STYLES, ANIMATION_TYPES } from '../../../../section.config'
import { PlateSectionElement } from '../../../../../../plate-config/Plugins/Section/Section.plugin'

type Props = {
  sectionElement: PlateSectionElement
}

const AnimateOptions = ({ sectionElement }: Props) => {
  const editor = useEditorRef()

  const onAnimateTypeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'no-animation') {
      editor.setNodes(
        {
          animate_type: event.target.value,
          animate_direction: null,
          animate_speed: null,
          animate_style: null,
        } as Partial<Node>,
        { at: [], match: (n: any) => n.id === sectionElement.id }
      )
    } else {
      editor.setNodes(
        {
          animate_type: event.target.value,
          animate_direction: sectionElement.animate_direction || 'right',
          animate_speed: sectionElement.animate_speed || 'medium',
          animate_style: sectionElement.animate_style || 'fade',
        } as Partial<Node>,
        { at: [], match: (n: any) => n.id === sectionElement.id }
      )
    }
    reanimate()
  }

  const onAnimateStyleChange = async (event: React.MouseEvent<HTMLButtonElement>, value: string) => {
    event.preventDefault()
    editor.setNodes(
      { animate_style: value } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    reanimate()
  }

  const onAnimateDirectionChange = async (event: React.MouseEvent<HTMLButtonElement>, value: string) => {
    event.preventDefault()
    editor.setNodes(
      { animate_direction: value } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    reanimate()
  }

  const onAnimateSpeedChange = async (event: React.MouseEvent<HTMLButtonElement>, value: string) => {
    event.preventDefault()
    editor.setNodes(
      { animate_speed: value } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    reanimate()
  }

  const onPreviewHandle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    reanimate()
  }

  const reanimate = () => {
    editor.setNodes(
      { animationCount: typeof sectionElement.animationCount === 'number' ? sectionElement.animationCount + 1 : 0 } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
  }

  return (
    <>
      <PopoverSelectorTitle title="Animation Option" />
      <RadioSelector
        options={ANIMATION_TYPES}
        current={sectionElement.animate_type as string || "no-animation"}
        setCurrent={onAnimateTypeChange}
      />
      {sectionElement.animate_type === "animate" &&
        <>
          <PopoverSelectorTitle title="Style" className="pt-6" />
          <AnimateButtonSelector
            options={ANIMATION_STYLES}
            current={sectionElement.animate_style as string || 'slide'}
            setCurrent={onAnimateStyleChange}
          />
          {sectionElement.animate_style === "slide" &&
            <>
              <PopoverSelectorTitle title="Direction" className="pt-6" />
              <AnimateButtonSelector
                options={ANIMATION_DIRECTIONS}
                current={sectionElement.animate_direction as string || 'right'}
                setCurrent={onAnimateDirectionChange}
              />
            </>
          }
          <PopoverSelectorTitle title="Speed" className="pt-6" />
          <AnimateButtonSelector
            options={ANIMATION_SPEEDS}
            current={sectionElement.animate_speed as string || 'medium'}
            setCurrent={onAnimateSpeedChange}
          />
          <button
            className="btn btn-white btn-block only-child w-full mt-6"
            onClick={onPreviewHandle}
          >
            Preview
          </button>
        </>
      }
    </>
  )
}

export default AnimateOptions
