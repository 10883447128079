import React, { useCallback, useState } from "react";
import { withRef } from '@udecode/cn';
import { findNode, getNodeParent } from "@udecode/plate-common";
import { isEditorReadOnly, PlateElement } from "@udecode/plate-common/react";
import ahoy from "ahoy.js"
import { useSelected } from "slate-react";

import { useAppSelector } from "../../store/hooks/redux-hooks";
import { colorClassToRGB } from "../../utils/color.util";
import { PlateButtonElement } from "../../plate-config/Plugins/Button/Button.plugin";
import { Popover, PopoverContent, PopoverTrigger, PopoverPortal } from "../../shared/Popover/Popover";
import ToolbarSeparator from "../../shared/ToolbarControls/ToolbarSeparator";
import ToolbarButton from "../../shared/ToolbarControls/ToolbarButton";
import ToolbarLink from "../../shared/ToolbarControls/ToolbarLink";
import ToolbarColorDropdown from "../../shared/ToolbarControls/ToolbarColorDropdown";
import ToolbarSizeDropdown from "../../shared/ToolbarControls/ToolbarSizeDropdown";
import ToolbarAlignDropdown from "../../shared/ToolbarControls/ToolbarAlignDropdown";
import ToolbarLinkDropdown from "../../shared/ToolbarControls/ToolbarLinkDropdown";
import HugeIcon from "../../shared/Icon/HugeIcon";


export const ButtonVoidElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const block = element as PlateButtonElement
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const selected = useSelected();
    const [content, setContent] = useState(block.content || '')
    const isReadOnly = isEditorReadOnly(editor);
    const { resources } = useAppSelector(state => state.page_resources)
    const { activePalette } = useAppSelector(state => state.page_colors)
    const analyticsEnabled = useAppSelector(state => state.page_side_effects.analyticsEnabled)
    const { pageId, account_id, pages, isLandingPage } = useAppSelector(state => state.page_addendums)

    const elementNode = findNode(editor, { at: [], match: (n: any) => n.id === block.id })!;
    const parentNode = elementNode && getNodeParent(editor, elementNode[1]);
    const section = editor.children[elementNode[1][0]]
    const matchingPage = pages?.find(page => page.id === block.internal_page_id)
    const matchingResource = resources?.find(resource => resource.id === block.internal_resource_id)
    const externalLinkPath = block.url && block.url.length > 0 ? block.url : null
    const internalLinkPath = matchingPage ? isLandingPage ? matchingPage.landing_link : matchingPage.builder_link : null
    const resourceLinkPath = matchingResource ? matchingResource.goto_url : null

    const goToLink = block.link_type === "external" ? externalLinkPath :
      block.link_type === "internal" ? internalLinkPath :
        block.link_type === "resource" ? resourceLinkPath : null

    const missingUrl =
      (block.link_type === "external" && !block.url) ||
      (block.link_type === "internal" && !block.internal_page_id) ||
      (block.link_type === "resource" && !block.internal_resource_id && !block.internal_template_resource_slot_id)

    const currentSize = !!parentNode ? parentNode.button_size ? parentNode.button_size as string : 'medium' : 'medium';
    const currentAlign = !!parentNode ? parentNode.align ? parentNode.align as string : 'left' : 'left';

    const onButtonDelete = () =>
      editor.removeNodes({ at: [], match: (n: any) => n.id === block.id })

    const onContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setContent(event.target.value)
      editor.setNodes({ content: event.target.value } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
    }

    const onAlignmentChange = (align: string) =>
      editor.setNodes({ align } as Partial<Node>, { at: [], match: (n: any) => n.id === parentNode?.id })

    const onSizeChange = (button_size: string) =>
      editor.setNodes({ button_size } as Partial<Node>, { at: [], match: (n: any) => n.id === parentNode?.id })

    const onButtonColorChange = (primary_color: string) =>
      editor.setNodes({ primary_color } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

    const onTextColorChange = (secondary_color: string) =>
      editor.setNodes({ secondary_color } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

    const onExternalUrlChange = (url: string) =>
      editor.setNodes(
        {
          url,
          link_type: 'external',
          internal_page_id: null,
          internal_resource_id: null,
          internal_template_resource_slot_id: null
        } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

    const onInternalPageIdChange = (internal_page_id: string) =>
      editor.setNodes(
        {
          url: null,
          link_type: 'internal',
          internal_page_id,
          internal_resource_id: null,
          internal_template_resource_slot_id: null
        } as Partial<Node>,
        { at: [], match: (n: any) => n.id === block.id }
      )

    const onInternalTemplateResourceSlotIdChange = (internal_template_resource_slot_id: string) =>
      editor.setNodes(
        {
          url: null,
          link_type: 'resource',
          internal_template_resource_slot_id,
          internal_resource_id: null,
          internal_page_id: null,
        } as Partial<Node>,
        { at: [], match: (n: any) => n.id === block.id }
      )

    const onInternalResourceIdChange = (internal_resource_id: string) =>
      editor.setNodes(
        {
          url: null,
          link_type: 'resource',
          internal_resource_id,
          internal_template_resource_slot_id: null,
          internal_page_id: null,
        } as Partial<Node>,
        { at: [], match: (n: any) => n.id === block.id }
      )

    const onDynamicUrlChange = (url: string) =>
      editor.setNodes(
        {
          url,
          link_type: 'dynamic',
          internal_resource_id: null,
          internal_template_resource_slot_id: null,
          internal_page_id: null,
        } as Partial<Node>,
        { at: [], match: (n: any) => n.id === block.id }
      )

    const onBtnColorReset = () =>
      editor.setNodes({ primary_color: null } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

    const onTextColorReset = () =>
      editor.setNodes({ secondary_color: null } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

    const trackBtnClick = useCallback((href: string) => {
      if (!analyticsEnabled) return
      console.log("Tracking button click", {
        page_id: pageId,
        section_id: section.id,
        block_id: block.id,
        type: "button_click",
        button_text: block.content,
        account_id,
        href,
      });
      return ahoy.track("page action", {
        page_id: pageId,
        section_id: section.id,
        block_id: block.id,
        type: "button_click",
        button_text: block.content,
        account_id,
        href,
      });
    }, [pageId, section, block]);

    const preventDefault = (e: Event) => e.preventDefault();

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={element}
        className={`${className} mx-[4px] py-4 page-block button-block`}
        contentEditable={false}
        suppressContentEditableWarning
        data-id={block.id}
        {...props}
      >
        <Popover open={!isReadOnly ? selected : false}   >
          <PopoverTrigger>
            {isReadOnly
              ?
              <a
                href={goToLink ? goToLink : undefined}
                style={{
                  ...(block.primary_color && { backgroundColor: colorClassToRGB(block.primary_color, 100, activePalette.colors)! }),
                }}
                className={`
                  btn flex items-center justify-center  min-w-[100px] hover:scale-110
                  ${block.primary_color ? '' : 'btn-white'}
                  ${block.secondary_color ? block.secondary_color : ''}
                  ${parentNode?.button_size === 'medium' ? 'btn-large' : ''}
                  ${parentNode?.button_size === 'large' ? 'btn-xl' : ''}
                `}
                onClick={trackBtnClick.bind(trackBtnClick, goToLink)}
                {
                ...(block.link_type === 'external' || block.link_type === 'resource')
                  ? { target: "_blank", rel: "noopener noreferrer" } : { target: "_self" }}
              >
                {block.content}
              </a>
              :
              <div className="relative">
                <input
                  type="text"
                  style={{
                    width: `${(block?.content?.length || 1) + 3}ch`,
                    ...(block.primary_color && { backgroundColor: colorClassToRGB(block.primary_color, 100, activePalette.colors)! }),
                  }}
                  placeholder="..."
                  className={`
                    btn px-[12px]  min-w-[100px] hover:scale-110
                    ${block.primary_color ? 'border-0' : 'btn-white'}
                    ${block.secondary_color ? block.secondary_color : ''}
                    ${parentNode?.button_size === 'medium' ? 'btn-large' : ''}
                    ${parentNode?.button_size === 'large' ? 'btn-xl' : ''}
                  `}
                  value={content}
                  onChange={onContentChange}
                />
                {missingUrl &&
                  <div className="text-gray-700 flex min-w-[110px] font-semibold text-xs text-center absolute -bottom-5 left-1/2 transform -translate-x-1/2 font-sans">
                    <HugeIcon name='unlink' size={16} className="text-gray-700 mx-1" />
                    No link added
                  </div>
                }
              </div>
            }
            {children}
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent
              onOpenAutoFocus={preventDefault}
              onCloseAutoFocus={preventDefault}
              side='top'
              sideOffset={10}
              align='center'
              className="block_toolbar"
            >
              <ToolbarColorDropdown
                color={element.primary_color as string}
                colorLabel="Button Color"
                onColorChange={onButtonColorChange}
                secondaryColor={element.secondary_color as string}
                secondaryColorLabel="Text Color"
                onSecondaryColorChange={onTextColorChange}
                onColorReset={onBtnColorReset}
                onSecondaryColorReset={onTextColorReset}
              />
              <ToolbarSeparator />
              <ToolbarLinkDropdown
                linkType={block.link_type}
                externalUrl={block.url}
                onExternalUrlChange={onExternalUrlChange}
                internal_page_id={block.internal_page_id}
                onInternalPageIdChange={onInternalPageIdChange}
                internal_template_resource_slot_id={block.internal_template_resource_slot_id}
                onInternalTemplateResourceSlotIdChange={onInternalTemplateResourceSlotIdChange}
                internal_resource_id={block.internal_resource_id}
                onInternalResourceIdChange={onInternalResourceIdChange}
                onDynamicUrlChange={onDynamicUrlChange}
              />
              {goToLink &&
                <ToolbarLink
                  iconName="linkSquare"
                  path={goToLink}
                  openInNewTab={(element.link_type === 'external' || element.link_type === 'resource')}
                />
              }
              <ToolbarSeparator />
              <ToolbarAlignDropdown onAlignChange={onAlignmentChange} currentAlign={currentAlign} />
              <ToolbarSeparator />
              <ToolbarSizeDropdown onSizeChange={onSizeChange} currentSize={currentSize} />
              <ToolbarSeparator />
              <ToolbarButton
                onClick={onButtonDelete}
                iconName='delete'
                type="delete"
              />
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </PlateElement>
    );
  }
);

export default ButtonVoidElement
