import React, { useState } from "react";
import * as Collapsible from "@radix-ui/react-collapsible";

import OptionCardWrapper from "./OptionCardWrapper/OptionCardWrapper";
import { PlateElementAiTextBlock } from "../../../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import CheckboxInput from "../../../../../shared/Inputs/CheckboxInput";

type Props = {
  element: PlateElementAiTextBlock
  onListKindChange: (list_kind: string) => void
  onListExtraSpacingChange: (list_extra_spacing: boolean) => void
}

const AdvancedListOptions: React.FC<Props> = ({ element, onListKindChange, onListExtraSpacingChange }) => {
  const [open, setOpen] = useState(false)

  const listOutputsParagraphs = element.details.list_kind === "feature_benefit" ||
    element.details.list_kind === "objection" ||
    element.details.list_kind === "bolded_summary_paragraph";

  return (
    <Collapsible.Root
      className="mt-2 text-right"
      open={open}
      onOpenChange={setOpen}
    >
      <Collapsible.Trigger asChild>
        <button className="link text-xs">
          {open ? "Hide Advanced Options" : "Show Advanced List Options"}
        </button>
      </Collapsible.Trigger>
      <Collapsible.Content className="text-left">
        <div className="form-group" >
          <label className="label"> Advanced List Type </label>
          <div className="rounded-xl p-4 bg-gray-200">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <OptionCardWrapper
                name="Text Only"
                isActive={element.details.list_kind === "standard"}
                onCardClick={onListKindChange.bind(onListKindChange, "standard")}
              >
                <ul className={`${element.details.list_style === "ordered" ? "list-decimal" : "list-disc"} list-outside pl-4`}>
                  <li className={"mt-2 first:mt-0"}>Venenatis ligula adipiscing fermentum</li>
                  <li className={"mt-2 first:mt-0"}>Malesuada amet adipiscing vestibulum</li>
                  <li className={"mt-2 first:mt-0"}>Malesuada adipiscing tristique quam</li>
                </ul>
              </OptionCardWrapper>
              <OptionCardWrapper
                name="Question & Answer"
                isActive={element.details.list_kind === "question_answer"}
                onCardClick={onListKindChange.bind(onListKindChange, "question_answer")}
              >
                <ul className={`${element.details.list_style === "ordered" ? "list-decimal" : "list-disc"} list-outside pl-4`}>
                  <li className={"mt-2 first:mt-0"}>
                    <div className="font-semibold">Egestas aenean pellentesque sit?</div>
                    <div className="mt-1"> Risus Ultricies Vulputate amet.</div>
                  </li>
                  <li className={"mt-2 first:mt-0"}>
                    <div className="font-semibold">Egestas pellentesque inceptos malesuada?</div>
                    <div className="mt-1">Mollis dolor fermentum vulputate.</div>
                  </li>
                </ul>
              </OptionCardWrapper>
              <OptionCardWrapper
                name="Bolded Summary List"
                isActive={element.details.list_kind === "bolded_summary"}
                onCardClick={onListKindChange.bind(onListKindChange, "bolded_summary")}
              >
                <ul className={`${element.details.list_style === "ordered" ? "list-decimal" : "list-disc"} list-outside pl-4`}>
                  <li className={"mt-2 first:mt-0"}>
                    <span className="font-semibold mr-1">Nullam Sem -</span>
                    Venenatis ligula adipiscing fermentum sit
                  </li>
                  <li className={"mt-2 first:mt-0"}>
                    <span className="font-semibold mr-1">Purus -</span>
                    Malesuada amet adipiscing porta vestibulum
                  </li>
                  <li className={"mt-2 first:mt-0"}>
                    <span className="font-semibold mr-1">Commodo Mollis -</span>
                    Malesuada adipiscing tristique quam sollicitudin
                  </li>
                </ul>
              </OptionCardWrapper>
              <OptionCardWrapper
                name="Paragraphs with Summary"
                isActive={element.details.list_kind === "bolded_summary_paragraph"}
                onCardClick={onListKindChange.bind(onListKindChange, "bolded_summary_paragraph")}
              >
                <div>
                  <div className="font-semibold mr-1"> Bibendum Dolor Sem </div>
                  <div className="mt-1"> Morbi leo risus, porta ac consectetur ac, vestibulum at eros. </div>
                </div>
                <div className="mt-4">
                  <div className="font-semibold mr-1"> Cursus Bibendum </div>
                  <div className="mt-1"> Nullam quis risus eget urna mollis ornare vel eu leo.</div>
                </div>
              </OptionCardWrapper>
              <OptionCardWrapper
                name="Address Concerns"
                isActive={element.details.list_kind === "objection"}
                onCardClick={onListKindChange.bind(onListKindChange, "objection")}
              >
                <div>
                  <div className="font-semibold mr-1"> Cursus Mattis Sem </div>
                  <div className="mt-1"> Pellentesque condimentum vestibulum venenatis ornare </div>
                  <div className="italic mt-1"> Bibendum dolor ornare condimentum pharetra </div>
                </div>
                <div className="mt-4">
                  <div className="font-semibold mr-1"> Egestas Tortor </div>
                  <div className="mt-1"> Cras ligula sollicitudin porta aenean </div>
                  <div className="italic mt-1"> Bibendum mullam etiam fringilla sag </div>
                </div>
              </OptionCardWrapper>
              <OptionCardWrapper
                name="Feature & Benefits"
                isActive={element.details.list_kind === "feature_benefit"}
                onCardClick={onListKindChange.bind(onListKindChange, "feature_benefit")}
              >
                <div>
                  <div className="font-semibold mr-1"> Cras Nullam Dapibus Egestas </div>
                  <div className="mt-1">  Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum </div>
                  <div className="mt-1"> Etiam porta sem malesuada magna mollis euismod </div>
                </div>
                <div className="mt-4">
                  <div className="font-semibold mr-1"> Cursus Ornare </div>
                  <div className="mt-1"> Maecenas sed diam eget risus varius blandit sit amet non magna </div>
                  <div className="mt-1"> Fusce dapibus, tellus ac cursus commodo </div>
                </div>
              </OptionCardWrapper>
            </div>
          </div>
        </div>
        {listOutputsParagraphs &&
          <CheckboxInput
            htmlFor={element.id}
            label={'Extra Spacing Between Paragraphs'}
            value={element.details.list_extra_spacing}
            setValue={onListExtraSpacingChange}
          />
        }
      </Collapsible.Content>
    </Collapsible.Root>
  )
}

export default AdvancedListOptions;
