import React, { useEffect, useState } from "react";
import { findNode } from "@udecode/plate-common";
import { isEditorReadOnly, PlateElement } from "@udecode/plate-common/react";
import { withRef } from '@udecode/cn';

import VideoPlayer from "./VideoPlayer/VideoPlayer";
import { PlateVideoElement } from "../../plate-config/Plugins/Video/Video.plugin";
import PreviewSelector from "./PreviewSelector/PreviewSelector";
import { useAppSelector } from "../../store/hooks/redux-hooks";

export const VideoVoidElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const block = element as PlateVideoElement;

    const isReadOnly = isEditorReadOnly(editor);
    const [isEditing, setIsEditing] = useState(!block.url);

    useEffect(() => {
      editor.setNodes({ isEditing } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id });
    }, [isEditing])

    const nodePath = findNode(editor, { at: [], match: { id: block.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === block.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === block.id;

    const setEditMode = (mode: boolean) => setIsEditing(mode);

    if (isReadOnly) return block.url
      ?
      <VideoPlayer
        ref={ref}
        element={block}
        className={className}
        isFirstChild={isFirstChild}
        isLastChild={isLastChild}
        setEditMode={setEditMode}
        plateElProps={props}
        isReadOnly={true}
      >
        {children}
      </VideoPlayer>
      : null;

    return (
      <>
        {!isEditing && block.url
          ?
          <VideoPlayer
            ref={ref}
            element={block}
            className={className}
            isFirstChild={isFirstChild}
            isLastChild={isLastChild}
            setEditMode={setEditMode}
            plateElProps={props}
            isReadOnly={isReadOnly}
          >
            {children}
          </VideoPlayer>
          :
          <PreviewSelector
            ref={ref}
            element={block}
            className={className}
            isFirstChild={isFirstChild}
            isLastChild={isLastChild}
            setEditMode={setEditMode}
            plateElProps={props}
          >
            {children}
          </PreviewSelector >
        }
      </>
    )
  }
);

export default VideoVoidElement;
