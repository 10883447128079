import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { PageData, ResourceSlot } from '../api/api.types'

interface ResourcesState {
  resources: any[]
  resource_slots: ResourceSlot[]
}

const initialState: ResourcesState = {
  resources: [],
  resource_slots: [],
}

export const pageResourcesSlice = createSlice({
  name: 'page_resources',
  initialState,
  reducers: {
    setResources: (_state, action: PayloadAction<PageData>) => {
      return {
        resources: action.payload.resources,
        resource_slots: action.payload.resource_slots
      }
    },
    setResourceSlot: (state, action: PayloadAction<ResourceSlot>) => {
      return {
        ...state,
        resource_slots: [...state.resource_slots, action.payload]
      }
    },
  },
})

export const { setResources, setResourceSlot } = pageResourcesSlice.actions

export default pageResourcesSlice.reducer