import { setResourceSlot } from "../../page_resources/page_resources";
import { revvedupApi } from "../api";
import { ResourceSlot } from "../api.types";
import { getPageDataAttributes } from "../data-attributes.util";


export const resourceEndpoints = revvedupApi.injectEndpoints({
  endpoints: (builder) => ({
    createResourceSlot: builder.mutation<ResourceSlot, string>({
      query: (about) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/pages/${requestData?.page_id}/template_resource_slots`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { resource_slot: { about } },
        }
      },
      async onQueryStarted({ }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setResourceSlot(data))
          }
        } catch (err) {
          console.log('Update nav display logo attribute error:', err);
        }
      }
    }),
  })
})

export const { useCreateResourceSlotMutation } = resourceEndpoints

