import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { PageData, RoomPages } from '../api/api.types'
import { PageDataAttributes } from '../api/data-attributes.util'

export interface PageAddendums {
  currency: string,
  template: boolean,
  pages: RoomPages[],
  readOnly: boolean,
  isMobilePreviewActive: boolean,
  commentsModalOpen: boolean,
  isLandingPage: boolean,
  requestAttributes: PageDataAttributes | null,
  pageId: string | null
  account_id: string | null
}

const initialState: PageAddendums = {
  currency: '',
  template: false,
  pages: [],
  readOnly: false,
  isMobilePreviewActive: false,
  commentsModalOpen: false,
  isLandingPage: false,
  requestAttributes: null,
  pageId: null,
  account_id: null
}

export const pageAddendumsSlice = createSlice({
  name: 'page_addendums',
  initialState,
  reducers: {
    setAddendums: (state, action: PayloadAction<{ pageData: PageData, requestAttributes: PageDataAttributes | null }>) => ({
      ...state,
      currency: action.payload.pageData.currency,
      template: action.payload.pageData.template,
      pages: action.payload.pageData.room_pages,
      requestAttributes: action.payload.requestAttributes,
      pageId: action.payload.pageData.id,
      account_id: action.payload.pageData.account_id
    }),
    toggleMobilePreview: (state) => ({ ...state, isMobilePreviewActive: !state.isMobilePreviewActive }),
    toggleEditorMode: (state) => ({ ...state, readOnly: !state.readOnly, isMobilePreviewActive: state.readOnly === true ? false : state.isMobilePreviewActive }),
    setCommentsModal: (state, action: PayloadAction<boolean>) => ({ ...state, commentsModalOpen: action.payload }),
    setLandingPageMode: (state, action: PayloadAction<boolean>) => ({ ...state, isLandingPage: action.payload }),
  },
})

export const { setAddendums, toggleEditorMode, toggleMobilePreview, setCommentsModal, setLandingPageMode } = pageAddendumsSlice.actions

export default pageAddendumsSlice.reducer
