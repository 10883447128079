import React from 'react';
import { PlateContent } from '@udecode/plate-common/react';
import type { PlateContentProps } from '@udecode/plate-common/react';


const Editor = React.forwardRef<HTMLDivElement, PlateContentProps>(
  ({ className, disabled, readOnly, ...props }, ref) => {

    return (
      <div ref={ref} className="relative w-full">
        <PlateContent
          className='relative whitespace-pre-wrap break-words outline-none'
          disableDefaultStyles
          readOnly={disabled ?? readOnly}
          aria-disabled={disabled}
          {...props}
        />
      </div>
    );
  }
);
Editor.displayName = 'Editor';

export { Editor };
