import React, { useRef } from "react"

import IconButton from "../../IconButton/IconButton"
import Icon from "../../Icon/Icon"


type Props = {
  onImageChange: (file: File) => void;
  onImageRemove: () => void;
  pageType: string;
  setPageType: (type: string) => void;
  activeCollection: any;
  setActiveCollection: (collection: any) => void;
  searchTerm: string;
  onSearchHandle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  currentImageUrl: string;
  onModalClose: () => void;
}

const ImagePickerHeader = ({
  pageType,
  setPageType,
  activeCollection,
  setActiveCollection,
  searchTerm,
  onSearchHandle,
  currentImageUrl,
  onImageChange,
  onImageRemove,
  onModalClose,
}: Props) => {
  const inputRef: React.LegacyRef<HTMLInputElement> = useRef(null);

  const handleUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onImageChange(e.target.files[0])
    }
  };

  const handleUploadImageClick = () => inputRef.current?.click()

  const onSeeCollectionsHandle = () => {
    setPageType("collections")
    setActiveCollection(null)
  }

  const preventFormSubmit = (e: React.FormEvent<HTMLFormElement>) => e.preventDefault()

  const TitleElement = () => {
    switch (pageType) {
      case "collection":
        return (
          <span>
            <span
              className="transition-all ease-in-out duration-300 cursor-pointer hover:text-blue-500 hover:border-b hover:border-blue-500"
              onClick={onSeeCollectionsHandle}
            >
              <span className="mr-2">
                <Icon icon='faUnsplash' />
              </span>
              Unsplash Images
            </span>
            <span className="text-gray-400 mx-2 text-sm">
              <Icon icon='faAngleRight' />
            </span>
            {activeCollection && activeCollection.title}
          </span>
        )
      case "collections":
        return (
          <span>
            <span className="mr-2">
              <Icon icon='faUnsplash' />
            </span>
            Unsplash Images
          </span>
        )
      case "search":
        return (
          <span>
            <span className="mr-2">
              <Icon icon='faUnsplash' />
            </span>
            Searching "{searchTerm}" images
          </span>
        )
      case "media":
        return (
          <span>
            <span className="mr-2">
              <Icon icon='faFolderOpen' />
            </span>
            Uploaded Images
          </span>
        )
      default:
        return null
    }
  }



  return (
    <div className="flex justify-between items-center p-5 rounded-t border-b border-gray-200">
      <div className="flex items-baseline gap-6">
        <h3 className="text-xl font-medium text-gray-900">
          <TitleElement />
        </h3>
        <button
          className="link text-xs font-light transition-all ease-in-out hover:border-b hover:border-blue-400"
          onClick={pageType === "media" ? setPageType.bind(setPageType, "collections") : setPageType.bind(setPageType, "media")}
        >
          {pageType === "media" ? "Browse Unsplash Images" : "See Uploaded Images"}
        </button>
        {currentImageUrl &&
          <button
            className="link text-xs font-light transition-all ease-in-out hover:border-b hover:border-blue-400"
            onClick={onImageRemove}
          >
            {currentImageUrl === "{{BUYER_LOGO}}" ? "Remove Dynamic Brand Logo" : "Remove Image"}
          </button>
        }
      </div>
      <div className="flex right gap-4 items-center">
        <div className="relative w-60">
          <Icon icon='faMagnifyingGlass' wrapperClassName="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none text-gray-400 text-sm" />
          <input
            type="text"
            className="form-control mt-0 pl-8 text-gray-700"
            placeholder="Search Unsplash Images"
            onChange={onSearchHandle}
            required
          />
        </div>
        <form onSubmit={preventFormSubmit}>
          <input ref={inputRef} type="file" multiple={false} onChange={handleUploadChange} className="hidden" />
          <button
            className="btn btn-primary"
            onClick={handleUploadImageClick}
          >
            Upload
          </button>
        </form>
        <IconButton icon='faX' btnClassName="text-gray-900 mx-4 p-0" onClick={onModalClose} />
      </div>
    </div >
  )
}

export default ImagePickerHeader
