export const textColorFromHex = (hex: string) => {
  return lightOrDarkColor(hex) === "light" ? "black" : "white";
}

export const lightOrDarkColor = (hex: string | null) => {
  if (!hex) {
    return "light"
  }
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 186) ? 'light' : 'dark';
}

export function getColorHexFromClass(color: string | null, colors: string[]) {
  if (!color) return null
  if (color === "color-black") return "#000000"
  if (color === "color-white") return "#ffffff"
  if (color.startsWith("#")) return color

  const myColors = colors?.length > 0 ? colors : []
  return myColors[+color.split("-")[1]]
}

export function colorClassToRGB(color: string, alpha: number, colors: string[]) {
  const hex = getColorHexFromClass(color, colors)
  if (hex) {
    return hexToRGB(hex, alpha)
  } else {
    return null
  }
}

export function hexToRGB(hex: string, alpha: number) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (typeof alpha === "undefined") {
    return `rgb(${r}, ${g}, ${b})`
  } else {
    return `rgba(${r}, ${g}, ${b}, ${alpha / 100})`
  }
}

export function textColorFromColorClass(color: string, colors: string[]) {
  const hex = getColorHexFromClass(color, colors)!
  return textColorFromHex(hex)
}

export const systemColorsArray = [
  { name: 'landing-red-300', color: '#fca5a5' },
  { name: 'landing-red-500', color: '#ef4444' },
  { name: 'landing-red-700', color: '#991b1b' },
  { name: 'landing-orange-300', color: '#fbd38d' },
  { name: 'landing-orange-500', color: '#ed8936' },
  { name: 'landing-orange-700', color: '#7f3c1e' },
  { name: 'landing-yellow-300', color: '#faf089' },
  { name: 'landing-yellow-500', color: '#f59e0b' },
  { name: 'landing-yellow-700', color: '#78350f' },
  { name: 'landing-green-300', color: '#9ae6b4' },
  { name: 'landing-green-500', color: '#22c55e' },
  { name: 'landing-green-700', color: '#22543d' },
  { name: 'landing-blue-300', color: '#93c5fd' },
  { name: 'landing-blue-500', color: '#2563eb' },
  { name: 'landing-blue-700', color: '#1d4ed8' },
  { name: 'landing-purple-300', color: '#d6bcfa' },
  { name: 'landing-purple-500', color: '#9333ea' },
  { name: 'landing-purple-700', color: '#6d28d9' },
]