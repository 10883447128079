import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface PageSideEffects {
  openGloablVarModal: boolean,
  disableAnimation: boolean,
  analyticsEnabled: boolean,
}

const initialState: PageSideEffects = {
  openGloablVarModal: false,
  disableAnimation: false,
  analyticsEnabled: false,
}

export const pageSideEffectsSlice = createSlice({
  name: 'page_side_effects',
  initialState,
  reducers: {
    openGlobalVarModal: (state) => ({
      ...state,
      openGloablVarModal: true
    }),
    closeGlobalVarModal: (state) => ({
      ...state,
      openGloablVarModal: false
    }),
    setAnimationStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      disableAnimation: action.payload
    }),
    setAnalyticsStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      analyticsEnabled: action.payload
    }),
  },
})

export const { openGlobalVarModal, closeGlobalVarModal, setAnimationStatus, setAnalyticsStatus } = pageSideEffectsSlice.actions

export default pageSideEffectsSlice.reducer