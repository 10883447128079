import React from "react";

import HugeIcon from '../../../../shared/Icon/HugeIcon';
import Tooltip from "../../../../shared/ToolTip/Tooltip";

type Props = {
  onColumnSwap: () => void;
}

const ColumnDivider: React.FC<Props> = ({ onColumnSwap }) => {
  return (
    <div
      className="
            absolute top-0 right-[-8px] z-10 w-[1px] flex flex-col justify-center items-center
            h-full opacity-0 cursor-default hover:opacity-100 px-[3px] transition-opacity duration-300 ease-in-out"
    >
      <div className="h-full bg-gray-400 w-[1px] flex items-center justify-center">
        <div
          className='bg-white w-8 h-8 rounded p-2 flex items-center justify-center cursor-pointer shadow-md'
          onClick={onColumnSwap}
          id="column-divider_tooltip-anchor"
        >
          <Tooltip
            content={'Swap Columns'}
            side={'bottom'}
            sideOffset={10}
            container={'column-divider_tooltip-anchor'}
            contentClassName="bg-black text-white rounded p-1 text-xs py-1 px-[6px]"
          >
            <div>
              <HugeIcon name="arrowReloadHorizontal" size={14} color="black" />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default ColumnDivider;