import React, { MouseEventHandler } from 'react';
import { cn, withRef } from '@udecode/cn';
import {
  focusEditor,
  MemoizedChildren,
  ParagraphPlugin,
  useEditorPlugin,
  useEditorRef,
  withHOC,
} from '@udecode/plate-common/react';
import { DraggableProvider, useDraggable, useDraggableState, useDropLine } from '@udecode/plate-dnd';
import { BlockSelectionPlugin } from '@udecode/plate-selection/react';
import type { DropTargetMonitor } from 'react-dnd';
import { findNode, type TEditor } from '@udecode/plate-common';
import type { PlateElementProps } from '@udecode/plate-common/react';
import type { DragItemNode } from '@udecode/plate-dnd';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { BulletedListPlugin, NumberedListPlugin } from '@udecode/plate-list/react';

import { ImagePlugin } from '../../plate-config/Plugins/Image/Image.plugin';
import { useMounted } from '../../hooks/useMounted';
import HugeIcon from '../../shared/Icon/HugeIcon';
import { AiTextBlockPlugin } from '../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin';
import { AssessmentPlugin } from '../../plate-config/Plugins/Assessment/Assessment.plugin';
import { EmbedPlugin } from '../../plate-config/Plugins/Embed/Embed.plugin';
import { VideoPlugin } from '../../plate-config/Plugins/Video/Video.plugin';
import { PricingPlugin } from '../../plate-config/Plugins/Pricing/Pricing.plugin';
import { HorizontalRulePlugin } from '../../plate-config/Plugins/HrLine/HrLine.plugin';
import { ButtonGroupPlugin } from '../../plate-config/Plugins/Button/ButtonGroup.plugin';
import { ColumnGroupPlugin } from '../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin';


export interface DraggableProps extends PlateElementProps {
  onDropHandler?: (
    editor: TEditor,
    props: {
      id: string;
      dragItem: DragItemNode;
      monitor: DropTargetMonitor<DragItemNode, unknown>;
      nodeRef: any;
    }
  ) => boolean;
}

export const Draggable = withHOC(
  DraggableProvider,
  withRef<'div', DraggableProps>(
    ({ className, onDropHandler, ...props }, ref) => {
      const { children, element } = props;

      const [hovering, setHovering] = React.useState(false);
      const editor = useEditorRef();
      const state = useDraggableState({ element, onDropHandler });
      const { isDragging } = state;
      const { previewRef, handleRef } = useDraggable(state);
      const mounted = useMounted();
      const { useOption } = useEditorPlugin(BlockSelectionPlugin);
      const isSelectionAreaVisible = useOption('isSelectionAreaVisible');
      const dropLineState = useDropLine();

      const node = findNode(editor, { at: [], match: { id: element.id } })!;

      const selectableElements = [
        ParagraphPlugin.key, HEADING_KEYS.h1, HEADING_KEYS.h2, BlockquotePlugin.key, BulletedListPlugin.key,
        NumberedListPlugin.key, ImagePlugin.key, AiTextBlockPlugin.key, AssessmentPlugin.key, EmbedPlugin.key,
        HorizontalRulePlugin.key, PricingPlugin.key, VideoPlugin.key
      ] as string[]

      const onDragIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (selectableElements.includes(element.type)) {
          focusEditor(editor)
          editor.select(node[1])
        }
        if (element.type === ButtonGroupPlugin.key) {
          focusEditor(editor)
          editor.select([...node[1], 0])
        }
        if (element.type === ColumnGroupPlugin.key) {
          focusEditor(editor)
          editor.select({ anchor: { path: [...node[1], 0, 0, 0], offset: 0 }, focus: { path: [...node[1], 0, 0, 0], offset: 0 } })
        }
      }

      const onDragIconMouseDown: MouseEventHandler<HTMLDivElement> = () =>
        editor
          .getApi(BlockSelectionPlugin)
          .blockSelection?.resetSelectedIds();

      return (
        <div
          ref={ref}
          className={cn('relative', isDragging && 'opacity-50', 'group')}
          onMouseEnter={setHovering.bind(setHovering, true)} onMouseLeave={setHovering.bind(setHovering, false)}
        >
          <div
            ref={ref}
            className={cn(
              'slate-gutterLeft',
              'absolute -top-px z-50 flex h-full -translate-x-full cursor-text opacity-0 hover:opacity-100 group-hover:opacity-100',
              isSelectionAreaVisible && 'hidden',
              className
            )}
            contentEditable={false}
            suppressContentEditableWarning={true}
          >
            <div
              className={cn('slate-blockToolbarWrapper', 'flex h-[1.5em]')}
              contentEditable={false}
              suppressContentEditableWarning={true}
            >
              <div className={cn('slate-blockToolbar', 'pointer-events-auto mr-1 flex items-center')} >
                <div
                  ref={handleRef} data-key={mounted ? (element.id as string) : undefined}
                  contentEditable={false}
                  suppressContentEditableWarning={true}
                >
                  {hovering && <div
                    contentEditable={false}
                    suppressContentEditableWarning={true}
                    className='bg-white rounded p-1 w-6 h-6 flex items-center justify-center shadow-md cursor-pointer'
                    onClick={onDragIconClick}
                    onMouseDown={onDragIconMouseDown}
                  >
                    <HugeIcon name="dragDrop" size={24} color={'black'} strokeWidth={3} />
                  </div>}
                </div>
              </div>
            </div>
          </div>

          <div ref={previewRef} className="slate-blockWrapper" >
            {!!dropLineState.dropLine &&
              <div
                className="relative bg-transparent h-[2px] overflow-hidden my-2"
                contentEditable={false}
                suppressContentEditableWarning={true}
              >
                <div
                  className={`
                    absolute inset-0 transform bg-blue-500 rounded-full transition-all duration-300
                    ${dropLineState.dropLine === 'top' ? "h-[4px] scale-x-100 opacity-100" : ""} 
                    ${dropLineState.dropLine === 'bottom' ? "h-[4px] scale-x-0 opacity-0" : " "}
                  `}
                />
              </div>}
            <MemoizedChildren>{children}</MemoizedChildren>
            {!!dropLineState.dropLine &&
              <div
                className="relative bg-transparent h-[2px] overflow-hidden my-2"
                contentEditable={false}
                suppressContentEditableWarning={true}
              >
                <div
                  className={`
                    absolute inset-0 transform bg-blue-500 rounded-full transition-all duration-300
                    ${dropLineState.dropLine === 'bottom' ? "h-[4px] scale-x-100 opacity-100" : ""} 
                    ${dropLineState.dropLine === 'top' ? "h-[4px] scale-x-0 opacity-0" : " "}
                    `}
                />
              </div>}
          </div>
        </div>
      );
    }
  )
);