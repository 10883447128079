import React, { memo } from "react"

import { EmbedConfig } from "../../config/config"
import TooltipIcon from "../../../../shared/ToolTip/TooltipIcon"
import TooltipLink from "../../../../shared/ToolTip/TooltipLink"


type Props = {
  platform: EmbedConfig
}

const SourceItem: React.FC<Props> = memo(({ platform }) => {
  return (
    <li
      id={`embed-supported-${platform.name}`}
      className="flex justify-center items-center pb-2"
    >
      <p className="pr-2">{platform.name}</p>
      <div className={`relative flex items-center group/customEmbed`}>
        <TooltipIcon
          tooltip={platform.linkFormat}
          side="top"
          container={`embed-supported-${platform.name}`}
          icon="faInfoCircle"
          iconWrapperClassName="text-sm pr-1 transition-all duration-200 hover:text-blue-500"
        />
        {platform.learnMore &&
          <TooltipLink
            tooltip="Learn more"
            side="top"
            container={`embed-supported-${platform.name}`}
            icon="faCircleQuestion"
            path={platform.learnMore}
            linkClassName="text-sm pr-1 transition-all duration-200 hover:text-blue-500"
          />
        }
      </div>
    </li>
  )
})

export default SourceItem