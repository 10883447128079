import React from 'react';
import { withRef } from '@udecode/cn';
import { PlateLeaf } from '@udecode/plate-common/react';

export const TextColorLeaf = withRef<typeof PlateLeaf>(
  ({ children, ...props }, ref) => {
    const color = props.leaf.font_color as string;
    return (
      <PlateLeaf
        ref={ref}
        asChild
        {...props}
      >
        <span className={color}>{children}</span>
      </PlateLeaf>
    )
  }
);