import React from "react";

import LabeledInput from "../../../../../../shared/Inputs/LabeledInput"
import { PricingTableLineItem } from "../../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import FormulaInput from "../../../../../../shared/FormulaInput/FormulaInput";
import { StoreCalcVariable } from "../../../../../../store/reducers/page_calc_variables/page_calc_variables";

type Props = {
  rowItem: PricingTableLineItem,
  onRowValueFormulaAttributesHandle: (value_formula_attributes: { calculation: string, notes: string | null }) => void,
  calc_variables: StoreCalcVariable[]
}


const ValueMenu = ({ rowItem, onRowValueFormulaAttributesHandle, calc_variables }: Props) => {

  const onNotesChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onRowValueFormulaAttributesHandle({ ...rowItem.value_formula_attributes, notes: e.target.value })

  const onFormulaChange = (formula: string) =>
    onRowValueFormulaAttributesHandle({ ...rowItem.value_formula_attributes, calculation: formula })

  return (
    <div className="flex flex-col p-4 gap-4">
      <LabeledInput
        type="text"
        htmlFor="value-notes"
        label="Value Notes"
        value={rowItem.value_formula_attributes.notes || ''}
        onChange={onNotesChange}
      />
      <FormulaInput
        additionalVariables={[{ name: "(QTY) Line Quantity", value: "{{QTY}}" }]}
        formula={rowItem.value_formula_attributes.calculation}
        setFormula={onFormulaChange}
        calc_variables={calc_variables}
      />
    </div>
  )
}

export default ValueMenu;
