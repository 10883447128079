import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  scrollTo(e) {
    e.preventDefault();
    const href = e.target.getAttribute("href");
    const offsetTop = document.querySelector(href).offsetTop - 94;

    scroll({
      top: offsetTop,
      behavior: "smooth"
    });
  }
}
