import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "hideable"]

  unlock() {
    console.log("unlocking")
    this.hideableTargets.forEach((hideable) => {
      hideable.classList.add("hidden")
    })

    this.inputTarget.disabled = false
  }
}
