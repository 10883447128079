import React from 'react'
import { createApi } from 'unsplash-js'

import { UnsplashImage } from '../unsplash.types';

type Props = {
  image: UnsplashImage;
  setImageFromCollection: (image: UnsplashImage) => void;
  unsplashClient: ReturnType<typeof createApi>;
}

const ImagePickerCard = ({ image, setImageFromCollection, unsplashClient }: Props) => {
  const handleClick = async () => {
    await unsplashClient.photos.trackDownload({ downloadLocation: image.links.download_location })
    setImageFromCollection(image)
  }


  return (
    <div className="basis-1/2 pr-4 pb-4 md:basis-1/3 lg:basis-1/4">
      <div
        className="relative image-card bg-cover bg-center rounded-xl transition ease-in-out duration-500"
        onClick={handleClick}
        style={{ backgroundImage: `url(${image.urls.small})` }}
      >
        <div className="rounded-xl absolute inset-0 w-full h-full opacity-0 cursor-pointer transition-all ease-in-out duration-500 hover:bg-gray-800 hover:bg-opacity-50 hover:opacity-100">
          <div className="flex items-center justify-center w-full h-full">
            <div className="btn btn-white btn-small shadow-lg transition-all ease-in-out duration-500 with-hover-ring">
              Select Image
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 text-xs text-center bg-gray-200/70 p-1">
            Photo by <a href={`${image.user.links.html}?utm_source=revvedupai&utm_medium=referral`} target="_blank" rel="noopener">
              {image.user.name}
            </a> on <a href="https://unsplash.com?utm_source=revvedupai&utm_medium=referral" target="_blank" rel="noopener">
              Unsplash
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImagePickerCard
