import React from 'react';

import Icon from "../../../../shared/Icon/Icon";
import TooltipButton from "../../../../shared/ToolTip/TooltipButton";

type Props = {
  aiTextReady: boolean,
  onActiveViewChange: (view: string) => void
}

const SettingsHeader: React.FC<Props> = ({ aiTextReady, onActiveViewChange }) => {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row gap-2 items-center">
        <h3 className="text-center text-xl font-semibold text-gray-800">
          AI Text Settings
        </h3>
        <div className={`badge ${aiTextReady ? 'positive' : 'danger'}`}>
          <div className="flex flex-row items-center gap-1">
            <span>{aiTextReady ? 'Ready' : 'Missing Information'}</span>
            <Icon icon={aiTextReady ? 'faCircleCheck' : 'faTriangleExclamation'} />
          </div>
        </div>
      </div>
      <TooltipButton
        container="ai-text-setting-anchor"
        tooltip={aiTextReady ? '' : "Please fill in the missing fields"}
        side="top"
        title="Summary"
        onClick={onActiveViewChange.bind(onActiveViewChange, 'summary')}
        disabled={!aiTextReady}
        btnClassName="btn btn-small btn-light-gray text-gray-700"
      />
    </div>
  )
}

export default SettingsHeader;