import React from "react";
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';

import { PlatePricingLocalElement } from "../../../plate-config/Plugins/Pricing/Pricing.plugin";
import { useAppSelector } from "../../../store/hooks/redux-hooks";
import { textColorFromColorClass } from "../../../utils/color.util";
import Head from "./Head/Head";
import Footer from "./Footer/Footer";
import Body from "./Body/Body";
import { getItemsForCalculation } from "../../../utils/calc_plugins.util";
import { StoreCalcVariable } from "../../../store/reducers/page_calc_variables/page_calc_variables";

type Props = {
  localBlock: PlatePricingLocalElement,
  calc_variables: StoreCalcVariable[]
}

const Summary: React.FC<Props> = ({ localBlock, calc_variables }) => {
  const colors = useAppSelector(state => state.page_colors.activePalette.colors)
  const currency = useAppSelector(state => state.page_addendums.currency)
  const textColor = textColorFromColorClass(localBlock?.secondary_color ? localBlock.secondary_color : '', colors)

  const summaryData = getItemsForCalculation(localBlock, currency, calc_variables)

  const isValueHidden = localBlock?.pricing_tables_attributes?.some(table => table.hide_value)
  return (
    <TableContainer
      contentEditable={false}
      suppressContentEditableWarning
      component={'section'}
      sx={{ overflowX: "visible" }}
    >
      <Table
        sx={{ borderCollapse: 'separate' }}
        className={`shadow rounded-xl px-3 py-5 background-${localBlock?.secondary_color}`}
        aria-label="Pricing tables summary"
        contentEditable={false}
        suppressContentEditableWarning
      >
        {!isValueHidden && <Head textColor={textColor} />}
        <Body textColor={textColor} summaryData={summaryData} isValueHidden={isValueHidden} />
        <Footer textColor={textColor} summaryData={summaryData} isValueHidden={isValueHidden} />
      </Table>
    </TableContainer>
  )
}

export default Summary