import { TableCell } from "@mui/material";
import React from "react";

type Props = {
  onRowAddHandle: () => void
  toggleRowOptions: () => void
};

export const FloatingMenu: React.FC<Props> = ({ onRowAddHandle, toggleRowOptions }) => {
  return (
    <TableCell
      sx={{
        position: 'absolute',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%) translateY(60%)',
        borderBottom: 'none',
        border: 'none',
        padding: "unset",
        minWidth: '200px',
        zIndex: 1000,
        '&:hover': {
          borderColor: 'transparent',
        },
      }}
      colSpan={6}
    >
      <button onClick={onRowAddHandle} className="btn btn-white btn-small whitespace-nowrap mr-3">Add Item</button>
      <button onClick={toggleRowOptions} id='row_options_popover_anchor' className="btn btn-white btn-small whitespace-nowrap" >Edit Item</button>
    </TableCell>
  );
}

export default FloatingMenu;