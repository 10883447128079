import React from "react";

import { PlateElementAiTextBlock } from "../../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import AdvancedListOptions from "./AdvancedListOptions/AdvancedListOptions";
import ListTypeAndLength from "./ListTypeAndLength/ListTypeAndLength";


type Props = {
  element: PlateElementAiTextBlock
  listItemsMin: number
  listItemsMax: number
  onListStyleChange: (list_style: string) => void
  onMinListItemsChange: (list_items_min: number) => void
  onMaxListItemsChange: (list_items_max: number) => void
  onListKindChange: (list_kind: string) => void
  onListExtraSpacingChange: (list_extra_spacing: boolean) => void
}

const ListOptions: React.FC<Props> = ({
  element,
  onListStyleChange,
  onMinListItemsChange,
  onMaxListItemsChange,
  onListKindChange,
  listItemsMax,
  listItemsMin,
  onListExtraSpacingChange
}) => {
  return (
    <>
      <h4 className="text-sm font-medium border-b border-gray-200 pb-1 text-gray-700 mt-6">
        List Length
      </h4>
      <div className="text-gray-700 text-xs mt-2 mb-6">
        Control the number of items that will be generated in the list
      </div>
      <ListTypeAndLength
        element={element}
        list_items_min={listItemsMin}
        list_items_max={listItemsMax}
        onListStyleChange={onListStyleChange}
        onMaxListItemsChange={onMaxListItemsChange}
        onMinListItemsChange={onMinListItemsChange}
      />
      <AdvancedListOptions
        element={element}
        onListKindChange={onListKindChange}
        onListExtraSpacingChange={onListExtraSpacingChange}
      />
      <h4 className="text-sm font-medium border-b border-gray-200 pb-1 text-gray-700 mt-6">
        List Item Details
      </h4>
      <div className="text-gray-700 text-xs mt-2 mb-6">
        Control the individual items that are generated in the list
      </div>
    </>
  )
}

export default ListOptions;