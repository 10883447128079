import type { FC } from 'react';
import type { WithDraggableOptions } from '@udecode/plate-dnd';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { createNodesWithHOC, ParagraphPlugin } from '@udecode/plate-common/react';
import { withDraggable as withDraggablePrimitive } from '@udecode/plate-dnd';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { BulletedListPlugin, NumberedListPlugin } from '@udecode/plate-list/react';

import { Draggable } from '../../../plate-components/DnD/draggable';
import type { DraggableProps } from '../../../plate-components/DnD/draggable';
import { HorizontalRulePlugin } from '../HrLine/HrLine.plugin';
import { ButtonGroupPlugin } from '../Button/ButtonGroup.plugin';
import { VideoPlugin } from '../Video/Video.plugin';
import { ImagePlugin } from '../Image/Image.plugin';
import { EmbedPlugin } from '../Embed/Embed.plugin';
import { AssessmentPlugin } from '../Assessment/Assessment.plugin';
import { PricingPlugin } from '../Pricing/Pricing.plugin';
import { ColumnGroupPlugin } from '../ColumnGroup/ColumnGroup.plugin';
import { AiTextBlockPlugin } from '../AiTextBlock/AiTextBlock.plugin';

export const withDraggable = (
  Component: FC,
  options?: WithDraggableOptions<
    Partial<Omit<DraggableProps, 'children' | 'editor' | 'element'>>
  >
) =>
  withDraggablePrimitive<DraggableProps>(Draggable, Component, options as any);

export const withDraggablesPrimitive = createNodesWithHOC(withDraggable);

export const withDraggables = (components: any) => {
  return withDraggablesPrimitive(components, [
    {
      keys: [
        ParagraphPlugin.key,
        BulletedListPlugin.key,
        NumberedListPlugin.key,
        ParagraphPlugin.key,
        HEADING_KEYS.h1,
        HEADING_KEYS.h2,
        BlockquotePlugin.key,
        HorizontalRulePlugin.key,
        NumberedListPlugin.key,
        BulletedListPlugin.key,
        ButtonGroupPlugin.key,
        VideoPlugin.key,
        ImagePlugin.key,
        EmbedPlugin.key,
        AssessmentPlugin.key,
        PricingPlugin.key,
        ColumnGroupPlugin.key,
        AiTextBlockPlugin.key
      ],
      level: null,
    },
    {
      key: ColumnGroupPlugin.key,
      draggableProps: {
        className: 'left-[-30px]',
      },
    },
  ]);
};