import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["accessType", "unlockRequirements", "requiredPasswordField", "passwordField",
                    "requiredEmailField", "emailVerificationField"];

  connect() {
    console.log("AccessRequirementsController connected");
    this.updateVisibility();
    this.togglePasswordFieldVisibility();
    this.toggleEmailVerificationFieldVisibility();
  }

  updateVisibility() {
    const selectedAccessType = this.accessTypeTargets.find(
      (accessType) => accessType.checked
    ).value;

    console.log("selectedAccessType", selectedAccessType)

    if (selectedAccessType === "public") {
      this.unlockRequirementsTarget.classList.add("hidden");
    } else {
      this.unlockRequirementsTarget.classList.remove("hidden");
    }

    // Update background color of the selected radio button's
    this.accessTypeTargets.forEach((accessType) => {
      const parentElement = accessType.closest("[data-access-type]");
      if (accessType.checked) {
        parentElement.classList.add("bg-gray-100");
      } else {
        parentElement.classList.remove("bg-gray-100");
      }
    });
  }

  togglePasswordFieldVisibility() {
    const passwordRequiredField = this.requiredPasswordFieldTargets[0];

    if (passwordRequiredField.checked) {
      this.passwordFieldTargets[0].classList.remove("hidden");
    } else {
      this.passwordFieldTargets[0].classList.add("hidden");
    }
  }

  toggleEmailVerificationFieldVisibility() {
    const emailRequiredField = this.requiredEmailFieldTargets[0];

    if (emailRequiredField.checked) {
      this.emailVerificationFieldTargets[0].classList.remove("hidden");
    } else {
      this.emailVerificationFieldTargets[0].classList.add("hidden");
    }
  }

}
