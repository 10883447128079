import React, { useState } from "react"
import { useEditorRef } from "@udecode/plate-common/react";

import { PlateElementAiTextBlock, UnionSourceTypes } from "../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import TooltipButton from "../../../shared/ToolTip/TooltipButton";
import SettingsHeader from "./SettingsHeader/SettingsHeader";
import SourcesCheckbox from "./SourcesCheckbox/SourcesCheckbox";
import TextTypeAndLength from "./TextTypeAndLength/TextTypeAndLength";
import Prompt from "./Prompt/Prompt";
import ListOptions from "./ListOptions/ListOptions";
import ColorSelector from "../../../shared/ColorSelector/ColorSelector";
import ResourcesCheckbox from "./ResourcesCheckbox/ResourcesCheckbox";
import { useAppSelector } from "../../../store/hooks/redux-hooks";
import PropsSelector from "../../../shared/PropsSelector/PropsSelector";
import Icon from "../../../shared/Icon/Icon";

type Props = {
  element: PlateElementAiTextBlock
  onActiveViewChange: (view: string) => void
  aiTextReady: boolean
}


const AiTextBlockSettings: React.FC<Props> = ({ element, onActiveViewChange, aiTextReady }) => {
  const editor = useEditorRef()
  const isTemplate = useAppSelector(state => state.page_addendums.template)

  // Has to be a local state to avoid case with cursor jumping to the end of the input
  const [prompt, setPrompt] = useState(element.details.prompt || '')
  const [lengthMin, setLengthMin] = useState(element.details.length_min || 0)
  const [lengthMax, setLengthMax] = useState(element.details.length_max || 0)
  const [listItemsMin, setListItemsMin] = useState(element.details.list_items_min || 0)
  const [listItemsMax, setListItemsMax] = useState(element.details.list_items_max || 0)

  const alignmentOptions = [
    { name: "left", label: <Icon icon="faAlignLeft" /> },
    { name: "center", label: <Icon icon="faAlignCenter" /> },
    { name: "right", label: <Icon icon="faAlignRight" /> },
  ]

  const elementOptions = [
    { name: "header", label: "Header" },
    { name: "paragraph", label: "Paragraph" },
    { name: "list", label: "List" },
  ]

  const onElementKindChange = (kind: string) => {
    editor.setNodes(
      { details: { ...element.details, kind } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onAlignmentChange = (alignment: string) => {
    editor.setNodes(
      { details: { ...element.details, alignment } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onSourceChange = (source: UnionSourceTypes) => {
    editor.setNodes(
      { details: { ...element.details, [source]: !element.details[source] } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onPromptChange = (prompt: string) => {
    setPrompt(prompt)
    editor.setNodes(
      { details: { ...element.details, prompt } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onTextTypeChange = (length_type: string) => {
    editor.setNodes(
      { details: { ...element.details, length_type } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onMinTextLengthChange = (length_min: number) => {
    setLengthMin(length_min)
    editor.setNodes(
      { details: { ...element.details, length_min } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onMaxTextLengthChange = (length_max: number) => {
    setLengthMax(length_max)
    editor.setNodes(
      { details: { ...element.details, length_max } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onListStyleChange = (list_style: string) => {
    editor.setNodes(
      { details: { ...element.details, list_style } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onMinListItemsChange = (list_items_min: number) => {
    setListItemsMin(list_items_min)
    editor.setNodes(
      { details: { ...element.details, list_items_min } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onMaxListItemsChange = (list_items_max: number) => {
    setListItemsMax(list_items_max)
    editor.setNodes(
      { details: { ...element.details, list_items_max } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onListKindChange = (list_kind: string) => {
    const listOutputsParagraphs = list_kind === "feature_benefit" || list_kind === "objection" || list_kind === "bolded_summary_paragraph"
    editor.setNodes(
      { details: { ...element.details, list_kind, list_extra_spacing: !listOutputsParagraphs ? false : element.details.list_extra_spacing } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onPrimaryColorChange = (primary_color: string) => {
    editor.setNodes(
      { primary_color } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onToggleSlotResouce = (resourceId: string) => {
    const idIncluded = element.details.template_resource_slot_ids.includes(resourceId)
    const template_resource_slot_ids = idIncluded
      ? [...element.details.template_resource_slot_ids.filter(id => id !== resourceId)]
      : [...element.details.template_resource_slot_ids, resourceId]

    editor.setNodes(
      { details: { ...element.details, template_resource_slot_ids } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onListExtraSpacingChange = (list_extra_spacing: boolean) => {
    editor.setNodes(
      { details: { ...element.details, list_extra_spacing } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const showAlignmentOptions = element.details.kind !== "list" ||
    (element.details.kind === "list" && (element.details.list_kind === "feature_benefit" ||
      element.details.list_kind === "objection" ||
      element.details.list_kind === "bolded_summary_paragraph"));

  return (
    <div id="ai-text-setting-anchor">
      <div className="text-left">
        <SettingsHeader aiTextReady={aiTextReady} onActiveViewChange={onActiveViewChange} />
        <PropsSelector
          prop={element.details.kind}
          onPropChange={onElementKindChange}
          options={elementOptions}
          label="Element to Generate"
          wrapperClassName="mt-6 w-96"
        />
        <Prompt onPromptChange={onPromptChange} prompt={prompt} />
        {element.details.kind === "list"
          ?
          <ListOptions
            element={element}
            listItemsMin={listItemsMin}
            listItemsMax={listItemsMax}
            onListStyleChange={onListStyleChange}
            onMinListItemsChange={onMinListItemsChange}
            onMaxListItemsChange={onMaxListItemsChange}
            onListKindChange={onListKindChange}
            onListExtraSpacingChange={onListExtraSpacingChange}
          />
          :
          <>
            <h4 className="text-sm font-medium border-b border-gray-200 pb-1 text-gray-700 mt-6">
              Content Details
            </h4>
            <div className="text-gray-700 text-xs mt-2 mb-6">
              Control the length and alignment of the content generated
            </div>
          </>
        }
        <TextTypeAndLength
          element={element}
          length_min={lengthMin}
          length_max={lengthMax}
          onTypeChange={onTextTypeChange}
          onMinLengthChange={onMinTextLengthChange}
          onMaxLengthChange={onMaxTextLengthChange}
        />
        {showAlignmentOptions &&
          <PropsSelector
            prop={element.details.alignment}
            onPropChange={onAlignmentChange}
            options={alignmentOptions}
            label="Alignment"
            wrapperClassName="mt-6 w-64"
          />
        }
        <div className="mt-6 w-64">
          <div className="label"> Text Color </div>
          <ColorSelector color={element.primary_color as string} setColor={onPrimaryColorChange} />
        </div>
        <SourcesCheckbox element={element} onSourceChange={onSourceChange} />
        {isTemplate && <ResourcesCheckbox element={element} onToggleSlotResouce={onToggleSlotResouce} />}
        <div className="flex justify-end mt-8">
          <TooltipButton
            container="ai-text-setting-anchor"
            tooltip={aiTextReady ? '' : "Please fill in the missing fields"}
            side="top"
            title="Save details"
            onClick={onActiveViewChange.bind(onActiveViewChange, 'summary')}
            disabled={!aiTextReady}
            btnClassName="btn btn-primary"
          />
        </div>
      </div>
    </div>
  )
}

export default AiTextBlockSettings
