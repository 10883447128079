import React, { useEffect, useRef, useState } from "react";
import { Resizable } from "re-resizable";
import { useEditorRef } from "@udecode/plate-common/react";

import { ImageConfig, PlateImageElement, ProgramaticTypes } from "../../../../plate-config/Plugins/Image/Image.plugin";
import { useAppSelector } from "../../../../store/hooks/redux-hooks";

type Props = {
  element: PlateImageElement
  isPopoverOpen: boolean
  onImageSizeChange: (width: string) => void
  isReadOnly?: boolean
  goToLink?: string
  selected: boolean
}

const ImageResizableBox = ({ element, onImageSizeChange, isPopoverOpen, isReadOnly, goToLink, selected }: Props) => {
  const editor = useEditorRef()
  const { width, image_url, align, shape } = element
  const [size, setSize] = useState({ width });
  const parentRef = useRef<HTMLImageElement>(null)
  const [dynamicWidth, setDynamicWidth] = useState(0)
  // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
  // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
  const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
  const profileImage = useAppSelector(state => state.page_images.profile_image)
  const isTemplate = useAppSelector(state => state.page_addendums.template)

  useEffect(() => {
    if (parentRef.current?.offsetWidth) {
      setDynamicWidth(parentRef.current?.offsetWidth! * Number(size.width.slice(0, -1)) / 100)
    }
  }, [parentRef.current?.offsetWidth, size, editor])

  const getClipPath = (shape: string) => {
    switch (shape) {
      case 'square':
        return 'polygon(0% 0%, 100% 0%, 100% 100%, 0 100%)'
      case 'circle':
        return 'circle(50% at 50% 50%)'
      case 'ellipse':
        return 'ellipse(25% 40% at 50% 50%)'
      case 'parallelogram':
        return 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)'
      case 'triangle':
        return ' polygon(50% 0%, 0% 100%, 100% 100%)'
      case 'star':
        return 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)'
      default:
        return 'polygon(0% 0%, 100% 0%, 100% 100%, 0 100%)'
    }
  }

  const isProgrammatic = image_url === ProgramaticTypes.BRAND_LOGO || image_url === ProgramaticTypes.PROFILE_IMAGE
  const profileImageUrl = (isTemplate && isReadOnly || !isTemplate)
    ? profileImage ? profileImage : ImageConfig.profileImagePlaceholderUrl
    : ImageConfig.profileImagePlaceholderUrl
  const image = isProgrammatic
    ? image_url === ProgramaticTypes.BRAND_LOGO ? ImageConfig.brandImagePlaceholderUrl : profileImageUrl
    : image_url
  const imageClass = shape === 'square' ? 'rounded-lg object-cover object-center' : 'object-cover object-center'
  const dynamicImageStyle = shape === 'square' ? {} : { width: dynamicWidth, height: dynamicWidth }

  const isActive = !isReadOnly ? isPopoverOpen : false

  return (
    <div
      className={`
        flex image-wrapper  ${isPopoverOpen ? 'active' : ''} ${align === 'center' ? 'justify-center' : ''}
        ${align === 'right' ? 'justify-end' : ''} ${align === 'left' ? 'justify-start' : ''}
      `}
      ref={parentRef}
    >
      <Resizable
        defaultSize={{ width: size.width, height: '100%' }}
        onResize={(_e, _direction, ref) => setSize({ width: ref.style.width })}
        onResizeStop={(_e, _direction, ref) => onImageSizeChange(ref.style.width)}
        minWidth={'30%'}
        maxWidth={'100%'}
        className={`resizer-wrapper ${isActive && selected ? 'active' : ''}`}
        handleClasses={{
          top: 'pointer-events-none', right: 'pointer-events-none',
          bottom: 'pointer-events-none', left: 'pointer-events-none',
        }}
        handleComponent={{
          top: <div className="resizer_border top" />,
          bottom: <div className="resizer_border bottom" />,
          right: <div className="resizer_border right" />,
          left: <div className="resizer_border left" />,
          bottomRight: <div className="resizer_dot bottom-right" />,
          bottomLeft: <div className="resizer_dot bottom-left" />,
          topRight: <div className="resizer_dot top-right" />,
          topLeft: <div className="resizer_dot top-left" />,
        }}
        enable={isReadOnly ? false : selected ? undefined : false}
      >
        <a
          href={isReadOnly ? goToLink ? goToLink : undefined : undefined}
          {...(element.link_type === 'external' || element.link_type === 'resource') ? { target: "_blank", rel: "noopener noreferrer" } : { target: "_self" }}
        >
          <div style={{ clipPath: getClipPath(shape), ...dynamicImageStyle }}>
            <img src={image as string} alt="img" draggable={false} className={imageClass} style={{ width: '100%', height: '100%' }} />
          </div>
        </a>
      </Resizable>
    </div>
  )
}

export default ImageResizableBox
