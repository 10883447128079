import React, { useEffect, useState } from "react";
import { PlateElement, useEditorRef } from "@udecode/plate-common/react";
import { useSelected } from "slate-react";
import { findNode, getParentNode } from "@udecode/plate-common";

import { PlateElementEmbed } from "../../../plate-config/Plugins/Embed/Embed.plugin";
import { Popover, PopoverContent, PopoverTrigger, PopoverPortal } from "../../../shared/Popover/Popover"
import { EmbedConfig, EmbedPlatform } from "../config/config";
import EmbedViewController from "./EmbedViewController/EmbedViewController";
import ResizableEmbed from "./ResizableEmbed/ResizableEmbed";
import { ColumnPlugin } from "../../../plate-config/Plugins/ColumnGroup/Column.plugin";
import ToolbarButton from "../../../shared/ToolbarControls/ToolbarButton";
import ToolbarSeparator from "../../../shared/ToolbarControls/ToolbarSeparator";
import ToolbarSizeDropdown from "../../../shared/ToolbarControls/ToolbarSizeDropdown";
import ToolbarAlignDropdown from "../../../shared/ToolbarControls/ToolbarAlignDropdown";


type Props = {
  element: PlateElementEmbed
  ref: any,
  className?: string,
  isFirstChild: boolean,
  isLastChild: boolean,
  setEditMode: (mode: boolean) => void
  plateElProps: any
  children: React.ReactNode;
  isReadOnly: boolean;
  platform: EmbedConfig
}

const EmbedIframe: React.FC<Props> = ({
  ref, element, className, isFirstChild, isLastChild,
  plateElProps, children, setEditMode, isReadOnly, platform
}) => {
  const editor = useEditorRef();
  const selected = useSelected()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(selected)
  }, [selected])

  const isActive = !isReadOnly ? open : false
  const nonResizablePlatforms = [EmbedPlatform.Facebook, EmbedPlatform.Twitter, EmbedPlatform.TikTok]
  const isNonResizable = nonResizablePlatforms.includes(platform.name)
  const nodePath = findNode(editor, { at: [], match: { id: element.id } })![1];
  const parentNode = getParentNode(editor, nodePath)!
  const parentNodeType = parentNode[0].type as string
  const isElColumnChild = parentNodeType === ColumnPlugin.key
  const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false
  const currentSize = element.width_mode ? element.width_mode : 'large'
  const currentAlign = element.align ? element.align : 'left'

  const onAlignChange = (align: string) =>
    editor.setNodes(
      { align } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === element.id } }
    )

  const onEditClick = () => setEditMode(true)

  const onElementDelete = () => editor.removeNodes({ at: [], match: (n: any) => n.id === element.id })

  const onSizeChange = (width_mode: string) =>
    editor.setNodes({ width_mode } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })


  return (
    <PlateElement
      ref={ref}
      id={element.id}
      editor={editor}
      data-id={element.id}
      element={element}
      className={`
        ${className} p-2 rounded-lg transition-all duration-300 ease-in-out
         ${isElColumnChild
          ? isLastColElement ? "mb-0" : "mb-4"
          : `page-block embed-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
        }
         ${!isReadOnly
          ? `${selected
            ? 'border-blue-500/60 border hover:border-blue-500/60'
            : 'hover:border-blue-500/30 border border-transparent'}`
          : ''
        } 
        `}
      data-plate-selectable
      contentEditable={false}
      suppressContentEditableWarning
      {...plateElProps}
    >
      <Popover open={isActive} onOpenChange={setOpen}>
        <PopoverTrigger className="w-full block">
          <div
            className={`flex
          ${element.align === 'left' ? 'justify-start' : ''}
          ${element.align === 'center' ? 'justify-center' : ''}   
          ${element.align === 'right' ? 'justify-end' : ''}
          `}>
            {
              isNonResizable
                ?
                <div className={`rounded-xl overflow-hidden`}>
                  <EmbedViewController platform={platform} element={element} />
                </div>
                :
                <ResizableEmbed element={element} platform={platform} isActive={isActive} />
            }
            {children}
          </div>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent
            side="top"
            align="center"
            className="block_toolbar"
          >
            <ToolbarAlignDropdown onAlignChange={onAlignChange} currentAlign={currentAlign} />
            <ToolbarSeparator />
            {platform.widthMode &&
              <>
                <ToolbarSizeDropdown onSizeChange={onSizeChange} currentSize={currentSize} />
                <ToolbarSeparator />
              </>
            }
            <ToolbarButton
              onClick={onEditClick}
              iconName='pencilEdit'
            />
            <ToolbarSeparator />
            <ToolbarButton
              onClick={onElementDelete}
              iconName='delete'
              type="delete"
            />
          </PopoverContent>
        </PopoverPortal>
      </Popover>
      {children}
    </PlateElement>
  )
}

export default EmbedIframe