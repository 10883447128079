import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["clamp", "text", "button"]

  connect() {
    if (this.textTarget.offsetHeight < 40) {
      this.buttonTarget.classList.add("hidden")
    }
  }

  toggle(e) {
    e.preventDefault()
    this.clampTarget.classList.add("line-clamp-none")
    this.buttonTarget.classList.add("hidden")
  }
}
