import React, { memo } from 'react';
import * as Switch from '@radix-ui/react-switch';

type Props = {
  value: boolean,
  setValue: (value: boolean) => void,
  label: string,
  wrapperClass?: string,
}

const SwitchInput = memo(({ value, setValue, label, wrapperClass }: Props) => {
  return (
    <div className={`flex items-center justify-between ${wrapperClass}`}>
      <span className="text-gray-600 text-sm font-semibold">
        {label}
      </span>
      <Switch.Root
        className="SwitchRoot"
        checked={value}
        onCheckedChange={setValue}
      >
        <Switch.Thumb className="SwitchThumb" />
      </Switch.Root>
    </div>
  )
})

export default SwitchInput;
