import React from 'react'

type Props = {
  text: string;
  images: any[];
  type: string;
  setShowBrandImages: (type: string) => void;
}

const ImagePickerMediaBrandFolder = ({
  text,
  images,
  type,
  setShowBrandImages,
}: Props) => {

  return (
    <div className="basis-1/2 pr-4 pb-4 md:basis-1/3 lg:basis-1/4">
      <div
        className="relative image-card bg-cover bg-center rounded-xl transition ease-in-out duration-500 border border-gray-300"
        onClick={setShowBrandImages.bind(setShowBrandImages, type)}
        style={{ backgroundImage: `url(${images[0].url})` }}
      >
        <div className="rounded-xl absolute inset-0 w-full h-full opacity-0 cursor-pointer transition-all ease-in-out duration-500 hover:bg-gray-800 hover:bg-opacity-50 hover:opacity-100">
          <div className="flex items-center justify-center w-full h-full">
            <div className="btn btn-white btn-small shadow-lg transition-all ease-in-out duration-500 with-hover-ring">
              See Images
            </div>
          </div>
        </div>
        <div className="rounded-b-xl absolute bottom-0 px-4 py-2 bg-white bg-opacity-50 w-full">
          <h3 className="text-xl font-bold text-gray-900">
            {text}
          </h3>
        </div>
      </div>
    </div>
  )
}

export default ImagePickerMediaBrandFolder
