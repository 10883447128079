import React, { useState } from "react"
import { findNode, setSelection } from "@udecode/plate-common"
import { useEditorRef } from "@udecode/plate-common/react";

import NameAndPositionMenu from "./NameAndPositionMenu/NameAndPositionMenu"
import StyleMenu from "./StyleMenu/StyleMenu"
import Warnings from "./Warnings/Warnings"
import { PlateSectionElement } from "../../../plate-config/Plugins/Section/Section.plugin"
import TooltipIcon from "../../../shared/ToolTip/TooltipIcon"
import ImagePicker from "../../../shared/ImagePicker/ImagePicker"


type Props = {
  sectionElement: PlateSectionElement;
}

const SectionMenu = ({ sectionElement }: Props) => {
  const editor = useEditorRef()
  const [showImagePicker, setShowImagePicker] = useState(false)
  const [imageTooltipOpen, setImageTooltipOpen] = useState(false)

  const node = findNode(editor, { at: [], match: (n: any) => n.id === sectionElement.id })!

  const onBackgroundImageChange = (background_image_url: string, image_source: string, image_external_id: string) => {
    editor.setNodes(
      { background_image_url, image_source, image_external_id } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    // NOTE: It will cause an error but still will move focus to current section. Such behaviour is expected 
    // because interaction with image picker modal steals focus from the editor and it's not possible to focus it back.
    // TODO: Fix the error
    setSelection(editor, { anchor: { path: node[1], offset: 0 }, focus: { path: node[1], offset: 0 } })
  }
  const onImageUnset = () => {
    editor.setNodes(
      { background_image_url: null, image_source: null, image_external_id: null } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    // NOTE: It will cause an error but still will move focus to current section. Such behaviour is expected 
    // because interaction with image picker modal steals focus from the editor and it's not possible to focus it back.
    // TODO: Fix the error
    setSelection(editor, { anchor: { path: node[1], offset: 0 }, focus: { path: node[1], offset: 0 } })
  }

  const onImagePickerClose = () => {
    setShowImagePicker(false)
    // NOTE: It will cause an error but still will move focus to current section. Such behaviour is expected 
    // because interaction with image picker modal steals focus from the editor and it's not possible to focus it back.
    // TODO: Fix the error
    setSelection(editor, { anchor: { path: node[1], offset: 0 }, focus: { path: node[1], offset: 0 } })
  }

  const isWarningShown =
    !!sectionElement.tint_color &&
    sectionElement.tint_opacity === 100 &&
    sectionElement.tint_kind === "regular" &&
    !!sectionElement.background_image_url

  return (
    <div contentEditable={false} suppressContentEditableWarning className="flex gap-4 left self-start">
      <div className="btn-group">
        <StyleMenu sectionElement={sectionElement} />
        <TooltipIcon
          tooltip="Background Image"
          side="bottom"
          icon="faImage"
          container={sectionElement.id as string}
          tooltipOpen={imageTooltipOpen}
          onClick={setShowImagePicker.bind(setShowImagePicker, true)}
          onMouseEnter={setImageTooltipOpen.bind(setImageTooltipOpen, true)}
          onMouseLeave={setImageTooltipOpen.bind(setImageTooltipOpen, false)}
          iconWrapperClassName="btn btn-small btn-white"
        />
        <NameAndPositionMenu sectionElement={sectionElement} />
      </div>
      <ImagePicker
        backgroundImageSource={sectionElement.image_source}
        currentImageUrl={sectionElement.background_image_url}
        modalOpen={showImagePicker}
        onImageChange={onBackgroundImageChange}
        onImageUnset={onImageUnset}
        onModalClose={onImagePickerClose}
      />
      {isWarningShown && <Warnings sectionElement={sectionElement} />}
    </div>
  )
}

export default SectionMenu