import React, { useEffect, useState } from "react";
import { useEditorRef } from "@udecode/plate-common/react";

import { Popover, PopoverContent, PopoverTrigger } from "../../../../shared/Popover/Popover";
import HugeIcon from '../../../../shared/Icon/HugeIcon';
import ToolbarButton from '../../../../shared/ToolbarControls/ToolbarButton';
import ToolbarSeparator from '../../../../shared/ToolbarControls/ToolbarSeparator';
import ImagePicker from "../../../../shared/ImagePicker/ImagePicker";
import { PlateColumnElement } from "../../../../plate-config/Plugins/ColumnGroup/Column.plugin";
import ToolbarColorDropdown from "../../../../shared/ToolbarControls/ToolbarColorDropdown";
import ToolbarAlignDropdown from "../../../../shared/ToolbarControls/ToolbarAlignDropdown";

type Props = {
  colIndex: number;
  element: PlateColumnElement
  onDeleteColumn: () => void;
  onAlignChange: (value: string) => void;
  onBgColorChange: (value: string) => void;
  onBgOpacityChange: (value: number) => void;
  setImageFromPicker: (image_url: string, image_source: string, image_external_id: string) => void;
  onImageUnset: () => void;
  onBgColorReset: () => void;
}

const ColumnToolbar: React.FC<Props> = ({
  colIndex, element, onDeleteColumn, onAlignChange, onBgColorChange,
  onBgOpacityChange, setImageFromPicker, onImageUnset, onBgColorReset
}) => {
  const editor = useEditorRef()
  const [open, setOpen] = useState(false)
  const [showImagePicker, setShowImagePicker] = useState(false)

  useEffect(() => {
    editor.setNodes({ menuOpen: open } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
  }, [open])

  const preventDefault = (e: Event) => e.preventDefault();

  const align = element.align ?? 'top'

  return (
    <>
      <Popover open={open} onOpenChange={setOpen} >
        <PopoverTrigger className='absolute top-[-8px] left-1/2 translate-x-[-50%] z-[2]'>
          <div
            contentEditable={false}
            suppressContentEditableWarning={true}
            className='bg-white rounded p-1 w-6 h-4 flex items-center justify-center shadow-md border border-gray-50 cursor-pointer'
            onClick={setOpen.bind(setOpen, !open)}
          >
            <HugeIcon name="moreHorizontal" size={24} color={'black'} />
          </div>
        </PopoverTrigger>
        <PopoverContent
          onOpenAutoFocus={preventDefault}
          onCloseAutoFocus={preventDefault}
          side='top'
          sideOffset={6}
          align='center'
          className="block_toolbar"
        >
          <ToolbarAlignDropdown onAlignChange={onAlignChange} currentAlign={align} variant="vertical" />
          <ToolbarSeparator />
          <ToolbarButton
            onClick={setShowImagePicker.bind(setShowImagePicker, true)}
            iconName='image'
          />
          <ToolbarColorDropdown
            color={element.bg_color}
            opacity={element.bg_opacity}
            onColorChange={onBgColorChange}
            onOpacityChange={onBgOpacityChange}
            colorLabel="Background Color"
            onColorReset={onBgColorReset}
          />
          <ToolbarSeparator />
          <ToolbarButton
            onClick={onDeleteColumn}
            iconName='delete'
            disabled={colIndex === 0 || colIndex === 1}
            type="delete"
          />
        </PopoverContent>
      </Popover>
      <ImagePicker
        backgroundImageSource={element.image_source as string}
        currentImageUrl={element.image_url as string}
        modalOpen={showImagePicker}
        onImageChange={setImageFromPicker}
        onImageUnset={onImageUnset}
        onModalClose={setShowImagePicker.bind(setShowImagePicker, false)}
        brandImages={false}
      />
    </>
  )
}

export default ColumnToolbar;