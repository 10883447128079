import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Feedback } from '../api/api.types'


const initialState: Feedback[] = []

export const pageCommentsSlice = createSlice({
  name: 'page_comments',
  initialState,
  reducers: {
    // TODO: Update when API will be ready
    setPageComments: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload
      }
    },
  },
})

export const { setPageComments } = pageCommentsSlice.actions

export default pageCommentsSlice.reducer