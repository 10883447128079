import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "inUse", "available", "inUseLabel", "availableLabel", "error", "confirmButton"];

  connect() {
    this.updateSeatsInUse();
    this.updateSeatsAvailable();
  }

  changeSeats(event) {
    event.preventDefault();

    const change = parseInt(event.currentTarget.dataset.change);
    let newValue = parseInt(this.inputTarget.value) + change;
    const occupiedSeats = parseInt(this.inUseTarget.textContent);

    if (newValue < occupiedSeats) {
      newValue = occupiedSeats;
    }

    this.inputTarget.value = newValue;
    this.errorTarget.classList.add("hidden");
    this.updateSeatsAvailable();
  }

  validateInput(event) {
    const inputValue = parseInt(this.inputTarget.value);
    const occupiedSeats = parseInt(this.inUseTarget.textContent);

    if (inputValue < occupiedSeats) {
      this.errorTarget.classList.remove("hidden");
      this.inputTarget.value = occupiedSeats;
      this.updateSeatsAvailable();
    } else {
      this.errorTarget.classList.add("hidden");
    }
  }

  updateSeatsInUse() {
    const occupiedSeats = parseInt(this.data.get("occupied"));
    this.inUseTarget.textContent = occupiedSeats;
    this.updatePluralization(occupiedSeats, this.inUseLabelTarget);
  }

  updateSeatsAvailable() {
    const totalSeats = parseInt(this.inputTarget.value);
    const occupiedSeats = parseInt(this.inUseTarget.textContent);
    const availableSeats = totalSeats - occupiedSeats;
    this.availableTarget.textContent = availableSeats;
    this.updatePluralization(availableSeats, this.availableLabelTarget);
    this.updateSubscriptionButtonHref(totalSeats);
  }

  updatePluralization(value, labelTarget) {
    labelTarget.textContent = value === 1 ? "seat" : "seats";
  }

  updateSubscriptionButtonHref(totalSeats) {
    const baseUrl = this.confirmButtonTarget.getAttribute("data-base-url");
    this.confirmButtonTarget.href = `${baseUrl}&quantity=${totalSeats}`;
  }
}
