export interface PageDataAttributes {
  base_url: string | null;
  hide_branding: string | null;
  page_id: string | null;
  data_line_item_variable_id: string | null;
  room_id: string | null;
  data_slider_variable_id: string | null;
  csrf_token: string | null;
}


export const getPageDataAttributes = (): PageDataAttributes | null => {
  const pageData = document.getElementById("react");
  const tokenData = document.querySelector("meta[name='csrf-token']");

  if (!pageData || !tokenData) {
    return null;
  } else {
    return {
      base_url: pageData.getAttribute('data-base-url'),
      hide_branding: pageData.getAttribute('data-hide-branding'),
      page_id: pageData.getAttribute('data-id'),
      data_line_item_variable_id: pageData.getAttribute('data-line-item-variable-id'),
      room_id: pageData.getAttribute('data-room-id'),
      data_slider_variable_id: pageData.getAttribute('data-slider-variable-id'),
      csrf_token: tokenData.getAttribute('content')
    }
  }
}
