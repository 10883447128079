import { TElement } from "@udecode/plate-common"
import { createPlatePlugin } from "@udecode/plate-common/react";
import { v4 as uuidv4 } from 'uuid';

// CRITICAL NOTE: In case of removing this and pricing table plugin, also remove shadow store related to variables!

export const AssessmentPlugin = createPlatePlugin({
  key: 'assessment',
  node: {
    type: 'assessment',
    isElement: true,
    isVoid: true,
  },
})

export interface PlateAssessmentElement extends TElement {
  id: string,
  type: typeof AssessmentPlugin.key,
  primary_color: string,
  secondary_color: string,
  image_url: null | string,
  image_source: null | string,
  image_external_id: null | string
  assessment_detail_attributes: {
    id: string,
    background_blur: number,
    tint_opacity: number,
    headline: string | null,
    slider_color: string,
    slider_variable_id: string,
    stat_prefix: string | null,
    stat_suffix: string | null,
    hide_price: boolean,
    hide_value: boolean,
    roi_locked: boolean,
    roi_locked_button_color: string | null,
    roi_locked_button_text: string | null,
    roi_locked_button_text_color: string | null,
    roi_locked_label: string | null,
    roi_locked_message: string | null,
    roi_locked_variable_id: string | null,
    stat_formula_attributes: {
      calculation: string,
    },
    value_formula_attributes: {
      calculation: string,
      notes: string,
    },
    price_formula_attributes: {
      calculation: string,
      notes: string,
    },
  },
}


export const createDefaultAssessmentElement = (slider_variable_id: string): TElement => ({
  type: AssessmentPlugin.key,
  primary_color: '#ffffff',
  secondary_color: 'black',
  image_url: null,
  image_source: null,
  image_external_id: null,
  assessment_detail_attributes: {
    id: uuidv4(),
    background_blur: 0,
    tint_opacity: 50,
    headline: null,
    slider_color: '#0d1e26',
    slider_variable_id,
    stat_prefix: null,
    stat_suffix: null,
    hide_price: false,
    hide_value: false,
    roi_locked: false,
    roi_locked_button_color: null,
    roi_locked_button_text: null,
    roi_locked_button_text_color: null,
    roi_locked_label: null,
    roi_locked_message: null,
    roi_locked_variable_id: null,
    stat_formula_attributes: {
      calculation: '{{A}} * 10',
    },
    value_formula_attributes: {
      calculation: '{{A}} * 500',
      notes: null,
    },
    price_formula_attributes: {
      calculation: '{{A}} * 50',
      notes: null,
    },
  },
  children: [{ text: '' }]
})
