import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { ColorData, PageData } from '../api/api.types'
import { setAvailablePalettes } from './page_color.config'

export interface PagePalette {
  palette: string,
  label: string,
  colors: string[],
  uniqueAccountColors?: boolean
  colorData?: ColorData[]
}

export interface PageStoreColors {
  activePalette: PagePalette,
  availablePalettes: PagePalette[],
}

const initialState: PageStoreColors = {
  activePalette: { palette: 'custom', label: 'Custom', colors: [] },
  availablePalettes: [],
}

export const pageColorsSlice = createSlice({
  name: 'page_colors',
  initialState,
  reducers: {
    setPageColors: (state, action: PayloadAction<PageData>) => {
      const palette = action.payload.styles.palette || state.activePalette.palette;
      const availablePalettes = setAvailablePalettes(action.payload);
      const activePalette = availablePalettes.find(p => p.palette === palette) || state.activePalette
      return {
        activePalette,
        availablePalettes
      }
    },
    setActivePalette: (state, action: PayloadAction<string>) => {
      const activePalette = state.availablePalettes.find(p => p.palette === action.payload);
      return {
        ...state,
        activePalette: activePalette!
      }

    },
    setCustomColors: (state, action: PayloadAction<{ colorData: ColorData[] }>) => {
      const availablePalettes = state.availablePalettes.map(palette => {
        if (palette.palette === 'custom') {
          return {
            ...palette,
            colorData: [...action.payload.colorData],
            colors: action.payload.colorData.map(color => color.hex)

          };
        }
        return palette;
      })
      const activePalette = availablePalettes.find(p => p.palette === 'custom')!
      return {
        ...state,
        availablePalettes,
        activePalette
      }
    },
  }
})

export const { setPageColors, setActivePalette, setCustomColors } = pageColorsSlice.actions

export default pageColorsSlice.reducer