import React from "react";
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { SummaryCalculationResult } from "../../../../utils/calc_plugins.util";


type Props = {
  textColor: string
  summaryData: SummaryCalculationResult
  isValueHidden?: boolean
}

const Body: React.FC<Props> = ({ textColor, summaryData, isValueHidden }) => {
  const oneTimePaymentData = summaryData.recurringBillingGroups.find(group => group.perDisplay === '')
  const recurringPaymentData = summaryData.recurringBillingGroups.filter(group => group.perDisplay !== '')

  return (
    <TableBody>
      {oneTimePaymentData && oneTimePaymentData?.price > 0 && (
        <TableRow >
          <TableCell colSpan={5} sx={{ padding: 0, borderBottom: 'unset' }}>
            <div className={`paragraph-font-family text-base text-left py-3 text-${textColor}`}>
              Total One Time Payment
            </div>
          </TableCell>
          <TableCell colSpan={2} sx={{ padding: 0, borderBottom: 'unset' }}>
            <div className={`paragraph-font-family text-base text-right py-3 text-${textColor}`}>{oneTimePaymentData.fullPriceDisplay}</div>
          </TableCell>
          {!isValueHidden && <TableCell colSpan={2} sx={{ padding: 0, borderBottom: 'unset' }}>
            <div className={`paragraph-font-family text-base text-right py-3 text-${textColor}`}>{oneTimePaymentData.fullValueDisplay}</div>
          </TableCell>}
        </TableRow>
      )}
      {recurringPaymentData.map((group, index) => {
        return (
          <TableRow key={index}>
            <TableCell colSpan={5} sx={{ padding: 0, borderBottom: 'unset' }}>
              {index === 0 && <div className={`paragraph-font-family text-base text-left py-3 text-${textColor}`}>
                Total Recurring Payments
              </div>}
            </TableCell>
            <TableCell colSpan={2} sx={{ padding: 0, borderBottom: 'unset' }}>
              <div className={`paragraph-font-family text-base text-right py-3 text-${textColor}`}>{group.displayPrice}
                <span className="text-sm ml-1"> / {group.perDisplay}</span>
              </div>
            </TableCell>
            {!isValueHidden && <TableCell colSpan={2} sx={{ padding: 0, borderBottom: 'unset' }}>
              <div className={`paragraph-font-family text-base text-right py-3 text-${textColor}`}>{group.displayValue}
                <span className="text-sm ml-1"> / {group.perDisplay}</span>
              </div>
            </TableCell>}
          </TableRow>
        )
      })}
    </TableBody>
  )
}

export default Body