import React from "react";
import { findNode } from "@udecode/plate-common";
import { useEditorRef } from "@udecode/plate-common/react";

import { Popover, PopoverTrigger, PopoverContent } from "../../../../../shared/Popover/Popover"
import PopoverSelectorTitle from "../../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"
import ColorSelector from "../../../../../shared/ColorSelector/ColorSelector"
import TooltipIcon from "../../../../../shared/ToolTip/TooltipIcon";
import TooltipButton from "../../../../../shared/ToolTip/TooltipButton";
import { PlatePricingElement, PricingTable } from "../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import { PlateSectionElement } from "../../../../../plate-config/Plugins/Section/Section.plugin";

type Props = {
  tableIndex: number
  element: PlatePricingElement
}

const Toolbar: React.FC<Props> = ({ tableIndex, element }) => {
  const editor = useEditorRef()

  const node = findNode(editor, { at: [], match: { id: element.id } })!
  const section = editor.children[node[1][0]] as PlateSectionElement
  const isWideContent = section.width === 'large'

  const onPrimaryColorChange = (primary_color: string) => {
    editor.setNodes(
      { primary_color } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onSecondaryColorHandle = (secondary_color: string) => {
    editor.setNodes(
      { secondary_color } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onWidthModeHandle = () =>
    editor.setNodes({ style: element.style ? null : 'wide' } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })


  const onTableDeleteHandle = () => {
    if (window.confirm('Are you sure you would like to delete this table section?')) {
      const pricing_tables_attributes = element.pricing_tables_attributes.filter((_table, i) => i !== tableIndex)
      editor.setNodes(
        { pricing_tables_attributes } as Partial<Node>,
        { at: [], match: (n: any) => n.id === element.id }
      )
    }
  }

  const onTableCopyHandle = () => {
    const pricing_tables_attributes = [...element.pricing_tables_attributes]
    pricing_tables_attributes.splice(tableIndex + 1, 0, element.pricing_tables_attributes[tableIndex])
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onTableMoveUpHandle = () => {
    const pricing_tables_attributes = [...element.pricing_tables_attributes] as PricingTable[]
    [pricing_tables_attributes[tableIndex], pricing_tables_attributes[tableIndex - 1]] = [pricing_tables_attributes[tableIndex - 1], pricing_tables_attributes[tableIndex]]
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onTableMoveDownHandle = () => {
    const pricing_tables_attributes = [...element.pricing_tables_attributes] as PricingTable[]
    [pricing_tables_attributes[tableIndex], pricing_tables_attributes[tableIndex + 1]] = [pricing_tables_attributes[tableIndex + 1], pricing_tables_attributes[tableIndex]]
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  return (
    <div className="btn-group">
      <Popover>
        <PopoverTrigger className="btn btn-small btn-white w-10 centered bg-white">
          <TooltipIcon tooltip="Colors" side="top" container={element.id} icon="faPalette" />
        </PopoverTrigger>
        <PopoverContent sideOffset={0} side="right" align="center">
          <div className="w-72 border-solid border border-gray-200 rounded-xl bg-white drop-shadow z-30 p-4">
            <PopoverSelectorTitle title="Table Color" />
            <ColorSelector color={element.primary_color} setColor={onPrimaryColorChange} />
            <PopoverSelectorTitle title="Summary Color" className="pt-6" />
            <ColorSelector color={element.secondary_color} setColor={onSecondaryColorHandle} />
          </div>
        </PopoverContent>
      </Popover>
      <TooltipButton
        tooltip="Move up"
        side="top"
        container={element.id}
        icon="faArrowUp"
        onClick={onTableMoveUpHandle}
        disabled={tableIndex === 0}
        btnClassName="btn btn-small btn-white w-10 centered"
      />
      <TooltipButton
        tooltip="Move down"
        side="top"
        container={element.id}
        icon="faArrowDown"
        onClick={onTableMoveDownHandle}
        disabled={!(element.pricing_tables_attributes.length > tableIndex + 1)}
        btnClassName="btn btn-small btn-white w-10 centered"
      />
      <TooltipButton
        tooltip="Duplicate"
        side="top"
        container={element.id}
        icon="faCopy"
        onClick={onTableCopyHandle}
        btnClassName="btn btn-small btn-white w-10 centered"
      />
      <TooltipButton
        tooltip={element.style === 'wide' ? "Adjustable mode" : "Wide mode"}
        side="top"
        container={element.id}
        icon={element.style === 'wide' ? "faMinimize" : "faUpRightAndDownLeftFromCenter"}
        onClick={onWidthModeHandle}
        btnClassName="btn btn-small btn-white w-10 centered"
        disabled={isWideContent}
      />
      <TooltipButton
        tooltip="Delete"
        side="top"
        container={element.id}
        icon="faTrashCan"
        onClick={onTableDeleteHandle}
        btnClassName="btn btn-small btn-white w-10 centered last-child"
      />
    </div>
  )
}

export default Toolbar