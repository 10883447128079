import { Controller } from "@hotwired/stimulus"
import anime from 'animejs/lib/anime.es.js';

export default class extends Controller {
  static targets = ["content", "hidable"]
  static values = { toggled: Boolean }

  connect() {
    this.toggledValue = false
  }

  toggle(e) {
    e.preventDefault()
    if (this.toggledValue) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    this.toggledValue = true

    // use anime to slide in the content
    this.contentTarget.style.display = "block";
    const contentHeight = this.contentTarget.clientHeight;
    this.contentTarget.style.height = 0;
    this.contentTarget.style.visibility = "visible";

    // Do not add this back in. It was causing wacky animation behavior.
    // But without it the ring isn't showing correctly
    // this.contentTarget.classList.remove("overflow-hidden")

    anime({
      targets: this.contentTarget,
      keyframes: [
        { height: 0 },
        { height: `${contentHeight}px` }
      ],
      duration: 500,
      easing: 'easeInSine',
      complete: () => {
        this.contentTarget.classList.remove("overflow-hidden")
      }
    });

    this.hidableTargets.forEach((element) => {
      element.classList.add("hidden")
    })
  }

  hide() {
    this.contentTarget.classList.add("hidden")
    this.contentTarget.classList.add("overflow-hidden")
    this.contentTarget.style.height = "0";
    this.contentTarget.style.opacity = "0";
    this.toggledValue = false
  }
}

// const slideToggle = (e) => {
//   const $subMenu = e.currentTarget.nextElementSibling;
//   if (!e.currentTarget.classList.contains("is-active")) {
//     e.currentTarget.classList.add("is-active");
//     $subMenu.style.display = "block";
//     const contentHeight = $subMenu.clientHeight;
//     $subMenu.style.height = 0;
//     $subMenu.style.visibility = "visible";
//     anime({
//       targets: $subMenu,
//       keyframes: [
//         { height: 0 },
//         { height: `${contentHeight}px` }
//       ],
//       duration: 500,
//       easing: 'easeInSine',
//     });
//   } else {
//     e.currentTarget.classList.remove("is-active");
//     const contentHeight = $subMenu.clientHeight;
//     anime({
//       targets: $subMenu,
//       keyframes: [
//         { height: `${contentHeight}px` },
//         { height: 0 }
//       ],
//       duration: 500,
//       easing: 'easeInSine',
//       complete: () => {
//         $subMenu.style.cssText = null;
//       }
//     });
//   }
// }
// const $buttons = document.querySelectorAll(".button");
// $buttons.forEach($button => {
//   $button.addEventListener("click", slideToggle);
// });
