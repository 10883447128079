import React, { useState } from "react";

import Icon from "../../../../../shared/Icon/Icon";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../../shared/Popover/Popover";
import { PagePalette } from "../../../../../store/reducers/page_colors/page_colors";
import ColorPaletteWarning from "../ColorPaletteWarning/ColorPaletteWarning";
import { useUpdateCustomColorsMutation } from "../../../../../store/reducers/api/colors/colors.api";
import { HEX_SELECTOR_INIT_COLORS, prepareColorAttributesForInsert } from "../../../../../store/reducers/page_colors/page_color.config";
import HexColorPicker from "../HexColorPicker/HexColorPicker";
import PopoverSelectorTitle from "../../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle";
import { textColorFromHex } from "../../../../../utils/color.util";
import IconButton from "../../../../../shared/IconButton/IconButton";

type Props = {
  activePalette: PagePalette,
}

const AddColorPopover = ({ activePalette }: Props) => {

  const [popoverOpen, setPopoverOpen] = useState(false)
  const [isColorSelectOpen, setIsColorSelectOpen] = useState(false)
  const [updateCustomColors] = useUpdateCustomColorsMutation();

  const initialColors = HEX_SELECTOR_INIT_COLORS.filter(color => !activePalette.colors.includes(color))

  //  TODO: ADD LOADING AND ERROR HANDLING
  const onColorPaletteChange = async (color: string) => {
    setIsColorSelectOpen(false)
    setPopoverOpen(false)
    await updateCustomColors(prepareColorAttributesForInsert(color, activePalette.colorData || []))
  }

  return (
    <Popover
      key="new-color-popover"
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
    >
      <PopoverTrigger>
        <div
          className={`
           h-14 w-14 rounded-full border-2 border-dashed border-gray-300 hover:border-solid
            hover:bg-gray-100 hover:ring hover:ring-gray-200 hover:border bg-white text-black
           `}
        >
          <Icon icon='faPlus' wrapperClassName="w-full h-full flex justify-center items-center" />
        </div>
      </PopoverTrigger>
      <PopoverContent sideOffset={10} side="bottom" align="center">
        {activePalette.palette === "custom"
          ?
          <div className="w-19em border-solid border border-gray-200 rounded-xl bg-white drop-shadow z-30 popover-container">
            <div className="p-4">
              {isColorSelectOpen
                ? (
                  <HexColorPicker
                    initialColor={null}
                    handleUpdateColor={onColorPaletteChange}
                    goBackHandle={setIsColorSelectOpen.bind(setIsColorSelectOpen, false)}
                  />
                )
                : (
                  <>
                    <div className="flex justify-between">
                      <PopoverSelectorTitle title="Add color" />
                    </div>
                    <div className="w-full">
                      <div className="flex flex-wrap text-center gap-2 mt-1">
                        {initialColors.map((color, index) =>
                          <button
                            key={index}
                            onClick={onColorPaletteChange.bind(onColorPaletteChange, color)}
                            className="h-10 w-10 rounded-full border border-gray-300 hover:ring-gray-200 hover:ring"
                            style={{ backgroundColor: color, color: textColorFromHex(color) }}
                          />
                        )}
                        <IconButton
                          btnClassName="btn h-10 w-10 p-0 flex justify-center rounded-full border border-gray-300 hover:ring-gray-200 hover:ring text-gray-700 background-gray-200"
                          icon="faPaintRoller"
                          onClick={setIsColorSelectOpen.bind(setIsColorSelectOpen, true)}
                        />
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
          :
          <ColorPaletteWarning />
        }
      </PopoverContent>
    </Popover>
  )
}

export default AddColorPopover;