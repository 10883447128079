import React from 'react'

import NavBarLogo from './NavBarLogo/NavBarLogo'
import { useAppSelector } from '../store/hooks/redux-hooks'
import { getColorHexFromClass, lightOrDarkColor } from '../utils/color.util'
import {
  useUpdateNavDisplayLogoAttributeMutation,
  useUpdateNavVisibilityAttributeMutation,
  useUpdateNavBgColorAttributeMutation,
  useUpdateNavTextColorAttributeMutation
} from '../store/reducers/api/navbar/navbar.api'
import NavBar from './NavBar/NavBar'
import NavBarMobile from './NavBarMobile/NavBarMobile'


const NavigationBar = () => {
  const { activePalette } = useAppSelector(state => state.page_colors)
  const navPageConfig = useAppSelector(state => state.page_navigation)
  const isMobilePreview = useAppSelector(state => state.page_addendums.isMobilePreviewActive)
  const isEditorReadOnly = useAppSelector(state => state.page_addendums.readOnly)
  const isLandingPage = useAppSelector(state => state.page_addendums.isLandingPage)

  const [updateNavDisplayLogoAttribute] = useUpdateNavDisplayLogoAttributeMutation()
  const [updateNavVisibilityAttribute] = useUpdateNavVisibilityAttributeMutation()
  const [updateNavBgColorAttribute] = useUpdateNavBgColorAttributeMutation()
  const [updateNavTextColorAttribute] = useUpdateNavTextColorAttributeMutation()

  const { nav_enabled, nav_background_color } = navPageConfig

  const backgroundColorHex = getColorHexFromClass(nav_background_color, activePalette.colors);
  const backgroundColorClassification = lightOrDarkColor(backgroundColorHex);
  const containerClasses = nav_enabled
    ? `landing-page-nav fixed p-4 ${isMobilePreview ? "rounded-t-lg" : ''} w-96 flex z-20 background-${nav_background_color} ${backgroundColorClassification}-background`
    : `landing-page-nav fixed p-4 ${isMobilePreview ? "rounded-t-lg" : ''} w-96 flex z-20 background-color-transparent`


  // TODO: ADD LOADING AND ERROR HANDLING
  const onBackgroundColorChange = async (nav_background_color: string) => await updateNavBgColorAttribute(nav_background_color)
  const onTextColorChange = async (nav_text_color: string) => await updateNavTextColorAttribute(nav_text_color)
  const onLogoVisibleChange = async (nav_display_logo: boolean) => await updateNavDisplayLogoAttribute(nav_display_logo)
  const onNavbarVisibilityChange = async (nav_enabled: boolean) => await updateNavVisibilityAttribute(nav_enabled)

  if ((isLandingPage || isEditorReadOnly) && !nav_enabled) {
    return null
  }

  return (
    <section id="tooltip-anchor-navBar" className={`${containerClasses} ${!isMobilePreview ? 'w-full' : ''} ${isMobilePreview ? 'flex-col' : ''}`} >
      <div className="flex justify-between items-center w-full">
        <NavBarLogo navPageConfig={navPageConfig} />
        {isMobilePreview
          ?
          <NavBarMobile />
          :
          <NavBar
            onBackgroundColorChange={onBackgroundColorChange}
            onTextColorChange={onTextColorChange}
            onLogoVisibleChange={onLogoVisibleChange}
            onNavbarVisibilityChange={onNavbarVisibilityChange}
          />}
      </div>
    </section>
  )
}

export default NavigationBar

