import React, { memo } from "react";

type Props = {
  isMobilePreviewActive: boolean,
  message: string
}

const SaveDataPopup = memo(({ isMobilePreviewActive, message }: Props) => {
  return (
    <div className={`fixed ${isMobilePreviewActive ? "top-[10rem]" : "top-[9rem]"} right-4 z-10 flex gap-4 global-page-actions`}>
      <div
        className="bg-blue-100 border border-gray-200 text-gray-700 px-4 py-1 rounded-full shadow-sm transition-all duration-300 ease-in-out"
      >
        {message}
      </div>
    </div>
  )
})

export default SaveDataPopup;
