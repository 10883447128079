import React from "react"

import Icon from "../../shared/Icon/Icon"

export const SECTION_WIDTH: { [key: string]: string } = {
  medium: 'width-medium',
  large: 'width-large',
  small: 'width-small'
}

export const SECTION_PADDING: { [key: string]: string } = {
  medium: 'vertical-padding-medium',
  large: 'vertical-padding-large',
  small: 'vertical-padding-small'
}

export type SectionStyleConfigType = {
  name: any,
  label: string | React.ReactElement,
}
export const SECTION_IMAGE_SIZING_OPTIONS: SectionStyleConfigType[] = [
  { name: "full_screen", label: "Full Screen" },
  { name: "adjustable", label: "Adjustable" },
]
export const BACKGROUND_TINT_KINDS: SectionStyleConfigType[] = [
  { name: "regular", label: "Regular" },
  { name: "blend", label: "Blend" },
]
export const SECTION_SIZING_OPTIONS: SectionStyleConfigType[] = [
  { name: "small", label: "S" },
  { name: "medium", label: "M" },
  { name: "large", label: "L" },
]
export const FONT_SIZING_OPTIONS: SectionStyleConfigType[] = [
  { name: "small", label: "S" },
  { name: "medium", label: "M" },
  { name: "large", label: "L" },
]
export const FONT_ALIGNMENT_OPTIONS: SectionStyleConfigType[] = [
  { name: "left", label: <Icon icon="faAlignLeft" /> },
  { name: "center", label: <Icon icon="faAlignCenter" /> },
  { name: "right", label: <Icon icon="faAlignRight" /> }
]
export const ANIMATION_SPEEDS: SectionStyleConfigType[] = [
  { name: "slow", label: "Slow" },
  { name: "medium", label: "Med" },
  { name: "fast", label: "Fast" },
]
export const ANIMATION_DIRECTIONS: SectionStyleConfigType[] = [
  { name: "left", label: <Icon icon="faArrowLeft" /> },
  { name: "right", label: <Icon icon="faArrowRight" /> },
  { name: "up", label: <Icon icon="faArrowUp" /> },
  { name: "down", label: <Icon icon="faArrowDown" /> },
]
export const ANIMATION_STYLES: SectionStyleConfigType[] = [
  { name: "fade", label: "Fade In" },
  { name: "slide", label: "Slide In" },
]
export const ANIMATION_TYPES: SectionStyleConfigType[] = [
  { name: "no-animation", label: "No Animation" },
  { name: "animate", label: "Animate Section" }
]

export interface SectionStyleTypes extends React.CSSProperties {
  '--section_plugin-background-color': string;
  '--section_plugin-background-image'?: string;
  '--section_plugin-background-filter'?: string;
  '--section_plugin-background-transform'?: string;
  '--section_plugin-background-blend-mode'?: string;
}
