import React, { memo } from "react"

import { WEIGHTS } from "../../../../../store/reducers/page_fonts/page_fonts.config"
import IconButton from "../../../../../shared/IconButton/IconButton"
import PopoverSelectorTitle from "../../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle"


type Props = {
  setActiveFontOption: (value: string) => void
  weight: number
  setWeight: (value: number) => void
  title: string,
  availableWeights: number[]
}

const FontWeightSelector = memo(({ setActiveFontOption, title, setWeight, weight, availableWeights }: Props) => {
  return (
    <>
      <IconButton
        icon="faArrowLeft"
        onClick={setActiveFontOption.bind(setActiveFontOption, 'default')}
        btnClassName="float-right mt-4 mr-4 btn btn-white btn-small"
        textAfter="Back"
        iconClassName="mr-2"
      />
      <div className="p-4">
        <PopoverSelectorTitle title={title} className="mt-6 mb-2" />
        <div className="flex flex-col gap-1">
          {WEIGHTS.map(({ value, title, fontClass }, i) => {
            return (
              <div key={i}>
                {availableWeights.includes(value) ? (
                  <button
                    onClick={setWeight.bind(setWeight, value)}
                    className={`flex justify-between hover:bg-gray-200 rounded-md p-2 w-full text-gray-700 text-left ${value === weight ? "bg-gray-300" : ""}`}
                  >
                    <span className={fontClass}>{title}</span>
                    <span className="font-light text-gray-500">{value}</span>
                  </button>
                ) : (
                  <div
                    className="flex justify-between rounded-md p-2 w-full text-gray-400 text-left bg-gray-50"
                  >
                    <span className={fontClass}> {title}</span>
                    <span className="font-light text-gray-300">{value}</span>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <IconButton
          icon="faCheck"
          onClick={setActiveFontOption.bind(setActiveFontOption, 'default')}
          btnClassName="btn btn-white btn-block mt-6"
          textAfter="Done"
          iconClassName="mr-2"
        />
      </div>
    </>
  )
})

export default FontWeightSelector
