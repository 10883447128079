import { Controller } from "@hotwired/stimulus"
import anime from 'animejs/lib/anime.es.js';
// import consumer from "../channels/consumer"
import { createConsumer } from '@rails/actioncable';

export default class extends Controller {
  static targets = ["text", "pagesLink"]

  connect() {
    this.watchForComplete();
    // this.textTargets.forEach(text => {
    //   text.innerHTML = text.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
    // });
    this.textTargets.forEach(text => {
      text.innerHTML = text.textContent.split(' ').map(word =>
        `<div class='inline-block'>${word.split('').map(letter => `<span class='letter'>${letter}</span>`).join('')}</div>`
      ).join(' ');
    });
    this.rotateText()
    this.observeDOMInsertion()
  }

  observeDOMInsertion() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((node) => {
            if (node.dataset?.sectionId) {
              node.scrollIntoView({ behavior: "smooth" });
            }
          });
        }
      });
    });

    // Options for the observer (which mutations to observe)
    let config = { attributes: false, childList: true, subtree: true };

    // Start observing the target node for configured mutations
    this.observer.observe(this.element, config);
  }

  disconnect() {
    this.observer.disconnect()
  }

  watchForComplete() {
    // console.log(`Watching for page completion: ${this.channel} ${this.idValue}`)
    let baseWss = document.body.dataset.wss
    const cable = createConsumer(`${baseWss}?processing=${this.channel}`);

    cable.subscriptions.create(
      { channel: this.channel, id: this.idValue },
      {
        received: (data) => {
          console.log(`Page finished processing: ${data}`)
          if (data.url) {
            window.location.href = data.url;
          }
        },
      }
    )
  }

  rotateText() {
    let index = 0;
    let self = this;
    const animateText = () => {
      this.textTargets[index].classList.remove("hidden")
      const target = `.flying-letters-${index} .letter`;
      const parent = `.flying-letters-${index}`;
      const finalOpacity = index === this.textTargets.length - 1 ? 1 : 0;
      anime.timeline({ loop: false })
        .add({
          targets: target,
          scale: [4, 1],
          opacity: [0, 1],
          translateZ: 0,
          easing: "easeOutExpo",
          duration: 950,
          delay: anime.stagger(40)
        }).add({
          targets: parent,
          opacity: finalOpacity,
          duration: 1000,
          easing: "easeOutExpo",
          delay: 5000,
          complete: () => {
            if (index <= this.textTargets.length - 2) {
              this.textTargets[index].classList.add("hidden")
              index++;
              if (index < this.textTargets.length) {
                animateText();
              }
            } else {
              self.showPagesLink();
            }
          }
        });
    }
    animateText();
  }

  showPagesLink() {
    this.pagesLinkTarget.classList.remove("hidden");
  }
}
