import { Controller } from "@hotwired/stimulus"

import { formatCurrency } from "../components/utils/calc_plugins.util"

export default class extends Controller {
  static targets = ["input", "error", "addSeats", "noSeats", "removeSeats", "proratedTotalDue",
    "additionalSeats", "additionalSeatsWord", "nextSubscriptionTotal", "totalSeats",
    "totalSeatsWord", "proratedTaxPerSeat", "proratedTaxTotalDue"];

  // connect() {
  //   console.log(`Manage seats. Available Seats: ${this.availableSeats}`)
  // }

  changeSeats(event) {
    event.preventDefault();

    const change = parseInt(event.currentTarget.dataset.change);
    let prevValue = parseInt(this.inputTarget.value)
    let newValue = prevValue + change;

    if (newValue + this.availableSeats < 0) {
      newValue = prevValue;
    }

    this.inputTarget.value = newValue;
    this.errorTarget.classList.add("hidden");
    this.handleSeatChange()
  }

  validateInput() {
    const inputValue = parseInt(this.inputTarget.value);

    if (inputValue + this.availableSeats < 0) {
      this.errorTarget.classList.remove("hidden");
      this.inputTarget.value = 1;
    } else {
      this.errorTarget.classList.add("hidden");
      this.handleSeatChange()
    }
  }

  handleSeatChange() {
    const inputValue = parseInt(this.inputTarget.value);

    // update the total due
    this.proratedTotalDueTargets.forEach((target) => {
      target.textContent = this.proratedTotalDue;
    })

    // update the additional seats in summary
    this.additionalSeatsTargets.forEach((target) => {
      target.textContent = Math.abs(inputValue);
    })

    // update monthly subscription price moving forward
    this.nextSubscriptionTotalTargets.forEach((target) => {
      target.textContent = this.nextSubscriptionTotalDue;
    })

    // update totalSeats number
    this.totalSeatsTargets.forEach((target) => {
      target.textContent = inputValue + this.currentSubscriptionSeats;
    })

    // check if value is positive or negative and apply the correct hidden and visible classes

    // update seatWord for singular or plural
    this.additionalSeatsWordTargets.forEach((target) => {
      target.textContent = this.seatWord(inputValue);
    })

    this.totalSeatsWordTargets.forEach((target) => {
      target.textContent = this.seatWord(inputValue + this.currentSubscriptionSeats);
    })

    this.proratedTaxTotalDueTargets.forEach((target) => {
      target.textContent = this.proratedTaxTotalDue;
    })

    if (inputValue === 0) {
      // need to hide all the elements in the target
      this.addSeatsTargets.forEach((target) => target.classList.add("hidden"));
      this.noSeatsTargets.forEach((target) => target.classList.remove("hidden"));
      this.removeSeatsTargets.forEach((target) => target.classList.add("hidden"));
    } else if (inputValue > 0) {
      this.addSeatsTargets.forEach((target) => target.classList.remove("hidden"));
      this.noSeatsTargets.forEach((target) => target.classList.add("hidden"));
      this.removeSeatsTargets.forEach((target) => target.classList.add("hidden"));
    } else if (inputValue < 0) {
      this.addSeatsTargets.forEach((target) => target.classList.add("hidden"));
      this.noSeatsTargets.forEach((target) => target.classList.add("hidden"));
      this.removeSeatsTargets.forEach((target) => target.classList.remove("hidden"));
    }
  }

  seatWord(count) {
    return count === 1 || count === -1 ? this.element.dataset.seatWord : this.element.dataset.seatsWord
  }

  get availableSeats() {
    return parseInt(this.element.dataset.availableSeats)
  }

  get currentSubscriptionSeats() {
    return parseInt(this.element.dataset.currentSubscriptionSeats)
  }

  get proratedSeatCost() {
    return parseInt(this.element.dataset.proratedSeatCost)
  }

  get subscriptionSeatCost() {
    return parseInt(this.element.dataset.subscriptionSeatCost)
  }

  get currency() {
    return this.element.dataset.currency
  }

  get proratedTaxPerSeat() {
    return parseInt(this.element.dataset.proratedTaxPerSeat)
  }

  get proratedTotalDue() {
    const amountCents = Math.abs(parseInt(this.inputTarget.value) * this.proratedSeatCost)
    return formatCurrency(amountCents / 100.0, this.currency)
  }

  get nextSubscriptionTotalDue() {
    const amountCents = (parseInt(this.inputTarget.value) + this.currentSubscriptionSeats) * this.subscriptionSeatCost;
    return formatCurrency(amountCents / 100.0, this.currency)
  }

  get proratedTaxTotalDue() {
    const amountCents = Math.abs(parseInt(this.inputTarget.value) * this.proratedTaxPerSeat)
    return formatCurrency(amountCents / 100.0, this.currency)
  }
}
