import React, { useEffect } from "react"

import { resetDataSavingState, resetImageProcessingState } from "../store/reducers/page_indicators/page_indicators"
import { useAppSelector, useAppDispatch } from "../store/hooks/redux-hooks"
import SaveDataPopup from "../shared/LoadingComponents/SaveDataPopup"
import ImageProcessingPopup from "../shared/LoadingComponents/ImageProcessing"
import { toggleMobilePreview } from "../store/reducers/page_addendums/page_addendums"
import TooltipButton from "../shared/ToolTip/TooltipButton";


const PageIndicators = () => {
  const {
    isDataSaving,
    isDataSaved,
    isImageProcessing,
    isImageProcessed
  } = useAppSelector(state => state.page_indicators)
  const { isMobilePreviewActive, readOnly } = useAppSelector(state => state.page_addendums)
  const dispatch = useAppDispatch()


  const onMobilePreviewChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(toggleMobilePreview())
  }

  useEffect(() => {
    if (isDataSaved) {
      setTimeout(() => {
        dispatch(resetDataSavingState())
      }, 2000)
    }
  }, [isDataSaved])

  useEffect(() => {
    if (isImageProcessed) {
      setTimeout(() => {
        dispatch(resetImageProcessingState())
      }, 2000)
    }
  }, [isImageProcessed])

  return (
    <>
      {!readOnly && isMobilePreviewActive &&
        <button
          onClick={onMobilePreviewChange}
          className="btn btn-large btn-white fixed top-[6rem] right-4 z-10 flex gap-4"
        >
          Hide Mobile Preview
        </button>
      }
      {readOnly &&
        <div className="fixed right-8 z-20 top-32">
          <div id="readonly_view_anchor" className="btn-group">
            <TooltipButton
              btnClassName={`btn btn-small btn-white p-5 ${isMobilePreviewActive ? 'bg-gray-50' : 'bg-gray-400'}`}
              icon='faMobile'
              onClick={onMobilePreviewChange}
              tooltip="Mobile Preview"
              side="top"
              container="readonly_view_anchor"
            />
            <TooltipButton
              btnClassName={`btn btn-small btn-white p-5 last-child ${isMobilePreviewActive ? 'bg-gray-400' : 'bg-gray-50'}`}
              icon='faLaptop'
              onClick={onMobilePreviewChange}
              tooltip="Desktop Preview"
              side="top"
              container="readonly_view_anchor"
            />
          </div>
        </div>
      }
      {isDataSaving &&
        <SaveDataPopup
          isMobilePreviewActive={isMobilePreviewActive}
          message={isDataSaved ? "Saved!" : "Saving..."}
        />
      }
      {isImageProcessing &&
        <ImageProcessingPopup
          isMobilePreviewActive={isMobilePreviewActive}
          message={isImageProcessed ? "Image uploaded!" : "Processing image..."}
        />
      }
    </>

  );
}

export default PageIndicators;