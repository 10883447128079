import { ColorData, PageData } from "../api/api.types";
import { PagePalette } from "./page_colors";

export const GLOBAL_COLOR_SELECTOR_DEFAULT_COLORS = ["#000000", "#ffffff"]

export const HEX_SELECTOR_INIT_COLORS = [
  "#e63946", "#f97316", "#eab308", "#22c55e", "#2fe1a6",
  "#06b6d4", "#008dd9", "#8b5cf6", "#ec4899", "#9c9ca2",
]

const buyerColors = (data: PageData) => {
  if ((!data.styles.buyer || !data.styles.buyer.colors) && data.template) { return ["#005582", "#acf3db"] }
  if (!data.styles.buyer || !data.styles.buyer.colors) { return [] }
  return data.styles.buyer.colors.map(color => {
    return color.hex
  })
}

const sellerColors = (data: PageData) => {
  if (!data.styles.seller || !data.styles.seller.seller_colors) { return [] }
  return data.styles.seller.seller_colors.map(color => {
    return color.hex
  })
}

const accountColors = (data: PageData) => {
  if (!data.styles.seller || !data.styles.seller.account_colors) { return [] }
  return data.styles.seller.account_colors.map(color => {
    return color.hex
  })
}

const customColors = (data: PageData) => {
  if (data.styles.colors.length > 0) {
    return data.styles.colors.map(color => {
      return color.hex
    })
  } else {
    return ["#008dd9", "#2fe1a6", "#e63946"]
  }
}

// Filter out white and black
const filterColors = (colors: string[]) => colors.filter(color => {
  return color !== "#ffffff" && color !== "#000000"
})

export const setAvailablePalettes = (data: PageData): PagePalette[] => {
  const buyerPaletteColors = filterColors(buyerColors(data))
  const sellerPaletteColors = filterColors(sellerColors(data))
  const accountPaletteColors = filterColors(accountColors(data))
  const customPaletteColors = filterColors(customColors(data))
  const uniqueAccountColors = !!data.styles?.seller?.account_colors

  return [
    { palette: 'buyer', label: 'Buyer', colors: buyerPaletteColors },
    { palette: 'seller', label: "Seller", colors: sellerPaletteColors },
    { palette: 'account', label: "Your Brand", colors: accountPaletteColors, uniqueAccountColors, },
    {
      palette: 'custom',
      label: "Custom",
      colors: customPaletteColors,
      colorData: data.styles.colors.length > 0
        ? data.styles.colors
        : [{ hex: '#008dd9', position: 0 }, { hex: "#2fe1a6", position: 1 }, { hex: "#e63946", position: 2 }] as ColorData[]
    },
  ]
}

export interface ColorAttributes {
  hex: string,
  position?: number,
  id?: string,
  _destroy?: boolean,
}

export function prepareColorAttributesForUpdate(color: string, position: number, customColorData: ColorData[]): ColorAttributes[] {
  const updatedCustomColorData = customColorData.map(data => {
    if (data.position === position) {
      return { id: data.id, hex: data.hex, _destroy: true }
    }
    return data
  })
  return [...updatedCustomColorData, { hex: color, position }]
}

export function prepareColorAttributesForDelete(color: string, customColorData: ColorData[]): ColorAttributes[] {
  const filteredCustomColorData = customColorData.filter(data => data.hex !== color).map((data, index) => {
    return { ...data, position: index }
  })
  const dataToDelete = customColorData.filter(data => data.hex === color).map(data => ({ ...data, _destroy: true }))
  return [...filteredCustomColorData, ...dataToDelete]
}

export function prepareColorAttributesForInsert(color: string, customColorData: ColorData[]): ColorAttributes[] {
  return [...customColorData, { hex: color, position: customColorData.length }]
}

export function prepareColorAttributesForOverride(activePalette: PagePalette, setAvailablePalettes: PagePalette[]): ColorAttributes[] {
  const currentColorData = setAvailablePalettes.find(palette => palette.palette === 'custom')?.colorData
  const dataToOverride = currentColorData
    ? currentColorData.map(data => ({ id: data.id, hex: data.hex, _destroy: true }))
    : []
  const newColorData = activePalette.colors.map((color, index) => ({ hex: color, position: index }))
  return [...dataToOverride, ...newColorData]
}