import React from 'react';
import { useEditorRef, focusEditor } from '@udecode/plate-common/react';
import { select, setMarks, getMarks } from '@udecode/plate-common';

import ToolbarSizeDropdown from '../../../shared/ToolbarControls/ToolbarSizeDropdown';

const FontSize = () => {
  const editor = useEditorRef()
  const marks = getMarks(editor)

  const onSizeChange = (font_size: string) => {
    setMarks(editor, { font_size });
    select(editor, editor.selection!);
    focusEditor(editor);
  }

  const activeOption = (marks?.font_size || 'medium') as string

  return <ToolbarSizeDropdown currentSize={activeOption} onSizeChange={onSizeChange} />
}

export default FontSize;
