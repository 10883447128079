import { createSlice } from '@reduxjs/toolkit'


interface PageIndicators {
  isDataSaving: boolean,
  isDataSaved: boolean,
  isImageProcessing: boolean,
  isImageProcessed: boolean,
}

const initialState: PageIndicators = {
  isDataSaving: false,
  isDataSaved: false,
  isImageProcessing: false,
  isImageProcessed: false,
}

export const pageIndicatorsSlice = createSlice({
  name: 'page_indicators',
  initialState,
  reducers: {
    setDataSaving: (state) => ({ ...state, isDataSaving: true }),
    setDataSaved: (state) => ({ ...state, isDataSaved: true }),
    resetDataSavingState: (state) => ({ ...state, isDataSaving: false, isDataSaved: false }),
    setImageProcessing: (state) => ({ ...state, isImageProcessing: true }),
    setImageProcessed: (state) => ({ ...state, isImageProcessed: true }),
    resetImageProcessingState: (state) => ({ ...state, isImageProcessing: false, isImageProcessed: false }),
  },
})

export const {
  setDataSaved,
  setDataSaving,
  resetDataSavingState,
  setImageProcessed,
  setImageProcessing,
  resetImageProcessingState
} = pageIndicatorsSlice.actions

export default pageIndicatorsSlice.reducer