import React, { useState } from 'react'

import IconButton from '../../shared/IconButton/IconButton'
import { useAppSelector } from '../../store/hooks/redux-hooks';

type Props = {
}


const NavBarMobile: React.FC<Props> = ({ }) => {
  const [mobileLinksVisible, setMobileLinksVisible] = useState(false)
  const navPageConfig = useAppSelector(state => state.page_navigation)
  const isEditorReadOnly = useAppSelector(state => state.page_addendums.readOnly)
  const isLandingPage = useAppSelector(state => state.page_addendums.isLandingPage)

  const { nav_text_color } = navPageConfig

  const isPreviewMode = isEditorReadOnly || isLandingPage

  const nav_router = isPreviewMode ? navPageConfig.nav_router.filter(route => route.status === 'active') : navPageConfig.nav_router

  return (
    <div>
      <IconButton icon='faBars'
        onClick={setMobileLinksVisible.bind(setMobileLinksVisible, !mobileLinksVisible)}
        btnClassName={`btn-nav-link ${nav_text_color ? nav_text_color : "color-black"}`}
      />
      <div className={`flex flex-col w-full gap-2 mt-4 ${mobileLinksVisible ? "" : "hidden"}`}>
        {nav_router.length > 1 && nav_router.map(route =>
          <a
            key={route.id}
            onClick={() => route.status === 'building' ? null : window.location.href = isLandingPage ? route.landing_link : route.builder_link}
            className={`block mobile-nav-link font-base relative ${nav_text_color ? nav_text_color : "color-black"} ${route.isActive ? 'active' : ''}`}
          >
            {route.name}
            {route.status === "draft" || route.status === 'building' && <div className="absolute top-2 right-2 w-2 h-2 rounded-full bg-red-500" />}
          </a>
        )}
      </div>
    </div>
  )
}

export default NavBarMobile