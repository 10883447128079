import Tribute from 'tributejs';
import axios from 'axios';

const reactEl = document.getElementById("react")
const pageId = reactEl?.dataset?.id || document.getElementById("page-id")?.dataset?.value

export const initTribute = (element, useHtml = true) => {
  const tributeConfig = {
    values: function (text, cb) {
      axios.get(`/core/pages/${pageId}/template_variables`)
        .then(response => {
          // Returns all variables. Need to filter by text. { name:, value:, about: }
          const variables = response.data;
          cb(variables)
        })
        .catch(error => console.log("Error fetching variable data:", error));
    },
    lookup: "name",
    trigger: "{",
    loadingItemTemplate: '<li class="loading">Loading...</li>',
    noMatchTemplate: '<li class="no-match">No Match Found</li>',
    menuItemTemplate: function (item) {
      return `
        <div>
          <div class="font-semibold text-sm">${item.original.name}</div>
          <div class="text-xs">${item.original.about}</div>
        </div>
      `;
    },
    selectTemplate: function (item) {
      if (useHtml && this.range.isContentEditable(this.current.element)) {
        return (
          `<span class="tribute-token badge primary">${item.original.value}</span>`
        );
      }

      return item.original.value;
    }
  };

  const tribute = new Tribute(tributeConfig);

  tribute.attach(element);

  // Only attach event listeners if the element is content editable
  if (element.isContentEditable) {
    attachEventListeners(element);
  }

  return tribute;
};

export const renderTributeTokens = (element) => {
  const tributeTokenRegex = /{{(.*?)}}/g;

  if (typeof element === "string") {
    return element.replace(tributeTokenRegex, (match, token) => {
      return `<span class="tribute-token badge primary">{{${token}}}</span>`;
    });
  } else if (element instanceof HTMLElement) {
    element.innerHTML = element.innerHTML.replace(tributeTokenRegex, (match, token) => {
      return `<span class="tribute-token badge primary">{{${token}}}</span>`;
    });
  } else {
    // Handle unexpected input types
    console.error('Invalid input for renderTributeTokens', element);
  }
}

const attachEventListeners = (element) => {
  element.addEventListener("keydown", lockVariableContent);
  element.addEventListener('paste', handleTributePasteEvent);
}

const lockVariableContent = (e) => {
  const selection = window.getSelection();
  const anchorNode = selection.anchorNode;
  // const range = selection.getRangeAt(0);

  if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || !selection.rangeCount) {
    return;
  }

  if (anchorNode && anchorNode.parentNode.classList.contains("tribute-token")) {
    // We are within the span that was added.
    const tributeSpan = anchorNode.parentNode;

    e.preventDefault();

    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      // We want this to jump to the beginning or end of the span.
      const newRange = document.createRange();
      if (e.key === 'ArrowLeft') {
        newRange.setStartBefore(tributeSpan);
        newRange.collapse(true);
      } else { // ArrowRight
        newRange.setStartAfter(tributeSpan);
        newRange.collapse(true);
      }
      selection.removeAllRanges();
      selection.addRange(newRange);

    } else if (e.key == 'Space' || e.keyCode == 32) {
      addSpaceAfterCursor()

    } else if (e.key === 'Backspace' || e.key === 'Delete') {
      tributeSpan.remove();
    }
  }
}

// {{EXAMPLE}} is an example of what gets pasted in
const handleTributePasteEvent = (event) => {
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedData = clipboardData.getData('Text');

  if (pastedData.includes('{{') && pastedData.includes('}}')) {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('Text');
    const formattedData = applyTributeToPastedContent(pastedData);
    insertHtmlAtCursor(formattedData);
  }
}

const applyTributeToPastedContent = (content) => {
  return renderTributeTokens(content); // Adjust this as needed
};

const insertHtmlAtCursor = (html) => {
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    range.deleteContents();

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    // Use a fragment to avoid reflow/repaint on each node insert
    const frag = document.createDocumentFragment();

    Array.from(tempDiv.childNodes).forEach(child => {
      frag.appendChild(child); // Append each child node to the fragment
    });

    range.insertNode(frag); // Insert the fragment into the range
  }

  addSpaceAfterCursor();
}

const addSpaceAfterCursor = () => {
  // It wasn't getting the correct tributeSpan without the setTimeout here.
  setTimeout(() => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const tributeSpan = range.endContainer.parentNode;

    if (tributeSpan && tributeSpan.classList.contains('tribute-token')) {
      const spaceNode = document.createTextNode('\u00A0'); // Unicode for &nbsp;
      const parentDiv = tributeSpan.parentNode;

      // Insert space after the tributeSpan
      parentDiv.insertBefore(spaceNode, tributeSpan.nextSibling);

      // Move cursor after the space
      range.setStartAfter(spaceNode);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, 20)
}
